export const LOAD_REPORT_REQUEST = 'LOAD_REPORT_REQUEST';
export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS';
export const LOAD_REPORT_FAILURE = 'LOAD_REPORT_FAILURE';

export const LOAD_REPORT_INFO_REQUEST = 'LOAD_REPORT_INFO_REQUEST';
export const LOAD_REPORT_INFO_SUCCESS = 'LOAD_REPORT_INFO_SUCCESS';
export const LOAD_REPORT_INFO_FAILURE = 'LOAD_REPORT_INFO_FAILURE';

export const LOAD_MULTI_REQUEST = 'LOAD_MULTI_REQUEST';
export const LOAD_MULTI_SUCCESS = 'LOAD_MULTI_SUCCESS';
export const LOAD_MULTI_FAILURE = 'LOAD_MULTI_FAILURE';

export const SAVE_REPORT_REQUEST = 'SAVE_REPORT_REQUEST';
export const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS';
export const SAVE_REPORT_FAILURE = 'SAVE_REPORT_FAILURE';

export const MODIFY_REPORT_REQUEST = 'MODIFY_REPORT_REQUEST';
export const MODIFY_REPORT_SUCCESS = 'MODIFY_REPORT_SUCCESS';
export const MODIFY_REPORT_FAILURE = 'MODIFY_REPORT_FAILURE';

export const REVIEW_RECIST_REQUEST = 'REVIEW_RECIST_REQUEST';
export const REVIEW_RECIST_SUCCESS = 'REVIEW_RECIST_SUCCESS';
export const REVIEW_RECIST_FAILURE = 'REVIEW_RECIST_FAILURE';

export const LOAD_REPORT_AUDIT_REQUEST = 'LOAD_REPORT_AUDIT_REQUEST';
export const LOAD_REPORT_AUDIT_SUCCESS = 'LOAD_REPORT_AUDIT_SUCCESS';
export const LOAD_REPORT_AUDIT_FAILURE = 'LOAD_REPORT_AUDIT_FAILURE';

export const LOAD_MULTI_AUDIT_REQUEST = 'LOAD_MULTI_AUDIT_REQUEST';
export const LOAD_MULTI_AUDIT_SUCCESS = 'LOAD_MULTI_AUDIT_SUCCESS';
export const LOAD_MULTI_AUDIT_FAILURE = 'LOAD_MULTI_AUDIT_FAILURE';

export const PICK_REPORT_REQUEST = 'PICK_REPORT_REQUEST';
export const PICK_REPORT_SUCCESS = 'PICK_REPORT_SUCCESS';
export const PICK_REPORT_FAILURE = 'PICK_REPORT_FAILURE';

export const PICK_INFO_REQUEST = 'PICK_INFO_REQUEST';
export const PICK_INFO_SUCCESS = 'PICK_INFO_SUCCESS';
export const PICK_INFO_FAILURE = 'PICK_INFO_FAILURE';

export const LOAD_TYPE_REQUEST = 'LOAD_TYPE_REQUEST';
export const LOAD_TYPE_SUCCESS = 'LOAD_TYPE_SUCCESS';
export const LOAD_TYPE_FAILURE = 'LOAD_TYPE_FAILURE';

export const loadReportAction = (timepointID, readerIndex, reportType) => {
    return {
        type: LOAD_REPORT_REQUEST,
        timepointID, readerIndex, reportType
    }
};

export const loadReportInfoAction = () => {
    return {
        type: LOAD_REPORT_INFO_REQUEST,
    }
};

export const loadMultiReportAction = (subjectID, reportType) => {
    return {
        type: LOAD_MULTI_REQUEST,
        subjectID, reportType
    }
};

export const saveReportAction = (data, timepointID, readerIndex, reportType, projectID) => {
    return {
        type: SAVE_REPORT_REQUEST,
        data, timepointID, readerIndex, reportType, projectID
    }
}

export const modifyReportAction = (data, timepointID, readerIndex, reportType, setVisible) => {
    return {
        type: MODIFY_REPORT_REQUEST,
        data, timepointID, readerIndex, reportType, setVisible
    }
}

export const reviewRecistAction = (data, setVisible) => {
    return {
        type: REVIEW_RECIST_REQUEST,
        data, setVisible
    }
}

export const loadReportAuditAction = (timepointID, readerIndex, reportType) => {
    return {
        type: LOAD_REPORT_AUDIT_REQUEST,
        timepointID, readerIndex, reportType,
    }
};

export const loadMultiAuditAction = (subjectID) => {
    return {
        type: LOAD_MULTI_AUDIT_REQUEST,
        subjectID
    }
};

export const pickReportAction = (data) => {
    return {
        type: PICK_REPORT_REQUEST,
        data,
    }
}

export const pickInfoAction = (data) => {
    return {
        type: PICK_INFO_REQUEST,
        data,
    }
}

export const loadTypeAction = () => {
    return {
        type: LOAD_TYPE_REQUEST,
    }
}