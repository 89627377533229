import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/mailAction';

function sendMailAPI(action) {
    return axios.post('/mail', action.data);
}

function* sendMail(action) {
    yield call(() => sendMailAPI(action));
    try {
        yield put({
            type: actionTypes.SEND_MAIL_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SEND_MAIL_FAILURE,
        })
    }
}
function* watchSendMail() {
    yield takeLatest(actionTypes.SEND_MAIL_REQUEST, sendMail);
}

export default function* loginSaga() {
    yield all([
        fork(watchSendMail),
    ])
}