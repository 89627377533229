import axios from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../_actions/queryAction';
import { message } from 'antd';

// Open Query //////////////////////////////////////////////////////////////////////////////////////////////////////////
function openQueryAPI(action) {
    const config = {headers: {'TI-DS-ID': action.data.sign.id, 'TI-DS-PW': action.data.sign.password}};
    return axios.post('/query/open', action.data.query, config);
}
function* openQuery(action) {
    try {
        const result = yield call(() => openQueryAPI(action));
        if(result.status === 200) {
            message.success({ content: 'Query submission is complete.'}).then();
            setTimeout(() => {
                action.data.setIsSpinning(false);
                action.data.setVisible(false);
            }, [1000]);
        }
        yield put({
            type: actionTypes.OPEN_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        action.data.setIsSpinning(false);
        yield put({
            type: actionTypes.OPEN_QUERY_FAILURE,
        });
    }
}
function* watchOpenQuery() {
    yield takeLatest(actionTypes.OPEN_QUERY_REQUEST, openQuery);
}

// Load Query //////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadQueryAPI() {
    return axios.get('/query');
}
function* loadQuery() {
    try {
        const result = yield call(loadQueryAPI);
        yield put({
            type: actionTypes.LOAD_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_QUERY_FAILURE,
        });
    }
}
function* watchLoadQuery() {
    yield takeLatest(actionTypes.LOAD_QUERY_REQUEST, loadQuery);
}

// Load To Query ///////////////////////////////////////////////////////////////////////////////////////////////////////
function loadToQueryAPI() {
    return axios.get('/query/to');
}
function* loadToQuery() {
    try {
        const result = yield call(loadToQueryAPI);
        yield put({
            type: actionTypes.LOAD_TO_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_TO_QUERY_FAILURE,
        });
    }
}
function* watchLoadToQuery() {
    yield takeLatest(actionTypes.LOAD_TO_QUERY_REQUEST, loadToQuery);
}

// Load From Query /////////////////////////////////////////////////////////////////////////////////////////////////////
function loadFromQueryAPI() {
    return axios.get('/query/from');
}
function* loadFromQuery() {
    try {
        const result = yield call(loadFromQueryAPI);
        yield put({
            type: actionTypes.LOAD_FROM_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_FROM_QUERY_FAILURE,
        });
    }
}
function* watchLoadFromQuery() {
    yield takeLatest(actionTypes.LOAD_FROM_QUERY_REQUEST, loadFromQuery);
}

// Close Query /////////////////////////////////////////////////////////////////////////////////////////////////////////
function closeQueryAPI(data) {
    const config = {headers: {'TI-DS-ID': data.sign.id, 'TI-DS-PW': data.sign.password}};
    return axios.post('/query/resolve/' + data.data, null, config);
}
function* closeQuery(action) {
    try {
        const result = yield call(() => closeQueryAPI(action.data));
        if(result.status === 200) {
            message.success({ content: 'Query is Closed.'}).then();
            action.data.setVisible(false);
        }
        yield put({
            type: actionTypes.CLOSE_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.CLOSE_QUERY_FAILURE,
        });
    }
}
function* watchCloseQuery() {
    yield takeLatest(actionTypes.CLOSE_QUERY_REQUEST, closeQuery);
}

// Reply Query /////////////////////////////////////////////////////////////////////////////////////////////////////////
function replyQueryAPI(action) {
    const config = {headers: {'TI-DS-ID': action.data.sign.id, 'TI-DS-PW': action.data.sign.password}};
    return axios.post('/query/reply/' + action.data.key, action.data.query, config);
}
function* replyQuery(action) {
    try {
        const result = yield call(() => replyQueryAPI(action));
        if(result.status === 200) {
            message.success({ content: 'Query submission is complete.'}).then();
            setTimeout(() => {
                action.data.setIsSpinning(false);
                action.data.setVisible(false);
                action.form.resetFields();
            }, [1000]);
        }
        yield put({
            type: actionTypes.REPLY_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        action.data.setIsSpinning(false);
        yield put({
            type: actionTypes.REPLY_QUERY_FAILURE,
        });
    }
}
function* watchReplyQuery() {
    yield takeLatest(actionTypes.REPLY_QUERY_REQUEST, replyQuery);
}

// Get Unread Query ////////////////////////////////////////////////////////////////////////////////////////////////////
function getUnreadQueryAPI() {
    return axios.get('/query/get-unread-cnt');
}
function* getUnreadQuery() {
    try {
        const result = yield call(getUnreadQueryAPI);
        yield put({
            type: actionTypes.GET_UNREAD_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_UNREAD_QUERY_FAILURE,
        });
    }
}
function* watchGetUnreadQuery() {
    yield takeLatest(actionTypes.GET_UNREAD_QUERY_REQUEST, getUnreadQuery);
}

// Read Query //////////////////////////////////////////////////////////////////////////////////////////////////////////
function readQueryAPI(action) {
    return axios.put('/query/read/' + action.url + action.data);
}
function* readQuery(action) {
    const result = yield call(() => readQueryAPI(action));
    try {
        yield put({
            type: actionTypes.READ_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.READ_QUERY_FAILURE,
        });
    }
}
function* watchReadQuery() {
    yield takeLatest(actionTypes.READ_QUERY_REQUEST, readQuery);
}

// Load Project ////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadProjectAPI() {
    return axios.get('/project/get-list');
}
function* loadProject() {
    try {
        const result = yield call(loadProjectAPI);
        yield put({
            type: actionTypes.LOAD_PROJECT_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_PROJECT_QUERY_FAILURE,
        });
    }
}
function* watchLoadProject() {
    yield takeLatest(actionTypes.LOAD_PROJECT_QUERY_REQUEST, loadProject);
}

// Load Subject ////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadSubjectAPI(data) {
    return axios.get('/subject/get-list/' + data);
}
function* loadSubject(action) {
    try {
        const result = yield call(() => loadSubjectAPI(action.data));
        yield put({
            type: actionTypes.LOAD_SUBJECT_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_SUBJECT_QUERY_FAILURE,
        });
    }
}
function* watchLoadSubject() {
    yield takeLatest(actionTypes.LOAD_SUBJECT_QUERY_REQUEST, loadSubject);
}

// Select Subject //////////////////////////////////////////////////////////////////////////////////////////////////////
function selectSubjectAPI(data) {
    return axios.get('/time-point/get-list-with-child/' + data);
}

function* selectSubject(action) {
    try {
        const result = yield call(() => selectSubjectAPI(action.data));
        yield put({
            type: actionTypes.SELECT_SUBJECT_QUERY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SELECT_SUBJECT_QUERY_FAILURE,
        });
    }
}
function* watchSelectSubject() {
    yield takeLatest(actionTypes.SELECT_SUBJECT_QUERY_REQUEST, selectSubject);
}

// Clear Subject ///////////////////////////////////////////////////////////////////////////////////////////////////////
function* clearSubject() {
    try {
        yield put({
            type: actionTypes.CLEAR_SUBJECT_QUERY_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.CLEAR_SUBJECT_QUERY_FAILURE,
        });
    }
}
function* watchClearSubject() {
    yield takeLatest(actionTypes.CLEAR_SUBJECT_QUERY_REQUEST, clearSubject);
}

export default function* querySaga() {
    yield all([
        fork(watchOpenQuery),
        fork(watchLoadQuery),
        fork(watchLoadToQuery),
        fork(watchLoadFromQuery),
        fork(watchCloseQuery),
        fork(watchReplyQuery),
        fork(watchGetUnreadQuery),
        fork(watchReadQuery),
        fork(watchLoadProject),
        fork(watchLoadSubject),
        fork(watchSelectSubject),
        fork(watchClearSubject),
    ])
}