export const LOAD_QC_REQUEST = 'LOAD_QC_REQUEST';
export const LOAD_QC_SUCCESS = 'LOAD_QC_SUCCESS';
export const LOAD_QC_FAILURE = 'LOAD_QC_FAILURE';

export const SAVE_QC_REQUEST = 'SAVE_QC_REQUEST';
export const SAVE_QC_SUCCESS = 'SAVE_QC_SUCCESS';
export const SAVE_QC_FAILURE = 'SAVE_QC_FAILURE';

export const LOAD_PSQ_REQUEST = 'LOAD_PSQ_REQUEST';
export const LOAD_PSQ_SUCCESS = 'LOAD_PSQ_SUCCESS';
export const LOAD_PSQ_FAILURE = 'LOAD_PSQ_FAILURE';

export const MODIFY_QC_REQUEST = 'MODIFY_QC_REQUEST';
export const MODIFY_QC_SUCCESS = 'MODIFY_QC_SUCCESS';
export const MODIFY_QC_FAILURE = 'MODIFY_QC_FAILURE';

export const RE_QC_REQUEST = 'RE_QC_REQUEST';
export const RE_QC_SUCCESS = 'RE_QC_SUCCESS';
export const RE_QC_FAILURE = 'RE_QC_FAILURE';

export const loadQCAction = (setIsLoading) => {
    return {
        type: LOAD_QC_REQUEST,
        setIsLoading,
    }
}

export const saveQCAction = (data, setVisible, setSpinning, mail) => {
    return {
        type: SAVE_QC_REQUEST,
        data,
        setVisible,
        setSpinning,
        mail
    }
};

export const loadPSQAction = (projectID, siteID) => {
    return {
        type: LOAD_PSQ_REQUEST,
        projectID, siteID
    }
};

export const modifyQCAction = (data, setVisible) => {
    return {
        type: MODIFY_QC_REQUEST,
        data, setVisible
    }
};

export const reQCAction = (timePointID, sign, comment, setVisible) => {
    return {
        type: RE_QC_REQUEST,
        timePointID, sign, comment, setVisible
    }
};