export const UPLOAD_DICOM_REQUEST = 'UPLOAD_DICOM_REQUEST';
export const UPLOAD_DICOM_SUCCESS = 'UPLOAD_DICOM_SUCCESS';
export const UPLOAD_DICOM_FAILURE = 'UPLOAD_DICOM_FAILURE';

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';

export const uploadDicomAction = (data, sign, setProgress, array, values, setViewUploadProgress, setIsUpload, seriesArray, selectList, dispatch, uploadLength, projectID, setSuccessModal, totalLength, result) => {
    return {
        type: UPLOAD_DICOM_REQUEST,
        data,
        sign, setProgress, array, values, setViewUploadProgress, setIsUpload, seriesArray, selectList, dispatch, uploadLength, projectID, setSuccessModal, totalLength, result
    }
};

export const uploadImageAction = (data, sign, setIsUploading, setUploadState, setViewPercentage, setPercentage, dispatch, setSuccessModal, projectID) => {
    return {
        type: UPLOAD_IMAGE_REQUEST,
        data, sign, setIsUploading, setUploadState, setViewPercentage, setPercentage, dispatch, setSuccessModal, projectID
    }
};