import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/siteAction';

// Load Site ///////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadSiteAPI() {
    return axios.get('/site/get-list');
}

function* loadSite() {
    const result = yield call(loadSiteAPI);
    try {
        yield put({
            type: actionTypes.LOAD_SITE_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_SITE_FAILURE,
        });
    }
}
function* watchLoadSite() {
    yield takeLatest(actionTypes.LOAD_SITE_REQUEST, loadSite);
}

// ADD Site ////////////////////////////////////////////////////////////////////////////////////////////////////////////
function addSiteAPI(data) {
    return axios.post('/site', data);
}

function* addSite(action) {
    const result = yield call(() => addSiteAPI(action.data));
    try {
        yield put({
            type: actionTypes.ADD_SITE_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.ADD_SITE_FAILURE,
        });
    }
}
function* watchAddSite() {
    yield takeLatest(actionTypes.ADD_SITE_REQUEST, addSite);
}


// Edit Site ///////////////////////////////////////////////////////////////////////////////////////////////////////////
function editSiteAPI(data) {
    return axios.put('/site', data);
}

function* editSite(action) {
    const result = yield call(() => editSiteAPI(action.data));
    try {
        yield put({
            type: actionTypes.EDIT_SITE_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.EDIT_SITE_FAILURE,
        });
    }
}
function* watchEditSite() {
    yield takeLatest(actionTypes.EDIT_SITE_REQUEST, editSite);
}


// Delete Site /////////////////////////////////////////////////////////////////////////////////////////////////////////
function deleteSiteAPI(data) {
    return axios.delete('/site/' + data);
}

function* deleteSite(action) {
    yield call(() => deleteSiteAPI(action.data.id));
    try {
        yield put({
            type: actionTypes.DELETE_SITE_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DELETE_SITE_FAILURE,
        });
    }
}
function* watchDeleteSite() {
    yield takeLatest(actionTypes.DELETE_SITE_REQUEST, deleteSite);
}



export default function* loginSaga() {
    yield all([
        fork(watchLoadSite),
        fork(watchAddSite),
        fork(watchEditSite),
        fork(watchDeleteSite),
    ])
}