import * as actionTypes from '../_actions/dmAction';

export const initialState = {
    projectList: [],
    projectSearchResultList: [],
    correlationData: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_PROJECT_SUCCESS :
            return {
                ...state,
                projectList: [...action.payload],
            };
        case actionTypes.LOAD_PROJECT_FAILURE :
            return {
                ...state,
            };
        case actionTypes.SEARCH_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SEARCH_PROJECT_SUCCESS :
            return {
                ...state,
                projectSearchResultList: [...action.payload],
            };
        case actionTypes.SEARCH_PROJECT_FAILURE :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_SDTM_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_SDTM_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_SDTM_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DOWNLOAD_MMF_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_MMF_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_MMF_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DATABASE_LOCK_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DATABASE_LOCK_SUCCESS :
            return {
                ...state,
                projectList: [...state.projectList.map(v => {
                    if(v.id === action.payload.id) return action.payload;
                    else return v;
                })]
            };
        case actionTypes.DATABASE_LOCK_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DATABASE_UNLOCK_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DATABASE_UNLOCK_SUCCESS :
            return {
                ...state,
                projectList: [...state.projectList.map(v => {
                    if(v.id === action.payload.id) return action.payload;
                    else return v;
                })]
            };
        case actionTypes.DATABASE_UNLOCK_FAILURE :
            return {
                ...state,
            };

        case actionTypes.GET_CORRELATION_REQUEST :
            return {
                ...state,
            };
        case actionTypes.GET_CORRELATION_SUCCESS :
            return {
                ...state,
                correlationData: action.payload,
            };
        case actionTypes.GET_CORRELATION_FAILURE :
            return {
                ...state,
            };

        default:
            return {
                ...state
            };
    }
};

export default reducer;