export const LOAD_LOG_REQUEST = 'LOAD_LOG_REQUEST';
export const LOAD_LOG_SUCCESS = 'LOAD_LOG_SUCCESS';
export const LOAD_LOG_FAILURE = 'LOAD_LOG_FAILURE';

export const DOWN_LOG_REQUEST = 'DOWN_LOG_REQUEST';
export const DOWN_LOG_SUCCESS = 'DOWN_LOG_SUCCESS';
export const DOWN_LOG_FAILURE = 'DOWN_LOG_FAILURE';

export const loadLogAction = () => {
    return {
        type: LOAD_LOG_REQUEST,
    }
};

export const downLogAction = () => {
    return {
        type: DOWN_LOG_REQUEST,
    }
};

