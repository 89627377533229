export const OPEN_QUERY_REQUEST = 'OPEN_QUERY_REQUEST';
export const OPEN_QUERY_SUCCESS = 'OPEN_QUERY_SUCCESS';
export const OPEN_QUERY_FAILURE = 'OPEN_QUERY_FAILURE';

export const LOAD_QUERY_REQUEST = 'LOAD_QUERY_REQUEST';
export const LOAD_QUERY_SUCCESS = 'LOAD_QUERY_SUCCESS';
export const LOAD_QUERY_FAILURE = 'LOAD_QUERY_FAILURE';

export const LOAD_TO_QUERY_REQUEST = 'LOAD_TO_QUERY_REQUEST';
export const LOAD_TO_QUERY_SUCCESS = 'LOAD_TO_QUERY_SUCCESS';
export const LOAD_TO_QUERY_FAILURE = 'LOAD_TO_QUERY_FAILURE';

export const LOAD_FROM_QUERY_REQUEST = 'LOAD_FROM_QUERY_REQUEST';
export const LOAD_FROM_QUERY_SUCCESS = 'LOAD_FROM_QUERY_SUCCESS';
export const LOAD_FROM_QUERY_FAILURE = 'LOAD_FROM_QUERY_FAILURE';

export const CLOSE_QUERY_REQUEST = 'CLOSE_QUERY_REQUEST';
export const CLOSE_QUERY_SUCCESS = 'CLOSE_QUERY_SUCCESS';
export const CLOSE_QUERY_FAILURE = 'CLOSE_QUERY_FAILURE';

export const REPLY_QUERY_REQUEST = 'REPLY_QUERY_REQUEST';
export const REPLY_QUERY_SUCCESS = 'REPLY_QUERY_SUCCESS';
export const REPLY_QUERY_FAILURE = 'REPLY_QUERY_FAILURE';

export const GET_UNREAD_QUERY_REQUEST = 'GET_UNREAD_QUERY_REQUEST';
export const GET_UNREAD_QUERY_SUCCESS = 'GET_UNREAD_QUERY_SUCCESS';
export const GET_UNREAD_QUERY_FAILURE = 'GET_UNREAD_QUERY_FAILURE';

export const READ_QUERY_REQUEST = 'READ_QUERY_REQUEST';
export const READ_QUERY_SUCCESS = 'READ_QUERY_SUCCESS';
export const READ_QUERY_FAILURE = 'READ_QUERY_FAILURE';

export const LOAD_PROJECT_QUERY_REQUEST = 'LOAD_PROJECT_QUERY_REQUEST';
export const LOAD_PROJECT_QUERY_SUCCESS = 'LOAD_PROJECT_QUERY_SUCCESS';
export const LOAD_PROJECT_QUERY_FAILURE = 'LOAD_PROJECT_QUERY_FAILURE';

export const LOAD_SUBJECT_QUERY_REQUEST = 'LOAD_SUBJECT_QUERY_REQUEST';
export const LOAD_SUBJECT_QUERY_SUCCESS = 'LOAD_SUBJECT_QUERY_SUCCESS';
export const LOAD_SUBJECT_QUERY_FAILURE = 'LOAD_SUBJECT_QUERY_FAILURE';

export const SELECT_SUBJECT_QUERY_REQUEST = 'SELECT_SUBJECT_QUERY_REQUEST';
export const SELECT_SUBJECT_QUERY_SUCCESS = 'SELECT_SUBJECT_QUERY_SUCCESS';
export const SELECT_SUBJECT_QUERY_FAILURE = 'SELECT_SUBJECT_QUERY_FAILURE';

export const CLEAR_SUBJECT_QUERY_REQUEST = 'CLEAR_SUBJECT_QUERY_REQUEST';
export const CLEAR_SUBJECT_QUERY_SUCCESS = 'CLEAR_SUBJECT_QUERY_SUCCESS';
export const CLEAR_SUBJECT_QUERY_FAILURE = 'CLEAR_SUBJECT_QUERY_FAILURE';

export const openQueryAction = (data) => {
    return {
        type: OPEN_QUERY_REQUEST,
        data,
    }
}

export const loadQueryAction = () => {
    return {
        type: LOAD_QUERY_REQUEST,
    }
}

export const loadToQueryAction = () => {
    return {
        type: LOAD_TO_QUERY_REQUEST,
    }
}

export const loadFromQueryAction = () => {
    return {
        type: LOAD_FROM_QUERY_REQUEST,
    }
}

export const closeQueryAction = (data) => {
    return {
        type: CLOSE_QUERY_REQUEST,
        data,
    }
}

export const replyQueryAction = (data, form) => {
    return {
        type: REPLY_QUERY_REQUEST,
        data, form
    }
}

export const getUnreadQueryAction = () => {
    return {
        type: GET_UNREAD_QUERY_REQUEST,
    }
}

export const readQueryAction = (data, url) => {
    return {
        type: READ_QUERY_REQUEST,
        data, url
    }
}

export const loadProjectActionInQuery = () => {
    return {
        type: LOAD_PROJECT_QUERY_REQUEST,
    }
}

export const loadSubjectActionInQuery = (data) => {
    return {
        type: LOAD_SUBJECT_QUERY_REQUEST,
        data,
    }
}

export const selectSubjectActionInQuery = (data) => {
    return {
        type: SELECT_SUBJECT_QUERY_REQUEST,
        data,
    }
}

export const clearSubjectInQuery = () => {
    return {
        type: CLEAR_SUBJECT_QUERY_REQUEST,
    }
}