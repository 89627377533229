import React from 'react';
import { convertBytes } from './convert-bytes';

const MAGIKEY_OFFSET = 128;
//Dicom File임을 알려주는 key value
const MAGICKEY = 'DICM';
//SQ itme의 종류 teg
const SequenceDelimitationItem = '0xFFFEE0DD';
//itme의 시작 teg
const ITEM = '0xFFFEE000';
// bigEndian방식인지 check하는 변수


export class IAidDicomLib {

    /**
     * Class 초기화
     */
    constructor() {
        /**
         * DICOM tag dictionary.
         * Generated using xml standard conversion
         *  from {@link https://github.com/ivmartel/dcmbench/tree/master/view/part06}
         *  with {@link http://medical.nema.org/medical/dicom/current/source/docbook/part06/part06.xml}
         * Conversion changes:
         * - (vr) "See Note" -> "NONE", "OB" -> "ox", "US" -> "xs"
         */
        this.dic = {
            '0x00020000': { 'vm': '1', 'vr': 'UL', name: 'FileMetaInformationGroupLength' },
            '0x00020001': { 'vm': '1', 'vr': 'OB', name: 'FileMetaInformationVersion' },
            '0x00020002': { 'vm': '1', 'vr': 'UI', name: 'MediaStorageSOPClassUID' },
            '0x00020003': { 'vm': '1', 'vr': 'UI', name: 'MediaStorageSOPInstanceUID' },
            '0x00020010': { 'vm': '1', 'vr': 'UI', name: 'TransferSyntaxUID' },
            '0x00020012': { 'vm': '1', 'vr': 'UI', name: 'ImplementationClassUID' },
            '0x00020013': { 'vm': '1', 'vr': 'SH', name: 'ImplementationVersionName' },
            '0x00020016': { 'vm': '1', 'vr': 'AE', name: 'SourceApplicationEntityTitle' },
            '0x00020017': { 'vm': '1', 'vr': 'AE', name: 'SendingApplicationEntityTitle' },
            '0x00020018': { 'vm': '1', 'vr': 'AE', name: 'ReceivingApplicationEntityTitle' },
            '0x00020100': { 'vm': '1', 'vr': 'UI', name: 'PrivateInformationCreatorUID' },
            '0x00020102': { 'vm': '1', 'vr': 'OB', name: 'PrivateInformation' },
            '0x00041130': { 'vm': '1', 'vr': 'CS', name: 'FilesetID' },
            '0x00041141': { 'vm': '1-8', 'vr': 'CS', name: 'FilesetDescriptorFileID' },
            '0x00041142': { 'vm': '1', 'vr': 'CS', name: 'SpecificCharacterSetofFilesetDescriptorFile' },
            '0x00041200': { 'vm': '1', 'vr': 'UL', name: 'OffsetoftheFirstDirectoryRecordoftheRootDirectoryEntity' },
            '0x00041202': { 'vm': '1', 'vr': 'UL', name: 'OffsetoftheLastDirectoryRecordoftheRootDirectoryEntity' },
            '0x00041212': { 'vm': '1', 'vr': 'US', name: 'FilesetConsistencyFlag' },
            '0x00041220': { 'vm': '1', 'vr': 'SQ', name: 'DirectoryRecordSequence' },
            '0x00041400': { 'vm': '1', 'vr': 'UL', name: 'OffsetoftheNextDirectoryRecord' },
            '0x00041410': { 'vm': '1', 'vr': 'US', name: 'RecordInuseFlag' },
            '0x00041420': { 'vm': '1', 'vr': 'UL', name: 'OffsetofReferencedLowerLevelDirectoryEntity' },
            '0x00041430': { 'vm': '1', 'vr': 'CS', name: 'DirectoryRecordType' },
            '0x00041432': { 'vm': '1', 'vr': 'UI', name: 'PrivateRecordUID' },
            '0x00041500': { 'vm': '1-8', 'vr': 'CS', name: 'ReferencedFileID' },
            '0x00041504': { 'vm': '1', 'vr': 'UL', name: 'MRDRDirectoryRecordOffset' },
            '0x00041510': { 'vm': '1', 'vr': 'UI', name: 'ReferencedSOPClassUIDinFile' },
            '0x00041511': { 'vm': '1', 'vr': 'UI', name: 'ReferencedSOPInstanceUIDinFile' },
            '0x00041512': { 'vm': '1', 'vr': 'UI', name: 'ReferencedTransferSyntaxUIDinFile' },
            '0x0004151A': { 'vm': '1-n', 'vr': 'UI', name: 'ReferencedRelatedGeneralSOPClassUIDinFile' },
            '0x00041600': { 'vm': '1', 'vr': 'UL', name: 'NumberofReferences' },
            '0x00080001': { 'vm': '1', 'vr': 'UL', name: 'LengthtoEnd' },
            '0x00080005': { 'vm': '1-n', 'vr': 'CS', name: 'SpecificCharacterSet' },
            '0x00080006': { 'vm': '1', 'vr': 'SQ', name: 'LanguageCodeSequence' },
            '0x00080008': { 'vm': '2-n', 'vr': 'CS', name: 'ImageType' },
            '0x00080010': { 'vm': '1', 'vr': 'SH', name: 'RecognitionCode' },
            '0x00080012': { 'vm': '1', 'vr': 'DA', name: 'InstanceCreationDate' },
            '0x00080013': { 'vm': '1', 'vr': 'TM', name: 'InstanceCreationTime' },
            '0x00080014': { 'vm': '1', 'vr': 'UI', name: 'InstanceCreatorUID' },
            '0x00080015': { 'vm': '1', 'vr': 'DT', name: 'InstanceCoercionDateTime' },
            '0x00080016': { 'vm': '1', 'vr': 'UI', name: 'SOPClassUID' },
            '0x00080018': { 'vm': '1', 'vr': 'UI', name: 'SOPInstanceUID' },
            '0x0008001A': { 'vm': '1-n', 'vr': 'UI', name: 'RelatedGeneralSOPClassUID' },
            '0x0008001B': { 'vm': '1', 'vr': 'UI', name: 'OriginalSpecializedSOPClassUID' },
            '0x00080020': { 'vm': '1', 'vr': 'DA', name: 'StudyDate' },
            '0x00080021': { 'vm': '1', 'vr': 'DA', name: 'SeriesDate' },
            '0x00080022': { 'vm': '1', 'vr': 'DA', name: 'AcquisitionDate' },
            '0x00080023': { 'vm': '1', 'vr': 'DA', name: 'ContentDate' },
            '0x00080024': { 'vm': '1', 'vr': 'DA', name: 'OverlayDate' },
            '0x00080025': { 'vm': '1', 'vr': 'DA', name: 'CurveDate' },
            '0x0008002A': { 'vm': '1', 'vr': 'DT', name: 'AcquisitionDateTime' },
            '0x00080030': { 'vm': '1', 'vr': 'TM', name: 'StudyTime' },
            '0x00080031': { 'vm': '1', 'vr': 'TM', name: 'SeriesTime' },
            '0x00080032': { 'vm': '1', 'vr': 'TM', name: 'AcquisitionTime' },
            '0x00080033': { 'vm': '1', 'vr': 'TM', name: 'ContentTime' },
            '0x00080034': { 'vm': '1', 'vr': 'TM', name: 'OverlayTime' },
            '0x00080035': { 'vm': '1', 'vr': 'TM', name: 'CurveTime' },
            '0x00080040': { 'vm': '1', 'vr': 'US', name: 'DataSetType' },
            '0x00080041': { 'vm': '1', 'vr': 'LO', name: 'DataSetSubtype' },
            '0x00080042': { 'vm': '1', 'vr': 'CS', name: 'NuclearMedicineSeriesType' },
            '0x00080050': { 'vm': '1', 'vr': 'SH', name: 'AccessionNumber' },
            '0x00080051': { 'vm': '1', 'vr': 'SQ', name: 'IssuerofAccessionNumberSequence' },
            '0x00080052': { 'vm': '1', 'vr': 'CS', name: 'QueryRetrieveLevel' },
            '0x00080053': { 'vm': '1', 'vr': 'CS', name: 'QueryRetrieveView' },
            '0x00080054': { 'vm': '1-n', 'vr': 'AE', name: 'RetrieveAETitle' },
            '0x00080055': { 'vm': '1', 'vr': 'AE', name: 'StationAETitle' },
            '0x00080056': { 'vm': '1', 'vr': 'CS', name: 'InstanceAvailability' },
            '0x00080058': { 'vm': '1-n', 'vr': 'UI', name: 'FailedSOPInstanceUIDList' },
            '0x00080060': { 'vm': '1', 'vr': 'CS', name: 'Modality' },
            '0x00080061': { 'vm': '1-n', 'vr': 'CS', name: 'ModalitiesinStudy' },
            '0x00080062': { 'vm': '1-n', 'vr': 'UI', name: 'SOPClassesinStudy' },
            '0x00080063': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicRegionsinStudyCodeSequence' },
            '0x00080064': { 'vm': '1', 'vr': 'CS', name: 'ConversionType' },
            '0x00080068': { 'vm': '1', 'vr': 'CS', name: 'PresentationIntentType' },
            '0x00080070': { 'vm': '1', 'vr': 'LO', name: 'Manufacturer' },
            '0x00080080': { 'vm': '1', 'vr': 'LO', name: 'InstitutionName' },
            '0x00080081': { 'vm': '1', 'vr': 'ST', name: 'InstitutionAddress' },
            '0x00080082': { 'vm': '1', 'vr': 'SQ', name: 'InstitutionCodeSequence' },
            '0x00080090': { 'vm': '1', 'vr': 'PN', name: 'ReferringPhysiciansName' },
            '0x00080092': { 'vm': '1', 'vr': 'ST', name: 'ReferringPhysiciansAddress' },
            '0x00080094': { 'vm': '1-n', 'vr': 'SH', name: 'ReferringPhysiciansTelephoneNumbers' },
            '0x00080096': { 'vm': '1', 'vr': 'SQ', name: 'ReferringPhysicianIdentificationSequence' },
            '0x0008009C': { 'vm': '1-n', 'vr': 'PN', name: 'ConsultingPhysiciansName' },
            '0x0008009D': { 'vm': '1', 'vr': 'SQ', name: 'ConsultingPhysicianIdentificationSequence' },
            '0x00080100': { 'vm': '1', 'vr': 'SH', name: 'CodeValue' },
            '0x00080101': { 'vm': '1', 'vr': 'LO', name: 'ExtendedCodeValue' },
            '0x00080102': { 'vm': '1', 'vr': 'SH', name: 'CodingSchemeDesignator' },
            '0x00080103': { 'vm': '1', 'vr': 'SH', name: 'CodingSchemeVersion' },
            '0x00080104': { 'vm': '1', 'vr': 'LO', name: 'CodeMeaning' },
            '0x00080105': { 'vm': '1', 'vr': 'CS', name: 'MappingResource' },
            '0x00080106': { 'vm': '1', 'vr': 'DT', name: 'ContextGroupVersion' },
            '0x00080107': { 'vm': '1', 'vr': 'DT', name: 'ContextGroupLocalVersion' },
            '0x00080108': { 'vm': '1', 'vr': 'LT', name: 'ExtendedCodeMeaning' },
            '0x00080109': { 'vm': '1', 'vr': 'SQ', name: 'CodingSchemeResourcesSequence' },
            '0x0008010A': { 'vm': '1', 'vr': 'CS', name: 'CodingSchemeURLType' },
            '0x0008010B': { 'vm': '1', 'vr': 'CS', name: 'ContextGroupExtensionFlag' },
            '0x0008010C': { 'vm': '1', 'vr': 'UI', name: 'CodingSchemeUID' },
            '0x0008010D': { 'vm': '1', 'vr': 'UI', name: 'ContextGroupExtensionCreatorUID' },
            '0x0008010E': { 'vm': '1', 'vr': 'UR', name: 'CodingSchemeURL' },
            '0x0008010F': { 'vm': '1', 'vr': 'CS', name: 'ContextIdentifier' },
            '0x00080110': { 'vm': '1', 'vr': 'SQ', name: 'CodingSchemeIdentificationSequence' },
            '0x00080112': { 'vm': '1', 'vr': 'LO', name: 'CodingSchemeRegistry' },
            '0x00080114': { 'vm': '1', 'vr': 'ST', name: 'CodingSchemeExternalID' },
            '0x00080115': { 'vm': '1', 'vr': 'ST', name: 'CodingSchemeName' },
            '0x00080116': { 'vm': '1', 'vr': 'ST', name: 'CodingSchemeResponsibleOrganization' },
            '0x00080117': { 'vm': '1', 'vr': 'UI', name: 'ContextUID' },
            '0x00080118': { 'vm': '1', 'vr': 'UI', name: 'MappingResourceUID' },
            '0x00080119': { 'vm': '1', 'vr': 'UC', name: 'LongCodeValue' },
            '0x00080120': { 'vm': '1', 'vr': 'UR', name: 'URNCodeValue' },
            '0x00080121': { 'vm': '1', 'vr': 'SQ', name: 'EquivalentCodeSequence' },
            '0x00080122': { 'vm': '1', 'vr': 'LO', name: 'MappingResourceName' },
            '0x00080123': { 'vm': '1', 'vr': 'SQ', name: 'ContextGroupIdentificationSequence' },
            '0x00080124': { 'vm': '1', 'vr': 'SQ', name: 'MappingResourceIdentificationSequence' },
            '0x00080201': { 'vm': '1', 'vr': 'SH', name: 'TimezoneOffsetFromUTC' },
            '0x00080220': { 'vm': '1', 'vr': 'SQ', name: 'ResponsibleGroupCodeSequence' },
            '0x00080221': { 'vm': '1', 'vr': 'CS', name: 'EquipmentModality' },
            '0x00080222': { 'vm': '1', 'vr': 'LO', name: 'ManufacturersRelatedModelGroup' },
            '0x00080300': { 'vm': '1', 'vr': 'SQ', name: 'PrivateDataElementCharacteristicsSequence' },
            '0x00080301': { 'vm': '1', 'vr': 'US', name: 'PrivateGroupReference' },
            '0x00080302': { 'vm': '1', 'vr': 'LO', name: 'PrivateCreatorReference' },
            '0x00080303': { 'vm': '1', 'vr': 'CS', name: 'BlockIdentifyingInformationStatus' },
            '0x00080304': { 'vm': '1-n', 'vr': 'US', name: 'NonidentifyingPrivateElements' },
            '0x00080306': { 'vm': '1-n', 'vr': 'US', name: 'IdentifyingPrivateElements' },
            '0x00080305': { 'vm': '1', 'vr': 'SQ', name: 'DeidentificationActionSequence' },
            '0x00080307': { 'vm': '1', 'vr': 'CS', name: 'DeidentificationAction' },
            '0x00080308': { 'vm': '1', 'vr': 'US', name: 'PrivateDataElement' },
            '0x00080309': { 'vm': '1-3', 'vr': 'UL', name: 'PrivateDataElementValueMultiplicity' },
            '0x0008030A': { 'vm': '1', 'vr': 'CS', name: 'PrivateDataElementValueRepresentation' },
            '0x0008030B': { 'vm': '1-2', 'vr': 'UL', name: 'PrivateDataElementNumberofItems' },
            '0x0008030C': { 'vm': '1', 'vr': 'UC', name: 'PrivateDataElementName' },
            '0x0008030D': { 'vm': '1', 'vr': 'UC', name: 'PrivateDataElementKeyword' },
            '0x0008030E': { 'vm': '1', 'vr': 'UT', name: 'PrivateDataElementDescription' },
            '0x0008030F': { 'vm': '1', 'vr': 'UT', name: 'PrivateDataElementEncoding' },
            '0x00080310': { 'vm': '1', 'vr': 'SQ', name: 'PrivateDataElementDefinitionSequence' },
            '0x00081000': { 'vm': '1', 'vr': 'AE', name: 'NetworkID' },
            '0x00081010': { 'vm': '1', 'vr': 'SH', name: 'StationName' },
            '0x00081030': { 'vm': '1', 'vr': 'LO', name: 'StudyDescription' },
            '0x00081032': { 'vm': '1', 'vr': 'SQ', name: 'ProcedureCodeSequence' },
            '0x0008103E': { 'vm': '1', 'vr': 'LO', name: 'SeriesDescription' },
            '0x0008103F': { 'vm': '1', 'vr': 'SQ', name: 'SeriesDescriptionCodeSequence' },
            '0x00081040': { 'vm': '1', 'vr': 'LO', name: 'InstitutionalDepartmentName' },
            '0x00081041': { 'vm': '1', 'vr': 'SQ', name: 'InstitutionalDepartmentTypeCodeSequence' },
            '0x00081048': { 'vm': '1-n', 'vr': 'PN', name: 'PhysiciansofRecord' },
            '0x00081049': { 'vm': '1', 'vr': 'SQ', name: 'PhysiciansofRecordIdentificationSequence' },
            '0x00081050': { 'vm': '1-n', 'vr': 'PN', name: 'PerformingPhysiciansName' },
            '0x00081052': { 'vm': '1', 'vr': 'SQ', name: 'PerformingPhysicianIdentificationSequence' },
            '0x00081060': { 'vm': '1-n', 'vr': 'PN', name: 'NameofPhysiciansReadingStudy' },
            '0x00081062': { 'vm': '1', 'vr': 'SQ', name: 'PhysiciansReadingStudyIdentificationSequence' },
            '0x00081070': { 'vm': '1-n', 'vr': 'PN', name: 'OperatorsName' },
            '0x00081072': { 'vm': '1', 'vr': 'SQ', name: 'OperatorIdentificationSequence' },
            '0x00081080': { 'vm': '1-n', 'vr': 'LO', name: 'AdmittingDiagnosesDescription' },
            '0x00081084': { 'vm': '1', 'vr': 'SQ', name: 'AdmittingDiagnosesCodeSequence' },
            '0x00081090': { 'vm': '1', 'vr': 'LO', name: 'ManufacturersModelName' },
            '0x00081100': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedResultsSequence' },
            '0x00081110': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedStudySequence' },
            '0x00081111': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPerformedProcedureStepSequence' },
            '0x00081115': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedSeriesSequence' },
            '0x00081120': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPatientSequence' },
            '0x00081125': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedVisitSequence' },
            '0x00081130': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedOverlaySequence' },
            '0x00081134': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedStereometricInstanceSequence' },
            '0x0008113A': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedWaveformSequence' },
            '0x00081140': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedImageSequence' },
            '0x00081145': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedCurveSequence' },
            '0x0008114A': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedInstanceSequence' },
            '0x0008114B': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedRealWorldValueMappingInstanceSequence' },
            '0x00081150': { 'vm': '1', 'vr': 'UI', name: 'ReferencedSOPClassUID' },
            '0x00081155': { 'vm': '1', 'vr': 'UI', name: 'ReferencedSOPInstanceUID' },
            '0x00081156': { 'vm': '1', 'vr': 'SQ', name: 'DefinitionSourceSequence' },
            '0x0008115A': { 'vm': '1-n', 'vr': 'UI', name: 'SOPClassesSupported' },
            '0x00081160': { 'vm': '1-n', 'vr': 'IS', name: 'ReferencedFrameNumber' },
            '0x00081161': { 'vm': '1-n', 'vr': 'UL', name: 'SimpleFrameList' },
            '0x00081162': { 'vm': '3-3n', 'vr': 'UL', name: 'CalculatedFrameList' },
            '0x00081163': { 'vm': '2', 'vr': 'FD', name: 'TimeRange' },
            '0x00081164': { 'vm': '1', 'vr': 'SQ', name: 'FrameExtractionSequence' },
            '0x00081167': { 'vm': '1', 'vr': 'UI', name: 'MultiframeSourceSOPInstanceUID' },
            '0x00081190': { 'vm': '1', 'vr': 'UR', name: 'RetrieveURL' },
            '0x00081195': { 'vm': '1', 'vr': 'UI', name: 'TransactionUID' },
            '0x00081196': { 'vm': '1', 'vr': 'US', name: 'WarningReason' },
            '0x00081197': { 'vm': '1', 'vr': 'US', name: 'FailureReason' },
            '0x00081198': { 'vm': '1', 'vr': 'SQ', name: 'FailedSOPSequence' },
            '0x00081199': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedSOPSequence' },
            '0x0008119A': { 'vm': '1', 'vr': 'SQ', name: 'OtherFailuresSequence' },
            '0x00081200': { 'vm': '1', 'vr': 'SQ', name: 'StudiesContainingOtherReferencedInstancesSequence' },
            '0x00081250': { 'vm': '1', 'vr': 'SQ', name: 'RelatedSeriesSequence' },
            '0x00082110': { 'vm': '1', 'vr': 'CS', name: 'LossyImageCompressionRetired' },
            '0x00082111': { 'vm': '1', 'vr': 'ST', name: 'DerivationDescription' },
            '0x00082112': { 'vm': '1', 'vr': 'SQ', name: 'SourceImageSequence' },
            '0x00082120': { 'vm': '1', 'vr': 'SH', name: 'StageName' },
            '0x00082122': { 'vm': '1', 'vr': 'IS', name: 'StageNumber' },
            '0x00082124': { 'vm': '1', 'vr': 'IS', name: 'NumberofStages' },
            '0x00082127': { 'vm': '1', 'vr': 'SH', name: 'ViewName' },
            '0x00082128': { 'vm': '1', 'vr': 'IS', name: 'ViewNumber' },
            '0x00082129': { 'vm': '1', 'vr': 'IS', name: 'NumberofEventTimers' },
            '0x0008212A': { 'vm': '1', 'vr': 'IS', name: 'NumberofViewsinStage' },
            '0x00082130': { 'vm': '1-n', 'vr': 'DS', name: 'EventElapsedTimes' },
            '0x00082132': { 'vm': '1-n', 'vr': 'LO', name: 'EventTimerNames' },
            '0x00082133': { 'vm': '1', 'vr': 'SQ', name: 'EventTimerSequence' },
            '0x00082134': { 'vm': '1', 'vr': 'FD', name: 'EventTimeOffset' },
            '0x00082135': { 'vm': '1', 'vr': 'SQ', name: 'EventCodeSequence' },
            '0x00082142': { 'vm': '1', 'vr': 'IS', name: 'StartTrim' },
            '0x00082143': { 'vm': '1', 'vr': 'IS', name: 'StopTrim' },
            '0x00082144': { 'vm': '1', 'vr': 'IS', name: 'RecommendedDisplayFrameRate' },
            '0x00082200': { 'vm': '1', 'vr': 'CS', name: 'TransducerPosition' },
            '0x00082204': { 'vm': '1', 'vr': 'CS', name: 'TransducerOrientation' },
            '0x00082208': { 'vm': '1', 'vr': 'CS', name: 'AnatomicStructure' },
            '0x00082218': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicRegionSequence' },
            '0x00082220': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicRegionModifierSequence' },
            '0x00082228': { 'vm': '1', 'vr': 'SQ', name: 'PrimaryAnatomicStructureSequence' },
            '0x00082229': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicStructureNSpaceorRegionSequence' },
            '0x00082230': { 'vm': '1', 'vr': 'SQ', name: 'PrimaryAnatomicStructureModifierSequence' },
            '0x00082240': { 'vm': '1', 'vr': 'SQ', name: 'TransducerPositionSequence' },
            '0x00082242': { 'vm': '1', 'vr': 'SQ', name: 'TransducerPositionModifierSequence' },
            '0x00082244': { 'vm': '1', 'vr': 'SQ', name: 'TransducerOrientationSequence' },
            '0x00082246': { 'vm': '1', 'vr': 'SQ', name: 'TransducerOrientationModifierSequence' },
            '0x00082251': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicStructureSpaceOrRegionCodeSequenceTrial' },
            '0x00082253': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicPortalOfEntranceCodeSequenceTrial' },
            '0x00082255': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicApproachDirectionCodeSequenceTrial' },
            '0x00082256': { 'vm': '1', 'vr': 'ST', name: 'AnatomicPerspectiveDescriptionTrial' },
            '0x00082257': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicPerspectiveCodeSequenceTrial' },
            '0x00082258': { 'vm': '1', 'vr': 'ST', name: 'AnatomicLocationOfExaminingInstrumentDescriptionTrial' },
            '0x00082259': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicLocationOfExaminingInstrumentCodeSequenceTrial' },
            '0x0008225A': { 'vm': '1', 'vr': 'SQ', name: 'AnatomicStructureSpaceOrRegionModifierCodeSequenceTrial' },
            '0x0008225C': { 'vm': '1', 'vr': 'SQ', name: 'OnAxisBackgroundAnatomicStructureCodeSequenceTrial' },
            '0x00083001': { 'vm': '1', 'vr': 'SQ', name: 'AlternateRepresentationSequence' },
            '0x00083010': { 'vm': '1-n', 'vr': 'UI', name: 'IrradiationEventUID' },
            '0x00083011': { 'vm': '1', 'vr': 'SQ', name: 'SourceIrradiationEventSequence' },
            '0x00083012': { 'vm': '1', 'vr': 'UI', name: 'RadiopharmaceuticalAdministrationEventUID' },
            '0x00084000': { 'vm': '1', 'vr': 'LT', name: 'IdentifyingComments' },
            '0x00089007': { 'vm': '4', 'vr': 'CS', name: 'FrameType' },
            '0x00089092': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedImageEvidenceSequence' },
            '0x00089121': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedRawDataSequence' },
            '0x00089123': { 'vm': '1', 'vr': 'UI', name: 'CreatorVersionUID' },
            '0x00089124': { 'vm': '1', 'vr': 'SQ', name: 'DerivationImageSequence' },
            '0x00089154': { 'vm': '1', 'vr': 'SQ', name: 'SourceImageEvidenceSequence' },
            '0x00089205': { 'vm': '1', 'vr': 'CS', name: 'PixelPresentation' },
            '0x00089206': { 'vm': '1', 'vr': 'CS', name: 'VolumetricProperties' },
            '0x00089207': { 'vm': '1', 'vr': 'CS', name: 'VolumeBasedCalculationTechnique' },
            '0x00089208': { 'vm': '1', 'vr': 'CS', name: 'ComplexImageComponent' },
            '0x00089209': { 'vm': '1', 'vr': 'CS', name: 'AcquisitionContrast' },
            '0x00089215': { 'vm': '1', 'vr': 'SQ', name: 'DerivationCodeSequence' },
            '0x00089237': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPresentationStateSequence' },
            '0x00089410': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedOtherPlaneSequence' },
            '0x00089458': { 'vm': '1', 'vr': 'SQ', name: 'FrameDisplaySequence' },
            '0x00089459': { 'vm': '1', 'vr': 'FL', name: 'RecommendedDisplayFrameRateinFloat' },
            '0x00089460': { 'vm': '1', 'vr': 'CS', name: 'SkipFrameRangeFlag' },
            '0x00100010': { 'vm': '1', 'vr': 'PN', name: 'PatientsName' },
            '0x00100020': { 'vm': '1', 'vr': 'LO', name: 'PatientID' },
            '0x00100021': { 'vm': '1', 'vr': 'LO', name: 'IssuerofPatientID' },
            '0x00100022': { 'vm': '1', 'vr': 'CS', name: 'TypeofPatientID' },
            '0x00100024': { 'vm': '1', 'vr': 'SQ', name: 'IssuerofPatientIDQualifiersSequence' },
            '0x00100026': { 'vm': '1', 'vr': 'SQ', name: 'SourcePatientGroupIdentificationSequence' },
            '0x00100027': { 'vm': '1', 'vr': 'SQ', name: 'GroupofPatientsIdentificationSequence' },
            '0x00100028': { 'vm': '3', 'vr': 'US', name: 'SubjectRelativePositioninImage' },
            '0x00100030': { 'vm': '1', 'vr': 'DA', name: 'PatientsBirthDate' },
            '0x00100032': { 'vm': '1', 'vr': 'TM', name: 'PatientsBirthTime' },
            '0x00100033': { 'vm': '1', 'vr': 'LO', name: 'PatientsBirthDateinAlternativeCalendar' },
            '0x00100034': { 'vm': '1', 'vr': 'LO', name: 'PatientsDeathDateinAlternativeCalendar' },
            '0x00100035': { 'vm': '1', 'vr': 'CS', name: 'PatientsAlternativeCalendar' },
            '0x00100040': { 'vm': '1', 'vr': 'CS', name: 'PatientsSex' },
            '0x00100050': { 'vm': '1', 'vr': 'SQ', name: 'PatientsInsurancePlanCodeSequence' },
            '0x00100101': { 'vm': '1', 'vr': 'SQ', name: 'PatientsPrimaryLanguageCodeSequence' },
            '0x00100102': { 'vm': '1', 'vr': 'SQ', name: 'PatientsPrimaryLanguageModifierCodeSequence' },
            '0x00100200': { 'vm': '1', 'vr': 'CS', name: 'QualityControlSubject' },
            '0x00100201': { 'vm': '1', 'vr': 'SQ', name: 'QualityControlSubjectTypeCodeSequence' },
            '0x00100212': { 'vm': '1', 'vr': 'UC', name: 'StrainDescription' },
            '0x00100213': { 'vm': '1', 'vr': 'LO', name: 'StrainNomenclature' },
            '0x00100214': { 'vm': '1', 'vr': 'LO', name: 'StrainStockNumber' },
            '0x00100215': { 'vm': '1', 'vr': 'SQ', name: 'StrainSourceRegistryCodeSequence' },
            '0x00100216': { 'vm': '1', 'vr': 'SQ', name: 'StrainStockSequence' },
            '0x00100217': { 'vm': '1', 'vr': 'LO', name: 'StrainSource' },
            '0x00100218': { 'vm': '1', 'vr': 'UT', name: 'StrainAdditionalInformation' },
            '0x00100219': { 'vm': '1', 'vr': 'SQ', name: 'StrainCodeSequence' },
            '0x00100221': { 'vm': '1', 'vr': 'SQ', name: 'GeneticModificationsSequence' },
            '0x00100222': { 'vm': '1', 'vr': 'UC', name: 'GeneticModificationsDescription' },
            '0x00100223': { 'vm': '1', 'vr': 'LO', name: 'GeneticModificationsNomenclature' },
            '0x00100229': { 'vm': '1', 'vr': 'SQ', name: 'GeneticModificationsCodeSequence' },
            '0x00101000': { 'vm': '1-n', 'vr': 'LO', name: 'OtherPatientIDs' },
            '0x00101001': { 'vm': '1-n', 'vr': 'PN', name: 'OtherPatientNames' },
            '0x00101002': { 'vm': '1', 'vr': 'SQ', name: 'OtherPatientIDsSequence' },
            '0x00101005': { 'vm': '1', 'vr': 'PN', name: 'PatientsBirthName' },
            '0x00101010': { 'vm': '1', 'vr': 'AS', name: 'PatientsAge' },
            '0x00101020': { 'vm': '1', 'vr': 'DS', name: 'PatientsSize' },
            '0x00101021': { 'vm': '1', 'vr': 'SQ', name: 'PatientsSizeCodeSequence' },
            '0x00101022': { 'vm': '1', 'vr': 'DS', name: 'PatientsBodyMassIndex' },
            '0x00101023': { 'vm': '1', 'vr': 'DS', name: 'MeasuredAPDimension' },
            '0x00101024': { 'vm': '1', 'vr': 'DS', name: 'MeasuredLateralDimension' },
            '0x00101030': { 'vm': '1', 'vr': 'DS', name: 'PatientsWeight' },
            '0x00101040': { 'vm': '1', 'vr': 'LO', name: 'PatientsAddress' },
            '0x00101050': { 'vm': '1-n', 'vr': 'LO', name: 'InsurancePlanIdentification' },
            '0x00101060': { 'vm': '1', 'vr': 'PN', name: 'PatientsMothersBirthName' },
            '0x00101080': { 'vm': '1', 'vr': 'LO', name: 'MilitaryRank' },
            '0x00101081': { 'vm': '1', 'vr': 'LO', name: 'BranchofService' },
            '0x00101090': { 'vm': '1', 'vr': 'LO', name: 'MedicalRecordLocator' },
            '0x00101100': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPatientPhotoSequence' },
            '0x00102000': { 'vm': '1-n', 'vr': 'LO', name: 'MedicalAlerts' },
            '0x00102110': { 'vm': '1-n', 'vr': 'LO', name: 'Allergies' },
            '0x00102150': { 'vm': '1', 'vr': 'LO', name: 'CountryofResidence' },
            '0x00102152': { 'vm': '1', 'vr': 'LO', name: 'RegionofResidence' },
            '0x00102154': { 'vm': '1-n', 'vr': 'SH', name: 'PatientsTelephoneNumbers' },
            '0x00102155': { 'vm': '1', 'vr': 'LT', name: 'PatientsTelecomInformation' },
            '0x00102160': { 'vm': '1', 'vr': 'SH', name: 'EthnicGroup' },
            '0x00102180': { 'vm': '1', 'vr': 'SH', name: 'Occupation' },
            '0x001021A0': { 'vm': '1', 'vr': 'CS', name: 'SmokingStatus' },
            '0x001021B0': { 'vm': '1', 'vr': 'LT', name: 'AdditionalPatientHistory' },
            '0x001021C0': { 'vm': '1', 'vr': 'US', name: 'PregnancyStatus' },
            '0x001021D0': { 'vm': '1', 'vr': 'DA', name: 'LastMenstrualDate' },
            '0x001021F0': { 'vm': '1', 'vr': 'LO', name: 'PatientsReligiousPreference' },
            '0x00102201': { 'vm': '1', 'vr': 'LO', name: 'PatientSpeciesDescription' },
            '0x00102202': { 'vm': '1', 'vr': 'SQ', name: 'PatientSpeciesCodeSequence' },
            '0x00102203': { 'vm': '1', 'vr': 'CS', name: 'PatientsSexNeutered' },
            '0x00102210': { 'vm': '1', 'vr': 'CS', name: 'AnatomicalOrientationType' },
            '0x00102292': { 'vm': '1', 'vr': 'LO', name: 'PatientBreedDescription' },
            '0x00102293': { 'vm': '1', 'vr': 'SQ', name: 'PatientBreedCodeSequence' },
            '0x00102294': { 'vm': '1', 'vr': 'SQ', name: 'BreedRegistrationSequence' },
            '0x00102295': { 'vm': '1', 'vr': 'LO', name: 'BreedRegistrationNumber' },
            '0x00102296': { 'vm': '1', 'vr': 'SQ', name: 'BreedRegistryCodeSequence' },
            '0x00102297': { 'vm': '1', 'vr': 'PN', name: 'ResponsiblePerson' },
            '0x00102298': { 'vm': '1', 'vr': 'CS', name: 'ResponsiblePersonRole' },
            '0x00102299': { 'vm': '1', 'vr': 'LO', name: 'ResponsibleOrganization' },
            '0x00104000': { 'vm': '1', 'vr': 'LT', name: 'PatientComments' },
            '0x00109431': { 'vm': '1', 'vr': 'FL', name: 'ExaminedBodyThickness' },
            '0x00120010': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialSponsorName' },
            '0x00120020': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialProtocolID' },
            '0x00120021': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialProtocolName' },
            '0x00120030': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialSiteID' },
            '0x00120031': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialSiteName' },
            '0x00120040': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialSubjectID' },
            '0x00120042': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialSubjectReadingID' },
            '0x00120050': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialTimePointID' },
            '0x00120051': { 'vm': '1', 'vr': 'ST', name: 'ClinicalTrialTimePointDescription' },
            '0x00120052': { 'vm': '1', 'vr': 'FD', name: 'LongitudinalTemporalOffsetfromEvent' },
            '0x00120053': { 'vm': '1', 'vr': 'CS', name: 'LongitudinalTemporalEventType' },
            '0x00120060': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialCoordinatingCenterName' },
            '0x00120062': { 'vm': '1', 'vr': 'CS', name: 'PatientIdentityRemoved' },
            '0x00120063': { 'vm': '1-n', 'vr': 'LO', name: 'DeidentificationMethod' },
            '0x00120064': { 'vm': '1', 'vr': 'SQ', name: 'DeidentificationMethodCodeSequence' },
            '0x00120071': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialSeriesID' },
            '0x00120072': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialSeriesDescription' },
            '0x00120081': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialProtocolEthicsCommitteeName' },
            '0x00120082': { 'vm': '1', 'vr': 'LO', name: 'ClinicalTrialProtocolEthicsCommitteeApprovalNumber' },
            '0x00120083': { 'vm': '1', 'vr': 'SQ', name: 'ConsentforClinicalTrialUseSequence' },
            '0x00120084': { 'vm': '1', 'vr': 'CS', name: 'DistributionType' },
            '0x00120085': { 'vm': '1', 'vr': 'CS', name: 'ConsentforDistributionFlag' },
            '0x00120086': { 'vm': '1', 'vr': 'DA', name: 'EthicsCommitteeApprovalEffectivenessStartDate' },
            '0x00120087': { 'vm': '1', 'vr': 'DA', name: 'EthicsCommitteeApprovalEffectivenessEndDate' },
            '0x00140023': { 'vm': '1', 'vr': 'ST', name: 'CADFileFormat' },
            '0x00140024': { 'vm': '1', 'vr': 'ST', name: 'ComponentReferenceSystem' },
            '0x00140025': { 'vm': '1', 'vr': 'ST', name: 'ComponentManufacturingProcedure' },
            '0x00140028': { 'vm': '1', 'vr': 'ST', name: 'ComponentManufacturer' },
            '0x00140030': { 'vm': '1-n', 'vr': 'DS', name: 'MaterialThickness' },
            '0x00140032': { 'vm': '1-n', 'vr': 'DS', name: 'MaterialPipeDiameter' },
            '0x00140034': { 'vm': '1-n', 'vr': 'DS', name: 'MaterialIsolationDiameter' },
            '0x00140042': { 'vm': '1', 'vr': 'ST', name: 'MaterialGrade' },
            '0x00140044': { 'vm': '1', 'vr': 'ST', name: 'MaterialPropertiesDescription' },
            '0x00140045': { 'vm': '1', 'vr': 'ST', name: 'MaterialPropertiesFileFormatRetired' },
            '0x00140046': { 'vm': '1', 'vr': 'LT', name: 'MaterialNotes' },
            '0x00140050': { 'vm': '1', 'vr': 'CS', name: 'ComponentShape' },
            '0x00140052': { 'vm': '1', 'vr': 'CS', name: 'CurvatureType' },
            '0x00140054': { 'vm': '1', 'vr': 'DS', name: 'OuterDiameter' },
            '0x00140056': { 'vm': '1', 'vr': 'DS', name: 'InnerDiameter' },
            '0x00140100': { 'vm': '1-n', 'vr': 'LO', name: 'ComponentWelderIDs' },
            '0x00140101': { 'vm': '1', 'vr': 'CS', name: 'SecondaryApprovalStatus' },
            '0x00140102': { 'vm': '1', 'vr': 'DA', name: 'SecondaryReviewDate' },
            '0x00140103': { 'vm': '1', 'vr': 'TM', name: 'SecondaryReviewTime' },
            '0x00140104': { 'vm': '1', 'vr': 'PN', name: 'SecondaryReviewerName' },
            '0x00140105': { 'vm': '1', 'vr': 'ST', name: 'RepairID' },
            '0x00140106': { 'vm': '1', 'vr': 'SQ', name: 'MultipleComponentApprovalSequence' },
            '0x00140107': { 'vm': '1-n', 'vr': 'CS', name: 'OtherApprovalStatus' },
            '0x00140108': { 'vm': '1-n', 'vr': 'CS', name: 'OtherSecondaryApprovalStatus' },
            '0x00141010': { 'vm': '1', 'vr': 'ST', name: 'ActualEnvironmentalConditions' },
            '0x00141020': { 'vm': '1', 'vr': 'DA', name: 'ExpiryDate' },
            '0x00141040': { 'vm': '1', 'vr': 'ST', name: 'EnvironmentalConditions' },
            '0x00142002': { 'vm': '1', 'vr': 'SQ', name: 'EvaluatorSequence' },
            '0x00142004': { 'vm': '1', 'vr': 'IS', name: 'EvaluatorNumber' },
            '0x00142006': { 'vm': '1', 'vr': 'PN', name: 'EvaluatorName' },
            '0x00142008': { 'vm': '1', 'vr': 'IS', name: 'EvaluationAttempt' },
            '0x00142012': { 'vm': '1', 'vr': 'SQ', name: 'IndicationSequence' },
            '0x00142014': { 'vm': '1', 'vr': 'IS', name: 'IndicationNumber' },
            '0x00142016': { 'vm': '1', 'vr': 'SH', name: 'IndicationLabel' },
            '0x00142018': { 'vm': '1', 'vr': 'ST', name: 'IndicationDescription' },
            '0x0014201A': { 'vm': '1-n', 'vr': 'CS', name: 'IndicationType' },
            '0x0014201C': { 'vm': '1', 'vr': 'CS', name: 'IndicationDisposition' },
            '0x0014201E': { 'vm': '1', 'vr': 'SQ', name: 'IndicationROISequence' },
            '0x00142030': { 'vm': '1', 'vr': 'SQ', name: 'IndicationPhysicalPropertySequence' },
            '0x00142032': { 'vm': '1', 'vr': 'SH', name: 'PropertyLabel' },
            '0x00142202': { 'vm': '1', 'vr': 'IS', name: 'CoordinateSystemNumberofAxes' },
            '0x00142204': { 'vm': '1', 'vr': 'SQ', name: 'CoordinateSystemAxesSequence' },
            '0x00142206': { 'vm': '1', 'vr': 'ST', name: 'CoordinateSystemAxisDescription' },
            '0x00142208': { 'vm': '1', 'vr': 'CS', name: 'CoordinateSystemDataSetMapping' },
            '0x0014220A': { 'vm': '1', 'vr': 'IS', name: 'CoordinateSystemAxisNumber' },
            '0x0014220C': { 'vm': '1', 'vr': 'CS', name: 'CoordinateSystemAxisType' },
            '0x0014220E': { 'vm': '1', 'vr': 'CS', name: 'CoordinateSystemAxisUnits' },
            '0x00142210': { 'vm': '1', 'vr': 'OB', name: 'CoordinateSystemAxisValues' },
            '0x00142220': { 'vm': '1', 'vr': 'SQ', name: 'CoordinateSystemTransformSequence' },
            '0x00142222': { 'vm': '1', 'vr': 'ST', name: 'TransformDescription' },
            '0x00142224': { 'vm': '1', 'vr': 'IS', name: 'TransformNumberofAxes' },
            '0x00142226': { 'vm': '1-n', 'vr': 'IS', name: 'TransformOrderofAxes' },
            '0x00142228': { 'vm': '1', 'vr': 'CS', name: 'TransformedAxisUnits' },
            '0x0014222A': { 'vm': '1-n', 'vr': 'DS', name: 'CoordinateSystemTransformRotationandScaleMatrix' },
            '0x0014222C': { 'vm': '1-n', 'vr': 'DS', name: 'CoordinateSystemTransformTranslationMatrix' },
            '0x00143011': { 'vm': '1', 'vr': 'DS', name: 'InternalDetectorFrameTime' },
            '0x00143012': { 'vm': '1', 'vr': 'DS', name: 'NumberofFramesIntegrated' },
            '0x00143020': { 'vm': '1', 'vr': 'SQ', name: 'DetectorTemperatureSequence' },
            '0x00143022': { 'vm': '1', 'vr': 'ST', name: 'SensorName' },
            '0x00143024': { 'vm': '1', 'vr': 'DS', name: 'HorizontalOffsetofSensor' },
            '0x00143026': { 'vm': '1', 'vr': 'DS', name: 'VerticalOffsetofSensor' },
            '0x00143028': { 'vm': '1', 'vr': 'DS', name: 'SensorTemperature' },
            '0x00143040': { 'vm': '1', 'vr': 'SQ', name: 'DarkCurrentSequence' },
            '0x00143050': { 'vm': '1', 'vr': 'OB', name: 'DarkCurrentCounts' },
            '0x00143060': { 'vm': '1', 'vr': 'SQ', name: 'GainCorrectionReferenceSequence' },
            '0x00143070': { 'vm': '1', 'vr': 'OB', name: 'AirCounts' },
            '0x00143071': { 'vm': '1', 'vr': 'DS', name: 'KVUsedinGainCalibration' },
            '0x00143072': { 'vm': '1', 'vr': 'DS', name: 'MAUsedinGainCalibration' },
            '0x00143073': { 'vm': '1', 'vr': 'DS', name: 'NumberofFramesUsedforIntegration' },
            '0x00143074': { 'vm': '1', 'vr': 'LO', name: 'FilterMaterialUsedinGainCalibration' },
            '0x00143075': { 'vm': '1', 'vr': 'DS', name: 'FilterThicknessUsedinGainCalibration' },
            '0x00143076': { 'vm': '1', 'vr': 'DA', name: 'DateofGainCalibration' },
            '0x00143077': { 'vm': '1', 'vr': 'TM', name: 'TimeofGainCalibration' },
            '0x00143080': { 'vm': '1', 'vr': 'OB', name: 'BadPixelImage' },
            '0x00143099': { 'vm': '1', 'vr': 'LT', name: 'CalibrationNotes' },
            '0x00144002': { 'vm': '1', 'vr': 'SQ', name: 'PulserEquipmentSequence' },
            '0x00144004': { 'vm': '1', 'vr': 'CS', name: 'PulserType' },
            '0x00144006': { 'vm': '1', 'vr': 'LT', name: 'PulserNotes' },
            '0x00144008': { 'vm': '1', 'vr': 'SQ', name: 'ReceiverEquipmentSequence' },
            '0x0014400A': { 'vm': '1', 'vr': 'CS', name: 'AmplifierType' },
            '0x0014400C': { 'vm': '1', 'vr': 'LT', name: 'ReceiverNotes' },
            '0x0014400E': { 'vm': '1', 'vr': 'SQ', name: 'PreAmplifierEquipmentSequence' },
            '0x0014400F': { 'vm': '1', 'vr': 'LT', name: 'PreAmplifierNotes' },
            '0x00144010': { 'vm': '1', 'vr': 'SQ', name: 'TransmitTransducerSequence' },
            '0x00144011': { 'vm': '1', 'vr': 'SQ', name: 'ReceiveTransducerSequence' },
            '0x00144012': { 'vm': '1', 'vr': 'US', name: 'NumberofElements' },
            '0x00144013': { 'vm': '1', 'vr': 'CS', name: 'ElementShape' },
            '0x00144014': { 'vm': '1', 'vr': 'DS', name: 'ElementDimensionA' },
            '0x00144015': { 'vm': '1', 'vr': 'DS', name: 'ElementDimensionB' },
            '0x00144016': { 'vm': '1', 'vr': 'DS', name: 'ElementPitchA' },
            '0x00144017': { 'vm': '1', 'vr': 'DS', name: 'MeasuredBeamDimensionA' },
            '0x00144018': { 'vm': '1', 'vr': 'DS', name: 'MeasuredBeamDimensionB' },
            '0x00144019': { 'vm': '1', 'vr': 'DS', name: 'LocationofMeasuredBeamDiameter' },
            '0x0014401A': { 'vm': '1', 'vr': 'DS', name: 'NominalFrequency' },
            '0x0014401B': { 'vm': '1', 'vr': 'DS', name: 'MeasuredCenterFrequency' },
            '0x0014401C': { 'vm': '1', 'vr': 'DS', name: 'MeasuredBandwidth' },
            '0x0014401D': { 'vm': '1', 'vr': 'DS', name: 'ElementPitchB' },
            '0x00144020': { 'vm': '1', 'vr': 'SQ', name: 'PulserSettingsSequence' },
            '0x00144022': { 'vm': '1', 'vr': 'DS', name: 'PulseWidth' },
            '0x00144024': { 'vm': '1', 'vr': 'DS', name: 'ExcitationFrequency' },
            '0x00144026': { 'vm': '1', 'vr': 'CS', name: 'ModulationType' },
            '0x00144028': { 'vm': '1', 'vr': 'DS', name: 'Damping' },
            '0x00144030': { 'vm': '1', 'vr': 'SQ', name: 'ReceiverSettingsSequence' },
            '0x00144031': { 'vm': '1', 'vr': 'DS', name: 'AcquiredSoundpathLength' },
            '0x00144032': { 'vm': '1', 'vr': 'CS', name: 'AcquisitionCompressionType' },
            '0x00144033': { 'vm': '1', 'vr': 'IS', name: 'AcquisitionSampleSize' },
            '0x00144034': { 'vm': '1', 'vr': 'DS', name: 'RectifierSmoothing' },
            '0x00144035': { 'vm': '1', 'vr': 'SQ', name: 'DACSequence' },
            '0x00144036': { 'vm': '1', 'vr': 'CS', name: 'DACType' },
            '0x00144038': { 'vm': '1-n', 'vr': 'DS', name: 'DACGainPoints' },
            '0x0014403A': { 'vm': '1-n', 'vr': 'DS', name: 'DACTimePoints' },
            '0x0014403C': { 'vm': '1-n', 'vr': 'DS', name: 'DACAmplitude' },
            '0x00144040': { 'vm': '1', 'vr': 'SQ', name: 'PreAmplifierSettingsSequence' },
            '0x00144050': { 'vm': '1', 'vr': 'SQ', name: 'TransmitTransducerSettingsSequence' },
            '0x00144051': { 'vm': '1', 'vr': 'SQ', name: 'ReceiveTransducerSettingsSequence' },
            '0x00144052': { 'vm': '1', 'vr': 'DS', name: 'IncidentAngle' },
            '0x00144054': { 'vm': '1', 'vr': 'ST', name: 'CouplingTechnique' },
            '0x00144056': { 'vm': '1', 'vr': 'ST', name: 'CouplingMedium' },
            '0x00144057': { 'vm': '1', 'vr': 'DS', name: 'CouplingVelocity' },
            '0x00144058': { 'vm': '1', 'vr': 'DS', name: 'ProbeCenterLocationX' },
            '0x00144059': { 'vm': '1', 'vr': 'DS', name: 'ProbeCenterLocationZ' },
            '0x0014405A': { 'vm': '1', 'vr': 'DS', name: 'SoundPathLength' },
            '0x0014405C': { 'vm': '1', 'vr': 'ST', name: 'DelayLawIdentifier' },
            '0x00144060': { 'vm': '1', 'vr': 'SQ', name: 'GateSettingsSequence' },
            '0x00144062': { 'vm': '1', 'vr': 'DS', name: 'GateThreshold' },
            '0x00144064': { 'vm': '1', 'vr': 'DS', name: 'VelocityofSound' },
            '0x00144070': { 'vm': '1', 'vr': 'SQ', name: 'CalibrationSettingsSequence' },
            '0x00144072': { 'vm': '1', 'vr': 'ST', name: 'CalibrationProcedure' },
            '0x00144074': { 'vm': '1', 'vr': 'SH', name: 'ProcedureVersion' },
            '0x00144076': { 'vm': '1', 'vr': 'DA', name: 'ProcedureCreationDate' },
            '0x00144078': { 'vm': '1', 'vr': 'DA', name: 'ProcedureExpirationDate' },
            '0x0014407A': { 'vm': '1', 'vr': 'DA', name: 'ProcedureLastModifiedDate' },
            '0x0014407C': { 'vm': '1-n', 'vr': 'TM', name: 'CalibrationTime' },
            '0x0014407E': { 'vm': '1-n', 'vr': 'DA', name: 'CalibrationDate' },
            '0x00144080': { 'vm': '1', 'vr': 'SQ', name: 'ProbeDriveEquipmentSequence' },
            '0x00144081': { 'vm': '1', 'vr': 'CS', name: 'DriveType' },
            '0x00144082': { 'vm': '1', 'vr': 'LT', name: 'ProbeDriveNotes' },
            '0x00144083': { 'vm': '1', 'vr': 'SQ', name: 'DriveProbeSequence' },
            '0x00144084': { 'vm': '1', 'vr': 'DS', name: 'ProbeInductance' },
            '0x00144085': { 'vm': '1', 'vr': 'DS', name: 'ProbeResistance' },
            '0x00144086': { 'vm': '1', 'vr': 'SQ', name: 'ReceiveProbeSequence' },
            '0x00144087': { 'vm': '1', 'vr': 'SQ', name: 'ProbeDriveSettingsSequence' },
            '0x00144088': { 'vm': '1', 'vr': 'DS', name: 'BridgeResistors' },
            '0x00144089': { 'vm': '1', 'vr': 'DS', name: 'ProbeOrientationAngle' },
            '0x0014408B': { 'vm': '1', 'vr': 'DS', name: 'UserSelectedGainY' },
            '0x0014408C': { 'vm': '1', 'vr': 'DS', name: 'UserSelectedPhase' },
            '0x0014408D': { 'vm': '1', 'vr': 'DS', name: 'UserSelectedOffsetX' },
            '0x0014408E': { 'vm': '1', 'vr': 'DS', name: 'UserSelectedOffsetY' },
            '0x00144091': { 'vm': '1', 'vr': 'SQ', name: 'ChannelSettingsSequence' },
            '0x00144092': { 'vm': '1', 'vr': 'DS', name: 'ChannelThreshold' },
            '0x0014409A': { 'vm': '1', 'vr': 'SQ', name: 'ScannerSettingsSequence' },
            '0x0014409B': { 'vm': '1', 'vr': 'ST', name: 'ScanProcedure' },
            '0x0014409C': { 'vm': '1', 'vr': 'DS', name: 'TranslationRateX' },
            '0x0014409D': { 'vm': '1', 'vr': 'DS', name: 'TranslationRateY' },
            '0x0014409F': { 'vm': '1', 'vr': 'DS', name: 'ChannelOverlap' },
            '0x001440A0': { 'vm': '1', 'vr': 'LO', name: 'ImageQualityIndicatorType' },
            '0x001440A1': { 'vm': '1', 'vr': 'LO', name: 'ImageQualityIndicatorMaterial' },
            '0x001440A2': { 'vm': '1', 'vr': 'LO', name: 'ImageQualityIndicatorSize' },
            '0x00145002': { 'vm': '1', 'vr': 'IS', name: 'LINACEnergy' },
            '0x00145004': { 'vm': '1', 'vr': 'IS', name: 'LINACOutput' },
            '0x00145100': { 'vm': '1', 'vr': 'US', name: 'ActiveAperture' },
            '0x00145101': { 'vm': '1', 'vr': 'DS', name: 'TotalAperture' },
            '0x00145102': { 'vm': '1', 'vr': 'DS', name: 'ApertureElevation' },
            '0x00145103': { 'vm': '1', 'vr': 'DS', name: 'MainLobeAngle' },
            '0x00145104': { 'vm': '1', 'vr': 'DS', name: 'MainRoofAngle' },
            '0x00145105': { 'vm': '1', 'vr': 'CS', name: 'ConnectorType' },
            '0x00145106': { 'vm': '1', 'vr': 'SH', name: 'WedgeModelNumber' },
            '0x00145107': { 'vm': '1', 'vr': 'DS', name: 'WedgeAngleFloat' },
            '0x00145108': { 'vm': '1', 'vr': 'DS', name: 'WedgeRoofAngle' },
            '0x00145109': { 'vm': '1', 'vr': 'CS', name: 'WedgeElement1Position' },
            '0x0014510A': { 'vm': '1', 'vr': 'DS', name: 'WedgeMaterialVelocity' },
            '0x0014510B': { 'vm': '1', 'vr': 'SH', name: 'WedgeMaterial' },
            '0x0014510C': { 'vm': '1', 'vr': 'DS', name: 'WedgeOffsetZ' },
            '0x0014510D': { 'vm': '1', 'vr': 'DS', name: 'WedgeOriginOffsetX' },
            '0x0014510E': { 'vm': '1', 'vr': 'DS', name: 'WedgeTimeDelay' },
            '0x0014510F': { 'vm': '1', 'vr': 'SH', name: 'WedgeName' },
            '0x00145110': { 'vm': '1', 'vr': 'SH', name: 'WedgeManufacturerName' },
            '0x00145111': { 'vm': '1', 'vr': 'LO', name: 'WedgeDescription' },
            '0x00145112': { 'vm': '1', 'vr': 'DS', name: 'NominalBeamAngle' },
            '0x00145113': { 'vm': '1', 'vr': 'DS', name: 'WedgeOffsetX' },
            '0x00145114': { 'vm': '1', 'vr': 'DS', name: 'WedgeOffsetY' },
            '0x00145115': { 'vm': '1', 'vr': 'DS', name: 'WedgeTotalLength' },
            '0x00145116': { 'vm': '1', 'vr': 'DS', name: 'WedgeInContactLength' },
            '0x00145117': { 'vm': '1', 'vr': 'DS', name: 'WedgeFrontGap' },
            '0x00145118': { 'vm': '1', 'vr': 'DS', name: 'WedgeTotalHeight' },
            '0x00145119': { 'vm': '1', 'vr': 'DS', name: 'WedgeFrontHeight' },
            '0x0014511A': { 'vm': '1', 'vr': 'DS', name: 'WedgeRearHeight' },
            '0x0014511B': { 'vm': '1', 'vr': 'DS', name: 'WedgeTotalWidth' },
            '0x0014511C': { 'vm': '1', 'vr': 'DS', name: 'WedgeInContactWidth' },
            '0x0014511D': { 'vm': '1', 'vr': 'DS', name: 'WedgeChamferHeight' },
            '0x0014511E': { 'vm': '1', 'vr': 'CS', name: 'WedgeCurve' },
            '0x0014511F': { 'vm': '1', 'vr': 'DS', name: 'RadiusAlongtheWedge' },
            '0x00160001': { 'vm': '1', 'vr': 'DS', name: 'WhitePoint' },
            '0x00160002': { 'vm': '3', 'vr': 'DS', name: 'PrimaryChromaticities' },
            '0x00160003': { 'vm': '1', 'vr': 'UT', name: 'BatteryLevel' },
            '0x00160004': { 'vm': '1', 'vr': 'DS', name: 'ExposureTimeinSeconds' },
            '0x00160005': { 'vm': '1', 'vr': 'DS', name: 'FNumber' },
            '0x00160006': { 'vm': '1', 'vr': 'IS', name: 'OECFRows' },
            '0x00160007': { 'vm': '1', 'vr': 'IS', name: 'OECFColumns' },
            '0x00160008': { 'vm': '1-n', 'vr': 'UC', name: 'OECFColumnNames' },
            '0x00160009': { 'vm': '1-n', 'vr': 'DS', name: 'OECFValues' },
            '0x0016000A': { 'vm': '1', 'vr': 'IS', name: 'SpatialFrequencyResponseRows' },
            '0x0016000B': { 'vm': '1', 'vr': 'IS', name: 'SpatialFrequencyResponseColumns' },
            '0x0016000C': { 'vm': '1-n', 'vr': 'UC', name: 'SpatialFrequencyResponseColumnNames' },
            '0x0016000D': { 'vm': '1-n', 'vr': 'DS', name: 'SpatialFrequencyResponseValues' },
            '0x0016000E': { 'vm': '1', 'vr': 'IS', name: 'ColorFilterArrayPatternRows' },
            '0x0016000F': { 'vm': '1', 'vr': 'IS', name: 'ColorFilterArrayPatternColumns' },
            '0x00160010': { 'vm': '1-n', 'vr': 'DS', name: 'ColorFilterArrayPatternValues' },
            '0x00160011': { 'vm': '1', 'vr': 'US', name: 'FlashFiringStatus' },
            '0x00160012': { 'vm': '1', 'vr': 'US', name: 'FlashReturnStatus' },
            '0x00160013': { 'vm': '1', 'vr': 'US', name: 'FlashMode' },
            '0x00160014': { 'vm': '1', 'vr': 'US', name: 'FlashFunctionPresent' },
            '0x00160015': { 'vm': '1', 'vr': 'US', name: 'FlashRedEyeMode' },
            '0x00160016': { 'vm': '1', 'vr': 'US', name: 'ExposureProgram' },
            '0x00160017': { 'vm': '1', 'vr': 'UT', name: 'SpectralSensitivity' },
            '0x00160018': { 'vm': '1', 'vr': 'IS', name: 'PhotographicSensitivity' },
            '0x00160019': { 'vm': '1', 'vr': 'IS', name: 'SelfTimerMode' },
            '0x0016001A': { 'vm': '1', 'vr': 'US', name: 'SensitivityType' },
            '0x0016001B': { 'vm': '1', 'vr': 'IS', name: 'StandardOutputSensitivity' },
            '0x0016001C': { 'vm': '1', 'vr': 'IS', name: 'RecommendedExposureIndex' },
            '0x0016001D': { 'vm': '1', 'vr': 'IS', name: 'ISOSpeed​' },
            '0x0016001E': { 'vm': '1', 'vr': 'IS', name: 'ISOSpeed​​Latitudeyyy' },
            '0x0016001F': { 'vm': '1', 'vr': 'IS', name: 'ISOSpeed​​Latitudezzz' },
            '0x00160020': { 'vm': '1', 'vr': 'UT', name: 'EXIFVersion' },
            '0x00160021': { 'vm': '1', 'vr': 'DS', name: 'ShutterSpeedValue' },
            '0x00160022': { 'vm': '1', 'vr': 'DS', name: 'ApertureValue' },
            '0x00160023': { 'vm': '1', 'vr': 'DS', name: 'BrightnessValue' },
            '0x00160024': { 'vm': '1', 'vr': 'DS', name: 'ExposureBiasValue' },
            '0x00160025': { 'vm': '1', 'vr': 'DS', name: 'MaxApertureValue' },
            '0x00160026': { 'vm': '1', 'vr': 'DS', name: 'SubjectDistance' },
            '0x00160027': { 'vm': '1', 'vr': 'US', name: 'MeteringMode' },
            '0x00160028': { 'vm': '1', 'vr': 'US', name: 'LightSource' },
            '0x00160029': { 'vm': '1', 'vr': 'DS', name: 'FocalLength' },
            '0x0016002A': { 'vm': '2-4', 'vr': 'IS', name: 'SubjectArea' },
            '0x0016002B': { 'vm': '1', 'vr': 'OB', name: 'MakerNote' },
            '0x00160030': { 'vm': '1', 'vr': 'DS', name: 'Temperature' },
            '0x00160031': { 'vm': '1', 'vr': 'DS', name: 'Humidity' },
            '0x00160032': { 'vm': '1', 'vr': 'DS', name: 'Pressure' },
            '0x00160033': { 'vm': '1', 'vr': 'DS', name: 'WaterDepth' },
            '0x00160034': { 'vm': '1', 'vr': 'DS', name: 'Acceleration' },
            '0x00160035': { 'vm': '1', 'vr': 'DS', name: 'CameraElevationAngle' },
            '0x00160036': { 'vm': '1-2', 'vr': 'DS', name: 'FlashEnergy' },
            '0x00160037': { 'vm': '2', 'vr': 'IS', name: 'SubjectLocation' },
            '0x00160038': { 'vm': '1', 'vr': 'DS', name: 'PhotographicExposureIndex' },
            '0x00160039': { 'vm': '1', 'vr': 'US', name: 'SensingMethod' },
            '0x0016003A': { 'vm': '1', 'vr': 'US', name: 'FileSource' },
            '0x0016003B': { 'vm': '1', 'vr': 'US', name: 'SceneType' },
            '0x00160041': { 'vm': '1', 'vr': 'US', name: 'CustomRendered' },
            '0x00160042': { 'vm': '1', 'vr': 'US', name: 'ExposureMode' },
            '0x00160043': { 'vm': '1', 'vr': 'US', name: 'WhiteBalance' },
            '0x00160044': { 'vm': '1', 'vr': 'DS', name: 'DigitalZoomRatio' },
            '0x00160045': { 'vm': '1', 'vr': 'IS', name: 'FocalLengthIn35mm​Film' },
            '0x00160046': { 'vm': '1', 'vr': 'US', name: 'SceneCaptureType' },
            '0x00160047': { 'vm': '1', 'vr': 'US', name: 'GainControl' },
            '0x00160048': { 'vm': '1', 'vr': 'US', name: 'Contrast' },
            '0x00160049': { 'vm': '1', 'vr': 'US', name: 'Saturation' },
            '0x0016004A': { 'vm': '1', 'vr': 'US', name: 'Sharpness' },
            '0x0016004B': { 'vm': '1', 'vr': 'OB', name: 'DeviceSettingDescription' },
            '0x0016004C': { 'vm': '1', 'vr': 'US', name: 'SubjectDistanceRange' },
            '0x0016004D': { 'vm': '1', 'vr': 'UT', name: 'CameraOwnerName' },
            '0x0016004E': { 'vm': '4', 'vr': 'DS', name: 'LensSpecification' },
            '0x0016004F': { 'vm': '1', 'vr': 'UT', name: 'LensMake' },
            '0x00160050': { 'vm': '1', 'vr': 'UT', name: 'LensModel' },
            '0x00160051': { 'vm': '1', 'vr': 'UT', name: 'LensSerialNumber' },
            '0x00160061': { 'vm': '1', 'vr': 'CS', name: 'InteroperabilityIndex' },
            '0x00160062': { 'vm': '1', 'vr': 'OB', name: 'InteroperabilityVersion' },
            '0x00160070': { 'vm': '1', 'vr': 'OB', name: 'GPSVersionID' },
            '0x00160071': { 'vm': '1', 'vr': 'CS', name: 'GPSLatitude​Ref' },
            '0x00160072': { 'vm': '3', 'vr': 'DS', name: 'GPSLatitude​' },
            '0x00160073': { 'vm': '1', 'vr': 'CS', name: 'GPSLongitudeRef' },
            '0x00160074': { 'vm': '3', 'vr': 'DS', name: 'GPSLongitude' },
            '0x00160075': { 'vm': '1', 'vr': 'US', name: 'GPSAltitude​Ref' },
            '0x00160076': { 'vm': '1', 'vr': 'DS', name: 'GPSAltitude​' },
            '0x00160077': { 'vm': '1', 'vr': 'DT', name: 'GPSTime​Stamp' },
            '0x00160078': { 'vm': '1', 'vr': 'UT', name: 'GPSSatellites' },
            '0x00160079': { 'vm': '1', 'vr': 'CS', name: 'GPSStatus' },
            '0x0016007A': { 'vm': '1', 'vr': 'CS', name: 'GPSMeasure​Mode' },
            '0x0016007B': { 'vm': '1', 'vr': 'DS', name: 'GPSDOP' },
            '0x0016007C': { 'vm': '1', 'vr': 'CS', name: 'GPSSpeed​Ref' },
            '0x0016007D': { 'vm': '1', 'vr': 'DS', name: 'GPSSpeed​' },
            '0x0016007E': { 'vm': '1', 'vr': 'CS', name: 'GPSTrack​Ref' },
            '0x0016007F': { 'vm': '1', 'vr': 'DS', name: 'GPSTrack' },
            '0x00160080': { 'vm': '1', 'vr': 'CS', name: 'GPSImg​DirectionRef' },
            '0x00160081': { 'vm': '1', 'vr': 'DS', name: 'GPSImg​Direction' },
            '0x00160082': { 'vm': '1', 'vr': 'UT', name: 'GPSMap​Datum' },
            '0x00160083': { 'vm': '1', 'vr': 'CS', name: 'GPSDest​LatitudeRef' },
            '0x00160084': { 'vm': '3', 'vr': 'DS', name: 'GPSDest​Latitude' },
            '0x00160085': { 'vm': '1', 'vr': 'CS', name: 'GPSDest​LongitudeRef' },
            '0x00160086': { 'vm': '3', 'vr': 'DS', name: 'GPSDest​Longitude' },
            '0x00160087': { 'vm': '1', 'vr': 'CS', name: 'GPSDest​BearingRef' },
            '0x00160088': { 'vm': '1', 'vr': 'DS', name: 'GPSDest​Bearing' },
            '0x00160089': { 'vm': '1', 'vr': 'CS', name: 'GPSDest​DistanceRef' },
            '0x0016008A': { 'vm': '1', 'vr': 'DS', name: 'GPSDest​Distance' },
            '0x0016008B': { 'vm': '1', 'vr': 'OB', name: 'GPSProcessing​Method' },
            '0x0016008C': { 'vm': '1', 'vr': 'OB', name: 'GPSArea​Information' },
            '0x0016008D': { 'vm': '1', 'vr': 'DT', name: 'GPSDate​Stamp' },
            '0x0016008E': { 'vm': '1', 'vr': 'IS', name: 'GPSDifferential' },
            '0x00180010': { 'vm': '1', 'vr': 'LO', name: 'ContrastBolusAgent' },
            '0x00180012': { 'vm': '1', 'vr': 'SQ', name: 'ContrastBolusAgentSequence' },
            '0x00180013': { 'vm': '1', 'vr': 'FL', name: 'ContrastBolusT1Relaxivity' },
            '0x00180014': { 'vm': '1', 'vr': 'SQ', name: 'ContrastBolusAdministrationRouteSequence' },
            '0x00180015': { 'vm': '1', 'vr': 'CS', name: 'BodyPartExamined' },
            '0x00180020': { 'vm': '1-n', 'vr': 'CS', name: 'ScanningSequence' },
            '0x00180021': { 'vm': '1-n', 'vr': 'CS', name: 'SequenceVariant' },
            '0x00180022': { 'vm': '1-n', 'vr': 'CS', name: 'ScanOptions' },
            '0x00180023': { 'vm': '1', 'vr': 'CS', name: 'MRAcquisitionType' },
            '0x00180024': { 'vm': '1', 'vr': 'SH', name: 'SequenceName' },
            '0x00180025': { 'vm': '1', 'vr': 'CS', name: 'AngioFlag' },
            '0x00180026': { 'vm': '1', 'vr': 'SQ', name: 'InterventionDrugInformationSequence' },
            '0x00180027': { 'vm': '1', 'vr': 'TM', name: 'InterventionDrugStopTime' },
            '0x00180028': { 'vm': '1', 'vr': 'DS', name: 'InterventionDrugDose' },
            '0x00180029': { 'vm': '1', 'vr': 'SQ', name: 'InterventionDrugCodeSequence' },
            '0x0018002A': { 'vm': '1', 'vr': 'SQ', name: 'AdditionalDrugSequence' },
            '0x00180030': { 'vm': '1-n', 'vr': 'LO', name: 'Radionuclide' },
            '0x00180031': { 'vm': '1', 'vr': 'LO', name: 'Radiopharmaceutical' },
            '0x00180032': { 'vm': '1', 'vr': 'DS', name: 'EnergyWindowCenterline' },
            '0x00180033': { 'vm': '1-n', 'vr': 'DS', name: 'EnergyWindowTotalWidth' },
            '0x00180034': { 'vm': '1', 'vr': 'LO', name: 'InterventionDrugName' },
            '0x00180035': { 'vm': '1', 'vr': 'TM', name: 'InterventionDrugStartTime' },
            '0x00180036': { 'vm': '1', 'vr': 'SQ', name: 'InterventionSequence' },
            '0x00180037': { 'vm': '1', 'vr': 'CS', name: 'TherapyType' },
            '0x00180038': { 'vm': '1', 'vr': 'CS', name: 'InterventionStatus' },
            '0x00180039': { 'vm': '1', 'vr': 'CS', name: 'TherapyDescription' },
            '0x0018003A': { 'vm': '1', 'vr': 'ST', name: 'InterventionDescription' },
            '0x00180040': { 'vm': '1', 'vr': 'IS', name: 'CineRate' },
            '0x00180042': { 'vm': '1', 'vr': 'CS', name: 'InitialCineRunState' },
            '0x00180050': { 'vm': '1', 'vr': 'DS', name: 'SliceThickness' },
            '0x00180060': { 'vm': '1', 'vr': 'DS', name: 'KVP' },
            '0x00180070': { 'vm': '1', 'vr': 'IS', name: 'CountsAccumulated' },
            '0x00180071': { 'vm': '1', 'vr': 'CS', name: 'AcquisitionTerminationCondition' },
            '0x00180072': { 'vm': '1', 'vr': 'DS', name: 'EffectiveDuration' },
            '0x00180073': { 'vm': '1', 'vr': 'CS', name: 'AcquisitionStartCondition' },
            '0x00180074': { 'vm': '1', 'vr': 'IS', name: 'AcquisitionStartConditionData' },
            '0x00180075': { 'vm': '1', 'vr': 'IS', name: 'AcquisitionTerminationConditionData' },
            '0x00180080': { 'vm': '1', 'vr': 'DS', name: 'RepetitionTime' },
            '0x00180081': { 'vm': '1', 'vr': 'DS', name: 'EchoTime' },
            '0x00180082': { 'vm': '1', 'vr': 'DS', name: 'InversionTime' },
            '0x00180083': { 'vm': '1', 'vr': 'DS', name: 'NumberofAverages' },
            '0x00180084': { 'vm': '1', 'vr': 'DS', name: 'ImagingFrequency' },
            '0x00180085': { 'vm': '1', 'vr': 'SH', name: 'ImagedNucleus' },
            '0x00180086': { 'vm': '1-n', 'vr': 'IS', name: 'EchoNumbers' },
            '0x00180087': { 'vm': '1', 'vr': 'DS', name: 'MagneticFieldStrength' },
            '0x00180088': { 'vm': '1', 'vr': 'DS', name: 'SpacingBetweenSlices' },
            '0x00180089': { 'vm': '1', 'vr': 'IS', name: 'NumberofPhaseEncodingSteps' },
            '0x00180090': { 'vm': '1', 'vr': 'DS', name: 'DataCollectionDiameter' },
            '0x00180091': { 'vm': '1', 'vr': 'IS', name: 'EchoTrainLength' },
            '0x00180093': { 'vm': '1', 'vr': 'DS', name: 'PercentSampling' },
            '0x00180094': { 'vm': '1', 'vr': 'DS', name: 'PercentPhaseFieldofView' },
            '0x00180095': { 'vm': '1', 'vr': 'DS', name: 'PixelBandwidth' },
            '0x00181000': { 'vm': '1', 'vr': 'LO', name: 'DeviceSerialNumber' },
            '0x00181002': { 'vm': '1', 'vr': 'UI', name: 'DeviceUID' },
            '0x00181003': { 'vm': '1', 'vr': 'LO', name: 'DeviceID' },
            '0x00181004': { 'vm': '1', 'vr': 'LO', name: 'PlateID' },
            '0x00181005': { 'vm': '1', 'vr': 'LO', name: 'GeneratorID' },
            '0x00181006': { 'vm': '1', 'vr': 'LO', name: 'GridID' },
            '0x00181007': { 'vm': '1', 'vr': 'LO', name: 'CassetteID' },
            '0x00181008': { 'vm': '1', 'vr': 'LO', name: 'GantryID' },
            '0x00181009': { 'vm': '1', 'vr': 'UT', name: 'UniqueDeviceIdentifier' },
            '0x0018100A': { 'vm': '1', 'vr': 'SQ', name: 'UDISequence' },
            '0x00181010': { 'vm': '1', 'vr': 'LO', name: 'SecondaryCaptureDeviceID' },
            '0x00181011': { 'vm': '1', 'vr': 'LO', name: 'HardcopyCreationDeviceID' },
            '0x00181012': { 'vm': '1', 'vr': 'DA', name: 'DateofSecondaryCapture' },
            '0x00181014': { 'vm': '1', 'vr': 'TM', name: 'TimeofSecondaryCapture' },
            '0x00181016': { 'vm': '1', 'vr': 'LO', name: 'SecondaryCaptureDeviceManufacturer' },
            '0x00181017': { 'vm': '1', 'vr': 'LO', name: 'HardcopyDeviceManufacturer' },
            '0x00181018': { 'vm': '1', 'vr': 'LO', name: 'SecondaryCaptureDeviceManufacturersModelName' },
            '0x00181019': { 'vm': '1-n', 'vr': 'LO', name: 'SecondaryCaptureDeviceSoftwareVersions' },
            '0x0018101A': { 'vm': '1-n', 'vr': 'LO', name: 'HardcopyDeviceSoftwareVersion' },
            '0x0018101B': { 'vm': '1', 'vr': 'LO', name: 'HardcopyDeviceManufacturersModelName' },
            '0x00181020': { 'vm': '1-n', 'vr': 'LO', name: 'SoftwareVersions' },
            '0x00181022': { 'vm': '1', 'vr': 'SH', name: 'VideoImageFormatAcquired' },
            '0x00181023': { 'vm': '1', 'vr': 'LO', name: 'DigitalImageFormatAcquired' },
            '0x00181030': { 'vm': '1', 'vr': 'LO', name: 'ProtocolName' },
            '0x00181040': { 'vm': '1', 'vr': 'LO', name: 'ContrastBolusRoute' },
            '0x00181041': { 'vm': '1', 'vr': 'DS', name: 'ContrastBolusVolume' },
            '0x00181042': { 'vm': '1', 'vr': 'TM', name: 'ContrastBolusStartTime' },
            '0x00181043': { 'vm': '1', 'vr': 'TM', name: 'ContrastBolusStopTime' },
            '0x00181044': { 'vm': '1', 'vr': 'DS', name: 'ContrastBolusTotalDose' },
            '0x00181045': { 'vm': '1', 'vr': 'IS', name: 'SyringeCounts' },
            '0x00181046': { 'vm': '1-n', 'vr': 'DS', name: 'ContrastFlowRate' },
            '0x00181047': { 'vm': '1-n', 'vr': 'DS', name: 'ContrastFlowDuration' },
            '0x00181048': { 'vm': '1', 'vr': 'CS', name: 'ContrastBolusIngredient' },
            '0x00181049': { 'vm': '1', 'vr': 'DS', name: 'ContrastBolusIngredientConcentration' },
            '0x00181050': { 'vm': '1', 'vr': 'DS', name: 'SpatialResolution' },
            '0x00181060': { 'vm': '1', 'vr': 'DS', name: 'TriggerTime' },
            '0x00181061': { 'vm': '1', 'vr': 'LO', name: 'TriggerSourceorType' },
            '0x00181062': { 'vm': '1', 'vr': 'IS', name: 'NominalInterval' },
            '0x00181063': { 'vm': '1', 'vr': 'DS', name: 'FrameTime' },
            '0x00181064': { 'vm': '1', 'vr': 'LO', name: 'CardiacFramingType' },
            '0x00181065': { 'vm': '1-n', 'vr': 'DS', name: 'FrameTimeVector' },
            '0x00181066': { 'vm': '1', 'vr': 'DS', name: 'FrameDelay' },
            '0x00181067': { 'vm': '1', 'vr': 'DS', name: 'ImageTriggerDelay' },
            '0x00181068': { 'vm': '1', 'vr': 'DS', name: 'MultiplexGroupTimeOffset' },
            '0x00181069': { 'vm': '1', 'vr': 'DS', name: 'TriggerTimeOffset' },
            '0x0018106A': { 'vm': '1', 'vr': 'CS', name: 'SynchronizationTrigger' },
            '0x0018106C': { 'vm': '2', 'vr': 'US', name: 'SynchronizationChannel' },
            '0x0018106E': { 'vm': '1', 'vr': 'UL', name: 'TriggerSamplePosition' },
            '0x00181070': { 'vm': '1', 'vr': 'LO', name: 'RadiopharmaceuticalRoute' },
            '0x00181071': { 'vm': '1', 'vr': 'DS', name: 'RadiopharmaceuticalVolume' },
            '0x00181072': { 'vm': '1', 'vr': 'TM', name: 'RadiopharmaceuticalStartTime' },
            '0x00181073': { 'vm': '1', 'vr': 'TM', name: 'RadiopharmaceuticalStopTime' },
            '0x00181074': { 'vm': '1', 'vr': 'DS', name: 'RadionuclideTotalDose' },
            '0x00181075': { 'vm': '1', 'vr': 'DS', name: 'RadionuclideHalfLife' },
            '0x00181076': { 'vm': '1', 'vr': 'DS', name: 'RadionuclidePositronFraction' },
            '0x00181077': { 'vm': '1', 'vr': 'DS', name: 'RadiopharmaceuticalSpecificActivity' },
            '0x00181078': { 'vm': '1', 'vr': 'DT', name: 'RadiopharmaceuticalStartDateTime' },
            '0x00181079': { 'vm': '1', 'vr': 'DT', name: 'RadiopharmaceuticalStopDateTime' },
            '0x00181080': { 'vm': '1', 'vr': 'CS', name: 'BeatRejectionFlag' },
            '0x00181081': { 'vm': '1', 'vr': 'IS', name: 'LowRRValue' },
            '0x00181082': { 'vm': '1', 'vr': 'IS', name: 'HighRRValue' },
            '0x00181083': { 'vm': '1', 'vr': 'IS', name: 'IntervalsAcquired' },
            '0x00181084': { 'vm': '1', 'vr': 'IS', name: 'IntervalsRejected' },
            '0x00181085': { 'vm': '1', 'vr': 'LO', name: 'PVCRejection' },
            '0x00181086': { 'vm': '1', 'vr': 'IS', name: 'SkipBeats' },
            '0x00181088': { 'vm': '1', 'vr': 'IS', name: 'HeartRate' },
            '0x00181090': { 'vm': '1', 'vr': 'IS', name: 'CardiacNumberofImages' },
            '0x00181094': { 'vm': '1', 'vr': 'IS', name: 'TriggerWindow' },
            '0x00181100': { 'vm': '1', 'vr': 'DS', name: 'ReconstructionDiameter' },
            '0x00181110': { 'vm': '1', 'vr': 'DS', name: 'DistanceSourcetoDetector' },
            '0x00181111': { 'vm': '1', 'vr': 'DS', name: 'DistanceSourcetoPatient' },
            '0x00181114': { 'vm': '1', 'vr': 'DS', name: 'EstimatedRadiographicMagnificationFactor' },
            '0x00181120': { 'vm': '1', 'vr': 'DS', name: 'GantryDetectorTilt' },
            '0x00181121': { 'vm': '1', 'vr': 'DS', name: 'GantryDetectorSlew' },
            '0x00181130': { 'vm': '1', 'vr': 'DS', name: 'TableHeight' },
            '0x00181131': { 'vm': '1', 'vr': 'DS', name: 'TableTraverse' },
            '0x00181134': { 'vm': '1', 'vr': 'CS', name: 'TableMotion' },
            '0x00181135': { 'vm': '1-n', 'vr': 'DS', name: 'TableVerticalIncrement' },
            '0x00181136': { 'vm': '1-n', 'vr': 'DS', name: 'TableLateralIncrement' },
            '0x00181137': { 'vm': '1-n', 'vr': 'DS', name: 'TableLongitudinalIncrement' },
            '0x00181138': { 'vm': '1', 'vr': 'DS', name: 'TableAngle' },
            '0x0018113A': { 'vm': '1', 'vr': 'CS', name: 'TableType' },
            '0x00181140': { 'vm': '1', 'vr': 'CS', name: 'RotationDirection' },
            '0x00181141': { 'vm': '1', 'vr': 'DS', name: 'AngularPosition' },
            '0x00181142': { 'vm': '1-n', 'vr': 'DS', name: 'RadialPosition' },
            '0x00181143': { 'vm': '1', 'vr': 'DS', name: 'ScanArc' },
            '0x00181144': { 'vm': '1', 'vr': 'DS', name: 'AngularStep' },
            '0x00181145': { 'vm': '1', 'vr': 'DS', name: 'CenterofRotationOffset' },
            '0x00181146': { 'vm': '1-n', 'vr': 'DS', name: 'RotationOffset' },
            '0x00181147': { 'vm': '1', 'vr': 'CS', name: 'FieldofViewShape' },
            '0x00181149': { 'vm': '1-2', 'vr': 'IS', name: 'FieldofViewDimensions' },
            '0x00181150': { 'vm': '1', 'vr': 'IS', name: 'ExposureTime' },
            '0x00181151': { 'vm': '1', 'vr': 'IS', name: 'XRayTubeCurrent' },
            '0x00181152': { 'vm': '1', 'vr': 'IS', name: 'Exposure' },
            '0x00181153': { 'vm': '1', 'vr': 'IS', name: 'ExposureinµAs' },
            '0x00181154': { 'vm': '1', 'vr': 'DS', name: 'AveragePulseWidth' },
            '0x00181155': { 'vm': '1', 'vr': 'CS', name: 'RadiationSetting' },
            '0x00181156': { 'vm': '1', 'vr': 'CS', name: 'RectificationType' },
            '0x0018115A': { 'vm': '1', 'vr': 'CS', name: 'RadiationMode' },
            '0x0018115E': { 'vm': '1', 'vr': 'DS', name: 'ImageandFluoroscopyAreaDoseProduct' },
            '0x00181160': { 'vm': '1', 'vr': 'SH', name: 'FilterType' },
            '0x00181161': { 'vm': '1-n', 'vr': 'LO', name: 'TypeofFilters' },
            '0x00181162': { 'vm': '1', 'vr': 'DS', name: 'IntensifierSize' },
            '0x00181164': { 'vm': '2', 'vr': 'DS', name: 'ImagerPixelSpacing' },
            '0x00181166': { 'vm': '1-n', 'vr': 'CS', name: 'Grid' },
            '0x00181170': { 'vm': '1', 'vr': 'IS', name: 'GeneratorPower' },
            '0x00181180': { 'vm': '1', 'vr': 'SH', name: 'CollimatorgridName' },
            '0x00181181': { 'vm': '1', 'vr': 'CS', name: 'CollimatorType' },
            '0x00181182': { 'vm': '1-2', 'vr': 'IS', name: 'FocalDistance' },
            '0x00181183': { 'vm': '1-2', 'vr': 'DS', name: 'XFocusCenter' },
            '0x00181184': { 'vm': '1-2', 'vr': 'DS', name: 'YFocusCenter' },
            '0x00181190': { 'vm': '1-n', 'vr': 'DS', name: 'FocalSpots' },
            '0x00181191': { 'vm': '1', 'vr': 'CS', name: 'AnodeTargetMaterial' },
            '0x001811A0': { 'vm': '1', 'vr': 'DS', name: 'BodyPartThickness' },
            '0x001811A2': { 'vm': '1', 'vr': 'DS', name: 'CompressionForce' },
            '0x001811A3': { 'vm': '1', 'vr': 'DS', name: 'CompressionPressure' },
            '0x001811A4': { 'vm': '1', 'vr': 'LO', name: 'PaddleDescription' },
            '0x001811A5': { 'vm': '1', 'vr': 'DS', name: 'CompressionContactArea' },
            '0x00181200': { 'vm': '1-n', 'vr': 'DA', name: 'DateofLastCalibration' },
            '0x00181201': { 'vm': '1-n', 'vr': 'TM', name: 'TimeofLastCalibration' },
            '0x00181202': { 'vm': '1', 'vr': 'DT', name: 'DateTimeofLastCalibration' },
            '0x00181210': { 'vm': '1-n', 'vr': 'SH', name: 'ConvolutionKernel' },
            '0x00181240': { 'vm': '1-n', 'vr': 'IS', name: 'UpperLowerPixelValues' },
            '0x00181242': { 'vm': '1', 'vr': 'IS', name: 'ActualFrameDuration' },
            '0x00181243': { 'vm': '1', 'vr': 'IS', name: 'CountRate' },
            '0x00181244': { 'vm': '1', 'vr': 'US', name: 'PreferredPlaybackSequencing' },
            '0x00181250': { 'vm': '1', 'vr': 'SH', name: 'ReceiveCoilName' },
            '0x00181251': { 'vm': '1', 'vr': 'SH', name: 'TransmitCoilName' },
            '0x00181260': { 'vm': '1', 'vr': 'SH', name: 'PlateType' },
            '0x00181261': { 'vm': '1', 'vr': 'LO', name: 'PhosphorType' },
            '0x00181271': { 'vm': '1', 'vr': 'FD', name: 'WaterEquivalentDiameter' },
            '0x00181272': { 'vm': '1', 'vr': 'SQ', name: 'WaterEquivalentDiameterCalculationMethodCodeSequence' },
            '0x00181300': { 'vm': '1', 'vr': 'DS', name: 'ScanVelocity' },
            '0x00181301': { 'vm': '1-n', 'vr': 'CS', name: 'WholeBodyTechnique' },
            '0x00181302': { 'vm': '1', 'vr': 'IS', name: 'ScanLength' },
            '0x00181310': { 'vm': '4', 'vr': 'US', name: 'AcquisitionMatrix' },
            '0x00181312': { 'vm': '1', 'vr': 'CS', name: 'InplanePhaseEncodingDirection' },
            '0x00181314': { 'vm': '1', 'vr': 'DS', name: 'FlipAngle' },
            '0x00181315': { 'vm': '1', 'vr': 'CS', name: 'VariableFlipAngleFlag' },
            '0x00181316': { 'vm': '1', 'vr': 'DS', name: 'SAR' },
            '0x00181318': { 'vm': '1', 'vr': 'DS', name: 'dBdt' },
            '0x00181320': { 'vm': '1', 'vr': 'FL', name: 'B1rms' },
            '0x00181400': { 'vm': '1', 'vr': 'LO', name: 'AcquisitionDeviceProcessingDescription' },
            '0x00181401': { 'vm': '1', 'vr': 'LO', name: 'AcquisitionDeviceProcessingCode' },
            '0x00181402': { 'vm': '1', 'vr': 'CS', name: 'CassetteOrientation' },
            '0x00181403': { 'vm': '1', 'vr': 'CS', name: 'CassetteSize' },
            '0x00181404': { 'vm': '1', 'vr': 'US', name: 'ExposuresonPlate' },
            '0x00181405': { 'vm': '1', 'vr': 'IS', name: 'RelativeXRayExposure' },
            '0x00181411': { 'vm': '1', 'vr': 'DS', name: 'ExposureIndex' },
            '0x00181412': { 'vm': '1', 'vr': 'DS', name: 'TargetExposureIndex' },
            '0x00181413': { 'vm': '1', 'vr': 'DS', name: 'DeviationIndex' },
            '0x00181450': { 'vm': '1', 'vr': 'DS', name: 'ColumnAngulation' },
            '0x00181460': { 'vm': '1', 'vr': 'DS', name: 'TomoLayerHeight' },
            '0x00181470': { 'vm': '1', 'vr': 'DS', name: 'TomoAngle' },
            '0x00181480': { 'vm': '1', 'vr': 'DS', name: 'TomoTime' },
            '0x00181490': { 'vm': '1', 'vr': 'CS', name: 'TomoType' },
            '0x00181491': { 'vm': '1', 'vr': 'CS', name: 'TomoClass' },
            '0x00181495': { 'vm': '1', 'vr': 'IS', name: 'NumberofTomosynthesisSourceImages' },
            '0x00181500': { 'vm': '1', 'vr': 'CS', name: 'PositionerMotion' },
            '0x00181508': { 'vm': '1', 'vr': 'CS', name: 'PositionerType' },
            '0x00181510': { 'vm': '1', 'vr': 'DS', name: 'PositionerPrimaryAngle' },
            '0x00181511': { 'vm': '1', 'vr': 'DS', name: 'PositionerSecondaryAngle' },
            '0x00181520': { 'vm': '1-n', 'vr': 'DS', name: 'PositionerPrimaryAngleIncrement' },
            '0x00181521': { 'vm': '1-n', 'vr': 'DS', name: 'PositionerSecondaryAngleIncrement' },
            '0x00181530': { 'vm': '1', 'vr': 'DS', name: 'DetectorPrimaryAngle' },
            '0x00181531': { 'vm': '1', 'vr': 'DS', name: 'DetectorSecondaryAngle' },
            '0x00181600': { 'vm': '1-3', 'vr': 'CS', name: 'ShutterShape' },
            '0x00181602': { 'vm': '1', 'vr': 'IS', name: 'ShutterLeftVerticalEdge' },
            '0x00181604': { 'vm': '1', 'vr': 'IS', name: 'ShutterRightVerticalEdge' },
            '0x00181606': { 'vm': '1', 'vr': 'IS', name: 'ShutterUpperHorizontalEdge' },
            '0x00181608': { 'vm': '1', 'vr': 'IS', name: 'ShutterLowerHorizontalEdge' },
            '0x00181610': { 'vm': '2', 'vr': 'IS', name: 'CenterofCircularShutter' },
            '0x00181612': { 'vm': '1', 'vr': 'IS', name: 'RadiusofCircularShutter' },
            '0x00181620': { 'vm': '2-2n', 'vr': 'IS', name: 'VerticesofthePolygonalShutter' },
            '0x00181622': { 'vm': '1', 'vr': 'US', name: 'ShutterPresentationValue' },
            '0x00181623': { 'vm': '1', 'vr': 'US', name: 'ShutterOverlayGroup' },
            '0x00181624': { 'vm': '3', 'vr': 'US', name: 'ShutterPresentationColorCIELabValue' },
            '0x00181700': { 'vm': '1-3', 'vr': 'CS', name: 'CollimatorShape' },
            '0x00181702': { 'vm': '1', 'vr': 'IS', name: 'CollimatorLeftVerticalEdge' },
            '0x00181704': { 'vm': '1', 'vr': 'IS', name: 'CollimatorRightVerticalEdge' },
            '0x00181706': { 'vm': '1', 'vr': 'IS', name: 'CollimatorUpperHorizontalEdge' },
            '0x00181708': { 'vm': '1', 'vr': 'IS', name: 'CollimatorLowerHorizontalEdge' },
            '0x00181710': { 'vm': '2', 'vr': 'IS', name: 'CenterofCircularCollimator' },
            '0x00181712': { 'vm': '1', 'vr': 'IS', name: 'RadiusofCircularCollimator' },
            '0x00181720': { 'vm': '2-2n', 'vr': 'IS', name: 'VerticesofthePolygonalCollimator' },
            '0x00181800': { 'vm': '1', 'vr': 'CS', name: 'AcquisitionTimeSynchronized' },
            '0x00181801': { 'vm': '1', 'vr': 'SH', name: 'TimeSource' },
            '0x00181802': { 'vm': '1', 'vr': 'CS', name: 'TimeDistributionProtocol' },
            '0x00181803': { 'vm': '1', 'vr': 'LO', name: 'NTPSourceAddress' },
            '0x00182001': { 'vm': '1-n', 'vr': 'IS', name: 'PageNumberVector' },
            '0x00182002': { 'vm': '1-n', 'vr': 'SH', name: 'FrameLabelVector' },
            '0x00182003': { 'vm': '1-n', 'vr': 'DS', name: 'FramePrimaryAngleVector' },
            '0x00182004': { 'vm': '1-n', 'vr': 'DS', name: 'FrameSecondaryAngleVector' },
            '0x00182005': { 'vm': '1-n', 'vr': 'DS', name: 'SliceLocationVector' },
            '0x00182006': { 'vm': '1-n', 'vr': 'SH', name: 'DisplayWindowLabelVector' },
            '0x00182010': { 'vm': '2', 'vr': 'DS', name: 'NominalScannedPixelSpacing' },
            '0x00182020': { 'vm': '1', 'vr': 'CS', name: 'DigitizingDeviceTransportDirection' },
            '0x00182030': { 'vm': '1', 'vr': 'DS', name: 'RotationofScannedFilm' },
            '0x00182041': { 'vm': '1', 'vr': 'SQ', name: 'BiopsyTargetSequence' },
            '0x00182042': { 'vm': '1', 'vr': 'UI', name: 'TargetUID' },
            '0x00182043': { 'vm': '2', 'vr': 'FL', name: 'LocalizingCursorPosition' },
            '0x00182044': { 'vm': '3', 'vr': 'FL', name: 'CalculatedTargetPosition' },
            '0x00182045': { 'vm': '1', 'vr': 'SH', name: 'TargetLabel' },
            '0x00182046': { 'vm': '1', 'vr': 'FL', name: 'DisplayedZValue' },
            '0x00183100': { 'vm': '1', 'vr': 'CS', name: 'IVUSAcquisition' },
            '0x00183101': { 'vm': '1', 'vr': 'DS', name: 'IVUSPullbackRate' },
            '0x00183102': { 'vm': '1', 'vr': 'DS', name: 'IVUSGatedRate' },
            '0x00183103': { 'vm': '1', 'vr': 'IS', name: 'IVUSPullbackStartFrameNumber' },
            '0x00183104': { 'vm': '1', 'vr': 'IS', name: 'IVUSPullbackStopFrameNumber' },
            '0x00183105': { 'vm': '1-n', 'vr': 'IS', name: 'LesionNumber' },
            '0x00184000': { 'vm': '1', 'vr': 'LT', name: 'AcquisitionComments' },
            '0x00185000': { 'vm': '1-n', 'vr': 'SH', name: 'OutputPower' },
            '0x00185010': { 'vm': '1-n', 'vr': 'LO', name: 'TransducerData' },
            '0x00185012': { 'vm': '1', 'vr': 'DS', name: 'FocusDepth' },
            '0x00185020': { 'vm': '1', 'vr': 'LO', name: 'ProcessingFunction' },
            '0x00185021': { 'vm': '1', 'vr': 'LO', name: 'PostprocessingFunction' },
            '0x00185022': { 'vm': '1', 'vr': 'DS', name: 'MechanicalIndex' },
            '0x00185024': { 'vm': '1', 'vr': 'DS', name: 'BoneThermalIndex' },
            '0x00185026': { 'vm': '1', 'vr': 'DS', name: 'CranialThermalIndex' },
            '0x00185027': { 'vm': '1', 'vr': 'DS', name: 'SoftTissueThermalIndex' },
            '0x00185028': { 'vm': '1', 'vr': 'DS', name: 'SoftTissuefocusThermalIndex' },
            '0x00185029': { 'vm': '1', 'vr': 'DS', name: 'SoftTissuesurfaceThermalIndex' },
            '0x00185030': { 'vm': '1', 'vr': 'DS', name: 'DynamicRange' },
            '0x00185040': { 'vm': '1', 'vr': 'DS', name: 'TotalGain' },
            '0x00185050': { 'vm': '1', 'vr': 'IS', name: 'DepthofScanField' },
            '0x00185100': { 'vm': '1', 'vr': 'CS', name: 'PatientPosition' },
            '0x00185101': { 'vm': '1', 'vr': 'CS', name: 'ViewPosition' },
            '0x00185104': { 'vm': '1', 'vr': 'SQ', name: 'ProjectionEponymousNameCodeSequence' },
            '0x00185210': { 'vm': '6', 'vr': 'DS', name: 'ImageTransformationMatrix' },
            '0x00185212': { 'vm': '3', 'vr': 'DS', name: 'ImageTranslationVector' },
            '0x00186000': { 'vm': '1', 'vr': 'DS', name: 'Sensitivity' },
            '0x00186011': { 'vm': '1', 'vr': 'SQ', name: 'SequenceofUltrasoundRegions' },
            '0x00186012': { 'vm': '1', 'vr': 'US', name: 'RegionSpatialFormat' },
            '0x00186014': { 'vm': '1', 'vr': 'US', name: 'RegionDataType' },
            '0x00186016': { 'vm': '1', 'vr': 'UL', name: 'RegionFlags' },
            '0x00186018': { 'vm': '1', 'vr': 'UL', name: 'RegionLocationMinX0' },
            '0x0018601A': { 'vm': '1', 'vr': 'UL', name: 'RegionLocationMinY0' },
            '0x0018601C': { 'vm': '1', 'vr': 'UL', name: 'RegionLocationMaxX1' },
            '0x0018601E': { 'vm': '1', 'vr': 'UL', name: 'RegionLocationMaxY1' },
            '0x00186020': { 'vm': '1', 'vr': 'SL', name: 'ReferencePixelX0' },
            '0x00186022': { 'vm': '1', 'vr': 'SL', name: 'ReferencePixelY0' },
            '0x00186024': { 'vm': '1', 'vr': 'US', name: 'PhysicalUnitsXDirection' },
            '0x00186026': { 'vm': '1', 'vr': 'US', name: 'PhysicalUnitsYDirection' },
            '0x00186028': { 'vm': '1', 'vr': 'FD', name: 'ReferencePixelPhysicalValueX' },
            '0x0018602A': { 'vm': '1', 'vr': 'FD', name: 'ReferencePixelPhysicalValueY' },
            '0x0018602C': { 'vm': '1', 'vr': 'FD', name: 'PhysicalDeltaX' },
            '0x0018602E': { 'vm': '1', 'vr': 'FD', name: 'PhysicalDeltaY' },
            '0x00186030': { 'vm': '1', 'vr': 'UL', name: 'TransducerFrequency' },
            '0x00186031': { 'vm': '1', 'vr': 'CS', name: 'TransducerType' },
            '0x00186032': { 'vm': '1', 'vr': 'UL', name: 'PulseRepetitionFrequency' },
            '0x00186034': { 'vm': '1', 'vr': 'FD', name: 'DopplerCorrectionAngle' },
            '0x00186036': { 'vm': '1', 'vr': 'FD', name: 'SteeringAngle' },
            '0x00186038': { 'vm': '1', 'vr': 'UL', name: 'DopplerSampleVolumeXPositionRetired' },
            '0x00186039': { 'vm': '1', 'vr': 'SL', name: 'DopplerSampleVolumeXPosition' },
            '0x0018603A': { 'vm': '1', 'vr': 'UL', name: 'DopplerSampleVolumeYPositionRetired' },
            '0x0018603B': { 'vm': '1', 'vr': 'SL', name: 'DopplerSampleVolumeYPosition' },
            '0x0018603C': { 'vm': '1', 'vr': 'UL', name: 'TMLinePositionX0Retired' },
            '0x0018603D': { 'vm': '1', 'vr': 'SL', name: 'TMLinePositionX0' },
            '0x0018603E': { 'vm': '1', 'vr': 'UL', name: 'TMLinePositionY0Retired' },
            '0x0018603F': { 'vm': '1', 'vr': 'SL', name: 'TMLinePositionY0' },
            '0x00186040': { 'vm': '1', 'vr': 'UL', name: 'TMLinePositionX1Retired' },
            '0x00186041': { 'vm': '1', 'vr': 'SL', name: 'TMLinePositionX1' },
            '0x00186042': { 'vm': '1', 'vr': 'UL', name: 'TMLinePositionY1Retired' },
            '0x00186043': { 'vm': '1', 'vr': 'SL', name: 'TMLinePositionY1' },
            '0x00186044': { 'vm': '1', 'vr': 'US', name: 'PixelComponentOrganization' },
            '0x00186046': { 'vm': '1', 'vr': 'UL', name: 'PixelComponentMask' },
            '0x00186048': { 'vm': '1', 'vr': 'UL', name: 'PixelComponentRangeStart' },
            '0x0018604A': { 'vm': '1', 'vr': 'UL', name: 'PixelComponentRangeStop' },
            '0x0018604C': { 'vm': '1', 'vr': 'US', name: 'PixelComponentPhysicalUnits' },
            '0x0018604E': { 'vm': '1', 'vr': 'US', name: 'PixelComponentDataType' },
            '0x00186050': { 'vm': '1', 'vr': 'UL', name: 'NumberofTableBreakPoints' },
            '0x00186052': { 'vm': '1-n', 'vr': 'UL', name: 'TableofXBreakPoints' },
            '0x00186054': { 'vm': '1-n', 'vr': 'FD', name: 'TableofYBreakPoints' },
            '0x00186056': { 'vm': '1', 'vr': 'UL', name: 'NumberofTableEntries' },
            '0x00186058': { 'vm': '1-n', 'vr': 'UL', name: 'TableofPixelValues' },
            '0x0018605A': { 'vm': '1-n', 'vr': 'FL', name: 'TableofParameterValues' },
            '0x00186060': { 'vm': '1-n', 'vr': 'FL', name: 'RWaveTimeVector' },
            '0x00187000': { 'vm': '1', 'vr': 'CS', name: 'DetectorConditionsNominalFlag' },
            '0x00187001': { 'vm': '1', 'vr': 'DS', name: 'DetectorTemperature' },
            '0x00187004': { 'vm': '1', 'vr': 'CS', name: 'DetectorType' },
            '0x00187005': { 'vm': '1', 'vr': 'CS', name: 'DetectorConfiguration' },
            '0x00187006': { 'vm': '1', 'vr': 'LT', name: 'DetectorDescription' },
            '0x00187008': { 'vm': '1', 'vr': 'LT', name: 'DetectorMode' },
            '0x0018700A': { 'vm': '1', 'vr': 'SH', name: 'DetectorID' },
            '0x0018700C': { 'vm': '1', 'vr': 'DA', name: 'DateofLastDetectorCalibration' },
            '0x0018700E': { 'vm': '1', 'vr': 'TM', name: 'TimeofLastDetectorCalibration' },
            '0x00187010': { 'vm': '1', 'vr': 'IS', name: 'ExposuresonDetectorSinceLastCalibration' },
            '0x00187011': { 'vm': '1', 'vr': 'IS', name: 'ExposuresonDetectorSinceManufactured' },
            '0x00187012': { 'vm': '1', 'vr': 'DS', name: 'DetectorTimeSinceLastExposure' },
            '0x00187014': { 'vm': '1', 'vr': 'DS', name: 'DetectorActiveTime' },
            '0x00187016': { 'vm': '1', 'vr': 'DS', name: 'DetectorActivationOffsetFromExposure' },
            '0x0018701A': { 'vm': '2', 'vr': 'DS', name: 'DetectorBinning' },
            '0x00187020': { 'vm': '2', 'vr': 'DS', name: 'DetectorElementPhysicalSize' },
            '0x00187022': { 'vm': '2', 'vr': 'DS', name: 'DetectorElementSpacing' },
            '0x00187024': { 'vm': '1', 'vr': 'CS', name: 'DetectorActiveShape' },
            '0x00187026': { 'vm': '1-2', 'vr': 'DS', name: 'DetectorActiveDimensions' },
            '0x00187028': { 'vm': '2', 'vr': 'DS', name: 'DetectorActiveOrigin' },
            '0x0018702A': { 'vm': '1', 'vr': 'LO', name: 'DetectorManufacturerName' },
            '0x0018702B': { 'vm': '1', 'vr': 'LO', name: 'DetectorManufacturersModelName' },
            '0x00187030': { 'vm': '2', 'vr': 'DS', name: 'FieldofViewOrigin' },
            '0x00187032': { 'vm': '1', 'vr': 'DS', name: 'FieldofViewRotation' },
            '0x00187034': { 'vm': '1', 'vr': 'CS', name: 'FieldofViewHorizontalFlip' },
            '0x00187036': { 'vm': '2', 'vr': 'FL', name: 'PixelDataAreaOriginRelativeToFOV' },
            '0x00187038': { 'vm': '1', 'vr': 'FL', name: 'PixelDataAreaRotationAngleRelativeToFOV' },
            '0x00187040': { 'vm': '1', 'vr': 'LT', name: 'GridAbsorbingMaterial' },
            '0x00187041': { 'vm': '1', 'vr': 'LT', name: 'GridSpacingMaterial' },
            '0x00187042': { 'vm': '1', 'vr': 'DS', name: 'GridThickness' },
            '0x00187044': { 'vm': '1', 'vr': 'DS', name: 'GridPitch' },
            '0x00187046': { 'vm': '2', 'vr': 'IS', name: 'GridAspectRatio' },
            '0x00187048': { 'vm': '1', 'vr': 'DS', name: 'GridPeriod' },
            '0x0018704C': { 'vm': '1', 'vr': 'DS', name: 'GridFocalDistance' },
            '0x00187050': { 'vm': '1-n', 'vr': 'CS', name: 'FilterMaterial' },
            '0x00187052': { 'vm': '1-n', 'vr': 'DS', name: 'FilterThicknessMinimum' },
            '0x00187054': { 'vm': '1-n', 'vr': 'DS', name: 'FilterThicknessMaximum' },
            '0x00187056': { 'vm': '1-n', 'vr': 'FL', name: 'FilterBeamPathLengthMinimum' },
            '0x00187058': { 'vm': '1-n', 'vr': 'FL', name: 'FilterBeamPathLengthMaximum' },
            '0x00187060': { 'vm': '1', 'vr': 'CS', name: 'ExposureControlMode' },
            '0x00187062': { 'vm': '1', 'vr': 'LT', name: 'ExposureControlModeDescription' },
            '0x00187064': { 'vm': '1', 'vr': 'CS', name: 'ExposureStatus' },
            '0x00187065': { 'vm': '1', 'vr': 'DS', name: 'PhototimerSetting' },
            '0x00188150': { 'vm': '1', 'vr': 'DS', name: 'ExposureTimeinµS' },
            '0x00188151': { 'vm': '1', 'vr': 'DS', name: 'XRayTubeCurrentinµA' },
            '0x00189004': { 'vm': '1', 'vr': 'CS', name: 'ContentQualification' },
            '0x00189005': { 'vm': '1', 'vr': 'SH', name: 'PulseSequenceName' },
            '0x00189006': { 'vm': '1', 'vr': 'SQ', name: 'MRImagingModifierSequence' },
            '0x00189008': { 'vm': '1', 'vr': 'CS', name: 'EchoPulseSequence' },
            '0x00189009': { 'vm': '1', 'vr': 'CS', name: 'InversionRecovery' },
            '0x00189010': { 'vm': '1', 'vr': 'CS', name: 'FlowCompensation' },
            '0x00189011': { 'vm': '1', 'vr': 'CS', name: 'MultipleSpinEcho' },
            '0x00189012': { 'vm': '1', 'vr': 'CS', name: 'MultiplanarExcitation' },
            '0x00189014': { 'vm': '1', 'vr': 'CS', name: 'PhaseContrast' },
            '0x00189015': { 'vm': '1', 'vr': 'CS', name: 'TimeofFlightContrast' },
            '0x00189016': { 'vm': '1', 'vr': 'CS', name: 'Spoiling' },
            '0x00189017': { 'vm': '1', 'vr': 'CS', name: 'SteadyStatePulseSequence' },
            '0x00189018': { 'vm': '1', 'vr': 'CS', name: 'EchoPlanarPulseSequence' },
            '0x00189019': { 'vm': '1', 'vr': 'FD', name: 'TagAngleFirstAxis' },
            '0x00189020': { 'vm': '1', 'vr': 'CS', name: 'MagnetizationTransfer' },
            '0x00189021': { 'vm': '1', 'vr': 'CS', name: 'T2Preparation' },
            '0x00189022': { 'vm': '1', 'vr': 'CS', name: 'BloodSignalNulling' },
            '0x00189024': { 'vm': '1', 'vr': 'CS', name: 'SaturationRecovery' },
            '0x00189025': { 'vm': '1', 'vr': 'CS', name: 'SpectrallySelectedSuppression' },
            '0x00189026': { 'vm': '1', 'vr': 'CS', name: 'SpectrallySelectedExcitation' },
            '0x00189027': { 'vm': '1', 'vr': 'CS', name: 'SpatialPresaturation' },
            '0x00189028': { 'vm': '1', 'vr': 'CS', name: 'Tagging' },
            '0x00189029': { 'vm': '1', 'vr': 'CS', name: 'OversamplingPhase' },
            '0x00189030': { 'vm': '1', 'vr': 'FD', name: 'TagSpacingFirstDimension' },
            '0x00189032': { 'vm': '1', 'vr': 'CS', name: 'GeometryofkSpaceTraversal' },
            '0x00189033': { 'vm': '1', 'vr': 'CS', name: 'SegmentedkSpaceTraversal' },
            '0x00189034': { 'vm': '1', 'vr': 'CS', name: 'RectilinearPhaseEncodeReordering' },
            '0x00189035': { 'vm': '1', 'vr': 'FD', name: 'TagThickness' },
            '0x00189036': { 'vm': '1', 'vr': 'CS', name: 'PartialFourierDirection' },
            '0x00189037': { 'vm': '1', 'vr': 'CS', name: 'CardiacSynchronizationTechnique' },
            '0x00189041': { 'vm': '1', 'vr': 'LO', name: 'ReceiveCoilManufacturerName' },
            '0x00189042': { 'vm': '1', 'vr': 'SQ', name: 'MRReceiveCoilSequence' },
            '0x00189043': { 'vm': '1', 'vr': 'CS', name: 'ReceiveCoilType' },
            '0x00189044': { 'vm': '1', 'vr': 'CS', name: 'QuadratureReceiveCoil' },
            '0x00189045': { 'vm': '1', 'vr': 'SQ', name: 'MultiCoilDefinitionSequence' },
            '0x00189046': { 'vm': '1', 'vr': 'LO', name: 'MultiCoilConfiguration' },
            '0x00189047': { 'vm': '1', 'vr': 'SH', name: 'MultiCoilElementName' },
            '0x00189048': { 'vm': '1', 'vr': 'CS', name: 'MultiCoilElementUsed' },
            '0x00189049': { 'vm': '1', 'vr': 'SQ', name: 'MRTransmitCoilSequence' },
            '0x00189050': { 'vm': '1', 'vr': 'LO', name: 'TransmitCoilManufacturerName' },
            '0x00189051': { 'vm': '1', 'vr': 'CS', name: 'TransmitCoilType' },
            '0x00189052': { 'vm': '1-2', 'vr': 'FD', name: 'SpectralWidth' },
            '0x00189053': { 'vm': '1-2', 'vr': 'FD', name: 'ChemicalShiftReference' },
            '0x00189054': { 'vm': '1', 'vr': 'CS', name: 'VolumeLocalizationTechnique' },
            '0x00189058': { 'vm': '1', 'vr': 'US', name: 'MRAcquisitionFrequencyEncodingSteps' },
            '0x00189059': { 'vm': '1', 'vr': 'CS', name: 'Decoupling' },
            '0x00189060': { 'vm': '1-2', 'vr': 'CS', name: 'DecoupledNucleus' },
            '0x00189061': { 'vm': '1-2', 'vr': 'FD', name: 'DecouplingFrequency' },
            '0x00189062': { 'vm': '1', 'vr': 'CS', name: 'DecouplingMethod' },
            '0x00189063': { 'vm': '1-2', 'vr': 'FD', name: 'DecouplingChemicalShiftReference' },
            '0x00189064': { 'vm': '1', 'vr': 'CS', name: 'kspaceFiltering' },
            '0x00189065': { 'vm': '1-2', 'vr': 'CS', name: 'TimeDomainFiltering' },
            '0x00189066': { 'vm': '1-2', 'vr': 'US', name: 'NumberofZeroFills' },
            '0x00189067': { 'vm': '1', 'vr': 'CS', name: 'BaselineCorrection' },
            '0x00189069': { 'vm': '1', 'vr': 'FD', name: 'ParallelReductionFactorInplane' },
            '0x00189070': { 'vm': '1', 'vr': 'FD', name: 'CardiacRRIntervalSpecified' },
            '0x00189073': { 'vm': '1', 'vr': 'FD', name: 'AcquisitionDuration' },
            '0x00189074': { 'vm': '1', 'vr': 'DT', name: 'FrameAcquisitionDateTime' },
            '0x00189075': { 'vm': '1', 'vr': 'CS', name: 'DiffusionDirectionality' },
            '0x00189076': { 'vm': '1', 'vr': 'SQ', name: 'DiffusionGradientDirectionSequence' },
            '0x00189077': { 'vm': '1', 'vr': 'CS', name: 'ParallelAcquisition' },
            '0x00189078': { 'vm': '1', 'vr': 'CS', name: 'ParallelAcquisitionTechnique' },
            '0x00189079': { 'vm': '1-n', 'vr': 'FD', name: 'InversionTimes' },
            '0x00189080': { 'vm': '1', 'vr': 'ST', name: 'MetaboliteMapDescription' },
            '0x00189081': { 'vm': '1', 'vr': 'CS', name: 'PartialFourier' },
            '0x00189082': { 'vm': '1', 'vr': 'FD', name: 'EffectiveEchoTime' },
            '0x00189083': { 'vm': '1', 'vr': 'SQ', name: 'MetaboliteMapCodeSequence' },
            '0x00189084': { 'vm': '1', 'vr': 'SQ', name: 'ChemicalShiftSequence' },
            '0x00189085': { 'vm': '1', 'vr': 'CS', name: 'CardiacSignalSource' },
            '0x00189087': { 'vm': '1', 'vr': 'FD', name: 'Diffusionbvalue' },
            '0x00189089': { 'vm': '3', 'vr': 'FD', name: 'DiffusionGradientOrientation' },
            '0x00189090': { 'vm': '3', 'vr': 'FD', name: 'VelocityEncodingDirection' },
            '0x00189091': { 'vm': '1', 'vr': 'FD', name: 'VelocityEncodingMinimumValue' },
            '0x00189092': { 'vm': '1', 'vr': 'SQ', name: 'VelocityEncodingAcquisitionSequence' },
            '0x00189093': { 'vm': '1', 'vr': 'US', name: 'NumberofkSpaceTrajectories' },
            '0x00189094': { 'vm': '1', 'vr': 'CS', name: 'CoverageofkSpace' },
            '0x00189095': { 'vm': '1', 'vr': 'UL', name: 'SpectroscopyAcquisitionPhaseRows' },
            '0x00189096': { 'vm': '1', 'vr': 'FD', name: 'ParallelReductionFactorInplaneRetired' },
            '0x00189098': { 'vm': '1-2', 'vr': 'FD', name: 'TransmitterFrequency' },
            '0x00189100': { 'vm': '1-2', 'vr': 'CS', name: 'ResonantNucleus' },
            '0x00189101': { 'vm': '1', 'vr': 'CS', name: 'FrequencyCorrection' },
            '0x00189103': { 'vm': '1', 'vr': 'SQ', name: 'MRSpectroscopyFOVGeometrySequence' },
            '0x00189104': { 'vm': '1', 'vr': 'FD', name: 'SlabThickness' },
            '0x00189105': { 'vm': '3', 'vr': 'FD', name: 'SlabOrientation' },
            '0x00189106': { 'vm': '3', 'vr': 'FD', name: 'MidSlabPosition' },
            '0x00189107': { 'vm': '1', 'vr': 'SQ', name: 'MRSpatialSaturationSequence' },
            '0x00189112': { 'vm': '1', 'vr': 'SQ', name: 'MRTimingandRelatedParametersSequence' },
            '0x00189114': { 'vm': '1', 'vr': 'SQ', name: 'MREchoSequence' },
            '0x00189115': { 'vm': '1', 'vr': 'SQ', name: 'MRModifierSequence' },
            '0x00189117': { 'vm': '1', 'vr': 'SQ', name: 'MRDiffusionSequence' },
            '0x00189118': { 'vm': '1', 'vr': 'SQ', name: 'CardiacSynchronizationSequence' },
            '0x00189119': { 'vm': '1', 'vr': 'SQ', name: 'MRAveragesSequence' },
            '0x00189125': { 'vm': '1', 'vr': 'SQ', name: 'MRFOVGeometrySequence' },
            '0x00189126': { 'vm': '1', 'vr': 'SQ', name: 'VolumeLocalizationSequence' },
            '0x00189127': { 'vm': '1', 'vr': 'UL', name: 'SpectroscopyAcquisitionDataColumns' },
            '0x00189147': { 'vm': '1', 'vr': 'CS', name: 'DiffusionAnisotropyType' },
            '0x00189151': { 'vm': '1', 'vr': 'DT', name: 'FrameReferenceDateTime' },
            '0x00189152': { 'vm': '1', 'vr': 'SQ', name: 'MRMetaboliteMapSequence' },
            '0x00189155': { 'vm': '1', 'vr': 'FD', name: 'ParallelReductionFactoroutofplane' },
            '0x00189159': { 'vm': '1', 'vr': 'UL', name: 'SpectroscopyAcquisitionOutofplanePhaseSteps' },
            '0x00189166': { 'vm': '1', 'vr': 'CS', name: 'BulkMotionStatus' },
            '0x00189168': { 'vm': '1', 'vr': 'FD', name: 'ParallelReductionFactorSecondInplane' },
            '0x00189169': { 'vm': '1', 'vr': 'CS', name: 'CardiacBeatRejectionTechnique' },
            '0x00189170': { 'vm': '1', 'vr': 'CS', name: 'RespiratoryMotionCompensationTechnique' },
            '0x00189171': { 'vm': '1', 'vr': 'CS', name: 'RespiratorySignalSource' },
            '0x00189172': { 'vm': '1', 'vr': 'CS', name: 'BulkMotionCompensationTechnique' },
            '0x00189173': { 'vm': '1', 'vr': 'CS', name: 'BulkMotionSignalSource' },
            '0x00189174': { 'vm': '1', 'vr': 'CS', name: 'ApplicableSafetyStandardAgency' },
            '0x00189175': { 'vm': '1', 'vr': 'LO', name: 'ApplicableSafetyStandardDescription' },
            '0x00189176': { 'vm': '1', 'vr': 'SQ', name: 'OperatingModeSequence' },
            '0x00189177': { 'vm': '1', 'vr': 'CS', name: 'OperatingModeType' },
            '0x00189178': { 'vm': '1', 'vr': 'CS', name: 'OperatingMode' },
            '0x00189179': { 'vm': '1', 'vr': 'CS', name: 'SpecificAbsorptionRateDefinition' },
            '0x00189180': { 'vm': '1', 'vr': 'CS', name: 'GradientOutputType' },
            '0x00189181': { 'vm': '1', 'vr': 'FD', name: 'SpecificAbsorptionRateValue' },
            '0x00189182': { 'vm': '1', 'vr': 'FD', name: 'GradientOutput' },
            '0x00189183': { 'vm': '1', 'vr': 'CS', name: 'FlowCompensationDirection' },
            '0x00189184': { 'vm': '1', 'vr': 'FD', name: 'TaggingDelay' },
            '0x00189185': { 'vm': '1', 'vr': 'ST', name: 'RespiratoryMotionCompensationTechniqueDescription' },
            '0x00189186': { 'vm': '1', 'vr': 'SH', name: 'RespiratorySignalSourceID' },
            '0x00189195': { 'vm': '1', 'vr': 'FD', name: 'ChemicalShiftMinimumIntegrationLimitinHz' },
            '0x00189196': { 'vm': '1', 'vr': 'FD', name: 'ChemicalShiftMaximumIntegrationLimitinHz' },
            '0x00189197': { 'vm': '1', 'vr': 'SQ', name: 'MRVelocityEncodingSequence' },
            '0x00189198': { 'vm': '1', 'vr': 'CS', name: 'FirstOrderPhaseCorrection' },
            '0x00189199': { 'vm': '1', 'vr': 'CS', name: 'WaterReferencedPhaseCorrection' },
            '0x00189200': { 'vm': '1', 'vr': 'CS', name: 'MRSpectroscopyAcquisitionType' },
            '0x00189214': { 'vm': '1', 'vr': 'CS', name: 'RespiratoryCyclePosition' },
            '0x00189217': { 'vm': '1', 'vr': 'FD', name: 'VelocityEncodingMaximumValue' },
            '0x00189218': { 'vm': '1', 'vr': 'FD', name: 'TagSpacingSecondDimension' },
            '0x00189219': { 'vm': '1', 'vr': 'SS', name: 'TagAngleSecondAxis' },
            '0x00189220': { 'vm': '1', 'vr': 'FD', name: 'FrameAcquisitionDuration' },
            '0x00189226': { 'vm': '1', 'vr': 'SQ', name: 'MRImageFrameTypeSequence' },
            '0x00189227': { 'vm': '1', 'vr': 'SQ', name: 'MRSpectroscopyFrameTypeSequence' },
            '0x00189231': { 'vm': '1', 'vr': 'US', name: 'MRAcquisitionPhaseEncodingStepsinplane' },
            '0x00189232': { 'vm': '1', 'vr': 'US', name: 'MRAcquisitionPhaseEncodingStepsoutofplane' },
            '0x00189234': { 'vm': '1', 'vr': 'UL', name: 'SpectroscopyAcquisitionPhaseColumns' },
            '0x00189236': { 'vm': '1', 'vr': 'CS', name: 'CardiacCyclePosition' },
            '0x00189239': { 'vm': '1', 'vr': 'SQ', name: 'SpecificAbsorptionRateSequence' },
            '0x00189240': { 'vm': '1', 'vr': 'US', name: 'RFEchoTrainLength' },
            '0x00189241': { 'vm': '1', 'vr': 'US', name: 'GradientEchoTrainLength' },
            '0x00189250': { 'vm': '1', 'vr': 'CS', name: 'ArterialSpinLabelingContrast' },
            '0x00189251': { 'vm': '1', 'vr': 'SQ', name: 'MRArterialSpinLabelingSequence' },
            '0x00189252': { 'vm': '1', 'vr': 'LO', name: 'ASLTechniqueDescription' },
            '0x00189253': { 'vm': '1', 'vr': 'US', name: 'ASLSlabNumber' },
            '0x00189254': { 'vm': '1', 'vr': 'FD', name: 'ASLSlabThickness' },
            '0x00189255': { 'vm': '3', 'vr': 'FD', name: 'ASLSlabOrientation' },
            '0x00189256': { 'vm': '3', 'vr': 'FD', name: 'ASLMidSlabPosition' },
            '0x00189257': { 'vm': '1', 'vr': 'CS', name: 'ASLContext' },
            '0x00189258': { 'vm': '1', 'vr': 'UL', name: 'ASLPulseTrainDuration' },
            '0x00189259': { 'vm': '1', 'vr': 'CS', name: 'ASLCrusherFlag' },
            '0x0018925A': { 'vm': '1', 'vr': 'FD', name: 'ASLCrusherFlowLimit' },
            '0x0018925B': { 'vm': '1', 'vr': 'LO', name: 'ASLCrusherDescription' },
            '0x0018925C': { 'vm': '1', 'vr': 'CS', name: 'ASLBolusCutoffFlag' },
            '0x0018925D': { 'vm': '1', 'vr': 'SQ', name: 'ASLBolusCutoffTimingSequence' },
            '0x0018925E': { 'vm': '1', 'vr': 'LO', name: 'ASLBolusCutoffTechnique' },
            '0x0018925F': { 'vm': '1', 'vr': 'UL', name: 'ASLBolusCutoffDelayTime' },
            '0x00189260': { 'vm': '1', 'vr': 'SQ', name: 'ASLSlabSequence' },
            '0x00189295': { 'vm': '1', 'vr': 'FD', name: 'ChemicalShiftMinimumIntegrationLimitinppm' },
            '0x00189296': { 'vm': '1', 'vr': 'FD', name: 'ChemicalShiftMaximumIntegrationLimitinppm' },
            '0x00189297': { 'vm': '1', 'vr': 'CS', name: 'WaterReferenceAcquisition' },
            '0x00189298': { 'vm': '1', 'vr': 'IS', name: 'EchoPeakPosition' },
            '0x00189301': { 'vm': '1', 'vr': 'SQ', name: 'CTAcquisitionTypeSequence' },
            '0x00189302': { 'vm': '1', 'vr': 'CS', name: 'AcquisitionType' },
            '0x00189303': { 'vm': '1', 'vr': 'FD', name: 'TubeAngle' },
            '0x00189304': { 'vm': '1', 'vr': 'SQ', name: 'CTAcquisitionDetailsSequence' },
            '0x00189305': { 'vm': '1', 'vr': 'FD', name: 'RevolutionTime' },
            '0x00189306': { 'vm': '1', 'vr': 'FD', name: 'SingleCollimationWidth' },
            '0x00189307': { 'vm': '1', 'vr': 'FD', name: 'TotalCollimationWidth' },
            '0x00189308': { 'vm': '1', 'vr': 'SQ', name: 'CTTableDynamicsSequence' },
            '0x00189309': { 'vm': '1', 'vr': 'FD', name: 'TableSpeed' },
            '0x00189310': { 'vm': '1', 'vr': 'FD', name: 'TableFeedperRotation' },
            '0x00189311': { 'vm': '1', 'vr': 'FD', name: 'SpiralPitchFactor' },
            '0x00189312': { 'vm': '1', 'vr': 'SQ', name: 'CTGeometrySequence' },
            '0x00189313': { 'vm': '3', 'vr': 'FD', name: 'DataCollectionCenterPatient' },
            '0x00189314': { 'vm': '1', 'vr': 'SQ', name: 'CTReconstructionSequence' },
            '0x00189315': { 'vm': '1', 'vr': 'CS', name: 'ReconstructionAlgorithm' },
            '0x00189316': { 'vm': '1', 'vr': 'CS', name: 'ConvolutionKernelGroup' },
            '0x00189317': { 'vm': '2', 'vr': 'FD', name: 'ReconstructionFieldofView' },
            '0x00189318': { 'vm': '3', 'vr': 'FD', name: 'ReconstructionTargetCenterPatient' },
            '0x00189319': { 'vm': '1', 'vr': 'FD', name: 'ReconstructionAngle' },
            '0x00189320': { 'vm': '1', 'vr': 'SH', name: 'ImageFilter' },
            '0x00189321': { 'vm': '1', 'vr': 'SQ', name: 'CTExposureSequence' },
            '0x00189322': { 'vm': '2', 'vr': 'FD', name: 'ReconstructionPixelSpacing' },
            '0x00189323': { 'vm': '1-n', 'vr': 'CS', name: 'ExposureModulationType' },
            '0x00189324': { 'vm': '1', 'vr': 'FD', name: 'EstimatedDoseSaving' },
            '0x00189325': { 'vm': '1', 'vr': 'SQ', name: 'CTXRayDetailsSequence' },
            '0x00189326': { 'vm': '1', 'vr': 'SQ', name: 'CTPositionSequence' },
            '0x00189327': { 'vm': '1', 'vr': 'FD', name: 'TablePosition' },
            '0x00189328': { 'vm': '1', 'vr': 'FD', name: 'ExposureTimeinms' },
            '0x00189329': { 'vm': '1', 'vr': 'SQ', name: 'CTImageFrameTypeSequence' },
            '0x00189330': { 'vm': '1', 'vr': 'FD', name: 'XRayTubeCurrentinmA' },
            '0x00189332': { 'vm': '1', 'vr': 'FD', name: 'ExposureinmAs' },
            '0x00189333': { 'vm': '1', 'vr': 'CS', name: 'ConstantVolumeFlag' },
            '0x00189334': { 'vm': '1', 'vr': 'CS', name: 'FluoroscopyFlag' },
            '0x00189335': { 'vm': '1', 'vr': 'FD', name: 'DistanceSourcetoDataCollectionCenter' },
            '0x00189337': { 'vm': '1', 'vr': 'US', name: 'ContrastBolusAgentNumber' },
            '0x00189338': { 'vm': '1', 'vr': 'SQ', name: 'ContrastBolusIngredientCodeSequence' },
            '0x00189340': { 'vm': '1', 'vr': 'SQ', name: 'ContrastAdministrationProfileSequence' },
            '0x00189341': { 'vm': '1', 'vr': 'SQ', name: 'ContrastBolusUsageSequence' },
            '0x00189342': { 'vm': '1', 'vr': 'CS', name: 'ContrastBolusAgentAdministered' },
            '0x00189343': { 'vm': '1', 'vr': 'CS', name: 'ContrastBolusAgentDetected' },
            '0x00189344': { 'vm': '1', 'vr': 'CS', name: 'ContrastBolusAgentPhase' },
            '0x00189345': { 'vm': '1', 'vr': 'FD', name: 'CTDIvol' },
            '0x00189346': { 'vm': '1', 'vr': 'SQ', name: 'CTDIPhantomTypeCodeSequence' },
            '0x00189351': { 'vm': '1', 'vr': 'FL', name: 'CalciumScoringMassFactorPatient' },
            '0x00189352': { 'vm': '3', 'vr': 'FL', name: 'CalciumScoringMassFactorDevice' },
            '0x00189353': { 'vm': '1', 'vr': 'FL', name: 'EnergyWeightingFactor' },
            '0x00189360': { 'vm': '1', 'vr': 'SQ', name: 'CTAdditionalXRaySourceSequence' },
            '0x00189361': { 'vm': '1', 'vr': 'CS', name: 'MultienergyCTAcquisition' },
            '0x00189362': { 'vm': '1', 'vr': 'SQ', name: 'MultienergyCTAcquisitionSequence' },
            '0x00189363': { 'vm': '1', 'vr': 'SQ', name: 'MultienergyCTProcessingSequence' },
            '0x00189364': { 'vm': '1', 'vr': 'SQ', name: 'MultienergyCTCharacteristicsSequence' },
            '0x00189365': { 'vm': '1', 'vr': 'SQ', name: 'MultienergyCTXRaySourceSequence' },
            '0x00189366': { 'vm': '1', 'vr': 'US', name: 'XRaySourceIndex' },
            '0x00189367': { 'vm': '1', 'vr': 'UC', name: 'XRaySourceID' },
            '0x00189368': { 'vm': '1', 'vr': 'CS', name: 'MultienergySourceTechnique' },
            '0x00189369': { 'vm': '1', 'vr': 'DT', name: 'SourceStartDateTime' },
            '0x0018936A': { 'vm': '1', 'vr': 'DT', name: 'SourceEndDateTime' },
            '0x0018936B': { 'vm': '1', 'vr': 'US', name: 'SwitchingPhaseNumber' },
            '0x0018936C': { 'vm': '1', 'vr': 'DS', name: 'SwitchingPhaseNominalDuration' },
            '0x0018936D': { 'vm': '1', 'vr': 'DS', name: 'SwitchingPhaseTransitionDuration' },
            '0x0018936E': { 'vm': '1', 'vr': 'DS', name: 'EffectiveBinEnergy' },
            '0x0018936F': { 'vm': '1', 'vr': 'SQ', name: 'MultienergyCTXRayDetectorSequence' },
            '0x00189370': { 'vm': '1', 'vr': 'US', name: 'XRayDetectorIndex' },
            '0x00189371': { 'vm': '1', 'vr': 'UC', name: 'XRayDetectorID' },
            '0x00189372': { 'vm': '1', 'vr': 'CS', name: 'MultienergyDetectorType' },
            '0x00189373': { 'vm': '1', 'vr': 'ST', name: 'XRayDetectorLabel' },
            '0x00189374': { 'vm': '1', 'vr': 'DS', name: 'NominalMaxEnergy' },
            '0x00189375': { 'vm': '1', 'vr': 'DS', name: 'NominalMinEnergy' },
            '0x00189376': { 'vm': '1-n', 'vr': 'US', name: 'ReferencedXRayDetectorIndex' },
            '0x00189377': { 'vm': '1-n', 'vr': 'US', name: 'ReferencedXRaySourceIndex' },
            '0x00189378': { 'vm': '1-n', 'vr': 'US', name: 'ReferencedPathIndex' },
            '0x00189379': { 'vm': '1', 'vr': 'SQ', name: 'MultienergyCTPathSequence' },
            '0x0018937A': { 'vm': '1', 'vr': 'US', name: 'MultienergyCTPathIndex' },
            '0x0018937B': { 'vm': '1', 'vr': 'UT', name: 'MultienergyAcquisitionDescription' },
            '0x0018937C': { 'vm': '1', 'vr': 'FD', name: 'MonoenergeticEnergyEquivalent' },
            '0x0018937D': { 'vm': '1', 'vr': 'SQ', name: 'MaterialCodeSequence' },
            '0x0018937E': { 'vm': '1', 'vr': 'CS', name: 'DecompositionMethod' },
            '0x0018937F': { 'vm': '1', 'vr': 'UT', name: 'DecompositionDescription' },
            '0x00189380': { 'vm': '1', 'vr': 'SQ', name: 'DecompositionAlgorithmIdentificationSequence' },
            '0x00189381': { 'vm': '1', 'vr': 'SQ', name: 'DecompositionMaterialSequence' },
            '0x00189382': { 'vm': '1', 'vr': 'SQ', name: 'MaterialAttenuationSequence' },
            '0x00189383': { 'vm': '1', 'vr': 'DS', name: 'PhotonEnergy' },
            '0x00189384': { 'vm': '1', 'vr': 'DS', name: 'XRayMassAttenuationCoefficient' },
            '0x00189401': { 'vm': '1', 'vr': 'SQ', name: 'ProjectionPixelCalibrationSequence' },
            '0x00189402': { 'vm': '1', 'vr': 'FL', name: 'DistanceSourcetoIsocenter' },
            '0x00189403': { 'vm': '1', 'vr': 'FL', name: 'DistanceObjecttoTableTop' },
            '0x00189404': { 'vm': '2', 'vr': 'FL', name: 'ObjectPixelSpacinginCenterofBeam' },
            '0x00189405': { 'vm': '1', 'vr': 'SQ', name: 'PositionerPositionSequence' },
            '0x00189406': { 'vm': '1', 'vr': 'SQ', name: 'TablePositionSequence' },
            '0x00189407': { 'vm': '1', 'vr': 'SQ', name: 'CollimatorShapeSequence' },
            '0x00189410': { 'vm': '1', 'vr': 'CS', name: 'PlanesinAcquisition' },
            '0x00189412': { 'vm': '1', 'vr': 'SQ', name: 'XAXRFFrameCharacteristicsSequence' },
            '0x00189417': { 'vm': '1', 'vr': 'SQ', name: 'FrameAcquisitionSequence' },
            '0x00189420': { 'vm': '1', 'vr': 'CS', name: 'XRayReceptorType' },
            '0x00189423': { 'vm': '1', 'vr': 'LO', name: 'AcquisitionProtocolName' },
            '0x00189424': { 'vm': '1', 'vr': 'LT', name: 'AcquisitionProtocolDescription' },
            '0x00189425': { 'vm': '1', 'vr': 'CS', name: 'ContrastBolusIngredientOpaque' },
            '0x00189426': { 'vm': '1', 'vr': 'FL', name: 'DistanceReceptorPlanetoDetectorHousing' },
            '0x00189427': { 'vm': '1', 'vr': 'CS', name: 'IntensifierActiveShape' },
            '0x00189428': { 'vm': '1-2', 'vr': 'FL', name: 'IntensifierActiveDimensions' },
            '0x00189429': { 'vm': '2', 'vr': 'FL', name: 'PhysicalDetectorSize' },
            '0x00189430': { 'vm': '2', 'vr': 'FL', name: 'PositionofIsocenterProjection' },
            '0x00189432': { 'vm': '1', 'vr': 'SQ', name: 'FieldofViewSequence' },
            '0x00189433': { 'vm': '1', 'vr': 'LO', name: 'FieldofViewDescription' },
            '0x00189434': { 'vm': '1', 'vr': 'SQ', name: 'ExposureControlSensingRegionsSequence' },
            '0x00189435': { 'vm': '1', 'vr': 'CS', name: 'ExposureControlSensingRegionShape' },
            '0x00189436': { 'vm': '1', 'vr': 'SS', name: 'ExposureControlSensingRegionLeftVerticalEdge' },
            '0x00189437': { 'vm': '1', 'vr': 'SS', name: 'ExposureControlSensingRegionRightVerticalEdge' },
            '0x00189438': { 'vm': '1', 'vr': 'SS', name: 'ExposureControlSensingRegionUpperHorizontalEdge' },
            '0x00189439': { 'vm': '1', 'vr': 'SS', name: 'ExposureControlSensingRegionLowerHorizontalEdge' },
            '0x00189440': { 'vm': '2', 'vr': 'SS', name: 'CenterofCircularExposureControlSensingRegion' },
            '0x00189441': { 'vm': '1', 'vr': 'US', name: 'RadiusofCircularExposureControlSensingRegion' },
            '0x00189442': { 'vm': '2-n', 'vr': 'SS', name: 'VerticesofthePolygonalExposureControlSensingRegion' },
            '0x00189447': { 'vm': '1', 'vr': 'FL', name: 'ColumnAngulationPatient' },
            '0x00189449': { 'vm': '1', 'vr': 'FL', name: 'BeamAngle' },
            '0x00189451': { 'vm': '1', 'vr': 'SQ', name: 'FrameDetectorParametersSequence' },
            '0x00189452': { 'vm': '1', 'vr': 'FL', name: 'CalculatedAnatomyThickness' },
            '0x00189455': { 'vm': '1', 'vr': 'SQ', name: 'CalibrationSequence' },
            '0x00189456': { 'vm': '1', 'vr': 'SQ', name: 'ObjectThicknessSequence' },
            '0x00189457': { 'vm': '1', 'vr': 'CS', name: 'PlaneIdentification' },
            '0x00189461': { 'vm': '1-2', 'vr': 'FL', name: 'FieldofViewDimensionsinFloat' },
            '0x00189462': { 'vm': '1', 'vr': 'SQ', name: 'IsocenterReferenceSystemSequence' },
            '0x00189463': { 'vm': '1', 'vr': 'FL', name: 'PositionerIsocenterPrimaryAngle' },
            '0x00189464': { 'vm': '1', 'vr': 'FL', name: 'PositionerIsocenterSecondaryAngle' },
            '0x00189465': { 'vm': '1', 'vr': 'FL', name: 'PositionerIsocenterDetectorRotationAngle' },
            '0x00189466': { 'vm': '1', 'vr': 'FL', name: 'TableXPositiontoIsocenter' },
            '0x00189467': { 'vm': '1', 'vr': 'FL', name: 'TableYPositiontoIsocenter' },
            '0x00189468': { 'vm': '1', 'vr': 'FL', name: 'TableZPositiontoIsocenter' },
            '0x00189469': { 'vm': '1', 'vr': 'FL', name: 'TableHorizontalRotationAngle' },
            '0x00189470': { 'vm': '1', 'vr': 'FL', name: 'TableHeadTiltAngle' },
            '0x00189471': { 'vm': '1', 'vr': 'FL', name: 'TableCradleTiltAngle' },
            '0x00189472': { 'vm': '1', 'vr': 'SQ', name: 'FrameDisplayShutterSequence' },
            '0x00189473': { 'vm': '1', 'vr': 'FL', name: 'AcquiredImageAreaDoseProduct' },
            '0x00189474': { 'vm': '1', 'vr': 'CS', name: 'CarmPositionerTabletopRelationship' },
            '0x00189476': { 'vm': '1', 'vr': 'SQ', name: 'XRayGeometrySequence' },
            '0x00189477': { 'vm': '1', 'vr': 'SQ', name: 'IrradiationEventIdentificationSequence' },
            '0x00189504': { 'vm': '1', 'vr': 'SQ', name: 'XRay3DFrameTypeSequence' },
            '0x00189506': { 'vm': '1', 'vr': 'SQ', name: 'ContributingSourcesSequence' },
            '0x00189507': { 'vm': '1', 'vr': 'SQ', name: 'XRay3DAcquisitionSequence' },
            '0x00189508': { 'vm': '1', 'vr': 'FL', name: 'PrimaryPositionerScanArc' },
            '0x00189509': { 'vm': '1', 'vr': 'FL', name: 'SecondaryPositionerScanArc' },
            '0x00189510': { 'vm': '1', 'vr': 'FL', name: 'PrimaryPositionerScanStartAngle' },
            '0x00189511': { 'vm': '1', 'vr': 'FL', name: 'SecondaryPositionerScanStartAngle' },
            '0x00189514': { 'vm': '1', 'vr': 'FL', name: 'PrimaryPositionerIncrement' },
            '0x00189515': { 'vm': '1', 'vr': 'FL', name: 'SecondaryPositionerIncrement' },
            '0x00189516': { 'vm': '1', 'vr': 'DT', name: 'StartAcquisitionDateTime' },
            '0x00189517': { 'vm': '1', 'vr': 'DT', name: 'EndAcquisitionDateTime' },
            '0x00189518': { 'vm': '1', 'vr': 'SS', name: 'PrimaryPositionerIncrementSign' },
            '0x00189519': { 'vm': '1', 'vr': 'SS', name: 'SecondaryPositionerIncrementSign' },
            '0x00189524': { 'vm': '1', 'vr': 'LO', name: 'ApplicationName' },
            '0x00189525': { 'vm': '1', 'vr': 'LO', name: 'ApplicationVersion' },
            '0x00189526': { 'vm': '1', 'vr': 'LO', name: 'ApplicationManufacturer' },
            '0x00189527': { 'vm': '1', 'vr': 'CS', name: 'AlgorithmType' },
            '0x00189528': { 'vm': '1', 'vr': 'LO', name: 'AlgorithmDescription' },
            '0x00189530': { 'vm': '1', 'vr': 'SQ', name: 'XRay3DReconstructionSequence' },
            '0x00189531': { 'vm': '1', 'vr': 'LO', name: 'ReconstructionDescription' },
            '0x00189538': { 'vm': '1', 'vr': 'SQ', name: 'PerProjectionAcquisitionSequence' },
            '0x00189541': { 'vm': '1', 'vr': 'SQ', name: 'DetectorPositionSequence' },
            '0x00189542': { 'vm': '1', 'vr': 'SQ', name: 'XRayAcquisitionDoseSequence' },
            '0x00189543': { 'vm': '1', 'vr': 'FD', name: 'XRaySourceIsocenterPrimaryAngle' },
            '0x00189544': { 'vm': '1', 'vr': 'FD', name: 'XRaySourceIsocenterSecondaryAngle' },
            '0x00189545': { 'vm': '1', 'vr': 'FD', name: 'BreastSupportIsocenterPrimaryAngle' },
            '0x00189546': { 'vm': '1', 'vr': 'FD', name: 'BreastSupportIsocenterSecondaryAngle' },
            '0x00189547': { 'vm': '1', 'vr': 'FD', name: 'BreastSupportXPositiontoIsocenter' },
            '0x00189548': { 'vm': '1', 'vr': 'FD', name: 'BreastSupportYPositiontoIsocenter' },
            '0x00189549': { 'vm': '1', 'vr': 'FD', name: 'BreastSupportZPositiontoIsocenter' },
            '0x00189550': { 'vm': '1', 'vr': 'FD', name: 'DetectorIsocenterPrimaryAngle' },
            '0x00189551': { 'vm': '1', 'vr': 'FD', name: 'DetectorIsocenterSecondaryAngle' },
            '0x00189552': { 'vm': '1', 'vr': 'FD', name: 'DetectorXPositiontoIsocenter' },
            '0x00189553': { 'vm': '1', 'vr': 'FD', name: 'DetectorYPositiontoIsocenter' },
            '0x00189554': { 'vm': '1', 'vr': 'FD', name: 'DetectorZPositiontoIsocenter' },
            '0x00189555': { 'vm': '1', 'vr': 'SQ', name: 'XRayGridSequence' },
            '0x00189556': { 'vm': '1', 'vr': 'SQ', name: 'XRayFilterSequence' },
            '0x00189557': { 'vm': '3', 'vr': 'FD', name: 'DetectorActiveAreaTLHCPosition' },
            '0x00189558': { 'vm': '6', 'vr': 'FD', name: 'DetectorActiveAreaOrientation' },
            '0x00189559': { 'vm': '1', 'vr': 'CS', name: 'PositionerPrimaryAngleDirection' },
            '0x00189601': { 'vm': '1', 'vr': 'SQ', name: 'DiffusionbmatrixSequence' },
            '0x00189602': { 'vm': '1', 'vr': 'FD', name: 'DiffusionbvalueXX' },
            '0x00189603': { 'vm': '1', 'vr': 'FD', name: 'DiffusionbvalueXY' },
            '0x00189604': { 'vm': '1', 'vr': 'FD', name: 'DiffusionbvalueXZ' },
            '0x00189605': { 'vm': '1', 'vr': 'FD', name: 'DiffusionbvalueYY' },
            '0x00189606': { 'vm': '1', 'vr': 'FD', name: 'DiffusionbvalueYZ' },
            '0x00189607': { 'vm': '1', 'vr': 'FD', name: 'DiffusionbvalueZZ' },
            '0x00189621': { 'vm': '1', 'vr': 'SQ', name: 'FunctionalMRSequence' },
            '0x00189622': { 'vm': '1', 'vr': 'CS', name: 'FunctionalSettlingPhaseFramesPresent' },
            '0x00189623': { 'vm': '1', 'vr': 'DT', name: 'FunctionalSyncPulse' },
            '0x00189624': { 'vm': '1', 'vr': 'CS', name: 'SettlingPhaseFrame' },
            '0x00189701': { 'vm': '1', 'vr': 'DT', name: 'DecayCorrectionDateTime' },
            '0x00189715': { 'vm': '1', 'vr': 'FD', name: 'StartDensityThreshold' },
            '0x00189716': { 'vm': '1', 'vr': 'FD', name: 'StartRelativeDensityDifferenceThreshold' },
            '0x00189717': { 'vm': '1', 'vr': 'FD', name: 'StartCardiacTriggerCountThreshold' },
            '0x00189718': { 'vm': '1', 'vr': 'FD', name: 'StartRespiratoryTriggerCountThreshold' },
            '0x00189719': { 'vm': '1', 'vr': 'FD', name: 'TerminationCountsThreshold' },
            '0x00189720': { 'vm': '1', 'vr': 'FD', name: 'TerminationDensityThreshold' },
            '0x00189721': { 'vm': '1', 'vr': 'FD', name: 'TerminationRelativeDensityThreshold' },
            '0x00189722': { 'vm': '1', 'vr': 'FD', name: 'TerminationTimeThreshold' },
            '0x00189723': { 'vm': '1', 'vr': 'FD', name: 'TerminationCardiacTriggerCountThreshold' },
            '0x00189724': { 'vm': '1', 'vr': 'FD', name: 'TerminationRespiratoryTriggerCountThreshold' },
            '0x00189725': { 'vm': '1', 'vr': 'CS', name: 'DetectorGeometry' },
            '0x00189726': { 'vm': '1', 'vr': 'FD', name: 'TransverseDetectorSeparation' },
            '0x00189727': { 'vm': '1', 'vr': 'FD', name: 'AxialDetectorDimension' },
            '0x00189729': { 'vm': '1', 'vr': 'US', name: 'RadiopharmaceuticalAgentNumber' },
            '0x00189732': { 'vm': '1', 'vr': 'SQ', name: 'PETFrameAcquisitionSequence' },
            '0x00189733': { 'vm': '1', 'vr': 'SQ', name: 'PETDetectorMotionDetailsSequence' },
            '0x00189734': { 'vm': '1', 'vr': 'SQ', name: 'PETTableDynamicsSequence' },
            '0x00189735': { 'vm': '1', 'vr': 'SQ', name: 'PETPositionSequence' },
            '0x00189736': { 'vm': '1', 'vr': 'SQ', name: 'PETFrameCorrectionFactorsSequence' },
            '0x00189737': { 'vm': '1', 'vr': 'SQ', name: 'RadiopharmaceuticalUsageSequence' },
            '0x00189738': { 'vm': '1', 'vr': 'CS', name: 'AttenuationCorrectionSource' },
            '0x00189739': { 'vm': '1', 'vr': 'US', name: 'NumberofIterations' },
            '0x00189740': { 'vm': '1', 'vr': 'US', name: 'NumberofSubsets' },
            '0x00189749': { 'vm': '1', 'vr': 'SQ', name: 'PETReconstructionSequence' },
            '0x00189751': { 'vm': '1', 'vr': 'SQ', name: 'PETFrameTypeSequence' },
            '0x00189755': { 'vm': '1', 'vr': 'CS', name: 'TimeofFlightInformationUsed' },
            '0x00189756': { 'vm': '1', 'vr': 'CS', name: 'ReconstructionType' },
            '0x00189758': { 'vm': '1', 'vr': 'CS', name: 'DecayCorrected' },
            '0x00189759': { 'vm': '1', 'vr': 'CS', name: 'AttenuationCorrected' },
            '0x00189760': { 'vm': '1', 'vr': 'CS', name: 'ScatterCorrected' },
            '0x00189761': { 'vm': '1', 'vr': 'CS', name: 'DeadTimeCorrected' },
            '0x00189762': { 'vm': '1', 'vr': 'CS', name: 'GantryMotionCorrected' },
            '0x00189763': { 'vm': '1', 'vr': 'CS', name: 'PatientMotionCorrected' },
            '0x00189764': { 'vm': '1', 'vr': 'CS', name: 'CountLossNormalizationCorrected' },
            '0x00189765': { 'vm': '1', 'vr': 'CS', name: 'RandomsCorrected' },
            '0x00189766': { 'vm': '1', 'vr': 'CS', name: 'NonuniformRadialSamplingCorrected' },
            '0x00189767': { 'vm': '1', 'vr': 'CS', name: 'SensitivityCalibrated' },
            '0x00189768': { 'vm': '1', 'vr': 'CS', name: 'DetectorNormalizationCorrection' },
            '0x00189769': { 'vm': '1', 'vr': 'CS', name: 'IterativeReconstructionMethod' },
            '0x00189770': { 'vm': '1', 'vr': 'CS', name: 'AttenuationCorrectionTemporalRelationship' },
            '0x00189771': { 'vm': '1', 'vr': 'SQ', name: 'PatientPhysiologicalStateSequence' },
            '0x00189772': { 'vm': '1', 'vr': 'SQ', name: 'PatientPhysiologicalStateCodeSequence' },
            '0x00189801': { 'vm': '1-n', 'vr': 'FD', name: 'DepthsofFocus' },
            '0x00189803': { 'vm': '1', 'vr': 'SQ', name: 'ExcludedIntervalsSequence' },
            '0x00189804': { 'vm': '1', 'vr': 'DT', name: 'ExclusionStartDateTime' },
            '0x00189805': { 'vm': '1', 'vr': 'FD', name: 'ExclusionDuration' },
            '0x00189806': { 'vm': '1', 'vr': 'SQ', name: 'USImageDescriptionSequence' },
            '0x00189807': { 'vm': '1', 'vr': 'SQ', name: 'ImageDataTypeSequence' },
            '0x00189808': { 'vm': '1', 'vr': 'CS', name: 'DataType' },
            '0x00189809': { 'vm': '1', 'vr': 'SQ', name: 'TransducerScanPatternCodeSequence' },
            '0x0018980B': { 'vm': '1', 'vr': 'CS', name: 'AliasedDataType' },
            '0x0018980C': { 'vm': '1', 'vr': 'CS', name: 'PositionMeasuringDeviceUsed' },
            '0x0018980D': { 'vm': '1', 'vr': 'SQ', name: 'TransducerGeometryCodeSequence' },
            '0x0018980E': { 'vm': '1', 'vr': 'SQ', name: 'TransducerBeamSteeringCodeSequence' },
            '0x0018980F': { 'vm': '1', 'vr': 'SQ', name: 'TransducerApplicationCodeSequence' },
            '0x00189810': { 'vm': '1', 'vr': 'US', name: 'ZeroVelocityPixelValue' },
            '0x00189900': { 'vm': '1', 'vr': 'LO', name: 'ReferenceLocationLabel' },
            '0x00189901': { 'vm': '1', 'vr': 'UT', name: 'ReferenceLocationDescription' },
            '0x00189902': { 'vm': '1', 'vr': 'SQ', name: 'ReferenceBasisCodeSequence' },
            '0x00189903': { 'vm': '1', 'vr': 'SQ', name: 'ReferenceGeometryCodeSequence' },
            '0x00189904': { 'vm': '1', 'vr': 'DS', name: 'OffsetDistance' },
            '0x00189905': { 'vm': '1', 'vr': 'CS', name: 'OffsetDirection' },
            '0x00189906': { 'vm': '1', 'vr': 'SQ', name: 'PotentialScheduledProtocolCodeSequence' },
            '0x00189907': { 'vm': '1', 'vr': 'SQ', name: 'PotentialRequestedProcedureCodeSequence' },
            '0x00189908': { 'vm': '1-n', 'vr': 'UC', name: 'PotentialReasonsforProcedure' },
            '0x00189909': { 'vm': '1', 'vr': 'SQ', name: 'PotentialReasonsforProcedureCodeSequence' },
            '0x0018990A': { 'vm': '1-n', 'vr': 'UC', name: 'PotentialDiagnosticTasks' },
            '0x0018990B': { 'vm': '1', 'vr': 'SQ', name: 'ContraindicationsCodeSequence' },
            '0x0018990C': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedDefinedProtocolSequence' },
            '0x0018990D': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPerformedProtocolSequence' },
            '0x0018990E': { 'vm': '1', 'vr': 'SQ', name: 'PredecessorProtocolSequence' },
            '0x0018990F': { 'vm': '1', 'vr': 'UT', name: 'ProtocolPlanningInformation' },
            '0x00189910': { 'vm': '1', 'vr': 'UT', name: 'ProtocolDesignRationale' },
            '0x00189911': { 'vm': '1', 'vr': 'SQ', name: 'PatientSpecificationSequence' },
            '0x00189912': { 'vm': '1', 'vr': 'SQ', name: 'ModelSpecificationSequence' },
            '0x00189913': { 'vm': '1', 'vr': 'SQ', name: 'ParametersSpecificationSequence' },
            '0x00189914': { 'vm': '1', 'vr': 'SQ', name: 'InstructionSequence' },
            '0x00189915': { 'vm': '1', 'vr': 'US', name: 'InstructionIndex' },
            '0x00189916': { 'vm': '1', 'vr': 'LO', name: 'InstructionText' },
            '0x00189917': { 'vm': '1', 'vr': 'UT', name: 'InstructionDescription' },
            '0x00189918': { 'vm': '1', 'vr': 'CS', name: 'InstructionPerformedFlag' },
            '0x00189919': { 'vm': '1', 'vr': 'DT', name: 'InstructionPerformedDateTime' },
            '0x0018991A': { 'vm': '1', 'vr': 'UT', name: 'InstructionPerformanceComment' },
            '0x0018991B': { 'vm': '1', 'vr': 'SQ', name: 'PatientPositioningInstructionSequence' },
            '0x0018991C': { 'vm': '1', 'vr': 'SQ', name: 'PositioningMethodCodeSequence' },
            '0x0018991D': { 'vm': '1', 'vr': 'SQ', name: 'PositioningLandmarkSequence' },
            '0x0018991E': { 'vm': '1', 'vr': 'UI', name: 'TargetFrameofReferenceUID' },
            '0x0018991F': { 'vm': '1', 'vr': 'SQ', name: 'AcquisitionProtocolElementSpecificationSequence' },
            '0x00189920': { 'vm': '1', 'vr': 'SQ', name: 'AcquisitionProtocolElementSequence' },
            '0x00189921': { 'vm': '1', 'vr': 'US', name: 'ProtocolElementNumber' },
            '0x00189922': { 'vm': '1', 'vr': 'LO', name: 'ProtocolElementName' },
            '0x00189923': { 'vm': '1', 'vr': 'UT', name: 'ProtocolElementCharacteristicsSummary' },
            '0x00189924': { 'vm': '1', 'vr': 'UT', name: 'ProtocolElementPurpose' },
            '0x00189930': { 'vm': '1', 'vr': 'CS', name: 'AcquisitionMotion' },
            '0x00189931': { 'vm': '1', 'vr': 'SQ', name: 'AcquisitionStartLocationSequence' },
            '0x00189932': { 'vm': '1', 'vr': 'SQ', name: 'AcquisitionEndLocationSequence' },
            '0x00189933': { 'vm': '1', 'vr': 'SQ', name: 'ReconstructionProtocolElementSpecificationSequence' },
            '0x00189934': { 'vm': '1', 'vr': 'SQ', name: 'ReconstructionProtocolElementSequence' },
            '0x00189935': { 'vm': '1', 'vr': 'SQ', name: 'StorageProtocolElementSpecificationSequence' },
            '0x00189936': { 'vm': '1', 'vr': 'SQ', name: 'StorageProtocolElementSequence' },
            '0x00189937': { 'vm': '1', 'vr': 'LO', name: 'RequestedSeriesDescription' },
            '0x00189938': { 'vm': '1-n', 'vr': 'US', name: 'SourceAcquisitionProtocolElementNumber' },
            '0x00189939': { 'vm': '1-n', 'vr': 'US', name: 'SourceAcquisitionBeamNumber' },
            '0x0018993A': { 'vm': '1-n', 'vr': 'US', name: 'SourceReconstructionProtocolElementNumber' },
            '0x0018993B': { 'vm': '1', 'vr': 'SQ', name: 'ReconstructionStartLocationSequence' },
            '0x0018993C': { 'vm': '1', 'vr': 'SQ', name: 'ReconstructionEndLocationSequence' },
            '0x0018993D': { 'vm': '1', 'vr': 'SQ', name: 'ReconstructionAlgorithmSequence' },
            '0x0018993E': { 'vm': '1', 'vr': 'SQ', name: 'ReconstructionTargetCenterLocationSequence' },
            '0x00189941': { 'vm': '1', 'vr': 'UT', name: 'ImageFilterDescription' },
            '0x00189942': { 'vm': '1', 'vr': 'FD', name: 'CTDIvolNotificationTrigger' },
            '0x00189943': { 'vm': '1', 'vr': 'FD', name: 'DLPNotificationTrigger' },
            '0x00189944': { 'vm': '1', 'vr': 'CS', name: 'AutoKVPSelectionType' },
            '0x00189945': { 'vm': '1', 'vr': 'FD', name: 'AutoKVPUpperBound' },
            '0x00189946': { 'vm': '1', 'vr': 'FD', name: 'AutoKVPLowerBound' },
            '0x00189947': { 'vm': '1', 'vr': 'CS', name: 'ProtocolDefinedPatientPosition' },
            '0x0018A001': { 'vm': '1', 'vr': 'SQ', name: 'ContributingEquipmentSequence' },
            '0x0018A002': { 'vm': '1', 'vr': 'DT', name: 'ContributionDateTime' },
            '0x0018A003': { 'vm': '1', 'vr': 'ST', name: 'ContributionDescription' },
            '0x0020000D': { 'vm': '1', 'vr': 'UI', name: 'StudyInstanceUID' },
            '0x0020000E': { 'vm': '1', 'vr': 'UI', name: 'SeriesInstanceUID' },
            '0x00200010': { 'vm': '1', 'vr': 'SH', name: 'StudyID' },
            '0x00200011': { 'vm': '1', 'vr': 'IS', name: 'SeriesNumber' },
            '0x00200012': { 'vm': '1', 'vr': 'IS', name: 'AcquisitionNumber' },
            '0x00200013': { 'vm': '1', 'vr': 'IS', name: 'InstanceNumber' },
            '0x00200014': { 'vm': '1', 'vr': 'IS', name: 'IsotopeNumber' },
            '0x00200015': { 'vm': '1', 'vr': 'IS', name: 'PhaseNumber' },
            '0x00200016': { 'vm': '1', 'vr': 'IS', name: 'IntervalNumber' },
            '0x00200017': { 'vm': '1', 'vr': 'IS', name: 'TimeSlotNumber' },
            '0x00200018': { 'vm': '1', 'vr': 'IS', name: 'AngleNumber' },
            '0x00200019': { 'vm': '1', 'vr': 'IS', name: 'ItemNumber' },
            '0x00200020': { 'vm': '2', 'vr': 'CS', name: 'PatientOrientation' },
            '0x00200022': { 'vm': '1', 'vr': 'IS', name: 'OverlayNumber' },
            '0x00200024': { 'vm': '1', 'vr': 'IS', name: 'CurveNumber' },
            '0x00200026': { 'vm': '1', 'vr': 'IS', name: 'LUTNumber' },
            '0x00200030': { 'vm': '3', 'vr': 'DS', name: 'ImagePosition' },
            '0x00200032': { 'vm': '3', 'vr': 'DS', name: 'ImagePositionPatient' },
            '0x00200035': { 'vm': '6', 'vr': 'DS', name: 'ImageOrientation' },
            '0x00200037': { 'vm': '6', 'vr': 'DS', name: 'ImageOrientationPatient' },
            '0x00200050': { 'vm': '1', 'vr': 'DS', name: 'Location' },
            '0x00200052': { 'vm': '1', 'vr': 'UI', name: 'FrameofReferenceUID' },
            '0x00200060': { 'vm': '1', 'vr': 'CS', name: 'Laterality' },
            '0x00200062': { 'vm': '1', 'vr': 'CS', name: 'ImageLaterality' },
            '0x00200070': { 'vm': '1', 'vr': 'LO', name: 'ImageGeometryType' },
            '0x00200080': { 'vm': '1-n', 'vr': 'CS', name: 'MaskingImage' },
            '0x002000AA': { 'vm': '1', 'vr': 'IS', name: 'ReportNumber' },
            '0x00200100': { 'vm': '1', 'vr': 'IS', name: 'TemporalPositionIdentifier' },
            '0x00200105': { 'vm': '1', 'vr': 'IS', name: 'NumberofTemporalPositions' },
            '0x00200110': { 'vm': '1', 'vr': 'DS', name: 'TemporalResolution' },
            '0x00200200': { 'vm': '1', 'vr': 'UI', name: 'SynchronizationFrameofReferenceUID' },
            '0x00200242': { 'vm': '1', 'vr': 'UI', name: 'SOPInstanceUIDofConcatenationSource' },
            '0x00201000': { 'vm': '1', 'vr': 'IS', name: 'SeriesinStudy' },
            '0x00201001': { 'vm': '1', 'vr': 'IS', name: 'AcquisitionsinSeries' },
            '0x00201002': { 'vm': '1', 'vr': 'IS', name: 'ImagesinAcquisition' },
            '0x00201003': { 'vm': '1', 'vr': 'IS', name: 'ImagesinSeries' },
            '0x00201004': { 'vm': '1', 'vr': 'IS', name: 'AcquisitionsinStudy' },
            '0x00201005': { 'vm': '1', 'vr': 'IS', name: 'ImagesinStudy' },
            '0x00201020': { 'vm': '1-n', 'vr': 'LO', name: 'Reference' },
            '0x0020103F': { 'vm': '1', 'vr': 'LO', name: 'TargetPositionReferenceIndicator' },
            '0x00201040': { 'vm': '1', 'vr': 'LO', name: 'PositionReferenceIndicator' },
            '0x00201041': { 'vm': '1', 'vr': 'DS', name: 'SliceLocation' },
            '0x00201070': { 'vm': '1-n', 'vr': 'IS', name: 'OtherStudyNumbers' },
            '0x00201200': { 'vm': '1', 'vr': 'IS', name: 'NumberofPatientRelatedStudies' },
            '0x00201202': { 'vm': '1', 'vr': 'IS', name: 'NumberofPatientRelatedSeries' },
            '0x00201204': { 'vm': '1', 'vr': 'IS', name: 'NumberofPatientRelatedInstances' },
            '0x00201206': { 'vm': '1', 'vr': 'IS', name: 'NumberofStudyRelatedSeries' },
            '0x00201208': { 'vm': '1', 'vr': 'IS', name: 'NumberofStudyRelatedInstances' },
            '0x00201209': { 'vm': '1', 'vr': 'IS', name: 'NumberofSeriesRelatedInstances' },
            '0x002031xx': { 'vm': '1-n', 'vr': 'CS', name: 'SourceImageIDs' },
            '0x00203401': { 'vm': '1', 'vr': 'CS', name: 'ModifyingDeviceID' },
            '0x00203402': { 'vm': '1', 'vr': 'CS', name: 'ModifiedImageID' },
            '0x00203403': { 'vm': '1', 'vr': 'DA', name: 'ModifiedImageDate' },
            '0x00203404': { 'vm': '1', 'vr': 'LO', name: 'ModifyingDeviceManufacturer' },
            '0x00203405': { 'vm': '1', 'vr': 'TM', name: 'ModifiedImageTime' },
            '0x00203406': { 'vm': '1', 'vr': 'LO', name: 'ModifiedImageDescription' },
            '0x00204000': { 'vm': '1', 'vr': 'LT', name: 'ImageComments' },
            '0x00205000': { 'vm': '1-n', 'vr': 'AT', name: 'OriginalImageIdentification' },
            '0x00205002': { 'vm': '1-n', 'vr': 'LO', name: 'OriginalImageIdentificationNomenclature' },
            '0x00209056': { 'vm': '1', 'vr': 'SH', name: 'StackID' },
            '0x00209057': { 'vm': '1', 'vr': 'UL', name: 'InStackPositionNumber' },
            '0x00209071': { 'vm': '1', 'vr': 'SQ', name: 'FrameAnatomySequence' },
            '0x00209072': { 'vm': '1', 'vr': 'CS', name: 'FrameLaterality' },
            '0x00209111': { 'vm': '1', 'vr': 'SQ', name: 'FrameContentSequence' },
            '0x00209113': { 'vm': '1', 'vr': 'SQ', name: 'PlanePositionSequence' },
            '0x00209116': { 'vm': '1', 'vr': 'SQ', name: 'PlaneOrientationSequence' },
            '0x00209128': { 'vm': '1', 'vr': 'UL', name: 'TemporalPositionIndex' },
            '0x00209153': { 'vm': '1', 'vr': 'FD', name: 'NominalCardiacTriggerDelayTime' },
            '0x00209154': { 'vm': '1', 'vr': 'FL', name: 'NominalCardiacTriggerTimePriorToRPeak' },
            '0x00209155': { 'vm': '1', 'vr': 'FL', name: 'ActualCardiacTriggerTimePriorToRPeak' },
            '0x00209156': { 'vm': '1', 'vr': 'US', name: 'FrameAcquisitionNumber' },
            '0x00209157': { 'vm': '1-n', 'vr': 'UL', name: 'DimensionIndexValues' },
            '0x00209158': { 'vm': '1', 'vr': 'LT', name: 'FrameComments' },
            '0x00209161': { 'vm': '1', 'vr': 'UI', name: 'ConcatenationUID' },
            '0x00209162': { 'vm': '1', 'vr': 'US', name: 'InconcatenationNumber' },
            '0x00209163': { 'vm': '1', 'vr': 'US', name: 'InconcatenationTotalNumber' },
            '0x00209164': { 'vm': '1', 'vr': 'UI', name: 'DimensionOrganizationUID' },
            '0x00209165': { 'vm': '1', 'vr': 'AT', name: 'DimensionIndexPointer' },
            '0x00209167': { 'vm': '1', 'vr': 'AT', name: 'FunctionalGroupPointer' },
            '0x00209170': { 'vm': '1', 'vr': 'SQ', name: 'UnassignedSharedConvertedAttributesSequence' },
            '0x00209171': { 'vm': '1', 'vr': 'SQ', name: 'UnassignedPerFrameConvertedAttributesSequence' },
            '0x00209172': { 'vm': '1', 'vr': 'SQ', name: 'ConversionSourceAttributesSequence' },
            '0x00209213': { 'vm': '1', 'vr': 'LO', name: 'DimensionIndexPrivateCreator' },
            '0x00209221': { 'vm': '1', 'vr': 'SQ', name: 'DimensionOrganizationSequence' },
            '0x00209222': { 'vm': '1', 'vr': 'SQ', name: 'DimensionIndexSequence' },
            '0x00209228': { 'vm': '1', 'vr': 'UL', name: 'ConcatenationFrameOffsetNumber' },
            '0x00209238': { 'vm': '1', 'vr': 'LO', name: 'FunctionalGroupPrivateCreator' },
            '0x00209241': { 'vm': '1', 'vr': 'FL', name: 'NominalPercentageofCardiacPhase' },
            '0x00209245': { 'vm': '1', 'vr': 'FL', name: 'NominalPercentageofRespiratoryPhase' },
            '0x00209246': { 'vm': '1', 'vr': 'FL', name: 'StartingRespiratoryAmplitude' },
            '0x00209247': { 'vm': '1', 'vr': 'CS', name: 'StartingRespiratoryPhase' },
            '0x00209248': { 'vm': '1', 'vr': 'FL', name: 'EndingRespiratoryAmplitude' },
            '0x00209249': { 'vm': '1', 'vr': 'CS', name: 'EndingRespiratoryPhase' },
            '0x00209250': { 'vm': '1', 'vr': 'CS', name: 'RespiratoryTriggerType' },
            '0x00209251': { 'vm': '1', 'vr': 'FD', name: 'RRIntervalTimeNominal' },
            '0x00209252': { 'vm': '1', 'vr': 'FD', name: 'ActualCardiacTriggerDelayTime' },
            '0x00209253': { 'vm': '1', 'vr': 'SQ', name: 'RespiratorySynchronizationSequence' },
            '0x00209254': { 'vm': '1', 'vr': 'FD', name: 'RespiratoryIntervalTime' },
            '0x00209255': { 'vm': '1', 'vr': 'FD', name: 'NominalRespiratoryTriggerDelayTime' },
            '0x00209256': { 'vm': '1', 'vr': 'FD', name: 'RespiratoryTriggerDelayThreshold' },
            '0x00209257': { 'vm': '1', 'vr': 'FD', name: 'ActualRespiratoryTriggerDelayTime' },
            '0x00209301': { 'vm': '3', 'vr': 'FD', name: 'ImagePositionVolume' },
            '0x00209302': { 'vm': '6', 'vr': 'FD', name: 'ImageOrientationVolume' },
            '0x00209307': { 'vm': '1', 'vr': 'CS', name: 'UltrasoundAcquisitionGeometry' },
            '0x00209308': { 'vm': '3', 'vr': 'FD', name: 'ApexPosition' },
            '0x00209309': { 'vm': '16', 'vr': 'FD', name: 'VolumetoTransducerMappingMatrix' },
            '0x0020930A': { 'vm': '16', 'vr': 'FD', name: 'VolumetoTableMappingMatrix' },
            '0x0020930B': { 'vm': '1', 'vr': 'CS', name: 'VolumetoTransducerRelationship' },
            '0x0020930C': { 'vm': '1', 'vr': 'CS', name: 'PatientFrameofReferenceSource' },
            '0x0020930D': { 'vm': '1', 'vr': 'FD', name: 'TemporalPositionTimeOffset' },
            '0x0020930E': { 'vm': '1', 'vr': 'SQ', name: 'PlanePositionVolumeSequence' },
            '0x0020930F': { 'vm': '1', 'vr': 'SQ', name: 'PlaneOrientationVolumeSequence' },
            '0x00209310': { 'vm': '1', 'vr': 'SQ', name: 'TemporalPositionSequence' },
            '0x00209311': { 'vm': '1', 'vr': 'CS', name: 'DimensionOrganizationType' },
            '0x00209312': { 'vm': '1', 'vr': 'UI', name: 'VolumeFrameofReferenceUID' },
            '0x00209313': { 'vm': '1', 'vr': 'UI', name: 'TableFrameofReferenceUID' },
            '0x00209421': { 'vm': '1', 'vr': 'LO', name: 'DimensionDescriptionLabel' },
            '0x00209450': { 'vm': '1', 'vr': 'SQ', name: 'PatientOrientationinFrameSequence' },
            '0x00209453': { 'vm': '1', 'vr': 'LO', name: 'FrameLabel' },
            '0x00209518': { 'vm': '1-n', 'vr': 'US', name: 'AcquisitionIndex' },
            '0x00209529': { 'vm': '1', 'vr': 'SQ', name: 'ContributingSOPInstancesReferenceSequence' },
            '0x00209536': { 'vm': '1', 'vr': 'US', name: 'ReconstructionIndex' },
            '0x00220001': { 'vm': '1', 'vr': 'US', name: 'LightPathFilterPassThroughWavelength' },
            '0x00220002': { 'vm': '2', 'vr': 'US', name: 'LightPathFilterPassBand' },
            '0x00220003': { 'vm': '1', 'vr': 'US', name: 'ImagePathFilterPassThroughWavelength' },
            '0x00220004': { 'vm': '2', 'vr': 'US', name: 'ImagePathFilterPassBand' },
            '0x00220005': { 'vm': '1', 'vr': 'CS', name: 'PatientEyeMovementCommanded' },
            '0x00220006': { 'vm': '1', 'vr': 'SQ', name: 'PatientEyeMovementCommandCodeSequence' },
            '0x00220007': { 'vm': '1', 'vr': 'FL', name: 'SphericalLensPower' },
            '0x00220008': { 'vm': '1', 'vr': 'FL', name: 'CylinderLensPower' },
            '0x00220009': { 'vm': '1', 'vr': 'FL', name: 'CylinderAxis' },
            '0x0022000A': { 'vm': '1', 'vr': 'FL', name: 'EmmetropicMagnification' },
            '0x0022000B': { 'vm': '1', 'vr': 'FL', name: 'IntraOcularPressure' },
            '0x0022000C': { 'vm': '1', 'vr': 'FL', name: 'HorizontalFieldofView' },
            '0x0022000D': { 'vm': '1', 'vr': 'CS', name: 'PupilDilated' },
            '0x0022000E': { 'vm': '1', 'vr': 'FL', name: 'DegreeofDilation' },
            '0x00220010': { 'vm': '1', 'vr': 'FL', name: 'StereoBaselineAngle' },
            '0x00220011': { 'vm': '1', 'vr': 'FL', name: 'StereoBaselineDisplacement' },
            '0x00220012': { 'vm': '1', 'vr': 'FL', name: 'StereoHorizontalPixelOffset' },
            '0x00220013': { 'vm': '1', 'vr': 'FL', name: 'StereoVerticalPixelOffset' },
            '0x00220014': { 'vm': '1', 'vr': 'FL', name: 'StereoRotation' },
            '0x00220015': { 'vm': '1', 'vr': 'SQ', name: 'AcquisitionDeviceTypeCodeSequence' },
            '0x00220016': { 'vm': '1', 'vr': 'SQ', name: 'IlluminationTypeCodeSequence' },
            '0x00220017': { 'vm': '1', 'vr': 'SQ', name: 'LightPathFilterTypeStackCodeSequence' },
            '0x00220018': { 'vm': '1', 'vr': 'SQ', name: 'ImagePathFilterTypeStackCodeSequence' },
            '0x00220019': { 'vm': '1', 'vr': 'SQ', name: 'LensesCodeSequence' },
            '0x0022001A': { 'vm': '1', 'vr': 'SQ', name: 'ChannelDescriptionCodeSequence' },
            '0x0022001B': { 'vm': '1', 'vr': 'SQ', name: 'RefractiveStateSequence' },
            '0x0022001C': { 'vm': '1', 'vr': 'SQ', name: 'MydriaticAgentCodeSequence' },
            '0x0022001D': { 'vm': '1', 'vr': 'SQ', name: 'RelativeImagePositionCodeSequence' },
            '0x0022001E': { 'vm': '1', 'vr': 'FL', name: 'CameraAngleofView' },
            '0x00220020': { 'vm': '1', 'vr': 'SQ', name: 'StereoPairsSequence' },
            '0x00220021': { 'vm': '1', 'vr': 'SQ', name: 'LeftImageSequence' },
            '0x00220022': { 'vm': '1', 'vr': 'SQ', name: 'RightImageSequence' },
            '0x00220028': { 'vm': '1', 'vr': 'CS', name: 'StereoPairsPresent' },
            '0x00220030': { 'vm': '1', 'vr': 'FL', name: 'AxialLengthoftheEye' },
            '0x00220031': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicFrameLocationSequence' },
            '0x00220032': { 'vm': '2-2n', 'vr': 'FL', name: 'ReferenceCoordinates' },
            '0x00220035': { 'vm': '1', 'vr': 'FL', name: 'DepthSpatialResolution' },
            '0x00220036': { 'vm': '1', 'vr': 'FL', name: 'MaximumDepthDistortion' },
            '0x00220037': { 'vm': '1', 'vr': 'FL', name: 'AlongscanSpatialResolution' },
            '0x00220038': { 'vm': '1', 'vr': 'FL', name: 'MaximumAlongscanDistortion' },
            '0x00220039': { 'vm': '1', 'vr': 'CS', name: 'OphthalmicImageOrientation' },
            '0x00220041': { 'vm': '1', 'vr': 'FL', name: 'DepthofTransverseImage' },
            '0x00220042': { 'vm': '1', 'vr': 'SQ', name: 'MydriaticAgentConcentrationUnitsSequence' },
            '0x00220048': { 'vm': '1', 'vr': 'FL', name: 'AcrossscanSpatialResolution' },
            '0x00220049': { 'vm': '1', 'vr': 'FL', name: 'MaximumAcrossscanDistortion' },
            '0x0022004E': { 'vm': '1', 'vr': 'DS', name: 'MydriaticAgentConcentration' },
            '0x00220055': { 'vm': '1', 'vr': 'FL', name: 'IlluminationWaveLength' },
            '0x00220056': { 'vm': '1', 'vr': 'FL', name: 'IlluminationPower' },
            '0x00220057': { 'vm': '1', 'vr': 'FL', name: 'IlluminationBandwidth' },
            '0x00220058': { 'vm': '1', 'vr': 'SQ', name: 'MydriaticAgentSequence' },
            '0x00221007': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialMeasurementsRightEyeSequence' },
            '0x00221008': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialMeasurementsLeftEyeSequence' },
            '0x00221009': { 'vm': '1', 'vr': 'CS', name: 'OphthalmicAxialMeasurementsDeviceType' },
            '0x00221010': { 'vm': '1', 'vr': 'CS', name: 'OphthalmicAxialLengthMeasurementsType' },
            '0x00221012': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthSequence' },
            '0x00221019': { 'vm': '1', 'vr': 'FL', name: 'OphthalmicAxialLength' },
            '0x00221024': { 'vm': '1', 'vr': 'SQ', name: 'LensStatusCodeSequence' },
            '0x00221025': { 'vm': '1', 'vr': 'SQ', name: 'VitreousStatusCodeSequence' },
            '0x00221028': { 'vm': '1', 'vr': 'SQ', name: 'IOLFormulaCodeSequence' },
            '0x00221029': { 'vm': '1', 'vr': 'LO', name: 'IOLFormulaDetail' },
            '0x00221033': { 'vm': '1', 'vr': 'FL', name: 'KeratometerIndex' },
            '0x00221035': { 'vm': '1', 'vr': 'SQ', name: 'SourceofOphthalmicAxialLengthCodeSequence' },
            '0x00221036': { 'vm': '1', 'vr': 'SQ', name: 'SourceofCornealSizeDataCodeSequence' },
            '0x00221037': { 'vm': '1', 'vr': 'FL', name: 'TargetRefraction' },
            '0x00221039': { 'vm': '1', 'vr': 'CS', name: 'RefractiveProcedureOccurred' },
            '0x00221040': { 'vm': '1', 'vr': 'SQ', name: 'RefractiveSurgeryTypeCodeSequence' },
            '0x00221044': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicUltrasoundMethodCodeSequence' },
            '0x00221045': { 'vm': '1', 'vr': 'SQ', name: 'SurgicallyInducedAstigmatismSequence' },
            '0x00221046': { 'vm': '1', 'vr': 'CS', name: 'TypeofOpticalCorrection' },
            '0x00221047': { 'vm': '1', 'vr': 'SQ', name: 'ToricIOLPowerSequence' },
            '0x00221048': { 'vm': '1', 'vr': 'SQ', name: 'PredictedToricErrorSequence' },
            '0x00221049': { 'vm': '1', 'vr': 'CS', name: 'PreSelectedforImplantation' },
            '0x0022104A': { 'vm': '1', 'vr': 'SQ', name: 'ToricIOLPowerforExactEmmetropiaSequence' },
            '0x0022104B': { 'vm': '1', 'vr': 'SQ', name: 'ToricIOLPowerforExactTargetRefractionSequence' },
            '0x00221050': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthMeasurementsSequence' },
            '0x00221053': { 'vm': '1', 'vr': 'FL', name: 'IOLPower' },
            '0x00221054': { 'vm': '1', 'vr': 'FL', name: 'PredictedRefractiveError' },
            '0x00221059': { 'vm': '1', 'vr': 'FL', name: 'OphthalmicAxialLengthVelocity' },
            '0x00221065': { 'vm': '1', 'vr': 'LO', name: 'LensStatusDescription' },
            '0x00221066': { 'vm': '1', 'vr': 'LO', name: 'VitreousStatusDescription' },
            '0x00221090': { 'vm': '1', 'vr': 'SQ', name: 'IOLPowerSequence' },
            '0x00221092': { 'vm': '1', 'vr': 'SQ', name: 'LensConstantSequence' },
            '0x00221093': { 'vm': '1', 'vr': 'LO', name: 'IOLManufacturer' },
            '0x00221094': { 'vm': '1', 'vr': 'LO', name: 'LensConstantDescription' },
            '0x00221095': { 'vm': '1', 'vr': 'LO', name: 'ImplantName' },
            '0x00221096': { 'vm': '1', 'vr': 'SQ', name: 'KeratometryMeasurementTypeCodeSequence' },
            '0x00221097': { 'vm': '1', 'vr': 'LO', name: 'ImplantPartNumber' },
            '0x00221100': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedOphthalmicAxialMeasurementsSequence' },
            '0x00221101': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthMeasurementsSegmentNameCodeSequence' },
            '0x00221103': { 'vm': '1', 'vr': 'SQ', name: 'RefractiveErrorBeforeRefractiveSurgeryCodeSequence' },
            '0x00221121': { 'vm': '1', 'vr': 'FL', name: 'IOLPowerForExactEmmetropia' },
            '0x00221122': { 'vm': '1', 'vr': 'FL', name: 'IOLPowerForExactTargetRefraction' },
            '0x00221125': { 'vm': '1', 'vr': 'SQ', name: 'AnteriorChamberDepthDefinitionCodeSequence' },
            '0x00221127': { 'vm': '1', 'vr': 'SQ', name: 'LensThicknessSequence' },
            '0x00221128': { 'vm': '1', 'vr': 'SQ', name: 'AnteriorChamberDepthSequence' },
            '0x0022112A': { 'vm': '1', 'vr': 'SQ', name: 'CalculationCommentSequence' },
            '0x0022112B': { 'vm': '1', 'vr': 'CS', name: 'CalculationCommentType' },
            '0x0022112C': { 'vm': '1', 'vr': 'LT', name: 'CalculationComment' },
            '0x00221130': { 'vm': '1', 'vr': 'FL', name: 'LensThickness' },
            '0x00221131': { 'vm': '1', 'vr': 'FL', name: 'AnteriorChamberDepth' },
            '0x00221132': { 'vm': '1', 'vr': 'SQ', name: 'SourceofLensThicknessDataCodeSequence' },
            '0x00221133': { 'vm': '1', 'vr': 'SQ', name: 'SourceofAnteriorChamberDepthDataCodeSequence' },
            '0x00221134': { 'vm': '1', 'vr': 'SQ', name: 'SourceofRefractiveMeasurementsSequence' },
            '0x00221135': { 'vm': '1', 'vr': 'SQ', name: 'SourceofRefractiveMeasurementsCodeSequence' },
            '0x00221140': { 'vm': '1', 'vr': 'CS', name: 'OphthalmicAxialLengthMeasurementModified' },
            '0x00221150': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthDataSourceCodeSequence' },
            '0x00221153': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthAcquisitionMethodCodeSequence' },
            '0x00221155': { 'vm': '1', 'vr': 'FL', name: 'SignaltoNoiseRatio' },
            '0x00221159': { 'vm': '1', 'vr': 'LO', name: 'OphthalmicAxialLengthDataSourceDescription' },
            '0x00221210': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthMeasurementsTotalLengthSequence' },
            '0x00221211': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthMeasurementsSegmentalLengthSequence' },
            '0x00221212': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthMeasurementsLengthSummationSequence' },
            '0x00221220': { 'vm': '1', 'vr': 'SQ', name: 'UltrasoundOphthalmicAxialLengthMeasurementsSequence' },
            '0x00221225': { 'vm': '1', 'vr': 'SQ', name: 'OpticalOphthalmicAxialLengthMeasurementsSequence' },
            '0x00221230': { 'vm': '1', 'vr': 'SQ', name: 'UltrasoundSelectedOphthalmicAxialLengthSequence' },
            '0x00221250': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthSelectionMethodCodeSequence' },
            '0x00221255': { 'vm': '1', 'vr': 'SQ', name: 'OpticalSelectedOphthalmicAxialLengthSequence' },
            '0x00221257': { 'vm': '1', 'vr': 'SQ', name: 'SelectedSegmentalOphthalmicAxialLengthSequence' },
            '0x00221260': { 'vm': '1', 'vr': 'SQ', name: 'SelectedTotalOphthalmicAxialLengthSequence' },
            '0x00221262': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthQualityMetricSequence' },
            '0x00221265': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicAxialLengthQualityMetricTypeCodeSequence' },
            '0x00221273': { 'vm': '1', 'vr': 'LO', name: 'OphthalmicAxialLengthQualityMetricTypeDescription' },
            '0x00221300': { 'vm': '1', 'vr': 'SQ', name: 'IntraocularLensCalculationsRightEyeSequence' },
            '0x00221310': { 'vm': '1', 'vr': 'SQ', name: 'IntraocularLensCalculationsLeftEyeSequence' },
            '0x00221330': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedOphthalmicAxialLengthMeasurementQCImageSequence' },
            '0x00221415': { 'vm': '1', 'vr': 'CS', name: 'OphthalmicMappingDeviceType' },
            '0x00221420': { 'vm': '1', 'vr': 'SQ', name: 'AcquisitionMethodCodeSequence' },
            '0x00221423': { 'vm': '1', 'vr': 'SQ', name: 'AcquisitionMethodAlgorithmSequence' },
            '0x00221436': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicThicknessMapTypeCodeSequence' },
            '0x00221443': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicThicknessMappingNormalsSequence' },
            '0x00221445': { 'vm': '1', 'vr': 'SQ', name: 'RetinalThicknessDefinitionCodeSequence' },
            '0x00221450': { 'vm': '1', 'vr': 'SQ', name: 'PixelValueMappingtoCodedConceptSequence' },
            '0x00221452': { 'vm': '1', 'vr': 'US', name: 'MappedPixelValue' },
            '0x00221454': { 'vm': '1', 'vr': 'LO', name: 'PixelValueMappingExplanation' },
            '0x00221458': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicThicknessMapQualityThresholdSequence' },
            '0x00221460': { 'vm': '1', 'vr': 'FL', name: 'OphthalmicThicknessMapThresholdQualityRating' },
            '0x00221463': { 'vm': '2', 'vr': 'FL', name: 'AnatomicStructureReferencePoint' },
            '0x00221465': { 'vm': '1', 'vr': 'SQ', name: 'RegistrationtoLocalizerSequence' },
            '0x00221466': { 'vm': '1', 'vr': 'CS', name: 'RegisteredLocalizerUnits' },
            '0x00221467': { 'vm': '2', 'vr': 'FL', name: 'RegisteredLocalizerTopLeftHandCorner' },
            '0x00221468': { 'vm': '2', 'vr': 'FL', name: 'RegisteredLocalizerBottomRightHandCorner' },
            '0x00221470': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicThicknessMapQualityRatingSequence' },
            '0x00221472': { 'vm': '1', 'vr': 'SQ', name: 'RelevantOPTAttributesSequence' },
            '0x00221512': { 'vm': '1', 'vr': 'SQ', name: 'TransformationMethodCodeSequence' },
            '0x00221513': { 'vm': '1', 'vr': 'SQ', name: 'TransformationAlgorithmSequence' },
            '0x00221515': { 'vm': '1', 'vr': 'CS', name: 'OphthalmicAxialLengthMethod' },
            '0x00221517': { 'vm': '1', 'vr': 'FL', name: 'OphthalmicFOV' },
            '0x00221518': { 'vm': '1', 'vr': 'SQ', name: 'TwoDimensionaltoThreeDimensionalMapSequence' },
            '0x00221525': { 'vm': '1', 'vr': 'SQ', name: 'WideFieldOphthalmicPhotographyQualityRatingSequence' },
            '0x00221526': { 'vm': '1', 'vr': 'SQ', name: 'WideFieldOphthalmicPhotographyQualityThresholdSequence' },
            '0x00221527': { 'vm': '1', 'vr': 'FL', name: 'WideFieldOphthalmicPhotographyThresholdQualityRating' },
            '0x00221528': { 'vm': '1', 'vr': 'FL', name: 'XCoordinatesCenterPixelViewAngle' },
            '0x00221529': { 'vm': '1', 'vr': 'FL', name: 'YCoordinatesCenterPixelViewAngle' },
            '0x00221530': { 'vm': '1', 'vr': 'UL', name: 'NumberofMapPoints' },
            '0x00221531': { 'vm': '1', 'vr': 'OF', name: 'TwoDimensionaltoThreeDimensionalMapData' },
            '0x00221612': { 'vm': '1', 'vr': 'SQ', name: 'DerivationAlgorithmSequence' },
            '0x00221615': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicImageTypeCodeSequence' },
            '0x00221616': { 'vm': '1', 'vr': 'LO', name: 'OphthalmicImageTypeDescription' },
            '0x00221618': { 'vm': '1', 'vr': 'SQ', name: 'ScanPatternTypeCodeSequence' },
            '0x00221620': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedSurfaceMeshIdentificationSequence' },
            '0x00221622': { 'vm': '1', 'vr': 'CS', name: 'OphthalmicVolumetricPropertiesFlag' },
            '0x00221624': { 'vm': '1', 'vr': 'FL', name: 'OphthalmicAnatomicReferencePointXCoordinate' },
            '0x00221626': { 'vm': '1', 'vr': 'FL', name: 'OphthalmicAnatomicReferencePointYCoordinate' },
            '0x00221628': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicEnFaceImageQualityRatingSequence' },
            '0x00221630': { 'vm': '1', 'vr': 'DS', name: 'QualityThreshold' },
            '0x00221640': { 'vm': '1', 'vr': 'SQ', name: 'OCTBscanAnalysisAcquisitionParametersSequence' },
            '0x00221642': { 'vm': '1', 'vr': 'UL', name: 'NumberofBscansPerFrame' },
            '0x00221643': { 'vm': '1', 'vr': 'FL', name: 'BscanSlabThickness' },
            '0x00221644': { 'vm': '1', 'vr': 'FL', name: 'DistanceBetweenBscanSlabs' },
            '0x00221645': { 'vm': '1', 'vr': 'FL', name: 'BscanCycleTime' },
            '0x00221646': { 'vm': '1-n', 'vr': 'FL', name: 'BscanCycleTimeVector' },
            '0x00221649': { 'vm': '1', 'vr': 'FL', name: 'AscanRate' },
            '0x00221650': { 'vm': '1', 'vr': 'FL', name: 'BscanRate' },
            '0x00221658': { 'vm': '1', 'vr': 'UL', name: 'SurfaceMeshZPixelOffset' },
            '0x00240010': { 'vm': '1', 'vr': 'FL', name: 'VisualFieldHorizontalExtent' },
            '0x00240011': { 'vm': '1', 'vr': 'FL', name: 'VisualFieldVerticalExtent' },
            '0x00240012': { 'vm': '1', 'vr': 'CS', name: 'VisualFieldShape' },
            '0x00240016': { 'vm': '1', 'vr': 'SQ', name: 'ScreeningTestModeCodeSequence' },
            '0x00240018': { 'vm': '1', 'vr': 'FL', name: 'MaximumStimulusLuminance' },
            '0x00240020': { 'vm': '1', 'vr': 'FL', name: 'BackgroundLuminance' },
            '0x00240021': { 'vm': '1', 'vr': 'SQ', name: 'StimulusColorCodeSequence' },
            '0x00240024': { 'vm': '1', 'vr': 'SQ', name: 'BackgroundIlluminationColorCodeSequence' },
            '0x00240025': { 'vm': '1', 'vr': 'FL', name: 'StimulusArea' },
            '0x00240028': { 'vm': '1', 'vr': 'FL', name: 'StimulusPresentationTime' },
            '0x00240032': { 'vm': '1', 'vr': 'SQ', name: 'FixationSequence' },
            '0x00240033': { 'vm': '1', 'vr': 'SQ', name: 'FixationMonitoringCodeSequence' },
            '0x00240034': { 'vm': '1', 'vr': 'SQ', name: 'VisualFieldCatchTrialSequence' },
            '0x00240035': { 'vm': '1', 'vr': 'US', name: 'FixationCheckedQuantity' },
            '0x00240036': { 'vm': '1', 'vr': 'US', name: 'PatientNotProperlyFixatedQuantity' },
            '0x00240037': { 'vm': '1', 'vr': 'CS', name: 'PresentedVisualStimuliDataFlag' },
            '0x00240038': { 'vm': '1', 'vr': 'US', name: 'NumberofVisualStimuli' },
            '0x00240039': { 'vm': '1', 'vr': 'CS', name: 'ExcessiveFixationLossesDataFlag' },
            '0x00240040': { 'vm': '1', 'vr': 'CS', name: 'ExcessiveFixationLosses' },
            '0x00240042': { 'vm': '1', 'vr': 'US', name: 'StimuliRetestingQuantity' },
            '0x00240044': { 'vm': '1', 'vr': 'LT', name: 'CommentsonPatientsPerformanceofVisualField' },
            '0x00240045': { 'vm': '1', 'vr': 'CS', name: 'FalseNegativesEstimateFlag' },
            '0x00240046': { 'vm': '1', 'vr': 'FL', name: 'FalseNegativesEstimate' },
            '0x00240048': { 'vm': '1', 'vr': 'US', name: 'NegativeCatchTrialsQuantity' },
            '0x00240050': { 'vm': '1', 'vr': 'US', name: 'FalseNegativesQuantity' },
            '0x00240051': { 'vm': '1', 'vr': 'CS', name: 'ExcessiveFalseNegativesDataFlag' },
            '0x00240052': { 'vm': '1', 'vr': 'CS', name: 'ExcessiveFalseNegatives' },
            '0x00240053': { 'vm': '1', 'vr': 'CS', name: 'FalsePositivesEstimateFlag' },
            '0x00240054': { 'vm': '1', 'vr': 'FL', name: 'FalsePositivesEstimate' },
            '0x00240055': { 'vm': '1', 'vr': 'CS', name: 'CatchTrialsDataFlag' },
            '0x00240056': { 'vm': '1', 'vr': 'US', name: 'PositiveCatchTrialsQuantity' },
            '0x00240057': { 'vm': '1', 'vr': 'CS', name: 'TestPointNormalsDataFlag' },
            '0x00240058': { 'vm': '1', 'vr': 'SQ', name: 'TestPointNormalsSequence' },
            '0x00240059': { 'vm': '1', 'vr': 'CS', name: 'GlobalDeviationProbabilityNormalsFlag' },
            '0x00240060': { 'vm': '1', 'vr': 'US', name: 'FalsePositivesQuantity' },
            '0x00240061': { 'vm': '1', 'vr': 'CS', name: 'ExcessiveFalsePositivesDataFlag' },
            '0x00240062': { 'vm': '1', 'vr': 'CS', name: 'ExcessiveFalsePositives' },
            '0x00240063': { 'vm': '1', 'vr': 'CS', name: 'VisualFieldTestNormalsFlag' },
            '0x00240064': { 'vm': '1', 'vr': 'SQ', name: 'ResultsNormalsSequence' },
            '0x00240065': { 'vm': '1', 'vr': 'SQ', name: 'AgeCorrectedSensitivityDeviationAlgorithmSequence' },
            '0x00240066': { 'vm': '1', 'vr': 'FL', name: 'GlobalDeviationFromNormal' },
            '0x00240067': { 'vm': '1', 'vr': 'SQ', name: 'GeneralizedDefectSensitivityDeviationAlgorithmSequence' },
            '0x00240068': { 'vm': '1', 'vr': 'FL', name: 'LocalizedDeviationFromNormal' },
            '0x00240069': { 'vm': '1', 'vr': 'LO', name: 'PatientReliabilityIndicator' },
            '0x00240070': { 'vm': '1', 'vr': 'FL', name: 'VisualFieldMeanSensitivity' },
            '0x00240071': { 'vm': '1', 'vr': 'FL', name: 'GlobalDeviationProbability' },
            '0x00240072': { 'vm': '1', 'vr': 'CS', name: 'LocalDeviationProbabilityNormalsFlag' },
            '0x00240073': { 'vm': '1', 'vr': 'FL', name: 'LocalizedDeviationProbability' },
            '0x00240074': { 'vm': '1', 'vr': 'CS', name: 'ShortTermFluctuationCalculated' },
            '0x00240075': { 'vm': '1', 'vr': 'FL', name: 'ShortTermFluctuation' },
            '0x00240076': { 'vm': '1', 'vr': 'CS', name: 'ShortTermFluctuationProbabilityCalculated' },
            '0x00240077': { 'vm': '1', 'vr': 'FL', name: 'ShortTermFluctuationProbability' },
            '0x00240078': { 'vm': '1', 'vr': 'CS', name: 'CorrectedLocalizedDeviationFromNormalCalculated' },
            '0x00240079': { 'vm': '1', 'vr': 'FL', name: 'CorrectedLocalizedDeviationFromNormal' },
            '0x00240080': { 'vm': '1', 'vr': 'CS', name: 'CorrectedLocalizedDeviationFromNormalProbabilityCalculated' },
            '0x00240081': { 'vm': '1', 'vr': 'FL', name: 'CorrectedLocalizedDeviationFromNormalProbability' },
            '0x00240083': { 'vm': '1', 'vr': 'SQ', name: 'GlobalDeviationProbabilitySequence' },
            '0x00240085': { 'vm': '1', 'vr': 'SQ', name: 'LocalizedDeviationProbabilitySequence' },
            '0x00240086': { 'vm': '1', 'vr': 'CS', name: 'FovealSensitivityMeasured' },
            '0x00240087': { 'vm': '1', 'vr': 'FL', name: 'FovealSensitivity' },
            '0x00240088': { 'vm': '1', 'vr': 'FL', name: 'VisualFieldTestDuration' },
            '0x00240089': { 'vm': '1', 'vr': 'SQ', name: 'VisualFieldTestPointSequence' },
            '0x00240090': { 'vm': '1', 'vr': 'FL', name: 'VisualFieldTestPointXCoordinate' },
            '0x00240091': { 'vm': '1', 'vr': 'FL', name: 'VisualFieldTestPointYCoordinate' },
            '0x00240092': { 'vm': '1', 'vr': 'FL', name: 'AgeCorrectedSensitivityDeviationValue' },
            '0x00240093': { 'vm': '1', 'vr': 'CS', name: 'StimulusResults' },
            '0x00240094': { 'vm': '1', 'vr': 'FL', name: 'SensitivityValue' },
            '0x00240095': { 'vm': '1', 'vr': 'CS', name: 'RetestStimulusSeen' },
            '0x00240096': { 'vm': '1', 'vr': 'FL', name: 'RetestSensitivityValue' },
            '0x00240097': { 'vm': '1', 'vr': 'SQ', name: 'VisualFieldTestPointNormalsSequence' },
            '0x00240098': { 'vm': '1', 'vr': 'FL', name: 'QuantifiedDefect' },
            '0x00240100': { 'vm': '1', 'vr': 'FL', name: 'AgeCorrectedSensitivityDeviationProbabilityValue' },
            '0x00240102': { 'vm': '1', 'vr': 'CS', name: 'GeneralizedDefectCorrectedSensitivityDeviationFlag' },
            '0x00240103': { 'vm': '1', 'vr': 'FL', name: 'GeneralizedDefectCorrectedSensitivityDeviationValue' },
            '0x00240104': {
                'vm': '1',
                'vr': 'FL',
                name: 'GeneralizedDefectCorrectedSensitivityDeviationProbabilityValue'
            },
            '0x00240105': { 'vm': '1', 'vr': 'FL', name: 'MinimumSensitivityValue' },
            '0x00240106': { 'vm': '1', 'vr': 'CS', name: 'BlindSpotLocalized' },
            '0x00240107': { 'vm': '1', 'vr': 'FL', name: 'BlindSpotXCoordinate' },
            '0x00240108': { 'vm': '1', 'vr': 'FL', name: 'BlindSpotYCoordinate' },
            '0x00240110': { 'vm': '1', 'vr': 'SQ', name: 'VisualAcuityMeasurementSequence' },
            '0x00240112': { 'vm': '1', 'vr': 'SQ', name: 'RefractiveParametersUsedonPatientSequence' },
            '0x00240113': { 'vm': '1', 'vr': 'CS', name: 'MeasurementLaterality' },
            '0x00240114': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicPatientClinicalInformationLeftEyeSequence' },
            '0x00240115': { 'vm': '1', 'vr': 'SQ', name: 'OphthalmicPatientClinicalInformationRightEyeSequence' },
            '0x00240117': { 'vm': '1', 'vr': 'CS', name: 'FovealPointNormativeDataFlag' },
            '0x00240118': { 'vm': '1', 'vr': 'FL', name: 'FovealPointProbabilityValue' },
            '0x00240120': { 'vm': '1', 'vr': 'CS', name: 'ScreeningBaselineMeasured' },
            '0x00240122': { 'vm': '1', 'vr': 'SQ', name: 'ScreeningBaselineMeasuredSequence' },
            '0x00240124': { 'vm': '1', 'vr': 'CS', name: 'ScreeningBaselineType' },
            '0x00240126': { 'vm': '1', 'vr': 'FL', name: 'ScreeningBaselineValue' },
            '0x00240202': { 'vm': '1', 'vr': 'LO', name: 'AlgorithmSource' },
            '0x00240306': { 'vm': '1', 'vr': 'LO', name: 'DataSetName' },
            '0x00240307': { 'vm': '1', 'vr': 'LO', name: 'DataSetVersion' },
            '0x00240308': { 'vm': '1', 'vr': 'LO', name: 'DataSetSource' },
            '0x00240309': { 'vm': '1', 'vr': 'LO', name: 'DataSetDescription' },
            '0x00240317': { 'vm': '1', 'vr': 'SQ', name: 'VisualFieldTestReliabilityGlobalIndexSequence' },
            '0x00240320': { 'vm': '1', 'vr': 'SQ', name: 'VisualFieldGlobalResultsIndexSequence' },
            '0x00240325': { 'vm': '1', 'vr': 'SQ', name: 'DataObservationSequence' },
            '0x00240338': { 'vm': '1', 'vr': 'CS', name: 'IndexNormalsFlag' },
            '0x00240341': { 'vm': '1', 'vr': 'FL', name: 'IndexProbability' },
            '0x00240344': { 'vm': '1', 'vr': 'SQ', name: 'IndexProbabilitySequence' },
            '0x00280002': { 'vm': '1', 'vr': 'US', name: 'SamplesperPixel' },
            '0x00280003': { 'vm': '1', 'vr': 'US', name: 'SamplesperPixelUsed' },
            '0x00280004': { 'vm': '1', 'vr': 'CS', name: 'PhotometricInterpretation' },
            '0x00280005': { 'vm': '1', 'vr': 'US', name: 'ImageDimensions' },
            '0x00280006': { 'vm': '1', 'vr': 'US', name: 'PlanarConfiguration' },
            '0x00280008': { 'vm': '1', 'vr': 'IS', name: 'NumberofFrames' },
            '0x00280009': { 'vm': '1-n', 'vr': 'AT', name: 'FrameIncrementPointer' },
            '0x0028000A': { 'vm': '1-n', 'vr': 'AT', name: 'FrameDimensionPointer' },
            '0x00280010': { 'vm': '1', 'vr': 'US', name: 'Rows' },
            '0x00280011': { 'vm': '1', 'vr': 'US', name: 'Columns' },
            '0x00280012': { 'vm': '1', 'vr': 'US', name: 'Planes' },
            '0x00280014': { 'vm': '1', 'vr': 'US', name: 'UltrasoundColorDataPresent' },
            '0x00280030': { 'vm': '2', 'vr': 'DS', name: 'PixelSpacing' },
            '0x00280031': { 'vm': '2', 'vr': 'DS', name: 'ZoomFactor' },
            '0x00280032': { 'vm': '2', 'vr': 'DS', name: 'ZoomCenter' },
            '0x00280034': { 'vm': '2', 'vr': 'IS', name: 'PixelAspectRatio' },
            '0x00280040': { 'vm': '1', 'vr': 'CS', name: 'ImageFormat' },
            '0x00280050': { 'vm': '1-n', 'vr': 'LO', name: 'ManipulatedImage' },
            '0x00280051': { 'vm': '1-n', 'vr': 'CS', name: 'CorrectedImage' },
            '0x0028005F': { 'vm': '1', 'vr': 'LO', name: 'CompressionRecognitionCode' },
            '0x00280060': { 'vm': '1', 'vr': 'CS', name: 'CompressionCode' },
            '0x00280061': { 'vm': '1', 'vr': 'SH', name: 'CompressionOriginator' },
            '0x00280062': { 'vm': '1', 'vr': 'LO', name: 'CompressionLabel' },
            '0x00280063': { 'vm': '1', 'vr': 'SH', name: 'CompressionDescription' },
            '0x00280065': { 'vm': '1-n', 'vr': 'CS', name: 'CompressionSequence' },
            '0x00280066': { 'vm': '1-n', 'vr': 'AT', name: 'CompressionStepPointers' },
            '0x00280068': { 'vm': '1', 'vr': 'US', name: 'RepeatInterval' },
            '0x00280069': { 'vm': '1', 'vr': 'US', name: 'BitsGrouped' },
            '0x00280070': { 'vm': '1-n', 'vr': 'US', name: 'PerimeterTable' },
            '0x00280071': { 'vm': '1', 'vr': 'US', name: 'PerimeterValue' },
            '0x00280080': { 'vm': '1', 'vr': 'US', name: 'PredictorRows' },
            '0x00280081': { 'vm': '1', 'vr': 'US', name: 'PredictorColumns' },
            '0x00280082': { 'vm': '1-n', 'vr': 'US', name: 'PredictorConstants' },
            '0x00280090': { 'vm': '1', 'vr': 'CS', name: 'BlockedPixels' },
            '0x00280091': { 'vm': '1', 'vr': 'US', name: 'BlockRows' },
            '0x00280092': { 'vm': '1', 'vr': 'US', name: 'BlockColumns' },
            '0x00280093': { 'vm': '1', 'vr': 'US', name: 'RowOverlap' },
            '0x00280094': { 'vm': '1', 'vr': 'US', name: 'ColumnOverlap' },
            '0x00280100': { 'vm': '1', 'vr': 'US', name: 'BitsAllocated' },
            '0x00280101': { 'vm': '1', 'vr': 'US', name: 'BitsStored' },
            '0x00280102': { 'vm': '1', 'vr': 'US', name: 'HighBit' },
            '0x00280103': { 'vm': '1', 'vr': 'US', name: 'PixelRepresentation' },
            '0x00280104': { 'vm': '1', 'vr': 'US', name: 'SmallestValidPixelValue' },
            '0x00280105': { 'vm': '1', 'vr': 'US', name: 'LargestValidPixelValue' },
            '0x00280106': { 'vm': '1', 'vr': 'US', name: 'SmallestImagePixelValue' },
            '0x00280107': { 'vm': '1', 'vr': 'US', name: 'LargestImagePixelValue' },
            '0x00280108': { 'vm': '1', 'vr': 'US', name: 'SmallestPixelValueinSeries' },
            '0x00280109': { 'vm': '1', 'vr': 'US', name: 'LargestPixelValueinSeries' },
            '0x00280110': { 'vm': '1', 'vr': 'US', name: 'SmallestImagePixelValueinPlane' },
            '0x00280111': { 'vm': '1', 'vr': 'US', name: 'LargestImagePixelValueinPlane' },
            '0x00280120': { 'vm': '1', 'vr': 'US', name: 'PixelPaddingValue' },
            '0x00280121': { 'vm': '1', 'vr': 'US', name: 'PixelPaddingRangeLimit' },
            '0x00280122': { 'vm': '1', 'vr': 'FL', name: 'FloatPixelPaddingValue' },
            '0x00280123': { 'vm': '1', 'vr': 'FD', name: 'DoubleFloatPixelPaddingValue' },
            '0x00280124': { 'vm': '1', 'vr': 'FL', name: 'FloatPixelPaddingRangeLimit' },
            '0x00280125': { 'vm': '1', 'vr': 'FD', name: 'DoubleFloatPixelPaddingRangeLimit' },
            '0x00280200': { 'vm': '1', 'vr': 'US', name: 'ImageLocation' },
            '0x00280300': { 'vm': '1', 'vr': 'CS', name: 'QualityControlImage' },
            '0x00280301': { 'vm': '1', 'vr': 'CS', name: 'BurnedInAnnotation' },
            '0x00280302': { 'vm': '1', 'vr': 'CS', name: 'RecognizableVisualFeatures' },
            '0x00280303': { 'vm': '1', 'vr': 'CS', name: 'LongitudinalTemporalInformationModified' },
            '0x00280304': { 'vm': '1', 'vr': 'UI', name: 'ReferencedColorPaletteInstanceUID' },
            '0x00280400': { 'vm': '1', 'vr': 'LO', name: 'TransformLabel' },
            '0x00280401': { 'vm': '1', 'vr': 'LO', name: 'TransformVersionNumber' },
            '0x00280402': { 'vm': '1', 'vr': 'US', name: 'NumberofTransformSteps' },
            '0x00280403': { 'vm': '1-n', 'vr': 'LO', name: 'SequenceofCompressedData' },
            '0x00280404': { 'vm': '1-n', 'vr': 'AT', name: 'DetailsofCoefficients' },
            '0x002804x0': { 'vm': '1', 'vr': 'US', name: 'RowsForNthOrderCoefficients' },
            '0x002804x1': { 'vm': '1', 'vr': 'US', name: 'ColumnsForNthOrderCoefficients' },
            '0x002804x2': { 'vm': '1-n', 'vr': 'LO', name: 'CoefficientCoding' },
            '0x002804x3': { 'vm': '1-n', 'vr': 'AT', name: 'CoefficientCodingPointers' },
            '0x00280700': { 'vm': '1', 'vr': 'LO', name: 'DCTLabel' },
            '0x00280701': { 'vm': '1-n', 'vr': 'CS', name: 'DataBlockDescription' },
            '0x00280702': { 'vm': '1-n', 'vr': 'AT', name: 'DataBlock' },
            '0x00280710': { 'vm': '1', 'vr': 'US', name: 'NormalizationFactorFormat' },
            '0x00280720': { 'vm': '1', 'vr': 'US', name: 'ZonalMapNumberFormat' },
            '0x00280721': { 'vm': '1-n', 'vr': 'AT', name: 'ZonalMapLocation' },
            '0x00280722': { 'vm': '1', 'vr': 'US', name: 'ZonalMapFormat' },
            '0x00280730': { 'vm': '1', 'vr': 'US', name: 'AdaptiveMapFormat' },
            '0x00280740': { 'vm': '1', 'vr': 'US', name: 'CodeNumberFormat' },
            '0x002808x0': { 'vm': '1-n', 'vr': 'CS', name: 'CodeLabel' },
            '0x002808x2': { 'vm': '1', 'vr': 'US', name: 'NumberofTables' },
            '0x002808x3': { 'vm': '1-n', 'vr': 'AT', name: 'CodeTableLocation' },
            '0x002808x4': { 'vm': '1', 'vr': 'US', name: 'BitsForCodeWord' },
            '0x002808x8': { 'vm': '1-n', 'vr': 'AT', name: 'ImageDataLocation' },
            '0x00280A02': { 'vm': '1', 'vr': 'CS', name: 'PixelSpacingCalibrationType' },
            '0x00280A04': { 'vm': '1', 'vr': 'LO', name: 'PixelSpacingCalibrationDescription' },
            '0x00281040': { 'vm': '1', 'vr': 'CS', name: 'PixelIntensityRelationship' },
            '0x00281041': { 'vm': '1', 'vr': 'SS', name: 'PixelIntensityRelationshipSign' },
            '0x00281050': { 'vm': '1-n', 'vr': 'DS', name: 'WindowCenter' },
            '0x00281051': { 'vm': '1-n', 'vr': 'DS', name: 'WindowWidth' },
            '0x00281052': { 'vm': '1', 'vr': 'DS', name: 'RescaleIntercept' },
            '0x00281053': { 'vm': '1', 'vr': 'DS', name: 'RescaleSlope' },
            '0x00281054': { 'vm': '1', 'vr': 'LO', name: 'RescaleType' },
            '0x00281055': { 'vm': '1-n', 'vr': 'LO', name: 'WindowCenter&WidthExplanation' },
            '0x00281056': { 'vm': '1', 'vr': 'CS', name: 'VOILUTFunction' },
            '0x00281080': { 'vm': '1', 'vr': 'CS', name: 'GrayScale' },
            '0x00281090': { 'vm': '1', 'vr': 'CS', name: 'RecommendedViewingMode' },
            '0x00281100': { 'vm': '3', 'vr': 'US', name: 'GrayLookupTableDescriptor' },
            '0x00281101': { 'vm': '3', 'vr': 'US', name: 'RedPaletteColorLookupTableDescriptor' },
            '0x00281102': { 'vm': '3', 'vr': 'US', name: 'GreenPaletteColorLookupTableDescriptor' },
            '0x00281103': { 'vm': '3', 'vr': 'US', name: 'BluePaletteColorLookupTableDescriptor' },
            '0x00281104': { 'vm': '3', 'vr': 'US', name: 'AlphaPaletteColorLookupTableDescriptor' },
            '0x00281111': { 'vm': '4', 'vr': 'US', name: 'LargeRedPaletteColorLookupTableDescriptor' },
            '0x00281112': { 'vm': '4', 'vr': 'US', name: 'LargeGreenPaletteColorLookupTableDescriptor' },
            '0x00281113': { 'vm': '4', 'vr': 'US', name: 'LargeBluePaletteColorLookupTableDescriptor' },
            '0x00281199': { 'vm': '1', 'vr': 'UI', name: 'PaletteColorLookupTableUID' },
            '0x00281200': { 'vm': '1-n or 1', 'vr': 'US or OW', name: 'GrayLookupTableData' },
            '0x00281201': { 'vm': '1', 'vr': 'OW', name: 'RedPaletteColorLookupTableData' },
            '0x00281202': { 'vm': '1', 'vr': 'OW', name: 'GreenPaletteColorLookupTableData' },
            '0x00281203': { 'vm': '1', 'vr': 'OW', name: 'BluePaletteColorLookupTableData' },
            '0x00281204': { 'vm': '1', 'vr': 'OW', name: 'AlphaPaletteColorLookupTableData' },
            '0x00281211': { 'vm': '1', 'vr': 'OW', name: 'LargeRedPaletteColorLookupTableData' },
            '0x00281212': { 'vm': '1', 'vr': 'OW', name: 'LargeGreenPaletteColorLookupTableData' },
            '0x00281213': { 'vm': '1', 'vr': 'OW', name: 'LargeBluePaletteColorLookupTableData' },
            '0x00281214': { 'vm': '1', 'vr': 'UI', name: 'LargePaletteColorLookupTableUID' },
            '0x00281221': { 'vm': '1', 'vr': 'OW', name: 'SegmentedRedPaletteColorLookupTableData' },
            '0x00281222': { 'vm': '1', 'vr': 'OW', name: 'SegmentedGreenPaletteColorLookupTableData' },
            '0x00281223': { 'vm': '1', 'vr': 'OW', name: 'SegmentedBluePaletteColorLookupTableData' },
            '0x00281224': { 'vm': '1', 'vr': 'OW', name: 'SegmentedAlphaPaletteColorLookupTableData' },
            '0x00281230': { 'vm': '1', 'vr': 'SQ', name: 'StoredValueColorRangeSequence' },
            '0x00281231': { 'vm': '1', 'vr': 'FD', name: 'MinimumStoredValueMapped' },
            '0x00281232': { 'vm': '1', 'vr': 'FD', name: 'MaximumStoredValueMapped' },
            '0x00281300': { 'vm': '1', 'vr': 'CS', name: 'BreastImplantPresent' },
            '0x00281350': { 'vm': '1', 'vr': 'CS', name: 'PartialView' },
            '0x00281351': { 'vm': '1', 'vr': 'ST', name: 'PartialViewDescription' },
            '0x00281352': { 'vm': '1', 'vr': 'SQ', name: 'PartialViewCodeSequence' },
            '0x0028135A': { 'vm': '1', 'vr': 'CS', name: 'SpatialLocationsPreserved' },
            '0x00281401': { 'vm': '1', 'vr': 'SQ', name: 'DataFrameAssignmentSequence' },
            '0x00281402': { 'vm': '1', 'vr': 'CS', name: 'DataPathAssignment' },
            '0x00281403': { 'vm': '1', 'vr': 'US', name: 'BitsMappedtoColorLookupTable' },
            '0x00281404': { 'vm': '1', 'vr': 'SQ', name: 'BlendingLUT1Sequence' },
            '0x00281405': { 'vm': '1', 'vr': 'CS', name: 'BlendingLUT1TransferFunction' },
            '0x00281406': { 'vm': '1', 'vr': 'FD', name: 'BlendingWeightConstant' },
            '0x00281407': { 'vm': '3', 'vr': 'US', name: 'BlendingLookupTableDescriptor' },
            '0x00281408': { 'vm': '1', 'vr': 'OW', name: 'BlendingLookupTableData' },
            '0x0028140B': { 'vm': '1', 'vr': 'SQ', name: 'EnhancedPaletteColorLookupTableSequence' },
            '0x0028140C': { 'vm': '1', 'vr': 'SQ', name: 'BlendingLUT2Sequence' },
            '0x0028140D': { 'vm': '1', 'vr': 'CS', name: 'BlendingLUT2TransferFunction' },
            '0x0028140E': { 'vm': '1', 'vr': 'CS', name: 'DataPathID' },
            '0x0028140F': { 'vm': '1', 'vr': 'CS', name: 'RGBLUTTransferFunction' },
            '0x00281410': { 'vm': '1', 'vr': 'CS', name: 'AlphaLUTTransferFunction' },
            '0x00282000': { 'vm': '1', 'vr': 'OB', name: 'ICCProfile' },
            '0x00282002': { 'vm': '1', 'vr': 'CS', name: 'ColorSpace' },
            '0x00282110': { 'vm': '1', 'vr': 'CS', name: 'LossyImageCompression' },
            '0x00282112': { 'vm': '1-n', 'vr': 'DS', name: 'LossyImageCompressionRatio' },
            '0x00282114': { 'vm': '1-n', 'vr': 'CS', name: 'LossyImageCompressionMethod' },
            '0x00283000': { 'vm': '1', 'vr': 'SQ', name: 'ModalityLUTSequence' },
            '0x00283002': { 'vm': '3', 'vr': 'US', name: 'LUTDescriptor' },
            '0x00283003': { 'vm': '1', 'vr': 'LO', name: 'LUTExplanation' },
            '0x00283004': { 'vm': '1', 'vr': 'LO', name: 'ModalityLUTType' },
            '0x00283006': { 'vm': '1-n or 1', 'vr': 'US or OW', name: 'LUTData' },
            '0x00283010': { 'vm': '1', 'vr': 'SQ', name: 'VOILUTSequence' },
            '0x00283110': { 'vm': '1', 'vr': 'SQ', name: 'SoftcopyVOILUTSequence' },
            '0x00284000': { 'vm': '1', 'vr': 'LT', name: 'ImagePresentationComments' },
            '0x00285000': { 'vm': '1', 'vr': 'SQ', name: 'BiPlaneAcquisitionSequence' },
            '0x00286010': { 'vm': '1', 'vr': 'US', name: 'RepresentativeFrameNumber' },
            '0x00286020': { 'vm': '1-n', 'vr': 'US', name: 'FrameNumbersofInterestFOI' },
            '0x00286022': { 'vm': '1-n', 'vr': 'LO', name: 'FrameofInterestDescription' },
            '0x00286023': { 'vm': '1-n', 'vr': 'CS', name: 'FrameofInterestType' },
            '0x00286030': { 'vm': '1-n', 'vr': 'US', name: 'MaskPointers' },
            '0x00286040': { 'vm': '1-n', 'vr': 'US', name: 'RWavePointer' },
            '0x00286100': { 'vm': '1', 'vr': 'SQ', name: 'MaskSubtractionSequence' },
            '0x00286101': { 'vm': '1', 'vr': 'CS', name: 'MaskOperation' },
            '0x00286102': { 'vm': '2-2n', 'vr': 'US', name: 'ApplicableFrameRange' },
            '0x00286110': { 'vm': '1-n', 'vr': 'US', name: 'MaskFrameNumbers' },
            '0x00286112': { 'vm': '1', 'vr': 'US', name: 'ContrastFrameAveraging' },
            '0x00286114': { 'vm': '2', 'vr': 'FL', name: 'MaskSubpixelShift' },
            '0x00286120': { 'vm': '1', 'vr': 'SS', name: 'TIDOffset' },
            '0x00286190': { 'vm': '1', 'vr': 'ST', name: 'MaskOperationExplanation' },
            '0x00287000': { 'vm': '1', 'vr': 'SQ', name: 'EquipmentAdministratorSequence' },
            '0x00287001': { 'vm': '1', 'vr': 'US', name: 'NumberofDisplaySubsystems' },
            '0x00287002': { 'vm': '1', 'vr': 'US', name: 'CurrentConfigurationID' },
            '0x00287003': { 'vm': '1', 'vr': 'US', name: 'DisplaySubsystemID' },
            '0x00287004': { 'vm': '1', 'vr': 'SH', name: 'DisplaySubsystemName' },
            '0x00287005': { 'vm': '1', 'vr': 'LO', name: 'DisplaySubsystemDescription' },
            '0x00287006': { 'vm': '1', 'vr': 'CS', name: 'SystemStatus' },
            '0x00287007': { 'vm': '1', 'vr': 'LO', name: 'SystemStatusComment' },
            '0x00287008': { 'vm': '1', 'vr': 'SQ', name: 'TargetLuminanceCharacteristicsSequence' },
            '0x00287009': { 'vm': '1', 'vr': 'US', name: 'LuminanceCharacteristicsID' },
            '0x0028700A': { 'vm': '1', 'vr': 'SQ', name: 'DisplaySubsystemConfigurationSequence' },
            '0x0028700B': { 'vm': '1', 'vr': 'US', name: 'ConfigurationID' },
            '0x0028700C': { 'vm': '1', 'vr': 'SH', name: 'ConfigurationName' },
            '0x0028700D': { 'vm': '1', 'vr': 'LO', name: 'ConfigurationDescription' },
            '0x0028700E': { 'vm': '1', 'vr': 'US', name: 'ReferencedTargetLuminanceCharacteristicsID' },
            '0x0028700F': { 'vm': '1', 'vr': 'SQ', name: 'QAResultsSequence' },
            '0x00287010': { 'vm': '1', 'vr': 'SQ', name: 'DisplaySubsystemQAResultsSequence' },
            '0x00287011': { 'vm': '1', 'vr': 'SQ', name: 'ConfigurationQAResultsSequence' },
            '0x00287012': { 'vm': '1', 'vr': 'SQ', name: 'MeasurementEquipmentSequence' },
            '0x00287013': { 'vm': '1-n', 'vr': 'CS', name: 'MeasurementFunctions' },
            '0x00287014': { 'vm': '1', 'vr': 'CS', name: 'MeasurementEquipmentType' },
            '0x00287015': { 'vm': '1', 'vr': 'SQ', name: 'VisualEvaluationResultSequence' },
            '0x00287016': { 'vm': '1', 'vr': 'SQ', name: 'DisplayCalibrationResultSequence' },
            '0x00287017': { 'vm': '1', 'vr': 'US', name: 'DDLValue' },
            '0x00287018': { 'vm': '2', 'vr': 'FL', name: 'CIExyWhitePoint' },
            '0x00287019': { 'vm': '1', 'vr': 'CS', name: 'DisplayFunctionType' },
            '0x0028701A': { 'vm': '1', 'vr': 'FL', name: 'GammaValue' },
            '0x0028701B': { 'vm': '1', 'vr': 'US', name: 'NumberofLuminancePoints' },
            '0x0028701C': { 'vm': '1', 'vr': 'SQ', name: 'LuminanceResponseSequence' },
            '0x0028701D': { 'vm': '1', 'vr': 'FL', name: 'TargetMinimumLuminance' },
            '0x0028701E': { 'vm': '1', 'vr': 'FL', name: 'TargetMaximumLuminance' },
            '0x0028701F': { 'vm': '1', 'vr': 'FL', name: 'LuminanceValue' },
            '0x00287020': { 'vm': '1', 'vr': 'LO', name: 'LuminanceResponseDescription' },
            '0x00287021': { 'vm': '1', 'vr': 'CS', name: 'WhitePointFlag' },
            '0x00287022': { 'vm': '1', 'vr': 'SQ', name: 'DisplayDeviceTypeCodeSequence' },
            '0x00287023': { 'vm': '1', 'vr': 'SQ', name: 'DisplaySubsystemSequence' },
            '0x00287024': { 'vm': '1', 'vr': 'SQ', name: 'LuminanceResultSequence' },
            '0x00287025': { 'vm': '1', 'vr': 'CS', name: 'AmbientLightValueSource' },
            '0x00287026': { 'vm': '1-n', 'vr': 'CS', name: 'MeasuredCharacteristics' },
            '0x00287027': { 'vm': '1', 'vr': 'SQ', name: 'LuminanceUniformityResultSequence' },
            '0x00287028': { 'vm': '1', 'vr': 'SQ', name: 'VisualEvaluationTestSequence' },
            '0x00287029': { 'vm': '1', 'vr': 'CS', name: 'TestResult' },
            '0x0028702A': { 'vm': '1', 'vr': 'LO', name: 'TestResultComment' },
            '0x0028702B': { 'vm': '1', 'vr': 'CS', name: 'TestImageValidation' },
            '0x0028702C': { 'vm': '1', 'vr': 'SQ', name: 'TestPatternCodeSequence' },
            '0x0028702D': { 'vm': '1', 'vr': 'SQ', name: 'MeasurementPatternCodeSequence' },
            '0x0028702E': { 'vm': '1', 'vr': 'SQ', name: 'VisualEvaluationMethodCodeSequence' },
            '0x00287FE0': { 'vm': '1', 'vr': 'UR', name: 'PixelDataProviderURL' },
            '0x00289001': { 'vm': '1', 'vr': 'UL', name: 'DataPointRows' },
            '0x00289002': { 'vm': '1', 'vr': 'UL', name: 'DataPointColumns' },
            '0x00289003': { 'vm': '1', 'vr': 'CS', name: 'SignalDomainColumns' },
            '0x00289099': { 'vm': '1', 'vr': 'US', name: 'LargestMonochromePixelValue' },
            '0x00289108': { 'vm': '1', 'vr': 'CS', name: 'DataRepresentation' },
            '0x00289110': { 'vm': '1', 'vr': 'SQ', name: 'PixelMeasuresSequence' },
            '0x00289132': { 'vm': '1', 'vr': 'SQ', name: 'FrameVOILUTSequence' },
            '0x00289145': { 'vm': '1', 'vr': 'SQ', name: 'PixelValueTransformationSequence' },
            '0x00289235': { 'vm': '1', 'vr': 'CS', name: 'SignalDomainRows' },
            '0x00289411': { 'vm': '1', 'vr': 'FL', name: 'DisplayFilterPercentage' },
            '0x00289415': { 'vm': '1', 'vr': 'SQ', name: 'FramePixelShiftSequence' },
            '0x00289416': { 'vm': '1', 'vr': 'US', name: 'SubtractionItemID' },
            '0x00289422': { 'vm': '1', 'vr': 'SQ', name: 'PixelIntensityRelationshipLUTSequence' },
            '0x00289443': { 'vm': '1', 'vr': 'SQ', name: 'FramePixelDataPropertiesSequence' },
            '0x00289444': { 'vm': '1', 'vr': 'CS', name: 'GeometricalProperties' },
            '0x00289445': { 'vm': '1', 'vr': 'FL', name: 'GeometricMaximumDistortion' },
            '0x00289446': { 'vm': '1-n', 'vr': 'CS', name: 'ImageProcessingApplied' },
            '0x00289454': { 'vm': '1', 'vr': 'CS', name: 'MaskSelectionMode' },
            '0x00289474': { 'vm': '1', 'vr': 'CS', name: 'LUTFunction' },
            '0x00289478': { 'vm': '1', 'vr': 'FL', name: 'MaskVisibilityPercentage' },
            '0x00289501': { 'vm': '1', 'vr': 'SQ', name: 'PixelShiftSequence' },
            '0x00289502': { 'vm': '1', 'vr': 'SQ', name: 'RegionPixelShiftSequence' },
            '0x00289503': { 'vm': '2-2n', 'vr': 'SS', name: 'VerticesoftheRegion' },
            '0x00289505': { 'vm': '1', 'vr': 'SQ', name: 'MultiframePresentationSequence' },
            '0x00289506': { 'vm': '2-2n', 'vr': 'US', name: 'PixelShiftFrameRange' },
            '0x00289507': { 'vm': '2-2n', 'vr': 'US', name: 'LUTFrameRange' },
            '0x00289520': { 'vm': '16', 'vr': 'DS', name: 'ImagetoEquipmentMappingMatrix' },
            '0x00289537': { 'vm': '1', 'vr': 'CS', name: 'EquipmentCoordinateSystemIdentification' },
            '0x0032000A': { 'vm': '1', 'vr': 'CS', name: 'StudyStatusID' },
            '0x0032000C': { 'vm': '1', 'vr': 'CS', name: 'StudyPriorityID' },
            '0x00320012': { 'vm': '1', 'vr': 'LO', name: 'StudyIDIssuer' },
            '0x00320032': { 'vm': '1', 'vr': 'DA', name: 'StudyVerifiedDate' },
            '0x00320033': { 'vm': '1', 'vr': 'TM', name: 'StudyVerifiedTime' },
            '0x00320034': { 'vm': '1', 'vr': 'DA', name: 'StudyReadDate' },
            '0x00320035': { 'vm': '1', 'vr': 'TM', name: 'StudyReadTime' },
            '0x00321000': { 'vm': '1', 'vr': 'DA', name: 'ScheduledStudyStartDate' },
            '0x00321001': { 'vm': '1', 'vr': 'TM', name: 'ScheduledStudyStartTime' },
            '0x00321010': { 'vm': '1', 'vr': 'DA', name: 'ScheduledStudyStopDate' },
            '0x00321011': { 'vm': '1', 'vr': 'TM', name: 'ScheduledStudyStopTime' },
            '0x00321020': { 'vm': '1', 'vr': 'LO', name: 'ScheduledStudyLocation' },
            '0x00321021': { 'vm': '1-n', 'vr': 'AE', name: 'ScheduledStudyLocationAETitle' },
            '0x00321030': { 'vm': '1', 'vr': 'LO', name: 'ReasonforStudy' },
            '0x00321031': { 'vm': '1', 'vr': 'SQ', name: 'RequestingPhysicianIdentificationSequence' },
            '0x00321032': { 'vm': '1', 'vr': 'PN', name: 'RequestingPhysician' },
            '0x00321033': { 'vm': '1', 'vr': 'LO', name: 'RequestingService' },
            '0x00321034': { 'vm': '1', 'vr': 'SQ', name: 'RequestingServiceCodeSequence' },
            '0x00321040': { 'vm': '1', 'vr': 'DA', name: 'StudyArrivalDate' },
            '0x00321041': { 'vm': '1', 'vr': 'TM', name: 'StudyArrivalTime' },
            '0x00321050': { 'vm': '1', 'vr': 'DA', name: 'StudyCompletionDate' },
            '0x00321051': { 'vm': '1', 'vr': 'TM', name: 'StudyCompletionTime' },
            '0x00321055': { 'vm': '1', 'vr': 'CS', name: 'StudyComponentStatusID' },
            '0x00321060': { 'vm': '1', 'vr': 'LO', name: 'RequestedProcedureDescription' },
            '0x00321064': { 'vm': '1', 'vr': 'SQ', name: 'RequestedProcedureCodeSequence' },
            '0x00321066': { 'vm': '1', 'vr': 'UT', name: 'ReasonforVisit' },
            '0x00321067': { 'vm': '1', 'vr': 'SQ', name: 'ReasonforVisitCodeSequence' },
            '0x00321070': { 'vm': '1', 'vr': 'LO', name: 'RequestedContrastAgent' },
            '0x00324000': { 'vm': '1', 'vr': 'LT', name: 'StudyComments' },
            '0x00380004': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPatientAliasSequence' },
            '0x00380008': { 'vm': '1', 'vr': 'CS', name: 'VisitStatusID' },
            '0x00380010': { 'vm': '1', 'vr': 'LO', name: 'AdmissionID' },
            '0x00380011': { 'vm': '1', 'vr': 'LO', name: 'IssuerofAdmissionID' },
            '0x00380014': { 'vm': '1', 'vr': 'SQ', name: 'IssuerofAdmissionIDSequence' },
            '0x00380016': { 'vm': '1', 'vr': 'LO', name: 'RouteofAdmissions' },
            '0x0038001A': { 'vm': '1', 'vr': 'DA', name: 'ScheduledAdmissionDate' },
            '0x0038001B': { 'vm': '1', 'vr': 'TM', name: 'ScheduledAdmissionTime' },
            '0x0038001C': { 'vm': '1', 'vr': 'DA', name: 'ScheduledDischargeDate' },
            '0x0038001D': { 'vm': '1', 'vr': 'TM', name: 'ScheduledDischargeTime' },
            '0x0038001E': { 'vm': '1', 'vr': 'LO', name: 'ScheduledPatientInstitutionResidence' },
            '0x00380020': { 'vm': '1', 'vr': 'DA', name: 'AdmittingDate' },
            '0x00380021': { 'vm': '1', 'vr': 'TM', name: 'AdmittingTime' },
            '0x00380030': { 'vm': '1', 'vr': 'DA', name: 'DischargeDate' },
            '0x00380032': { 'vm': '1', 'vr': 'TM', name: 'DischargeTime' },
            '0x00380040': { 'vm': '1', 'vr': 'LO', name: 'DischargeDiagnosisDescription' },
            '0x00380044': { 'vm': '1', 'vr': 'SQ', name: 'DischargeDiagnosisCodeSequence' },
            '0x00380050': { 'vm': '1', 'vr': 'LO', name: 'SpecialNeeds' },
            '0x00380060': { 'vm': '1', 'vr': 'LO', name: 'ServiceEpisodeID' },
            '0x00380061': { 'vm': '1', 'vr': 'LO', name: 'IssuerofServiceEpisodeID' },
            '0x00380062': { 'vm': '1', 'vr': 'LO', name: 'ServiceEpisodeDescription' },
            '0x00380064': { 'vm': '1', 'vr': 'SQ', name: 'IssuerofServiceEpisodeIDSequence' },
            '0x00380100': { 'vm': '1', 'vr': 'SQ', name: 'PertinentDocumentsSequence' },
            '0x00380101': { 'vm': '1', 'vr': 'SQ', name: 'PertinentResourcesSequence' },
            '0x00380102': { 'vm': '1', 'vr': 'LO', name: 'ResourceDescription' },
            '0x00380300': { 'vm': '1', 'vr': 'LO', name: 'CurrentPatientLocation' },
            '0x00380400': { 'vm': '1', 'vr': 'LO', name: 'PatientsInstitutionResidence' },
            '0x00380500': { 'vm': '1', 'vr': 'LO', name: 'PatientState' },
            '0x00380502': { 'vm': '1', 'vr': 'SQ', name: 'PatientClinicalTrialParticipationSequence' },
            '0x00384000': { 'vm': '1', 'vr': 'LT', name: 'VisitComments' },
            '0x003A0004': { 'vm': '1', 'vr': 'CS', name: 'WaveformOriginality' },
            '0x003A0005': { 'vm': '1', 'vr': 'US', name: 'NumberofWaveformChannels' },
            '0x003A0010': { 'vm': '1', 'vr': 'UL', name: 'NumberofWaveformSamples' },
            '0x003A001A': { 'vm': '1', 'vr': 'DS', name: 'SamplingFrequency' },
            '0x003A0020': { 'vm': '1', 'vr': 'SH', name: 'MultiplexGroupLabel' },
            '0x003A0200': { 'vm': '1', 'vr': 'SQ', name: 'ChannelDefinitionSequence' },
            '0x003A0202': { 'vm': '1', 'vr': 'IS', name: 'WaveformChannelNumber' },
            '0x003A0203': { 'vm': '1', 'vr': 'SH', name: 'ChannelLabel' },
            '0x003A0205': { 'vm': '1-n', 'vr': 'CS', name: 'ChannelStatus' },
            '0x003A0208': { 'vm': '1', 'vr': 'SQ', name: 'ChannelSourceSequence' },
            '0x003A0209': { 'vm': '1', 'vr': 'SQ', name: 'ChannelSourceModifiersSequence' },
            '0x003A020A': { 'vm': '1', 'vr': 'SQ', name: 'SourceWaveformSequence' },
            '0x003A020C': { 'vm': '1', 'vr': 'LO', name: 'ChannelDerivationDescription' },
            '0x003A0210': { 'vm': '1', 'vr': 'DS', name: 'ChannelSensitivity' },
            '0x003A0211': { 'vm': '1', 'vr': 'SQ', name: 'ChannelSensitivityUnitsSequence' },
            '0x003A0212': { 'vm': '1', 'vr': 'DS', name: 'ChannelSensitivityCorrectionFactor' },
            '0x003A0213': { 'vm': '1', 'vr': 'DS', name: 'ChannelBaseline' },
            '0x003A0214': { 'vm': '1', 'vr': 'DS', name: 'ChannelTimeSkew' },
            '0x003A0215': { 'vm': '1', 'vr': 'DS', name: 'ChannelSampleSkew' },
            '0x003A0218': { 'vm': '1', 'vr': 'DS', name: 'ChannelOffset' },
            '0x003A021A': { 'vm': '1', 'vr': 'US', name: 'WaveformBitsStored' },
            '0x003A0220': { 'vm': '1', 'vr': 'DS', name: 'FilterLowFrequency' },
            '0x003A0221': { 'vm': '1', 'vr': 'DS', name: 'FilterHighFrequency' },
            '0x003A0222': { 'vm': '1', 'vr': 'DS', name: 'NotchFilterFrequency' },
            '0x003A0223': { 'vm': '1', 'vr': 'DS', name: 'NotchFilterBandwidth' },
            '0x003A0230': { 'vm': '1', 'vr': 'FL', name: 'WaveformDataDisplayScale' },
            '0x003A0231': { 'vm': '3', 'vr': 'US', name: 'WaveformDisplayBackgroundCIELabValue' },
            '0x003A0240': { 'vm': '1', 'vr': 'SQ', name: 'WaveformPresentationGroupSequence' },
            '0x003A0241': { 'vm': '1', 'vr': 'US', name: 'PresentationGroupNumber' },
            '0x003A0242': { 'vm': '1', 'vr': 'SQ', name: 'ChannelDisplaySequence' },
            '0x003A0244': { 'vm': '3', 'vr': 'US', name: 'ChannelRecommendedDisplayCIELabValue' },
            '0x003A0245': { 'vm': '1', 'vr': 'FL', name: 'ChannelPosition' },
            '0x003A0246': { 'vm': '1', 'vr': 'CS', name: 'DisplayShadingFlag' },
            '0x003A0247': { 'vm': '1', 'vr': 'FL', name: 'FractionalChannelDisplayScale' },
            '0x003A0248': { 'vm': '1', 'vr': 'FL', name: 'AbsoluteChannelDisplayScale' },
            '0x003A0300': { 'vm': '1', 'vr': 'SQ', name: 'MultiplexedAudioChannelsDescriptionCodeSequence' },
            '0x003A0301': { 'vm': '1', 'vr': 'IS', name: 'ChannelIdentificationCode' },
            '0x003A0302': { 'vm': '1', 'vr': 'CS', name: 'ChannelMode' },
            '0x00400001': { 'vm': '1-n', 'vr': 'AE', name: 'ScheduledStationAETitle' },
            '0x00400002': { 'vm': '1', 'vr': 'DA', name: 'ScheduledProcedureStepStartDate' },
            '0x00400003': { 'vm': '1', 'vr': 'TM', name: 'ScheduledProcedureStepStartTime' },
            '0x00400004': { 'vm': '1', 'vr': 'DA', name: 'ScheduledProcedureStepEndDate' },
            '0x00400005': { 'vm': '1', 'vr': 'TM', name: 'ScheduledProcedureStepEndTime' },
            '0x00400006': { 'vm': '1', 'vr': 'PN', name: 'ScheduledPerformingPhysiciansName' },
            '0x00400007': { 'vm': '1', 'vr': 'LO', name: 'ScheduledProcedureStepDescription' },
            '0x00400008': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledProtocolCodeSequence' },
            '0x00400009': { 'vm': '1', 'vr': 'SH', name: 'ScheduledProcedureStepID' },
            '0x0040000A': { 'vm': '1', 'vr': 'SQ', name: 'StageCodeSequence' },
            '0x0040000B': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledPerformingPhysicianIdentificationSequence' },
            '0x00400010': { 'vm': '1-n', 'vr': 'SH', name: 'ScheduledStationName' },
            '0x00400011': { 'vm': '1', 'vr': 'SH', name: 'ScheduledProcedureStepLocation' },
            '0x00400012': { 'vm': '1', 'vr': 'LO', name: 'PreMedication' },
            '0x00400020': { 'vm': '1', 'vr': 'CS', name: 'ScheduledProcedureStepStatus' },
            '0x00400026': { 'vm': '1', 'vr': 'SQ', name: 'OrderPlacerIdentifierSequence' },
            '0x00400027': { 'vm': '1', 'vr': 'SQ', name: 'OrderFillerIdentifierSequence' },
            '0x00400031': { 'vm': '1', 'vr': 'UT', name: 'LocalNamespaceEntityID' },
            '0x00400032': { 'vm': '1', 'vr': 'UT', name: 'UniversalEntityID' },
            '0x00400033': { 'vm': '1', 'vr': 'CS', name: 'UniversalEntityIDType' },
            '0x00400035': { 'vm': '1', 'vr': 'CS', name: 'IdentifierTypeCode' },
            '0x00400036': { 'vm': '1', 'vr': 'SQ', name: 'AssigningFacilitySequence' },
            '0x00400039': { 'vm': '1', 'vr': 'SQ', name: 'AssigningJurisdictionCodeSequence' },
            '0x0040003A': { 'vm': '1', 'vr': 'SQ', name: 'AssigningAgencyorDepartmentCodeSequence' },
            '0x00400100': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledProcedureStepSequence' },
            '0x00400220': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedNonImageCompositeSOPInstanceSequence' },
            '0x00400241': { 'vm': '1', 'vr': 'AE', name: 'PerformedStationAETitle' },
            '0x00400242': { 'vm': '1', 'vr': 'SH', name: 'PerformedStationName' },
            '0x00400243': { 'vm': '1', 'vr': 'SH', name: 'PerformedLocation' },
            '0x00400244': { 'vm': '1', 'vr': 'DA', name: 'PerformedProcedureStepStartDate' },
            '0x00400245': { 'vm': '1', 'vr': 'TM', name: 'PerformedProcedureStepStartTime' },
            '0x00400250': { 'vm': '1', 'vr': 'DA', name: 'PerformedProcedureStepEndDate' },
            '0x00400251': { 'vm': '1', 'vr': 'TM', name: 'PerformedProcedureStepEndTime' },
            '0x00400252': { 'vm': '1', 'vr': 'CS', name: 'PerformedProcedureStepStatus' },
            '0x00400253': { 'vm': '1', 'vr': 'SH', name: 'PerformedProcedureStepID' },
            '0x00400254': { 'vm': '1', 'vr': 'LO', name: 'PerformedProcedureStepDescription' },
            '0x00400255': { 'vm': '1', 'vr': 'LO', name: 'PerformedProcedureTypeDescription' },
            '0x00400260': { 'vm': '1', 'vr': 'SQ', name: 'PerformedProtocolCodeSequence' },
            '0x00400261': { 'vm': '1', 'vr': 'CS', name: 'PerformedProtocolType' },
            '0x00400270': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledStepAttributesSequence' },
            '0x00400275': { 'vm': '1', 'vr': 'SQ', name: 'RequestAttributesSequence' },
            '0x00400280': { 'vm': '1', 'vr': 'ST', name: 'CommentsonthePerformedProcedureStep' },
            '0x00400281': { 'vm': '1', 'vr': 'SQ', name: 'PerformedProcedureStepDiscontinuationReasonCodeSequence' },
            '0x00400293': { 'vm': '1', 'vr': 'SQ', name: 'QuantitySequence' },
            '0x00400294': { 'vm': '1', 'vr': 'DS', name: 'Quantity' },
            '0x00400295': { 'vm': '1', 'vr': 'SQ', name: 'MeasuringUnitsSequence' },
            '0x00400296': { 'vm': '1', 'vr': 'SQ', name: 'BillingItemSequence' },
            '0x00400300': { 'vm': '1', 'vr': 'US', name: 'TotalTimeofFluoroscopy' },
            '0x00400301': { 'vm': '1', 'vr': 'US', name: 'TotalNumberofExposures' },
            '0x00400302': { 'vm': '1', 'vr': 'US', name: 'EntranceDose' },
            '0x00400303': { 'vm': '1-2', 'vr': 'US', name: 'ExposedArea' },
            '0x00400306': { 'vm': '1', 'vr': 'DS', name: 'DistanceSourcetoEntrance' },
            '0x00400307': { 'vm': '1', 'vr': 'DS', name: 'DistanceSourcetoSupport' },
            '0x0040030E': { 'vm': '1', 'vr': 'SQ', name: 'ExposureDoseSequence' },
            '0x00400310': { 'vm': '1', 'vr': 'ST', name: 'CommentsonRadiationDose' },
            '0x00400312': { 'vm': '1', 'vr': 'DS', name: 'XRayOutput' },
            '0x00400314': { 'vm': '1', 'vr': 'DS', name: 'HalfValueLayer' },
            '0x00400316': { 'vm': '1', 'vr': 'DS', name: 'OrganDose' },
            '0x00400318': { 'vm': '1', 'vr': 'CS', name: 'OrganExposed' },
            '0x00400320': { 'vm': '1', 'vr': 'SQ', name: 'BillingProcedureStepSequence' },
            '0x00400321': { 'vm': '1', 'vr': 'SQ', name: 'FilmConsumptionSequence' },
            '0x00400324': { 'vm': '1', 'vr': 'SQ', name: 'BillingSuppliesandDevicesSequence' },
            '0x00400330': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedProcedureStepSequence' },
            '0x00400340': { 'vm': '1', 'vr': 'SQ', name: 'PerformedSeriesSequence' },
            '0x00400400': { 'vm': '1', 'vr': 'LT', name: 'CommentsontheScheduledProcedureStep' },
            '0x00400440': { 'vm': '1', 'vr': 'SQ', name: 'ProtocolContextSequence' },
            '0x00400441': { 'vm': '1', 'vr': 'SQ', name: 'ContentItemModifierSequence' },
            '0x00400500': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledSpecimenSequence' },
            '0x0040050A': { 'vm': '1', 'vr': 'LO', name: 'SpecimenAccessionNumber' },
            '0x00400512': { 'vm': '1', 'vr': 'LO', name: 'ContainerIdentifier' },
            '0x00400513': { 'vm': '1', 'vr': 'SQ', name: 'IssueroftheContainerIdentifierSequence' },
            '0x00400515': { 'vm': '1', 'vr': 'SQ', name: 'AlternateContainerIdentifierSequence' },
            '0x00400518': { 'vm': '1', 'vr': 'SQ', name: 'ContainerTypeCodeSequence' },
            '0x0040051A': { 'vm': '1', 'vr': 'LO', name: 'ContainerDescription' },
            '0x00400520': { 'vm': '1', 'vr': 'SQ', name: 'ContainerComponentSequence' },
            '0x00400550': { 'vm': '1', 'vr': 'SQ', name: 'SpecimenSequence' },
            '0x00400551': { 'vm': '1', 'vr': 'LO', name: 'SpecimenIdentifier' },
            '0x00400552': { 'vm': '1', 'vr': 'SQ', name: 'SpecimenDescriptionSequenceTrial' },
            '0x00400553': { 'vm': '1', 'vr': 'ST', name: 'SpecimenDescriptionTrial' },
            '0x00400554': { 'vm': '1', 'vr': 'UI', name: 'SpecimenUID' },
            '0x00400555': { 'vm': '1', 'vr': 'SQ', name: 'AcquisitionContextSequence' },
            '0x00400556': { 'vm': '1', 'vr': 'ST', name: 'AcquisitionContextDescription' },
            '0x0040059A': { 'vm': '1', 'vr': 'SQ', name: 'SpecimenTypeCodeSequence' },
            '0x00400560': { 'vm': '1', 'vr': 'SQ', name: 'SpecimenDescriptionSequence' },
            '0x00400562': { 'vm': '1', 'vr': 'SQ', name: 'IssueroftheSpecimenIdentifierSequence' },
            '0x00400600': { 'vm': '1', 'vr': 'LO', name: 'SpecimenShortDescription' },
            '0x00400602': { 'vm': '1', 'vr': 'UT', name: 'SpecimenDetailedDescription' },
            '0x00400610': { 'vm': '1', 'vr': 'SQ', name: 'SpecimenPreparationSequence' },
            '0x00400612': { 'vm': '1', 'vr': 'SQ', name: 'SpecimenPreparationStepContentItemSequence' },
            '0x00400620': { 'vm': '1', 'vr': 'SQ', name: 'SpecimenLocalizationContentItemSequence' },
            '0x004006FA': { 'vm': '1', 'vr': 'LO', name: 'SlideIdentifier' },
            '0x00400710': { 'vm': '1', 'vr': 'SQ', name: 'WholeSlideMicroscopyImageFrameTypeSequence' },
            '0x0040071A': { 'vm': '1', 'vr': 'SQ', name: 'ImageCenterPointCoordinatesSequence' },
            '0x0040072A': { 'vm': '1', 'vr': 'DS', name: 'XOffsetinSlideCoordinateSystem' },
            '0x0040073A': { 'vm': '1', 'vr': 'DS', name: 'YOffsetinSlideCoordinateSystem' },
            '0x0040074A': { 'vm': '1', 'vr': 'DS', name: 'ZOffsetinSlideCoordinateSystem' },
            '0x004008D8': { 'vm': '1', 'vr': 'SQ', name: 'PixelSpacingSequence' },
            '0x004008DA': { 'vm': '1', 'vr': 'SQ', name: 'CoordinateSystemAxisCodeSequence' },
            '0x004008EA': { 'vm': '1', 'vr': 'SQ', name: 'MeasurementUnitsCodeSequence' },
            '0x004009F8': { 'vm': '1', 'vr': 'SQ', name: 'VitalStainCodeSequenceTrial' },
            '0x00401001': { 'vm': '1', 'vr': 'SH', name: 'RequestedProcedureID' },
            '0x00401002': { 'vm': '1', 'vr': 'LO', name: 'ReasonfortheRequestedProcedure' },
            '0x00401003': { 'vm': '1', 'vr': 'SH', name: 'RequestedProcedurePriority' },
            '0x00401004': { 'vm': '1', 'vr': 'LO', name: 'PatientTransportArrangements' },
            '0x00401005': { 'vm': '1', 'vr': 'LO', name: 'RequestedProcedureLocation' },
            '0x00401006': { 'vm': '1', 'vr': 'SH', name: 'PlacerOrderNumberProcedure' },
            '0x00401007': { 'vm': '1', 'vr': 'SH', name: 'FillerOrderNumberProcedure' },
            '0x00401008': { 'vm': '1', 'vr': 'LO', name: 'ConfidentialityCode' },
            '0x00401009': { 'vm': '1', 'vr': 'SH', name: 'ReportingPriority' },
            '0x0040100A': { 'vm': '1', 'vr': 'SQ', name: 'ReasonforRequestedProcedureCodeSequence' },
            '0x00401010': { 'vm': '1-n', 'vr': 'PN', name: 'NamesofIntendedRecipientsofResults' },
            '0x00401011': { 'vm': '1', 'vr': 'SQ', name: 'IntendedRecipientsofResultsIdentificationSequence' },
            '0x00401012': { 'vm': '1', 'vr': 'SQ', name: 'ReasonForPerformedProcedureCodeSequence' },
            '0x00401060': { 'vm': '1', 'vr': 'LO', name: 'RequestedProcedureDescriptionTrial' },
            '0x00401101': { 'vm': '1', 'vr': 'SQ', name: 'PersonIdentificationCodeSequence' },
            '0x00401102': { 'vm': '1', 'vr': 'ST', name: 'PersonsAddress' },
            '0x00401103': { 'vm': '1-n', 'vr': 'LO', name: 'PersonsTelephoneNumbers' },
            '0x00401104': { 'vm': '1', 'vr': 'LT', name: 'PersonsTelecomInformation' },
            '0x00401400': { 'vm': '1', 'vr': 'LT', name: 'RequestedProcedureComments' },
            '0x00402001': { 'vm': '1', 'vr': 'LO', name: 'ReasonfortheImagingServiceRequest' },
            '0x00402004': { 'vm': '1', 'vr': 'DA', name: 'IssueDateofImagingServiceRequest' },
            '0x00402005': { 'vm': '1', 'vr': 'TM', name: 'IssueTimeofImagingServiceRequest' },
            '0x00402006': { 'vm': '1', 'vr': 'SH', name: 'PlacerOrderNumberImagingServiceRequestRetired' },
            '0x00402007': { 'vm': '1', 'vr': 'SH', name: 'FillerOrderNumberImagingServiceRequestRetired' },
            '0x00402008': { 'vm': '1', 'vr': 'PN', name: 'OrderEnteredBy' },
            '0x00402009': { 'vm': '1', 'vr': 'SH', name: 'OrderEnterersLocation' },
            '0x00402010': { 'vm': '1', 'vr': 'SH', name: 'OrderCallbackPhoneNumber' },
            '0x00402011': { 'vm': '1', 'vr': 'LT', name: 'OrderCallbackTelecomInformation' },
            '0x00402016': { 'vm': '1', 'vr': 'LO', name: 'PlacerOrderNumberImagingServiceRequest' },
            '0x00402017': { 'vm': '1', 'vr': 'LO', name: 'FillerOrderNumberImagingServiceRequest' },
            '0x00402400': { 'vm': '1', 'vr': 'LT', name: 'ImagingServiceRequestComments' },
            '0x00403001': { 'vm': '1', 'vr': 'LO', name: 'ConfidentialityConstraintonPatientDataDescription' },
            '0x00404001': { 'vm': '1', 'vr': 'CS', name: 'GeneralPurposeScheduledProcedureStepStatus' },
            '0x00404002': { 'vm': '1', 'vr': 'CS', name: 'GeneralPurposePerformedProcedureStepStatus' },
            '0x00404003': { 'vm': '1', 'vr': 'CS', name: 'GeneralPurposeScheduledProcedureStepPriority' },
            '0x00404004': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledProcessingApplicationsCodeSequence' },
            '0x00404005': { 'vm': '1', 'vr': 'DT', name: 'ScheduledProcedureStepStartDateTime' },
            '0x00404006': { 'vm': '1', 'vr': 'CS', name: 'MultipleCopiesFlag' },
            '0x00404007': { 'vm': '1', 'vr': 'SQ', name: 'PerformedProcessingApplicationsCodeSequence' },
            '0x00404008': { 'vm': '1', 'vr': 'DT', name: 'ScheduledProcedureStepExpirationDateTime' },
            '0x00404009': { 'vm': '1', 'vr': 'SQ', name: 'HumanPerformerCodeSequence' },
            '0x00404010': { 'vm': '1', 'vr': 'DT', name: 'ScheduledProcedureStepModificationDateTime' },
            '0x00404011': { 'vm': '1', 'vr': 'DT', name: 'ExpectedCompletionDateTime' },
            '0x00404015': { 'vm': '1', 'vr': 'SQ', name: 'ResultingGeneralPurposePerformedProcedureStepsSequence' },
            '0x00404016': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedGeneralPurposeScheduledProcedureStepSequence' },
            '0x00404018': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledWorkitemCodeSequence' },
            '0x00404019': { 'vm': '1', 'vr': 'SQ', name: 'PerformedWorkitemCodeSequence' },
            '0x00404020': { 'vm': '1', 'vr': 'CS', name: 'InputAvailabilityFlag' },
            '0x00404021': { 'vm': '1', 'vr': 'SQ', name: 'InputInformationSequence' },
            '0x00404022': { 'vm': '1', 'vr': 'SQ', name: 'RelevantInformationSequence' },
            '0x00404023': {
                'vm': '1',
                'vr': 'UI',
                name: 'ReferencedGeneralPurposeScheduledProcedureStepTransactionUID'
            },
            '0x00404025': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledStationNameCodeSequence' },
            '0x00404026': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledStationClassCodeSequence' },
            '0x00404027': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledStationGeographicLocationCodeSequence' },
            '0x00404028': { 'vm': '1', 'vr': 'SQ', name: 'PerformedStationNameCodeSequence' },
            '0x00404029': { 'vm': '1', 'vr': 'SQ', name: 'PerformedStationClassCodeSequence' },
            '0x00404030': { 'vm': '1', 'vr': 'SQ', name: 'PerformedStationGeographicLocationCodeSequence' },
            '0x00404031': { 'vm': '1', 'vr': 'SQ', name: 'RequestedSubsequentWorkitemCodeSequence' },
            '0x00404032': { 'vm': '1', 'vr': 'SQ', name: 'NonDICOMOutputCodeSequence' },
            '0x00404033': { 'vm': '1', 'vr': 'SQ', name: 'OutputInformationSequence' },
            '0x00404034': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledHumanPerformersSequence' },
            '0x00404035': { 'vm': '1', 'vr': 'SQ', name: 'ActualHumanPerformersSequence' },
            '0x00404036': { 'vm': '1', 'vr': 'LO', name: 'HumanPerformersOrganization' },
            '0x00404037': { 'vm': '1', 'vr': 'PN', name: 'HumanPerformersName' },
            '0x00404040': { 'vm': '1', 'vr': 'CS', name: 'RawDataHandling' },
            '0x00404041': { 'vm': '1', 'vr': 'CS', name: 'InputReadinessState' },
            '0x00404050': { 'vm': '1', 'vr': 'DT', name: 'PerformedProcedureStepStartDateTime' },
            '0x00404051': { 'vm': '1', 'vr': 'DT', name: 'PerformedProcedureStepEndDateTime' },
            '0x00404052': { 'vm': '1', 'vr': 'DT', name: 'ProcedureStepCancellationDateTime' },
            '0x00404070': { 'vm': '1', 'vr': 'SQ', name: 'OutputDestinationSequence' },
            '0x00404071': { 'vm': '1', 'vr': 'SQ', name: 'DICOMStorageSequence' },
            '0x00404072': { 'vm': '1', 'vr': 'SQ', name: 'STOWRSStorageSequence' },
            '0x00404073': { 'vm': '1', 'vr': 'UR', name: 'StorageURL' },
            '0x00404074': { 'vm': '1', 'vr': 'SQ', name: 'XDSStorageSequence' },
            '0x00408302': { 'vm': '1', 'vr': 'DS', name: 'EntranceDoseinmGy' },
            '0x00408303': { 'vm': '1', 'vr': 'CS', name: 'EntranceDoseDerivation' },
            '0x00409092': { 'vm': '1', 'vr': 'SQ', name: 'ParametricMapFrameTypeSequence' },
            '0x00409094': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedImageRealWorldValueMappingSequence' },
            '0x00409096': { 'vm': '1', 'vr': 'SQ', name: 'RealWorldValueMappingSequence' },
            '0x00409098': { 'vm': '1', 'vr': 'SQ', name: 'PixelValueMappingCodeSequence' },
            '0x00409210': { 'vm': '1', 'vr': 'SH', name: 'LUTLabel' },
            '0x00409211': { 'vm': '1', 'vr': 'US', name: 'RealWorldValueLastValueMapped' },
            '0x00409212': { 'vm': '1-n', 'vr': 'FD', name: 'RealWorldValueLUTData' },
            '0x00409213': { 'vm': '1', 'vr': 'FD', name: 'DoubleFloatRealWorldValueLastValueMapped' },
            '0x00409214': { 'vm': '1', 'vr': 'FD', name: 'DoubleFloatRealWorldValueFirstValueMapped' },
            '0x00409216': { 'vm': '1', 'vr': 'US', name: 'RealWorldValueFirstValueMapped' },
            '0x00409220': { 'vm': '1', 'vr': 'SQ', name: 'QuantityDefinitionSequence' },
            '0x00409224': { 'vm': '1', 'vr': 'FD', name: 'RealWorldValueIntercept' },
            '0x00409225': { 'vm': '1', 'vr': 'FD', name: 'RealWorldValueSlope' },
            '0x0040A007': { 'vm': '1', 'vr': 'CS', name: 'FindingsFlagTrial' },
            '0x0040A010': { 'vm': '1', 'vr': 'CS', name: 'RelationshipType' },
            '0x0040A020': { 'vm': '1', 'vr': 'SQ', name: 'FindingsSequenceTrial' },
            '0x0040A021': { 'vm': '1', 'vr': 'UI', name: 'FindingsGroupUIDTrial' },
            '0x0040A022': { 'vm': '1', 'vr': 'UI', name: 'ReferencedFindingsGroupUIDTrial' },
            '0x0040A023': { 'vm': '1', 'vr': 'DA', name: 'FindingsGroupRecordingDateTrial' },
            '0x0040A024': { 'vm': '1', 'vr': 'TM', name: 'FindingsGroupRecordingTimeTrial' },
            '0x0040A026': { 'vm': '1', 'vr': 'SQ', name: 'FindingsSourceCategoryCodeSequenceTrial' },
            '0x0040A027': { 'vm': '1', 'vr': 'LO', name: 'VerifyingOrganization' },
            '0x0040A028': { 'vm': '1', 'vr': 'SQ', name: 'DocumentingOrganizationIdentifierCodeSequenceTrial' },
            '0x0040A030': { 'vm': '1', 'vr': 'DT', name: 'VerificationDateTime' },
            '0x0040A032': { 'vm': '1', 'vr': 'DT', name: 'ObservationDateTime' },
            '0x0040A040': { 'vm': '1', 'vr': 'CS', name: 'ValueType' },
            '0x0040A043': { 'vm': '1', 'vr': 'SQ', name: 'ConceptNameCodeSequence' },
            '0x0040A047': { 'vm': '1', 'vr': 'LO', name: 'MeasurementPrecisionDescriptionTrial' },
            '0x0040A050': { 'vm': '1', 'vr': 'CS', name: 'ContinuityOfContent' },
            '0x0040A057': { 'vm': '1-n', 'vr': 'CS', name: 'UrgencyorPriorityAlertsTrial' },
            '0x0040A060': { 'vm': '1', 'vr': 'LO', name: 'SequencingIndicatorTrial' },
            '0x0040A066': { 'vm': '1', 'vr': 'SQ', name: 'DocumentIdentifierCodeSequenceTrial' },
            '0x0040A067': { 'vm': '1', 'vr': 'PN', name: 'DocumentAuthorTrial' },
            '0x0040A068': { 'vm': '1', 'vr': 'SQ', name: 'DocumentAuthorIdentifierCodeSequenceTrial' },
            '0x0040A070': { 'vm': '1', 'vr': 'SQ', name: 'IdentifierCodeSequenceTrial' },
            '0x0040A073': { 'vm': '1', 'vr': 'SQ', name: 'VerifyingObserverSequence' },
            '0x0040A074': { 'vm': '1', 'vr': 'OB', name: 'ObjectBinaryIdentifierTrial' },
            '0x0040A075': { 'vm': '1', 'vr': 'PN', name: 'VerifyingObserverName' },
            '0x0040A076': { 'vm': '1', 'vr': 'SQ', name: 'DocumentingObserverIdentifierCodeSequenceTrial' },
            '0x0040A078': { 'vm': '1', 'vr': 'SQ', name: 'AuthorObserverSequence' },
            '0x0040A07A': { 'vm': '1', 'vr': 'SQ', name: 'ParticipantSequence' },
            '0x0040A07C': { 'vm': '1', 'vr': 'SQ', name: 'CustodialOrganizationSequence' },
            '0x0040A080': { 'vm': '1', 'vr': 'CS', name: 'ParticipationType' },
            '0x0040A082': { 'vm': '1', 'vr': 'DT', name: 'ParticipationDateTime' },
            '0x0040A084': { 'vm': '1', 'vr': 'CS', name: 'ObserverType' },
            '0x0040A085': { 'vm': '1', 'vr': 'SQ', name: 'ProcedureIdentifierCodeSequenceTrial' },
            '0x0040A088': { 'vm': '1', 'vr': 'SQ', name: 'VerifyingObserverIdentificationCodeSequence' },
            '0x0040A089': { 'vm': '1', 'vr': 'OB', name: 'ObjectDirectoryBinaryIdentifierTrial' },
            '0x0040A090': { 'vm': '1', 'vr': 'SQ', name: 'EquivalentCDADocumentSequence' },
            '0x0040A0B0': { 'vm': '2-2n', 'vr': 'US', name: 'ReferencedWaveformChannels' },
            '0x0040A110': { 'vm': '1', 'vr': 'DA', name: 'DateofDocumentorVerbalTransactionTrial' },
            '0x0040A112': { 'vm': '1', 'vr': 'TM', name: 'TimeofDocumentCreationorVerbalTransactionTrial' },
            '0x0040A120': { 'vm': '1', 'vr': 'DT', name: 'DateTime' },
            '0x0040A121': { 'vm': '1', 'vr': 'DA', name: 'Date' },
            '0x0040A122': { 'vm': '1', 'vr': 'TM', name: 'Time' },
            '0x0040A123': { 'vm': '1', 'vr': 'PN', name: 'PersonName' },
            '0x0040A124': { 'vm': '1', 'vr': 'UI', name: 'UID' },
            '0x0040A125': { 'vm': '2', 'vr': 'CS', name: 'ReportStatusIDTrial' },
            '0x0040A130': { 'vm': '1', 'vr': 'CS', name: 'TemporalRangeType' },
            '0x0040A132': { 'vm': '1-n', 'vr': 'UL', name: 'ReferencedSamplePositions' },
            '0x0040A136': { 'vm': '1-n', 'vr': 'US', name: 'ReferencedFrameNumbers' },
            '0x0040A138': { 'vm': '1-n', 'vr': 'DS', name: 'ReferencedTimeOffsets' },
            '0x0040A13A': { 'vm': '1-n', 'vr': 'DT', name: 'ReferencedDateTime' },
            '0x0040A160': { 'vm': '1', 'vr': 'UT', name: 'TextValue' },
            '0x0040A161': { 'vm': '1-n', 'vr': 'FD', name: 'FloatingPointValue' },
            '0x0040A162': { 'vm': '1-n', 'vr': 'SL', name: 'RationalNumeratorValue' },
            '0x0040A163': { 'vm': '1-n', 'vr': 'UL', name: 'RationalDenominatorValue' },
            '0x0040A167': { 'vm': '1', 'vr': 'SQ', name: 'ObservationCategoryCodeSequenceTrial' },
            '0x0040A168': { 'vm': '1', 'vr': 'SQ', name: 'ConceptCodeSequence' },
            '0x0040A16A': { 'vm': '1', 'vr': 'ST', name: 'BibliographicCitationTrial' },
            '0x0040A170': { 'vm': '1', 'vr': 'SQ', name: 'PurposeofReferenceCodeSequence' },
            '0x0040A171': { 'vm': '1', 'vr': 'UI', name: 'ObservationUID' },
            '0x0040A172': { 'vm': '1', 'vr': 'UI', name: 'ReferencedObservationUIDTrial' },
            '0x0040A173': { 'vm': '1', 'vr': 'CS', name: 'ReferencedObservationClassTrial' },
            '0x0040A174': { 'vm': '1', 'vr': 'CS', name: 'ReferencedObjectObservationClassTrial' },
            '0x0040A180': { 'vm': '1', 'vr': 'US', name: 'AnnotationGroupNumber' },
            '0x0040A192': { 'vm': '1', 'vr': 'DA', name: 'ObservationDateTrial' },
            '0x0040A193': { 'vm': '1', 'vr': 'TM', name: 'ObservationTimeTrial' },
            '0x0040A194': { 'vm': '1', 'vr': 'CS', name: 'MeasurementAutomationTrial' },
            '0x0040A195': { 'vm': '1', 'vr': 'SQ', name: 'ModifierCodeSequence' },
            '0x0040A224': { 'vm': '1', 'vr': 'ST', name: 'IdentificationDescriptionTrial' },
            '0x0040A290': { 'vm': '1', 'vr': 'CS', name: 'CoordinatesSetGeometricTypeTrial' },
            '0x0040A296': { 'vm': '1', 'vr': 'SQ', name: 'AlgorithmCodeSequenceTrial' },
            '0x0040A297': { 'vm': '1', 'vr': 'ST', name: 'AlgorithmDescriptionTrial' },
            '0x0040A29A': { 'vm': '2-2n', 'vr': 'SL', name: 'PixelCoordinatesSetTrial' },
            '0x0040A300': { 'vm': '1', 'vr': 'SQ', name: 'MeasuredValueSequence' },
            '0x0040A301': { 'vm': '1', 'vr': 'SQ', name: 'NumericValueQualifierCodeSequence' },
            '0x0040A307': { 'vm': '1', 'vr': 'PN', name: 'CurrentObserverTrial' },
            '0x0040A30A': { 'vm': '1-n', 'vr': 'DS', name: 'NumericValue' },
            '0x0040A313': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedAccessionSequenceTrial' },
            '0x0040A33A': { 'vm': '1', 'vr': 'ST', name: 'ReportStatusCommentTrial' },
            '0x0040A340': { 'vm': '1', 'vr': 'SQ', name: 'ProcedureContextSequenceTrial' },
            '0x0040A352': { 'vm': '1', 'vr': 'PN', name: 'VerbalSourceTrial' },
            '0x0040A353': { 'vm': '1', 'vr': 'ST', name: 'AddressTrial' },
            '0x0040A354': { 'vm': '1', 'vr': 'LO', name: 'TelephoneNumberTrial' },
            '0x0040A358': { 'vm': '1', 'vr': 'SQ', name: 'VerbalSourceIdentifierCodeSequenceTrial' },
            '0x0040A360': { 'vm': '1', 'vr': 'SQ', name: 'PredecessorDocumentsSequence' },
            '0x0040A370': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedRequestSequence' },
            '0x0040A372': { 'vm': '1', 'vr': 'SQ', name: 'PerformedProcedureCodeSequence' },
            '0x0040A375': { 'vm': '1', 'vr': 'SQ', name: 'CurrentRequestedProcedureEvidenceSequence' },
            '0x0040A380': { 'vm': '1', 'vr': 'SQ', name: 'ReportDetailSequenceTrial' },
            '0x0040A385': { 'vm': '1', 'vr': 'SQ', name: 'PertinentOtherEvidenceSequence' },
            '0x0040A390': { 'vm': '1', 'vr': 'SQ', name: 'HL7StructuredDocumentReferenceSequence' },
            '0x0040A402': { 'vm': '1', 'vr': 'UI', name: 'ObservationSubjectUIDTrial' },
            '0x0040A403': { 'vm': '1', 'vr': 'CS', name: 'ObservationSubjectClassTrial' },
            '0x0040A404': { 'vm': '1', 'vr': 'SQ', name: 'ObservationSubjectTypeCodeSequenceTrial' },
            '0x0040A491': { 'vm': '1', 'vr': 'CS', name: 'CompletionFlag' },
            '0x0040A492': { 'vm': '1', 'vr': 'LO', name: 'CompletionFlagDescription' },
            '0x0040A493': { 'vm': '1', 'vr': 'CS', name: 'VerificationFlag' },
            '0x0040A494': { 'vm': '1', 'vr': 'CS', name: 'ArchiveRequested' },
            '0x0040A496': { 'vm': '1', 'vr': 'CS', name: 'PreliminaryFlag' },
            '0x0040A504': { 'vm': '1', 'vr': 'SQ', name: 'ContentTemplateSequence' },
            '0x0040A525': { 'vm': '1', 'vr': 'SQ', name: 'IdenticalDocumentsSequence' },
            '0x0040A600': { 'vm': '1', 'vr': 'CS', name: 'ObservationSubjectContextFlagTrial' },
            '0x0040A601': { 'vm': '1', 'vr': 'CS', name: 'ObserverContextFlagTrial' },
            '0x0040A603': { 'vm': '1', 'vr': 'CS', name: 'ProcedureContextFlagTrial' },
            '0x0040A730': { 'vm': '1', 'vr': 'SQ', name: 'ContentSequence' },
            '0x0040A731': { 'vm': '1', 'vr': 'SQ', name: 'RelationshipSequenceTrial' },
            '0x0040A732': { 'vm': '1', 'vr': 'SQ', name: 'RelationshipTypeCodeSequenceTrial' },
            '0x0040A744': { 'vm': '1', 'vr': 'SQ', name: 'LanguageCodeSequenceTrial' },
            '0x0040A992': { 'vm': '1', 'vr': 'ST', name: 'UniformResourceLocatorTrial' },
            '0x0040B020': { 'vm': '1', 'vr': 'SQ', name: 'WaveformAnnotationSequence' },
            '0x0040DB00': { 'vm': '1', 'vr': 'CS', name: 'TemplateIdentifier' },
            '0x0040DB06': { 'vm': '1', 'vr': 'DT', name: 'TemplateVersion' },
            '0x0040DB07': { 'vm': '1', 'vr': 'DT', name: 'TemplateLocalVersion' },
            '0x0040DB0B': { 'vm': '1', 'vr': 'CS', name: 'TemplateExtensionFlag' },
            '0x0040DB0C': { 'vm': '1', 'vr': 'UI', name: 'TemplateExtensionOrganizationUID' },
            '0x0040DB0D': { 'vm': '1', 'vr': 'UI', name: 'TemplateExtensionCreatorUID' },
            '0x0040DB73': { 'vm': '1-n', 'vr': 'UL', name: 'ReferencedContentItemIdentifier' },
            '0x0040E001': { 'vm': '1', 'vr': 'ST', name: 'HL7InstanceIdentifier' },
            '0x0040E004': { 'vm': '1', 'vr': 'DT', name: 'HL7DocumentEffectiveTime' },
            '0x0040E006': { 'vm': '1', 'vr': 'SQ', name: 'HL7DocumentTypeCodeSequence' },
            '0x0040E008': { 'vm': '1', 'vr': 'SQ', name: 'DocumentClassCodeSequence' },
            '0x0040E010': { 'vm': '1', 'vr': 'UR', name: 'RetrieveURI' },
            '0x0040E011': { 'vm': '1', 'vr': 'UI', name: 'RetrieveLocationUID' },
            '0x0040E020': { 'vm': '1', 'vr': 'CS', name: 'TypeofInstances' },
            '0x0040E021': { 'vm': '1', 'vr': 'SQ', name: 'DICOMRetrievalSequence' },
            '0x0040E022': { 'vm': '1', 'vr': 'SQ', name: 'DICOMMediaRetrievalSequence' },
            '0x0040E023': { 'vm': '1', 'vr': 'SQ', name: 'WADORetrievalSequence' },
            '0x0040E024': { 'vm': '1', 'vr': 'SQ', name: 'XDSRetrievalSequence' },
            '0x0040E025': { 'vm': '1', 'vr': 'SQ', name: 'WADORSRetrievalSequence' },
            '0x0040E030': { 'vm': '1', 'vr': 'UI', name: 'RepositoryUniqueID' },
            '0x0040E031': { 'vm': '1', 'vr': 'UI', name: 'HomeCommunityID' },
            '0x00420010': { 'vm': '1', 'vr': 'ST', name: 'DocumentTitle' },
            '0x00420011': { 'vm': '1', 'vr': 'OB', name: 'EncapsulatedDocument' },
            '0x00420012': { 'vm': '1', 'vr': 'LO', name: 'MIMETypeofEncapsulatedDocument' },
            '0x00420013': { 'vm': '1', 'vr': 'SQ', name: 'SourceInstanceSequence' },
            '0x00420014': { 'vm': '1-n', 'vr': 'LO', name: 'ListofMIMETypes' },
            '0x00420015': { 'vm': '1', 'vr': 'UL', name: 'EncapsulatedDocumentLength' },
            '0x00440001': { 'vm': '1', 'vr': 'ST', name: 'ProductPackageIdentifier' },
            '0x00440002': { 'vm': '1', 'vr': 'CS', name: 'SubstanceAdministrationApproval' },
            '0x00440003': { 'vm': '1', 'vr': 'LT', name: 'ApprovalStatusFurtherDescription' },
            '0x00440004': { 'vm': '1', 'vr': 'DT', name: 'ApprovalStatusDateTime' },
            '0x00440007': { 'vm': '1', 'vr': 'SQ', name: 'ProductTypeCodeSequence' },
            '0x00440008': { 'vm': '1-n', 'vr': 'LO', name: 'ProductName' },
            '0x00440009': { 'vm': '1', 'vr': 'LT', name: 'ProductDescription' },
            '0x0044000A': { 'vm': '1', 'vr': 'LO', name: 'ProductLotIdentifier' },
            '0x0044000B': { 'vm': '1', 'vr': 'DT', name: 'ProductExpirationDateTime' },
            '0x00440010': { 'vm': '1', 'vr': 'DT', name: 'SubstanceAdministrationDateTime' },
            '0x00440011': { 'vm': '1', 'vr': 'LO', name: 'SubstanceAdministrationNotes' },
            '0x00440012': { 'vm': '1', 'vr': 'LO', name: 'SubstanceAdministrationDeviceID' },
            '0x00440013': { 'vm': '1', 'vr': 'SQ', name: 'ProductParameterSequence' },
            '0x00440019': { 'vm': '1', 'vr': 'SQ', name: 'SubstanceAdministrationParameterSequence' },
            '0x00440100': { 'vm': '1', 'vr': 'SQ', name: 'ApprovalSequence' },
            '0x00440101': { 'vm': '1', 'vr': 'SQ', name: 'AssertionCodeSequence' },
            '0x00440102': { 'vm': '1', 'vr': 'UI', name: 'AssertionUID' },
            '0x00440103': { 'vm': '1', 'vr': 'SQ', name: 'AsserterIdentificationSequence' },
            '0x00440104': { 'vm': '1', 'vr': 'DT', name: 'AssertionDateTime' },
            '0x00440105': { 'vm': '1', 'vr': 'DT', name: 'AssertionExpirationDateTime' },
            '0x00440106': { 'vm': '1', 'vr': 'UT', name: 'AssertionComments' },
            '0x00440107': { 'vm': '1', 'vr': 'SQ', name: 'RelatedAssertionSequence' },
            '0x00440108': { 'vm': '1', 'vr': 'UI', name: 'ReferencedAssertionUID' },
            '0x00440109': { 'vm': '1', 'vr': 'SQ', name: 'ApprovalSubjectSequence' },
            '0x0044010A': { 'vm': '1', 'vr': 'SQ', name: 'OrganizationalRoleCodeSequence' },
            '0x00460012': { 'vm': '1', 'vr': 'LO', name: 'LensDescription' },
            '0x00460014': { 'vm': '1', 'vr': 'SQ', name: 'RightLensSequence' },
            '0x00460015': { 'vm': '1', 'vr': 'SQ', name: 'LeftLensSequence' },
            '0x00460016': { 'vm': '1', 'vr': 'SQ', name: 'UnspecifiedLateralityLensSequence' },
            '0x00460018': { 'vm': '1', 'vr': 'SQ', name: 'CylinderSequence' },
            '0x00460028': { 'vm': '1', 'vr': 'SQ', name: 'PrismSequence' },
            '0x00460030': { 'vm': '1', 'vr': 'FD', name: 'HorizontalPrismPower' },
            '0x00460032': { 'vm': '1', 'vr': 'CS', name: 'HorizontalPrismBase' },
            '0x00460034': { 'vm': '1', 'vr': 'FD', name: 'VerticalPrismPower' },
            '0x00460036': { 'vm': '1', 'vr': 'CS', name: 'VerticalPrismBase' },
            '0x00460038': { 'vm': '1', 'vr': 'CS', name: 'LensSegmentType' },
            '0x00460040': { 'vm': '1', 'vr': 'FD', name: 'OpticalTransmittance' },
            '0x00460042': { 'vm': '1', 'vr': 'FD', name: 'ChannelWidth' },
            '0x00460044': { 'vm': '1', 'vr': 'FD', name: 'PupilSize' },
            '0x00460046': { 'vm': '1', 'vr': 'FD', name: 'CornealSize' },
            '0x00460047': { 'vm': '1', 'vr': 'SQ', name: 'CornealSizeSequence' },
            '0x00460050': { 'vm': '1', 'vr': 'SQ', name: 'AutorefractionRightEyeSequence' },
            '0x00460052': { 'vm': '1', 'vr': 'SQ', name: 'AutorefractionLeftEyeSequence' },
            '0x00460060': { 'vm': '1', 'vr': 'FD', name: 'DistancePupillaryDistance' },
            '0x00460062': { 'vm': '1', 'vr': 'FD', name: 'NearPupillaryDistance' },
            '0x00460063': { 'vm': '1', 'vr': 'FD', name: 'IntermediatePupillaryDistance' },
            '0x00460064': { 'vm': '1', 'vr': 'FD', name: 'OtherPupillaryDistance' },
            '0x00460070': { 'vm': '1', 'vr': 'SQ', name: 'KeratometryRightEyeSequence' },
            '0x00460071': { 'vm': '1', 'vr': 'SQ', name: 'KeratometryLeftEyeSequence' },
            '0x00460074': { 'vm': '1', 'vr': 'SQ', name: 'SteepKeratometricAxisSequence' },
            '0x00460075': { 'vm': '1', 'vr': 'FD', name: 'RadiusofCurvature' },
            '0x00460076': { 'vm': '1', 'vr': 'FD', name: 'KeratometricPower' },
            '0x00460077': { 'vm': '1', 'vr': 'FD', name: 'KeratometricAxis' },
            '0x00460080': { 'vm': '1', 'vr': 'SQ', name: 'FlatKeratometricAxisSequence' },
            '0x00460092': { 'vm': '1', 'vr': 'CS', name: 'BackgroundColor' },
            '0x00460094': { 'vm': '1', 'vr': 'CS', name: 'Optotype' },
            '0x00460095': { 'vm': '1', 'vr': 'CS', name: 'OptotypePresentation' },
            '0x00460097': { 'vm': '1', 'vr': 'SQ', name: 'SubjectiveRefractionRightEyeSequence' },
            '0x00460098': { 'vm': '1', 'vr': 'SQ', name: 'SubjectiveRefractionLeftEyeSequence' },
            '0x00460100': { 'vm': '1', 'vr': 'SQ', name: 'AddNearSequence' },
            '0x00460101': { 'vm': '1', 'vr': 'SQ', name: 'AddIntermediateSequence' },
            '0x00460102': { 'vm': '1', 'vr': 'SQ', name: 'AddOtherSequence' },
            '0x00460104': { 'vm': '1', 'vr': 'FD', name: 'AddPower' },
            '0x00460106': { 'vm': '1', 'vr': 'FD', name: 'ViewingDistance' },
            '0x00460110': { 'vm': '1', 'vr': 'SQ', name: 'CorneaMeasurementsSequence' },
            '0x00460111': { 'vm': '1', 'vr': 'SQ', name: 'SourceofCorneaMeasurementDataCodeSequence' },
            '0x00460112': { 'vm': '1', 'vr': 'SQ', name: 'SteepCornealAxisSequence' },
            '0x00460113': { 'vm': '1', 'vr': 'SQ', name: 'FlatCornealAxisSequence' },
            '0x00460114': { 'vm': '1', 'vr': 'FD', name: 'CornealPower' },
            '0x00460115': { 'vm': '1', 'vr': 'FD', name: 'CornealAxis' },
            '0x00460116': { 'vm': '1', 'vr': 'SQ', name: 'CorneaMeasurementMethodCodeSequence' },
            '0x00460117': { 'vm': '1', 'vr': 'FL', name: 'RefractiveIndexofCornea' },
            '0x00460118': { 'vm': '1', 'vr': 'FL', name: 'RefractiveIndexofAqueousHumor' },
            '0x00460121': { 'vm': '1', 'vr': 'SQ', name: 'VisualAcuityTypeCodeSequence' },
            '0x00460122': { 'vm': '1', 'vr': 'SQ', name: 'VisualAcuityRightEyeSequence' },
            '0x00460123': { 'vm': '1', 'vr': 'SQ', name: 'VisualAcuityLeftEyeSequence' },
            '0x00460124': { 'vm': '1', 'vr': 'SQ', name: 'VisualAcuityBothEyesOpenSequence' },
            '0x00460125': { 'vm': '1', 'vr': 'CS', name: 'ViewingDistanceType' },
            '0x00460135': { 'vm': '2', 'vr': 'SS', name: 'VisualAcuityModifiers' },
            '0x00460137': { 'vm': '1', 'vr': 'FD', name: 'DecimalVisualAcuity' },
            '0x00460139': { 'vm': '1', 'vr': 'LO', name: 'OptotypeDetailedDefinition' },
            '0x00460145': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedRefractiveMeasurementsSequence' },
            '0x00460146': { 'vm': '1', 'vr': 'FD', name: 'SpherePower' },
            '0x00460147': { 'vm': '1', 'vr': 'FD', name: 'CylinderPower' },
            '0x00460201': { 'vm': '1', 'vr': 'CS', name: 'CornealTopographySurface' },
            '0x00460202': { 'vm': '2', 'vr': 'FL', name: 'CornealVertexLocation' },
            '0x00460203': { 'vm': '1', 'vr': 'FL', name: 'PupilCentroidXCoordinate' },
            '0x00460204': { 'vm': '1', 'vr': 'FL', name: 'PupilCentroidYCoordinate' },
            '0x00460205': { 'vm': '1', 'vr': 'FL', name: 'EquivalentPupilRadius' },
            '0x00460207': { 'vm': '1', 'vr': 'SQ', name: 'CornealTopographyMapTypeCodeSequence' },
            '0x00460208': { 'vm': '2-2n', 'vr': 'IS', name: 'VerticesoftheOutlineofPupil' },
            '0x00460210': { 'vm': '1', 'vr': 'SQ', name: 'CornealTopographyMappingNormalsSequence' },
            '0x00460211': { 'vm': '1', 'vr': 'SQ', name: 'MaximumCornealCurvatureSequence' },
            '0x00460212': { 'vm': '1', 'vr': 'FL', name: 'MaximumCornealCurvature' },
            '0x00460213': { 'vm': '2', 'vr': 'FL', name: 'MaximumCornealCurvatureLocation' },
            '0x00460215': { 'vm': '1', 'vr': 'SQ', name: 'MinimumKeratometricSequence' },
            '0x00460218': { 'vm': '1', 'vr': 'SQ', name: 'SimulatedKeratometricCylinderSequence' },
            '0x00460220': { 'vm': '1', 'vr': 'FL', name: 'AverageCornealPower' },
            '0x00460224': { 'vm': '1', 'vr': 'FL', name: 'CornealISValue' },
            '0x00460227': { 'vm': '1', 'vr': 'FL', name: 'AnalyzedArea' },
            '0x00460230': { 'vm': '1', 'vr': 'FL', name: 'SurfaceRegularityIndex' },
            '0x00460232': { 'vm': '1', 'vr': 'FL', name: 'SurfaceAsymmetryIndex' },
            '0x00460234': { 'vm': '1', 'vr': 'FL', name: 'CornealEccentricityIndex' },
            '0x00460236': { 'vm': '1', 'vr': 'FL', name: 'KeratoconusPredictionIndex' },
            '0x00460238': { 'vm': '1', 'vr': 'FL', name: 'DecimalPotentialVisualAcuity' },
            '0x00460242': { 'vm': '1', 'vr': 'CS', name: 'CornealTopographyMapQualityEvaluation' },
            '0x00460244': { 'vm': '1', 'vr': 'SQ', name: 'SourceImageCornealProcessedDataSequence' },
            '0x00460247': { 'vm': '3', 'vr': 'FL', name: 'CornealPointLocation' },
            '0x00460248': { 'vm': '1', 'vr': 'CS', name: 'CornealPointEstimated' },
            '0x00460249': { 'vm': '1', 'vr': 'FL', name: 'AxialPower' },
            '0x00460250': { 'vm': '1', 'vr': 'FL', name: 'TangentialPower' },
            '0x00460251': { 'vm': '1', 'vr': 'FL', name: 'RefractivePower' },
            '0x00460252': { 'vm': '1', 'vr': 'FL', name: 'RelativeElevation' },
            '0x00460253': { 'vm': '1', 'vr': 'FL', name: 'CornealWavefront' },
            '0x00480001': { 'vm': '1', 'vr': 'FL', name: 'ImagedVolumeWidth' },
            '0x00480002': { 'vm': '1', 'vr': 'FL', name: 'ImagedVolumeHeight' },
            '0x00480003': { 'vm': '1', 'vr': 'FL', name: 'ImagedVolumeDepth' },
            '0x00480006': { 'vm': '1', 'vr': 'UL', name: 'TotalPixelMatrixColumns' },
            '0x00480007': { 'vm': '1', 'vr': 'UL', name: 'TotalPixelMatrixRows' },
            '0x00480008': { 'vm': '1', 'vr': 'SQ', name: 'TotalPixelMatrixOriginSequence' },
            '0x00480010': { 'vm': '1', 'vr': 'CS', name: 'SpecimenLabelinImage' },
            '0x00480011': { 'vm': '1', 'vr': 'CS', name: 'FocusMethod' },
            '0x00480012': { 'vm': '1', 'vr': 'CS', name: 'ExtendedDepthofField' },
            '0x00480013': { 'vm': '1', 'vr': 'US', name: 'NumberofFocalPlanes' },
            '0x00480014': { 'vm': '1', 'vr': 'FL', name: 'DistanceBetweenFocalPlanes' },
            '0x00480015': { 'vm': '3', 'vr': 'US', name: 'RecommendedAbsentPixelCIELabValue' },
            '0x00480100': { 'vm': '1', 'vr': 'SQ', name: 'IlluminatorTypeCodeSequence' },
            '0x00480102': { 'vm': '6', 'vr': 'DS', name: 'ImageOrientationSlide' },
            '0x00480105': { 'vm': '1', 'vr': 'SQ', name: 'OpticalPathSequence' },
            '0x00480106': { 'vm': '1', 'vr': 'SH', name: 'OpticalPathIdentifier' },
            '0x00480107': { 'vm': '1', 'vr': 'ST', name: 'OpticalPathDescription' },
            '0x00480108': { 'vm': '1', 'vr': 'SQ', name: 'IlluminationColorCodeSequence' },
            '0x00480110': { 'vm': '1', 'vr': 'SQ', name: 'SpecimenReferenceSequence' },
            '0x00480111': { 'vm': '1', 'vr': 'DS', name: 'CondenserLensPower' },
            '0x00480112': { 'vm': '1', 'vr': 'DS', name: 'ObjectiveLensPower' },
            '0x00480113': { 'vm': '1', 'vr': 'DS', name: 'ObjectiveLensNumericalAperture' },
            '0x00480120': { 'vm': '1', 'vr': 'SQ', name: 'PaletteColorLookupTableSequence' },
            '0x00480200': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedImageNavigationSequence' },
            '0x00480201': { 'vm': '2', 'vr': 'US', name: 'TopLeftHandCornerofLocalizerArea' },
            '0x00480202': { 'vm': '2', 'vr': 'US', name: 'BottomRightHandCornerofLocalizerArea' },
            '0x00480207': { 'vm': '1', 'vr': 'SQ', name: 'OpticalPathIdentificationSequence' },
            '0x0048021A': { 'vm': '1', 'vr': 'SQ', name: 'PlanePositionSlideSequence' },
            '0x0048021E': { 'vm': '1', 'vr': 'SL', name: 'ColumnPositionInTotalImagePixelMatrix' },
            '0x0048021F': { 'vm': '1', 'vr': 'SL', name: 'RowPositionInTotalImagePixelMatrix' },
            '0x00480301': { 'vm': '1', 'vr': 'CS', name: 'PixelOriginInterpretation' },
            '0x00480302': { 'vm': '1', 'vr': 'UL', name: 'NumberofOpticalPaths' },
            '0x00480303': { 'vm': '1', 'vr': 'UL', name: 'TotalPixelMatrixFocalPlanes' },
            '0x00500004': { 'vm': '1', 'vr': 'CS', name: 'CalibrationImage' },
            '0x00500010': { 'vm': '1', 'vr': 'SQ', name: 'DeviceSequence' },
            '0x00500012': { 'vm': '1', 'vr': 'SQ', name: 'ContainerComponentTypeCodeSequence' },
            '0x00500013': { 'vm': '1', 'vr': 'FD', name: 'ContainerComponentThickness' },
            '0x00500014': { 'vm': '1', 'vr': 'DS', name: 'DeviceLength' },
            '0x00500015': { 'vm': '1', 'vr': 'FD', name: 'ContainerComponentWidth' },
            '0x00500016': { 'vm': '1', 'vr': 'DS', name: 'DeviceDiameter' },
            '0x00500017': { 'vm': '1', 'vr': 'CS', name: 'DeviceDiameterUnits' },
            '0x00500018': { 'vm': '1', 'vr': 'DS', name: 'DeviceVolume' },
            '0x00500019': { 'vm': '1', 'vr': 'DS', name: 'InterMarkerDistance' },
            '0x0050001A': { 'vm': '1', 'vr': 'CS', name: 'ContainerComponentMaterial' },
            '0x0050001B': { 'vm': '1', 'vr': 'LO', name: 'ContainerComponentID' },
            '0x0050001C': { 'vm': '1', 'vr': 'FD', name: 'ContainerComponentLength' },
            '0x0050001D': { 'vm': '1', 'vr': 'FD', name: 'ContainerComponentDiameter' },
            '0x0050001E': { 'vm': '1', 'vr': 'LO', name: 'ContainerComponentDescription' },
            '0x00500020': { 'vm': '1', 'vr': 'LO', name: 'DeviceDescription' },
            '0x00500021': { 'vm': '1', 'vr': 'ST', name: 'LongDeviceDescription' },
            '0x00520001': { 'vm': '1', 'vr': 'FL', name: 'ContrastBolusIngredientPercentbyVolume' },
            '0x00520002': { 'vm': '1', 'vr': 'FD', name: 'OCTFocalDistance' },
            '0x00520003': { 'vm': '1', 'vr': 'FD', name: 'BeamSpotSize' },
            '0x00520004': { 'vm': '1', 'vr': 'FD', name: 'EffectiveRefractiveIndex' },
            '0x00520006': { 'vm': '1', 'vr': 'CS', name: 'OCTAcquisitionDomain' },
            '0x00520007': { 'vm': '1', 'vr': 'FD', name: 'OCTOpticalCenterWavelength' },
            '0x00520008': { 'vm': '1', 'vr': 'FD', name: 'AxialResolution' },
            '0x00520009': { 'vm': '1', 'vr': 'FD', name: 'RangingDepth' },
            '0x00520011': { 'vm': '1', 'vr': 'FD', name: 'AlineRate' },
            '0x00520012': { 'vm': '1', 'vr': 'US', name: 'AlinesPerFrame' },
            '0x00520013': { 'vm': '1', 'vr': 'FD', name: 'CatheterRotationalRate' },
            '0x00520014': { 'vm': '1', 'vr': 'FD', name: 'AlinePixelSpacing' },
            '0x00520016': { 'vm': '1', 'vr': 'SQ', name: 'ModeofPercutaneousAccessSequence' },
            '0x00520025': { 'vm': '1', 'vr': 'SQ', name: 'IntravascularOCTFrameTypeSequence' },
            '0x00520026': { 'vm': '1', 'vr': 'CS', name: 'OCTZOffsetApplied' },
            '0x00520027': { 'vm': '1', 'vr': 'SQ', name: 'IntravascularFrameContentSequence' },
            '0x00520028': { 'vm': '1', 'vr': 'FD', name: 'IntravascularLongitudinalDistance' },
            '0x00520029': { 'vm': '1', 'vr': 'SQ', name: 'IntravascularOCTFrameContentSequence' },
            '0x00520030': { 'vm': '1', 'vr': 'SS', name: 'OCTZOffsetCorrection' },
            '0x00520031': { 'vm': '1', 'vr': 'CS', name: 'CatheterDirectionofRotation' },
            '0x00520033': { 'vm': '1', 'vr': 'FD', name: 'SeamLineLocation' },
            '0x00520034': { 'vm': '1', 'vr': 'FD', name: 'FirstAlineLocation' },
            '0x00520036': { 'vm': '1', 'vr': 'US', name: 'SeamLineIndex' },
            '0x00520038': { 'vm': '1', 'vr': 'US', name: 'NumberofPaddedAlines' },
            '0x00520039': { 'vm': '1', 'vr': 'CS', name: 'InterpolationType' },
            '0x0052003A': { 'vm': '1', 'vr': 'CS', name: 'RefractiveIndexApplied' },
            '0x00540010': { 'vm': '1-n', 'vr': 'US', name: 'EnergyWindowVector' },
            '0x00540011': { 'vm': '1', 'vr': 'US', name: 'NumberofEnergyWindows' },
            '0x00540012': { 'vm': '1', 'vr': 'SQ', name: 'EnergyWindowInformationSequence' },
            '0x00540013': { 'vm': '1', 'vr': 'SQ', name: 'EnergyWindowRangeSequence' },
            '0x00540014': { 'vm': '1', 'vr': 'DS', name: 'EnergyWindowLowerLimit' },
            '0x00540015': { 'vm': '1', 'vr': 'DS', name: 'EnergyWindowUpperLimit' },
            '0x00540016': { 'vm': '1', 'vr': 'SQ', name: 'RadiopharmaceuticalInformationSequence' },
            '0x00540017': { 'vm': '1', 'vr': 'IS', name: 'ResidualSyringeCounts' },
            '0x00540018': { 'vm': '1', 'vr': 'SH', name: 'EnergyWindowName' },
            '0x00540020': { 'vm': '1-n', 'vr': 'US', name: 'DetectorVector' },
            '0x00540021': { 'vm': '1', 'vr': 'US', name: 'NumberofDetectors' },
            '0x00540022': { 'vm': '1', 'vr': 'SQ', name: 'DetectorInformationSequence' },
            '0x00540030': { 'vm': '1-n', 'vr': 'US', name: 'PhaseVector' },
            '0x00540031': { 'vm': '1', 'vr': 'US', name: 'NumberofPhases' },
            '0x00540032': { 'vm': '1', 'vr': 'SQ', name: 'PhaseInformationSequence' },
            '0x00540033': { 'vm': '1', 'vr': 'US', name: 'NumberofFramesinPhase' },
            '0x00540036': { 'vm': '1', 'vr': 'IS', name: 'PhaseDelay' },
            '0x00540038': { 'vm': '1', 'vr': 'IS', name: 'PauseBetweenFrames' },
            '0x00540039': { 'vm': '1', 'vr': 'CS', name: 'PhaseDescription' },
            '0x00540050': { 'vm': '1-n', 'vr': 'US', name: 'RotationVector' },
            '0x00540051': { 'vm': '1', 'vr': 'US', name: 'NumberofRotations' },
            '0x00540052': { 'vm': '1', 'vr': 'SQ', name: 'RotationInformationSequence' },
            '0x00540053': { 'vm': '1', 'vr': 'US', name: 'NumberofFramesinRotation' },
            '0x00540060': { 'vm': '1-n', 'vr': 'US', name: 'RRIntervalVector' },
            '0x00540061': { 'vm': '1', 'vr': 'US', name: 'NumberofRRIntervals' },
            '0x00540062': { 'vm': '1', 'vr': 'SQ', name: 'GatedInformationSequence' },
            '0x00540063': { 'vm': '1', 'vr': 'SQ', name: 'DataInformationSequence' },
            '0x00540070': { 'vm': '1-n', 'vr': 'US', name: 'TimeSlotVector' },
            '0x00540071': { 'vm': '1', 'vr': 'US', name: 'NumberofTimeSlots' },
            '0x00540072': { 'vm': '1', 'vr': 'SQ', name: 'TimeSlotInformationSequence' },
            '0x00540073': { 'vm': '1', 'vr': 'DS', name: 'TimeSlotTime' },
            '0x00540080': { 'vm': '1-n', 'vr': 'US', name: 'SliceVector' },
            '0x00540081': { 'vm': '1', 'vr': 'US', name: 'NumberofSlices' },
            '0x00540090': { 'vm': '1-n', 'vr': 'US', name: 'AngularViewVector' },
            '0x00540100': { 'vm': '1-n', 'vr': 'US', name: 'TimeSliceVector' },
            '0x00540101': { 'vm': '1', 'vr': 'US', name: 'NumberofTimeSlices' },
            '0x00540200': { 'vm': '1', 'vr': 'DS', name: 'StartAngle' },
            '0x00540202': { 'vm': '1', 'vr': 'CS', name: 'TypeofDetectorMotion' },
            '0x00540210': { 'vm': '1-n', 'vr': 'IS', name: 'TriggerVector' },
            '0x00540211': { 'vm': '1', 'vr': 'US', name: 'NumberofTriggersinPhase' },
            '0x00540220': { 'vm': '1', 'vr': 'SQ', name: 'ViewCodeSequence' },
            '0x00540222': { 'vm': '1', 'vr': 'SQ', name: 'ViewModifierCodeSequence' },
            '0x00540300': { 'vm': '1', 'vr': 'SQ', name: 'RadionuclideCodeSequence' },
            '0x00540302': { 'vm': '1', 'vr': 'SQ', name: 'AdministrationRouteCodeSequence' },
            '0x00540304': { 'vm': '1', 'vr': 'SQ', name: 'RadiopharmaceuticalCodeSequence' },
            '0x00540306': { 'vm': '1', 'vr': 'SQ', name: 'CalibrationDataSequence' },
            '0x00540308': { 'vm': '1', 'vr': 'US', name: 'EnergyWindowNumber' },
            '0x00540400': { 'vm': '1', 'vr': 'SH', name: 'ImageID' },
            '0x00540410': { 'vm': '1', 'vr': 'SQ', name: 'PatientOrientationCodeSequence' },
            '0x00540412': { 'vm': '1', 'vr': 'SQ', name: 'PatientOrientationModifierCodeSequence' },
            '0x00540414': { 'vm': '1', 'vr': 'SQ', name: 'PatientGantryRelationshipCodeSequence' },
            '0x00540500': { 'vm': '1', 'vr': 'CS', name: 'SliceProgressionDirection' },
            '0x00540501': { 'vm': '1', 'vr': 'CS', name: 'ScanProgressionDirection' },
            '0x00541000': { 'vm': '2', 'vr': 'CS', name: 'SeriesType' },
            '0x00541001': { 'vm': '1', 'vr': 'CS', name: 'Units' },
            '0x00541002': { 'vm': '1', 'vr': 'CS', name: 'CountsSource' },
            '0x00541004': { 'vm': '1', 'vr': 'CS', name: 'ReprojectionMethod' },
            '0x00541006': { 'vm': '1', 'vr': 'CS', name: 'SUVType' },
            '0x00541100': { 'vm': '1', 'vr': 'CS', name: 'RandomsCorrectionMethod' },
            '0x00541101': { 'vm': '1', 'vr': 'LO', name: 'AttenuationCorrectionMethod' },
            '0x00541102': { 'vm': '1', 'vr': 'CS', name: 'DecayCorrection' },
            '0x00541103': { 'vm': '1', 'vr': 'LO', name: 'ReconstructionMethod' },
            '0x00541104': { 'vm': '1', 'vr': 'LO', name: 'DetectorLinesofResponseUsed' },
            '0x00541105': { 'vm': '1', 'vr': 'LO', name: 'ScatterCorrectionMethod' },
            '0x00541200': { 'vm': '1', 'vr': 'DS', name: 'AxialAcceptance' },
            '0x00541201': { 'vm': '2', 'vr': 'IS', name: 'AxialMash' },
            '0x00541202': { 'vm': '1', 'vr': 'IS', name: 'TransverseMash' },
            '0x00541203': { 'vm': '2', 'vr': 'DS', name: 'DetectorElementSize' },
            '0x00541210': { 'vm': '1', 'vr': 'DS', name: 'CoincidenceWindowWidth' },
            '0x00541220': { 'vm': '1-n', 'vr': 'CS', name: 'SecondaryCountsType' },
            '0x00541300': { 'vm': '1', 'vr': 'DS', name: 'FrameReferenceTime' },
            '0x00541310': { 'vm': '1', 'vr': 'IS', name: 'PrimaryPromptsCountsAccumulated' },
            '0x00541311': { 'vm': '1-n', 'vr': 'IS', name: 'SecondaryCountsAccumulated' },
            '0x00541320': { 'vm': '1', 'vr': 'DS', name: 'SliceSensitivityFactor' },
            '0x00541321': { 'vm': '1', 'vr': 'DS', name: 'DecayFactor' },
            '0x00541322': { 'vm': '1', 'vr': 'DS', name: 'DoseCalibrationFactor' },
            '0x00541323': { 'vm': '1', 'vr': 'DS', name: 'ScatterFractionFactor' },
            '0x00541324': { 'vm': '1', 'vr': 'DS', name: 'DeadTimeFactor' },
            '0x00541330': { 'vm': '1', 'vr': 'US', name: 'ImageIndex' },
            '0x00541400': { 'vm': '1-n', 'vr': 'CS', name: 'CountsIncluded' },
            '0x00541401': { 'vm': '1', 'vr': 'CS', name: 'DeadTimeCorrectionFlag' },
            '0x00603000': { 'vm': '1', 'vr': 'SQ', name: 'HistogramSequence' },
            '0x00603002': { 'vm': '1', 'vr': 'US', name: 'HistogramNumberofBins' },
            '0x00603004': { 'vm': '1', 'vr': 'US', name: 'HistogramFirstBinValue' },
            '0x00603006': { 'vm': '1', 'vr': 'US', name: 'HistogramLastBinValue' },
            '0x00603008': { 'vm': '1', 'vr': 'US', name: 'HistogramBinWidth' },
            '0x00603010': { 'vm': '1', 'vr': 'LO', name: 'HistogramExplanation' },
            '0x00603020': { 'vm': '1-n', 'vr': 'UL', name: 'HistogramData' },
            '0x00620001': { 'vm': '1', 'vr': 'CS', name: 'SegmentationType' },
            '0x00620002': { 'vm': '1', 'vr': 'SQ', name: 'SegmentSequence' },
            '0x00620003': { 'vm': '1', 'vr': 'SQ', name: 'SegmentedPropertyCategoryCodeSequence' },
            '0x00620004': { 'vm': '1', 'vr': 'US', name: 'SegmentNumber' },
            '0x00620005': { 'vm': '1', 'vr': 'LO', name: 'SegmentLabel' },
            '0x00620006': { 'vm': '1', 'vr': 'ST', name: 'SegmentDescription' },
            '0x00620007': { 'vm': '1', 'vr': 'SQ', name: 'SegmentationAlgorithmIdentificationSequence' },
            '0x00620008': { 'vm': '1', 'vr': 'CS', name: 'SegmentAlgorithmType' },
            '0x00620009': { 'vm': '1', 'vr': 'LO', name: 'SegmentAlgorithmName' },
            '0x0062000A': { 'vm': '1', 'vr': 'SQ', name: 'SegmentIdentificationSequence' },
            '0x0062000B': { 'vm': '1-n', 'vr': 'US', name: 'ReferencedSegmentNumber' },
            '0x0062000C': { 'vm': '1', 'vr': 'US', name: 'RecommendedDisplayGrayscaleValue' },
            '0x0062000D': { 'vm': '3', 'vr': 'US', name: 'RecommendedDisplayCIELabValue' },
            '0x0062000E': { 'vm': '1', 'vr': 'US', name: 'MaximumFractionalValue' },
            '0x0062000F': { 'vm': '1', 'vr': 'SQ', name: 'SegmentedPropertyTypeCodeSequence' },
            '0x00620010': { 'vm': '1', 'vr': 'CS', name: 'SegmentationFractionalType' },
            '0x00620011': { 'vm': '1', 'vr': 'SQ', name: 'SegmentedPropertyTypeModifierCodeSequence' },
            '0x00620012': { 'vm': '1', 'vr': 'SQ', name: 'UsedSegmentsSequence' },
            '0x00620013': { 'vm': '1', 'vr': 'CS', name: 'SegmentsOverlap' },
            '0x00620020': { 'vm': '1', 'vr': 'UT', name: 'TrackingID' },
            '0x00620021': { 'vm': '1', 'vr': 'UI', name: 'TrackingUID' },
            '0x00640002': { 'vm': '1', 'vr': 'SQ', name: 'DeformableRegistrationSequence' },
            '0x00640003': { 'vm': '1', 'vr': 'UI', name: 'SourceFrameofReferenceUID' },
            '0x00640005': { 'vm': '1', 'vr': 'SQ', name: 'DeformableRegistrationGridSequence' },
            '0x00640007': { 'vm': '3', 'vr': 'UL', name: 'GridDimensions' },
            '0x00640008': { 'vm': '3', 'vr': 'FD', name: 'GridResolution' },
            '0x00640009': { 'vm': '1', 'vr': 'OF', name: 'VectorGridData' },
            '0x0064000F': { 'vm': '1', 'vr': 'SQ', name: 'PreDeformationMatrixRegistrationSequence' },
            '0x00640010': { 'vm': '1', 'vr': 'SQ', name: 'PostDeformationMatrixRegistrationSequence' },
            '0x00660001': { 'vm': '1', 'vr': 'UL', name: 'NumberofSurfaces' },
            '0x00660002': { 'vm': '1', 'vr': 'SQ', name: 'SurfaceSequence' },
            '0x00660003': { 'vm': '1', 'vr': 'UL', name: 'SurfaceNumber' },
            '0x00660004': { 'vm': '1', 'vr': 'LT', name: 'SurfaceComments' },
            '0x00660009': { 'vm': '1', 'vr': 'CS', name: 'SurfaceProcessing' },
            '0x0066000A': { 'vm': '1', 'vr': 'FL', name: 'SurfaceProcessingRatio' },
            '0x0066000B': { 'vm': '1', 'vr': 'LO', name: 'SurfaceProcessingDescription' },
            '0x0066000C': { 'vm': '1', 'vr': 'FL', name: 'RecommendedPresentationOpacity' },
            '0x0066000D': { 'vm': '1', 'vr': 'CS', name: 'RecommendedPresentationType' },
            '0x0066000E': { 'vm': '1', 'vr': 'CS', name: 'FiniteVolume' },
            '0x00660010': { 'vm': '1', 'vr': 'CS', name: 'Manifold' },
            '0x00660011': { 'vm': '1', 'vr': 'SQ', name: 'SurfacePointsSequence' },
            '0x00660012': { 'vm': '1', 'vr': 'SQ', name: 'SurfacePointsNormalsSequence' },
            '0x00660013': { 'vm': '1', 'vr': 'SQ', name: 'SurfaceMeshPrimitivesSequence' },
            '0x00660015': { 'vm': '1', 'vr': 'UL', name: 'NumberofSurfacePoints' },
            '0x00660016': { 'vm': '1', 'vr': 'OF', name: 'PointCoordinatesData' },
            '0x00660017': { 'vm': '3', 'vr': 'FL', name: 'PointPositionAccuracy' },
            '0x00660018': { 'vm': '1', 'vr': 'FL', name: 'MeanPointDistance' },
            '0x00660019': { 'vm': '1', 'vr': 'FL', name: 'MaximumPointDistance' },
            '0x0066001A': { 'vm': '6', 'vr': 'FL', name: 'PointsBoundingBoxCoordinates' },
            '0x0066001B': { 'vm': '3', 'vr': 'FL', name: 'AxisofRotation' },
            '0x0066001C': { 'vm': '3', 'vr': 'FL', name: 'CenterofRotation' },
            '0x0066001E': { 'vm': '1', 'vr': 'UL', name: 'NumberofVectors' },
            '0x0066001F': { 'vm': '1', 'vr': 'US', name: 'VectorDimensionality' },
            '0x00660020': { 'vm': '1-n', 'vr': 'FL', name: 'VectorAccuracy' },
            '0x00660021': { 'vm': '1', 'vr': 'OF', name: 'VectorCoordinateData' },
            '0x00660023': { 'vm': '1', 'vr': 'OW', name: 'TrianglePointIndexList' },
            '0x00660024': { 'vm': '1', 'vr': 'OW', name: 'EdgePointIndexList' },
            '0x00660025': { 'vm': '1', 'vr': 'OW', name: 'VertexPointIndexList' },
            '0x00660026': { 'vm': '1', 'vr': 'SQ', name: 'TriangleStripSequence' },
            '0x00660027': { 'vm': '1', 'vr': 'SQ', name: 'TriangleFanSequence' },
            '0x00660028': { 'vm': '1', 'vr': 'SQ', name: 'LineSequence' },
            '0x00660029': { 'vm': '1', 'vr': 'OW', name: 'PrimitivePointIndexList' },
            '0x0066002A': { 'vm': '1', 'vr': 'UL', name: 'SurfaceCount' },
            '0x0066002B': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedSurfaceSequence' },
            '0x0066002C': { 'vm': '1', 'vr': 'UL', name: 'ReferencedSurfaceNumber' },
            '0x0066002D': { 'vm': '1', 'vr': 'SQ', name: 'SegmentSurfaceGenerationAlgorithmIdentificationSequence' },
            '0x0066002E': { 'vm': '1', 'vr': 'SQ', name: 'SegmentSurfaceSourceInstanceSequence' },
            '0x0066002F': { 'vm': '1', 'vr': 'SQ', name: 'AlgorithmFamilyCodeSequence' },
            '0x00660030': { 'vm': '1', 'vr': 'SQ', name: 'AlgorithmNameCodeSequence' },
            '0x00660031': { 'vm': '1', 'vr': 'LO', name: 'AlgorithmVersion' },
            '0x00660032': { 'vm': '1', 'vr': 'LT', name: 'AlgorithmParameters' },
            '0x00660034': { 'vm': '1', 'vr': 'SQ', name: 'FacetSequence' },
            '0x00660035': { 'vm': '1', 'vr': 'SQ', name: 'SurfaceProcessingAlgorithmIdentificationSequence' },
            '0x00660036': { 'vm': '1', 'vr': 'LO', name: 'AlgorithmName' },
            '0x00660037': { 'vm': '1', 'vr': 'FL', name: 'RecommendedPointRadius' },
            '0x00660038': { 'vm': '1', 'vr': 'FL', name: 'RecommendedLineThickness' },
            '0x00660040': { 'vm': '1', 'vr': 'OL', name: 'LongPrimitivePointIndexList' },
            '0x00660041': { 'vm': '1', 'vr': 'OL', name: 'LongTrianglePointIndexList' },
            '0x00660042': { 'vm': '1', 'vr': 'OL', name: 'LongEdgePointIndexList' },
            '0x00660043': { 'vm': '1', 'vr': 'OL', name: 'LongVertexPointIndexList' },
            '0x00660101': { 'vm': '1', 'vr': 'SQ', name: 'TrackSetSequence' },
            '0x00660102': { 'vm': '1', 'vr': 'SQ', name: 'TrackSequence' },
            '0x00660103': { 'vm': '1', 'vr': 'OW', name: 'RecommendedDisplayCIELabValueList' },
            '0x00660104': { 'vm': '1', 'vr': 'SQ', name: 'TrackingAlgorithmIdentificationSequence' },
            '0x00660105': { 'vm': '1', 'vr': 'UL', name: 'TrackSetNumber' },
            '0x00660106': { 'vm': '1', 'vr': 'LO', name: 'TrackSetLabel' },
            '0x00660107': { 'vm': '1', 'vr': 'UT', name: 'TrackSetDescription' },
            '0x00660108': { 'vm': '1', 'vr': 'SQ', name: 'TrackSetAnatomicalTypeCodeSequence' },
            '0x00660121': { 'vm': '1', 'vr': 'SQ', name: 'MeasurementsSequence' },
            '0x00660124': { 'vm': '1', 'vr': 'SQ', name: 'TrackSetStatisticsSequence' },
            '0x00660125': { 'vm': '1', 'vr': 'OF', name: 'FloatingPointValues' },
            '0x00660129': { 'vm': '1', 'vr': 'OL', name: 'TrackPointIndexList' },
            '0x00660130': { 'vm': '1', 'vr': 'SQ', name: 'TrackStatisticsSequence' },
            '0x00660132': { 'vm': '1', 'vr': 'SQ', name: 'MeasurementValuesSequence' },
            '0x00660133': { 'vm': '1', 'vr': 'SQ', name: 'DiffusionAcquisitionCodeSequence' },
            '0x00660134': { 'vm': '1', 'vr': 'SQ', name: 'DiffusionModelCodeSequence' },
            '0x00686210': { 'vm': '1', 'vr': 'LO', name: 'ImplantSize' },
            '0x00686221': { 'vm': '1', 'vr': 'LO', name: 'ImplantTemplateVersion' },
            '0x00686222': { 'vm': '1', 'vr': 'SQ', name: 'ReplacedImplantTemplateSequence' },
            '0x00686223': { 'vm': '1', 'vr': 'CS', name: 'ImplantType' },
            '0x00686224': { 'vm': '1', 'vr': 'SQ', name: 'DerivationImplantTemplateSequence' },
            '0x00686225': { 'vm': '1', 'vr': 'SQ', name: 'OriginalImplantTemplateSequence' },
            '0x00686226': { 'vm': '1', 'vr': 'DT', name: 'EffectiveDateTime' },
            '0x00686230': { 'vm': '1', 'vr': 'SQ', name: 'ImplantTargetAnatomySequence' },
            '0x00686260': { 'vm': '1', 'vr': 'SQ', name: 'InformationFromManufacturerSequence' },
            '0x00686265': { 'vm': '1', 'vr': 'SQ', name: 'NotificationFromManufacturerSequence' },
            '0x00686270': { 'vm': '1', 'vr': 'DT', name: 'InformationIssueDateTime' },
            '0x00686280': { 'vm': '1', 'vr': 'ST', name: 'InformationSummary' },
            '0x006862A0': { 'vm': '1', 'vr': 'SQ', name: 'ImplantRegulatoryDisapprovalCodeSequence' },
            '0x006862A5': { 'vm': '1', 'vr': 'FD', name: 'OverallTemplateSpatialTolerance' },
            '0x006862C0': { 'vm': '1', 'vr': 'SQ', name: 'HPGLDocumentSequence' },
            '0x006862D0': { 'vm': '1', 'vr': 'US', name: 'HPGLDocumentID' },
            '0x006862D5': { 'vm': '1', 'vr': 'LO', name: 'HPGLDocumentLabel' },
            '0x006862E0': { 'vm': '1', 'vr': 'SQ', name: 'ViewOrientationCodeSequence' },
            '0x006862F0': { 'vm': '1', 'vr': 'SQ', name: 'ViewOrientationModifierCodeSequence' },
            '0x006862F2': { 'vm': '1', 'vr': 'FD', name: 'HPGLDocumentScaling' },
            '0x00686300': { 'vm': '1', 'vr': 'OB', name: 'HPGLDocument' },
            '0x00686310': { 'vm': '1', 'vr': 'US', name: 'HPGLContourPenNumber' },
            '0x00686320': { 'vm': '1', 'vr': 'SQ', name: 'HPGLPenSequence' },
            '0x00686330': { 'vm': '1', 'vr': 'US', name: 'HPGLPenNumber' },
            '0x00686340': { 'vm': '1', 'vr': 'LO', name: 'HPGLPenLabel' },
            '0x00686345': { 'vm': '1', 'vr': 'ST', name: 'HPGLPenDescription' },
            '0x00686346': { 'vm': '2', 'vr': 'FD', name: 'RecommendedRotationPoint' },
            '0x00686347': { 'vm': '4', 'vr': 'FD', name: 'BoundingRectangle' },
            '0x00686350': { 'vm': '1-n', 'vr': 'US', name: 'ImplantTemplate3DModelSurfaceNumber' },
            '0x00686360': { 'vm': '1', 'vr': 'SQ', name: 'SurfaceModelDescriptionSequence' },
            '0x00686380': { 'vm': '1', 'vr': 'LO', name: 'SurfaceModelLabel' },
            '0x00686390': { 'vm': '1', 'vr': 'FD', name: 'SurfaceModelScalingFactor' },
            '0x006863A0': { 'vm': '1', 'vr': 'SQ', name: 'MaterialsCodeSequence' },
            '0x006863A4': { 'vm': '1', 'vr': 'SQ', name: 'CoatingMaterialsCodeSequence' },
            '0x006863A8': { 'vm': '1', 'vr': 'SQ', name: 'ImplantTypeCodeSequence' },
            '0x006863AC': { 'vm': '1', 'vr': 'SQ', name: 'FixationMethodCodeSequence' },
            '0x006863B0': { 'vm': '1', 'vr': 'SQ', name: 'MatingFeatureSetsSequence' },
            '0x006863C0': { 'vm': '1', 'vr': 'US', name: 'MatingFeatureSetID' },
            '0x006863D0': { 'vm': '1', 'vr': 'LO', name: 'MatingFeatureSetLabel' },
            '0x006863E0': { 'vm': '1', 'vr': 'SQ', name: 'MatingFeatureSequence' },
            '0x006863F0': { 'vm': '1', 'vr': 'US', name: 'MatingFeatureID' },
            '0x00686400': { 'vm': '1', 'vr': 'SQ', name: 'MatingFeatureDegreeofFreedomSequence' },
            '0x00686410': { 'vm': '1', 'vr': 'US', name: 'DegreeofFreedomID' },
            '0x00686420': { 'vm': '1', 'vr': 'CS', name: 'DegreeofFreedomType' },
            '0x00686430': { 'vm': '1', 'vr': 'SQ', name: 't2DMatingFeatureCoordinatesSequence' },
            '0x00686440': { 'vm': '1', 'vr': 'US', name: 'ReferencedHPGLDocumentID' },
            '0x00686450': { 'vm': '2', 'vr': 'FD', name: 't2DMatingPoint' },
            '0x00686460': { 'vm': '4', 'vr': 'FD', name: 't2DMatingAxes' },
            '0x00686470': { 'vm': '1', 'vr': 'SQ', name: 't2DDegreeofFreedomSequence' },
            '0x00686490': { 'vm': '3', 'vr': 'FD', name: 't3DDegreeofFreedomAxis' },
            '0x006864A0': { 'vm': '2', 'vr': 'FD', name: 'RangeofFreedom' },
            '0x006864C0': { 'vm': '3', 'vr': 'FD', name: 't3DMatingPoint' },
            '0x006864D0': { 'vm': '9', 'vr': 'FD', name: 't3DMatingAxes' },
            '0x006864F0': { 'vm': '3', 'vr': 'FD', name: 't2DDegreeofFreedomAxis' },
            '0x00686500': { 'vm': '1', 'vr': 'SQ', name: 'PlanningLandmarkPointSequence' },
            '0x00686510': { 'vm': '1', 'vr': 'SQ', name: 'PlanningLandmarkLineSequence' },
            '0x00686520': { 'vm': '1', 'vr': 'SQ', name: 'PlanningLandmarkPlaneSequence' },
            '0x00686530': { 'vm': '1', 'vr': 'US', name: 'PlanningLandmarkID' },
            '0x00686540': { 'vm': '1', 'vr': 'LO', name: 'PlanningLandmarkDescription' },
            '0x00686545': { 'vm': '1', 'vr': 'SQ', name: 'PlanningLandmarkIdentificationCodeSequence' },
            '0x00686550': { 'vm': '1', 'vr': 'SQ', name: 't2DPointCoordinatesSequence' },
            '0x00686560': { 'vm': '2', 'vr': 'FD', name: 't2DPointCoordinates' },
            '0x00686590': { 'vm': '3', 'vr': 'FD', name: 't3DPointCoordinates' },
            '0x006865A0': { 'vm': '1', 'vr': 'SQ', name: 't2DLineCoordinatesSequence' },
            '0x006865B0': { 'vm': '4', 'vr': 'FD', name: 't2DLineCoordinates' },
            '0x006865D0': { 'vm': '6', 'vr': 'FD', name: 't3DLineCoordinates' },
            '0x006865E0': { 'vm': '1', 'vr': 'SQ', name: 't2DPlaneCoordinatesSequence' },
            '0x006865F0': { 'vm': '4', 'vr': 'FD', name: 't2DPlaneIntersection' },
            '0x00686610': { 'vm': '3', 'vr': 'FD', name: 't3DPlaneOrigin' },
            '0x00686620': { 'vm': '3', 'vr': 'FD', name: 't3DPlaneNormal' },
            '0x00687001': { 'vm': '1', 'vr': 'CS', name: 'ModelModification' },
            '0x00687002': { 'vm': '1', 'vr': 'CS', name: 'ModelMirroring' },
            '0x00687003': { 'vm': '1', 'vr': 'SQ', name: 'ModelUsageCodeSequence' },
            '0x00700001': { 'vm': '1', 'vr': 'SQ', name: 'GraphicAnnotationSequence' },
            '0x00700002': { 'vm': '1', 'vr': 'CS', name: 'GraphicLayer' },
            '0x00700003': { 'vm': '1', 'vr': 'CS', name: 'BoundingBoxAnnotationUnits' },
            '0x00700004': { 'vm': '1', 'vr': 'CS', name: 'AnchorPointAnnotationUnits' },
            '0x00700005': { 'vm': '1', 'vr': 'CS', name: 'GraphicAnnotationUnits' },
            '0x00700006': { 'vm': '1', 'vr': 'ST', name: 'UnformattedTextValue' },
            '0x00700008': { 'vm': '1', 'vr': 'SQ', name: 'TextObjectSequence' },
            '0x00700009': { 'vm': '1', 'vr': 'SQ', name: 'GraphicObjectSequence' },
            '0x00700010': { 'vm': '2', 'vr': 'FL', name: 'BoundingBoxTopLeftHandCorner' },
            '0x00700011': { 'vm': '2', 'vr': 'FL', name: 'BoundingBoxBottomRightHandCorner' },
            '0x00700012': { 'vm': '1', 'vr': 'CS', name: 'BoundingBoxTextHorizontalJustification' },
            '0x00700014': { 'vm': '2', 'vr': 'FL', name: 'AnchorPoint' },
            '0x00700015': { 'vm': '1', 'vr': 'CS', name: 'AnchorPointVisibility' },
            '0x00700020': { 'vm': '1', 'vr': 'US', name: 'GraphicDimensions' },
            '0x00700021': { 'vm': '1', 'vr': 'US', name: 'NumberofGraphicPoints' },
            '0x00700022': { 'vm': '2-n', 'vr': 'FL', name: 'GraphicData' },
            '0x00700023': { 'vm': '1', 'vr': 'CS', name: 'GraphicType' },
            '0x00700024': { 'vm': '1', 'vr': 'CS', name: 'GraphicFilled' },
            '0x00700040': { 'vm': '1', 'vr': 'IS', name: 'ImageRotationRetired' },
            '0x00700041': { 'vm': '1', 'vr': 'CS', name: 'ImageHorizontalFlip' },
            '0x00700042': { 'vm': '1', 'vr': 'US', name: 'ImageRotation' },
            '0x00700050': { 'vm': '2', 'vr': 'US', name: 'DisplayedAreaTopLeftHandCornerTrial' },
            '0x00700051': { 'vm': '2', 'vr': 'US', name: 'DisplayedAreaBottomRightHandCornerTrial' },
            '0x00700052': { 'vm': '2', 'vr': 'SL', name: 'DisplayedAreaTopLeftHandCorner' },
            '0x00700053': { 'vm': '2', 'vr': 'SL', name: 'DisplayedAreaBottomRightHandCorner' },
            '0x0070005A': { 'vm': '1', 'vr': 'SQ', name: 'DisplayedAreaSelectionSequence' },
            '0x00700060': { 'vm': '1', 'vr': 'SQ', name: 'GraphicLayerSequence' },
            '0x00700062': { 'vm': '1', 'vr': 'IS', name: 'GraphicLayerOrder' },
            '0x00700066': { 'vm': '1', 'vr': 'US', name: 'GraphicLayerRecommendedDisplayGrayscaleValue' },
            '0x00700067': { 'vm': '3', 'vr': 'US', name: 'GraphicLayerRecommendedDisplayRGBValue' },
            '0x00700068': { 'vm': '1', 'vr': 'LO', name: 'GraphicLayerDescription' },
            '0x00700080': { 'vm': '1', 'vr': 'CS', name: 'ContentLabel' },
            '0x00700081': { 'vm': '1', 'vr': 'LO', name: 'ContentDescription' },
            '0x00700082': { 'vm': '1', 'vr': 'DA', name: 'PresentationCreationDate' },
            '0x00700083': { 'vm': '1', 'vr': 'TM', name: 'PresentationCreationTime' },
            '0x00700084': { 'vm': '1', 'vr': 'PN', name: 'ContentCreatorsName' },
            '0x00700086': { 'vm': '1', 'vr': 'SQ', name: 'ContentCreatorsIdentificationCodeSequence' },
            '0x00700087': { 'vm': '1', 'vr': 'SQ', name: 'AlternateContentDescriptionSequence' },
            '0x00700100': { 'vm': '1', 'vr': 'CS', name: 'PresentationSizeMode' },
            '0x00700101': { 'vm': '2', 'vr': 'DS', name: 'PresentationPixelSpacing' },
            '0x00700102': { 'vm': '2', 'vr': 'IS', name: 'PresentationPixelAspectRatio' },
            '0x00700103': { 'vm': '1', 'vr': 'FL', name: 'PresentationPixelMagnificationRatio' },
            '0x00700207': { 'vm': '1', 'vr': 'LO', name: 'GraphicGroupLabel' },
            '0x00700208': { 'vm': '1', 'vr': 'ST', name: 'GraphicGroupDescription' },
            '0x00700209': { 'vm': '1', 'vr': 'SQ', name: 'CompoundGraphicSequence' },
            '0x00700226': { 'vm': '1', 'vr': 'UL', name: 'CompoundGraphicInstanceID' },
            '0x00700227': { 'vm': '1', 'vr': 'LO', name: 'FontName' },
            '0x00700228': { 'vm': '1', 'vr': 'CS', name: 'FontNameType' },
            '0x00700229': { 'vm': '1', 'vr': 'LO', name: 'CSSFontName' },
            '0x00700230': { 'vm': '1', 'vr': 'FD', name: 'RotationAngle' },
            '0x00700231': { 'vm': '1', 'vr': 'SQ', name: 'TextStyleSequence' },
            '0x00700232': { 'vm': '1', 'vr': 'SQ', name: 'LineStyleSequence' },
            '0x00700233': { 'vm': '1', 'vr': 'SQ', name: 'FillStyleSequence' },
            '0x00700234': { 'vm': '1', 'vr': 'SQ', name: 'GraphicGroupSequence' },
            '0x00700241': { 'vm': '3', 'vr': 'US', name: 'TextColorCIELabValue' },
            '0x00700242': { 'vm': '1', 'vr': 'CS', name: 'HorizontalAlignment' },
            '0x00700243': { 'vm': '1', 'vr': 'CS', name: 'VerticalAlignment' },
            '0x00700244': { 'vm': '1', 'vr': 'CS', name: 'ShadowStyle' },
            '0x00700245': { 'vm': '1', 'vr': 'FL', name: 'ShadowOffsetX' },
            '0x00700246': { 'vm': '1', 'vr': 'FL', name: 'ShadowOffsetY' },
            '0x00700247': { 'vm': '3', 'vr': 'US', name: 'ShadowColorCIELabValue' },
            '0x00700248': { 'vm': '1', 'vr': 'CS', name: 'Underlined' },
            '0x00700249': { 'vm': '1', 'vr': 'CS', name: 'Bold' },
            '0x00700250': { 'vm': '1', 'vr': 'CS', name: 'Italic' },
            '0x00700251': { 'vm': '3', 'vr': 'US', name: 'PatternOnColorCIELabValue' },
            '0x00700252': { 'vm': '3', 'vr': 'US', name: 'PatternOffColorCIELabValue' },
            '0x00700253': { 'vm': '1', 'vr': 'FL', name: 'LineThickness' },
            '0x00700254': { 'vm': '1', 'vr': 'CS', name: 'LineDashingStyle' },
            '0x00700255': { 'vm': '1', 'vr': 'UL', name: 'LinePattern' },
            '0x00700256': { 'vm': '1', 'vr': 'OB', name: 'FillPattern' },
            '0x00700257': { 'vm': '1', 'vr': 'CS', name: 'FillMode' },
            '0x00700258': { 'vm': '1', 'vr': 'FL', name: 'ShadowOpacity' },
            '0x00700261': { 'vm': '1', 'vr': 'FL', name: 'GapLength' },
            '0x00700262': { 'vm': '1', 'vr': 'FL', name: 'DiameterofVisibility' },
            '0x00700273': { 'vm': '2', 'vr': 'FL', name: 'RotationPoint' },
            '0x00700274': { 'vm': '1', 'vr': 'CS', name: 'TickAlignment' },
            '0x00700278': { 'vm': '1', 'vr': 'CS', name: 'ShowTickLabel' },
            '0x00700279': { 'vm': '1', 'vr': 'CS', name: 'TickLabelAlignment' },
            '0x00700282': { 'vm': '1', 'vr': 'CS', name: 'CompoundGraphicUnits' },
            '0x00700284': { 'vm': '1', 'vr': 'FL', name: 'PatternOnOpacity' },
            '0x00700285': { 'vm': '1', 'vr': 'FL', name: 'PatternOffOpacity' },
            '0x00700287': { 'vm': '1', 'vr': 'SQ', name: 'MajorTicksSequence' },
            '0x00700288': { 'vm': '1', 'vr': 'FL', name: 'TickPosition' },
            '0x00700289': { 'vm': '1', 'vr': 'SH', name: 'TickLabel' },
            '0x00700294': { 'vm': '1', 'vr': 'CS', name: 'CompoundGraphicType' },
            '0x00700295': { 'vm': '1', 'vr': 'UL', name: 'GraphicGroupID' },
            '0x00700306': { 'vm': '1', 'vr': 'CS', name: 'ShapeType' },
            '0x00700308': { 'vm': '1', 'vr': 'SQ', name: 'RegistrationSequence' },
            '0x00700309': { 'vm': '1', 'vr': 'SQ', name: 'MatrixRegistrationSequence' },
            '0x0070030A': { 'vm': '1', 'vr': 'SQ', name: 'MatrixSequence' },
            '0x0070030B': {
                'vm': '16',
                'vr': 'FD',
                name: 'FrameofReferencetoDisplayedCoordinateSystemTransformationMatrix'
            },
            '0x0070030C': { 'vm': '1', 'vr': 'CS', name: 'FrameofReferenceTransformationMatrixType' },
            '0x0070030D': { 'vm': '1', 'vr': 'SQ', name: 'RegistrationTypeCodeSequence' },
            '0x0070030F': { 'vm': '1', 'vr': 'ST', name: 'FiducialDescription' },
            '0x00700310': { 'vm': '1', 'vr': 'SH', name: 'FiducialIdentifier' },
            '0x00700311': { 'vm': '1', 'vr': 'SQ', name: 'FiducialIdentifierCodeSequence' },
            '0x00700312': { 'vm': '1', 'vr': 'FD', name: 'ContourUncertaintyRadius' },
            '0x00700314': { 'vm': '1', 'vr': 'SQ', name: 'UsedFiducialsSequence' },
            '0x00700318': { 'vm': '1', 'vr': 'SQ', name: 'GraphicCoordinatesDataSequence' },
            '0x0070031A': { 'vm': '1', 'vr': 'UI', name: 'FiducialUID' },
            '0x0070031B': { 'vm': '1', 'vr': 'UI', name: 'ReferencedFiducialUID' },
            '0x0070031C': { 'vm': '1', 'vr': 'SQ', name: 'FiducialSetSequence' },
            '0x0070031E': { 'vm': '1', 'vr': 'SQ', name: 'FiducialSequence' },
            '0x0070031F': { 'vm': '1', 'vr': 'SQ', name: 'FiducialsPropertyCategoryCodeSequence' },
            '0x00700401': { 'vm': '3', 'vr': 'US', name: 'GraphicLayerRecommendedDisplayCIELabValue' },
            '0x00700402': { 'vm': '1', 'vr': 'SQ', name: 'BlendingSequence' },
            '0x00700403': { 'vm': '1', 'vr': 'FL', name: 'RelativeOpacity' },
            '0x00700404': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedSpatialRegistrationSequence' },
            '0x00700405': { 'vm': '1', 'vr': 'CS', name: 'BlendingPosition' },
            '0x00701101': { 'vm': '1', 'vr': 'UI', name: 'PresentationDisplayCollectionUID' },
            '0x00701102': { 'vm': '1', 'vr': 'UI', name: 'PresentationSequenceCollectionUID' },
            '0x00701103': { 'vm': '1', 'vr': 'US', name: 'PresentationSequencePositionIndex' },
            '0x00701104': { 'vm': '1', 'vr': 'SQ', name: 'RenderedImageReferenceSequence' },
            '0x00701201': { 'vm': '1', 'vr': 'SQ', name: 'VolumetricPresentationStateInputSequence' },
            '0x00701202': { 'vm': '1', 'vr': 'CS', name: 'PresentationInputType' },
            '0x00701203': { 'vm': '1', 'vr': 'US', name: 'InputSequencePositionIndex' },
            '0x00701204': { 'vm': '1', 'vr': 'CS', name: 'Crop' },
            '0x00701205': { 'vm': '1-n', 'vr': 'US', name: 'CroppingSpecificationIndex' },
            '0x00701206': { 'vm': '1', 'vr': 'CS', name: 'CompositingMethod' },
            '0x00701207': { 'vm': '1', 'vr': 'US', name: 'VolumetricPresentationInputNumber' },
            '0x00701208': { 'vm': '1', 'vr': 'CS', name: 'ImageVolumeGeometry' },
            '0x00701209': { 'vm': '1', 'vr': 'UI', name: 'VolumetricPresentationInputSetUID' },
            '0x0070120A': { 'vm': '1', 'vr': 'SQ', name: 'VolumetricPresentationInputSetSequence' },
            '0x0070120B': { 'vm': '1', 'vr': 'CS', name: 'GlobalCrop' },
            '0x0070120C': { 'vm': '1-n', 'vr': 'US', name: 'GlobalCroppingSpecificationIndex' },
            '0x0070120D': { 'vm': '1', 'vr': 'CS', name: 'RenderingMethod' },
            '0x00701301': { 'vm': '1', 'vr': 'SQ', name: 'VolumeCroppingSequence' },
            '0x00701302': { 'vm': '1', 'vr': 'CS', name: 'VolumeCroppingMethod' },
            '0x00701303': { 'vm': '6', 'vr': 'FD', name: 'BoundingBoxCrop' },
            '0x00701304': { 'vm': '1', 'vr': 'SQ', name: 'ObliqueCroppingPlaneSequence' },
            '0x00701305': { 'vm': '4', 'vr': 'FD', name: 'Plane' },
            '0x00701306': { 'vm': '3', 'vr': 'FD', name: 'PlaneNormal' },
            '0x00701309': { 'vm': '1', 'vr': 'US', name: 'CroppingSpecificationNumber' },
            '0x00701501': { 'vm': '1', 'vr': 'CS', name: 'MultiPlanarReconstructionStyle' },
            '0x00701502': { 'vm': '1', 'vr': 'CS', name: 'MPRThicknessType' },
            '0x00701503': { 'vm': '1', 'vr': 'FD', name: 'MPRSlabThickness' },
            '0x00701505': { 'vm': '3', 'vr': 'FD', name: 'MPRTopLeftHandCorner' },
            '0x00701507': { 'vm': '3', 'vr': 'FD', name: 'MPRViewWidthDirection' },
            '0x00701508': { 'vm': '1', 'vr': 'FD', name: 'MPRViewWidth' },
            '0x0070150C': { 'vm': '1', 'vr': 'UL', name: 'NumberofVolumetricCurvePoints' },
            '0x0070150D': { 'vm': '1', 'vr': 'OD', name: 'VolumetricCurvePoints' },
            '0x00701511': { 'vm': '3', 'vr': 'FD', name: 'MPRViewHeightDirection' },
            '0x00701512': { 'vm': '1', 'vr': 'FD', name: 'MPRViewHeight' },
            '0x00701602': { 'vm': '1', 'vr': 'CS', name: 'RenderProjection' },
            '0x00701603': { 'vm': '3', 'vr': 'FD', name: 'ViewpointPosition' },
            '0x00701604': { 'vm': '3', 'vr': 'FD', name: 'ViewpointLookAtPoint' },
            '0x00701605': { 'vm': '3', 'vr': 'FD', name: 'ViewpointUpDirection' },
            '0x00701606': { 'vm': '6', 'vr': 'FD', name: 'RenderFieldofView' },
            '0x00701607': { 'vm': '1', 'vr': 'FD', name: 'SamplingStepSize' },
            '0x00701701': { 'vm': '1', 'vr': 'CS', name: 'ShadingStyle' },
            '0x00701702': { 'vm': '1', 'vr': 'FD', name: 'AmbientReflectionIntensity' },
            '0x00701703': { 'vm': '3', 'vr': 'FD', name: 'LightDirection' },
            '0x00701704': { 'vm': '1', 'vr': 'FD', name: 'DiffuseReflectionIntensity' },
            '0x00701705': { 'vm': '1', 'vr': 'FD', name: 'SpecularReflectionIntensity' },
            '0x00701706': { 'vm': '1', 'vr': 'FD', name: 'Shininess' },
            '0x00701801': { 'vm': '1', 'vr': 'SQ', name: 'PresentationStateClassificationComponentSequence' },
            '0x00701802': { 'vm': '1', 'vr': 'CS', name: 'ComponentType' },
            '0x00701803': { 'vm': '1', 'vr': 'SQ', name: 'ComponentInputSequence' },
            '0x00701804': { 'vm': '1', 'vr': 'US', name: 'VolumetricPresentationInputIndex' },
            '0x00701805': { 'vm': '1', 'vr': 'SQ', name: 'PresentationStateCompositorComponentSequence' },
            '0x00701806': { 'vm': '1', 'vr': 'SQ', name: 'WeightingTransferFunctionSequence' },
            '0x00701807': { 'vm': '3', 'vr': 'US', name: 'WeightingLookupTableDescriptor' },
            '0x00701808': { 'vm': '1', 'vr': 'OB', name: 'WeightingLookupTableData' },
            '0x00701901': { 'vm': '1', 'vr': 'SQ', name: 'VolumetricAnnotationSequence' },
            '0x00701903': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedStructuredContextSequence' },
            '0x00701904': { 'vm': '1', 'vr': 'UI', name: 'ReferencedContentItem' },
            '0x00701905': { 'vm': '1', 'vr': 'SQ', name: 'VolumetricPresentationInputAnnotationSequence' },
            '0x00701907': { 'vm': '1', 'vr': 'CS', name: 'AnnotationClipping' },
            '0x00701A01': { 'vm': '1', 'vr': 'CS', name: 'PresentationAnimationStyle' },
            '0x00701A03': { 'vm': '1', 'vr': 'FD', name: 'RecommendedAnimationRate' },
            '0x00701A04': { 'vm': '1', 'vr': 'SQ', name: 'AnimationCurveSequence' },
            '0x00701A05': { 'vm': '1', 'vr': 'FD', name: 'AnimationStepSize' },
            '0x00701A06': { 'vm': '1', 'vr': 'FD', name: 'SwivelRange' },
            '0x00701A07': { 'vm': '1', 'vr': 'OD', name: 'VolumetricCurveUpDirections' },
            '0x00701A08': { 'vm': '1', 'vr': 'SQ', name: 'VolumeStreamSequence' },
            '0x00701A09': { 'vm': '1', 'vr': 'LO', name: 'RGBATransferFunctionDescription' },
            '0x00701B01': { 'vm': '1', 'vr': 'SQ', name: 'AdvancedBlendingSequence' },
            '0x00701B02': { 'vm': '1', 'vr': 'US', name: 'BlendingInputNumber' },
            '0x00701B03': { 'vm': '1', 'vr': 'SQ', name: 'BlendingDisplayInputSequence' },
            '0x00701B04': { 'vm': '1', 'vr': 'SQ', name: 'BlendingDisplaySequence' },
            '0x00701B06': { 'vm': '1', 'vr': 'CS', name: 'BlendingMode' },
            '0x00701B07': { 'vm': '1', 'vr': 'CS', name: 'TimeSeriesBlending' },
            '0x00701B08': { 'vm': '1', 'vr': 'CS', name: 'GeometryforDisplay' },
            '0x00701B11': { 'vm': '1', 'vr': 'SQ', name: 'ThresholdSequence' },
            '0x00701B12': { 'vm': '1', 'vr': 'SQ', name: 'ThresholdValueSequence' },
            '0x00701B13': { 'vm': '1', 'vr': 'CS', name: 'ThresholdType' },
            '0x00701B14': { 'vm': '1', 'vr': 'FD', name: 'ThresholdValue' },
            '0x00720002': { 'vm': '1', 'vr': 'SH', name: 'HangingProtocolName' },
            '0x00720004': { 'vm': '1', 'vr': 'LO', name: 'HangingProtocolDescription' },
            '0x00720006': { 'vm': '1', 'vr': 'CS', name: 'HangingProtocolLevel' },
            '0x00720008': { 'vm': '1', 'vr': 'LO', name: 'HangingProtocolCreator' },
            '0x0072000A': { 'vm': '1', 'vr': 'DT', name: 'HangingProtocolCreationDate​Time' },
            '0x0072000C': { 'vm': '1', 'vr': 'SQ', name: 'HangingProtocolDefinitionSequence' },
            '0x0072000E': { 'vm': '1', 'vr': 'SQ', name: 'HangingProtocolUserIdentificationCodeSequence' },
            '0x00720010': { 'vm': '1', 'vr': 'LO', name: 'HangingProtocolUserGroupName' },
            '0x00720012': { 'vm': '1', 'vr': 'SQ', name: 'SourceHangingProtocolSequence' },
            '0x00720014': { 'vm': '1', 'vr': 'US', name: 'NumberofPriorsReferenced' },
            '0x00720020': { 'vm': '1', 'vr': 'SQ', name: 'ImageSetsSequence' },
            '0x00720022': { 'vm': '1', 'vr': 'SQ', name: 'ImageSetSelectorSequence' },
            '0x00720024': { 'vm': '1', 'vr': 'CS', name: 'ImageSetSelectorUsageFlag' },
            '0x00720026': { 'vm': '1', 'vr': 'AT', name: 'SelectorAttribute' },
            '0x00720028': { 'vm': '1', 'vr': 'US', name: 'SelectorValueNumber' },
            '0x00720030': { 'vm': '1', 'vr': 'SQ', name: 'TimeBasedImageSetsSequence' },
            '0x00720032': { 'vm': '1', 'vr': 'US', name: 'ImageSetNumber' },
            '0x00720034': { 'vm': '1', 'vr': 'CS', name: 'ImageSetSelectorCategory' },
            '0x00720038': { 'vm': '2', 'vr': 'US', name: 'RelativeTime' },
            '0x0072003A': { 'vm': '1', 'vr': 'CS', name: 'RelativeTimeUnits' },
            '0x0072003C': { 'vm': '2', 'vr': 'SS', name: 'AbstractPriorValue' },
            '0x0072003E': { 'vm': '1', 'vr': 'SQ', name: 'AbstractPriorCodeSequence' },
            '0x00720040': { 'vm': '1', 'vr': 'LO', name: 'ImageSetLabel' },
            '0x00720050': { 'vm': '1', 'vr': 'CS', name: 'SelectorAttributeVR' },
            '0x00720052': { 'vm': '1-n', 'vr': 'AT', name: 'SelectorSequencePointer' },
            '0x00720054': { 'vm': '1-n', 'vr': 'LO', name: 'SelectorSequencePointerPrivateCreator' },
            '0x00720056': { 'vm': '1', 'vr': 'LO', name: 'SelectorAttributePrivateCreator' },
            '0x0072005E': { 'vm': '1-n', 'vr': 'AE', name: 'SelectorAEValue' },
            '0x0072005F': { 'vm': '1-n', 'vr': 'AS', name: 'SelectorASValue' },
            '0x00720060': { 'vm': '1-n', 'vr': 'AT', name: 'SelectorATValue' },
            '0x00720061': { 'vm': '1-n', 'vr': 'DA', name: 'SelectorDAValue' },
            '0x00720062': { 'vm': '1-n', 'vr': 'CS', name: 'SelectorCSValue' },
            '0x00720063': { 'vm': '1-n', 'vr': 'DT', name: 'SelectorDTValue' },
            '0x00720064': { 'vm': '1-n', 'vr': 'IS', name: 'SelectorISValue' },
            '0x00720065': { 'vm': '1', 'vr': 'OB', name: 'SelectorOBValue' },
            '0x00720066': { 'vm': '1-n', 'vr': 'LO', name: 'SelectorLOValue' },
            '0x00720067': { 'vm': '1', 'vr': 'OF', name: 'SelectorOFValue' },
            '0x00720068': { 'vm': '1', 'vr': 'LT', name: 'SelectorLTValue' },
            '0x00720069': { 'vm': '1', 'vr': 'OW', name: 'SelectorOWValue' },
            '0x0072006A': { 'vm': '1-n', 'vr': 'PN', name: 'SelectorPNValue' },
            '0x0072006B': { 'vm': '1-n', 'vr': 'TM', name: 'SelectorTMValue' },
            '0x0072006C': { 'vm': '1-n', 'vr': 'SH', name: 'SelectorSHValue' },
            '0x0072006D': { 'vm': '1', 'vr': 'UN', name: 'SelectorUNValue' },
            '0x0072006E': { 'vm': '1', 'vr': 'ST', name: 'SelectorSTValue' },
            '0x0072006F': { 'vm': '1-n', 'vr': 'UC', name: 'SelectorUCValue' },
            '0x00720070': { 'vm': '1', 'vr': 'UT', name: 'SelectorUTValue' },
            '0x00720071': { 'vm': '1', 'vr': 'UR', name: 'SelectorURValue' },
            '0x00720072': { 'vm': '1-n', 'vr': 'DS', name: 'SelectorDSValue' },
            '0x00720073': { 'vm': '1', 'vr': 'OD', name: 'SelectorODValue' },
            '0x00720074': { 'vm': '1-n', 'vr': 'FD', name: 'SelectorFDValue' },
            '0x00720075': { 'vm': '1', 'vr': 'OL', name: 'SelectorOLValue' },
            '0x00720076': { 'vm': '1-n', 'vr': 'FL', name: 'SelectorFLValue' },
            '0x00720078': { 'vm': '1-n', 'vr': 'UL', name: 'SelectorULValue' },
            '0x0072007A': { 'vm': '1-n', 'vr': 'US', name: 'SelectorUSValue' },
            '0x0072007C': { 'vm': '1-n', 'vr': 'SL', name: 'SelectorSLValue' },
            '0x0072007E': { 'vm': '1-n', 'vr': 'SS', name: 'SelectorSSValue' },
            '0x0072007F': { 'vm': '1-n', 'vr': 'UI', name: 'SelectorUIValue' },
            '0x00720080': { 'vm': '1', 'vr': 'SQ', name: 'SelectorCodeSequenceValue' },
            '0x00720100': { 'vm': '1', 'vr': 'US', name: 'NumberofScreens' },
            '0x00720102': { 'vm': '1', 'vr': 'SQ', name: 'NominalScreenDefinitionSequence' },
            '0x00720104': { 'vm': '1', 'vr': 'US', name: 'NumberofVerticalPixels' },
            '0x00720106': { 'vm': '1', 'vr': 'US', name: 'NumberofHorizontalPixels' },
            '0x00720108': { 'vm': '4', 'vr': 'FD', name: 'DisplayEnvironmentSpatialPosition' },
            '0x0072010A': { 'vm': '1', 'vr': 'US', name: 'ScreenMinimumGrayscaleBitDepth' },
            '0x0072010C': { 'vm': '1', 'vr': 'US', name: 'ScreenMinimumColorBitDepth' },
            '0x0072010E': { 'vm': '1', 'vr': 'US', name: 'ApplicationMaximumRepaintTime' },
            '0x00720200': { 'vm': '1', 'vr': 'SQ', name: 'DisplaySetsSequence' },
            '0x00720202': { 'vm': '1', 'vr': 'US', name: 'DisplaySetNumber' },
            '0x00720203': { 'vm': '1', 'vr': 'LO', name: 'DisplaySetLabel' },
            '0x00720204': { 'vm': '1', 'vr': 'US', name: 'DisplaySetPresentationGroup' },
            '0x00720206': { 'vm': '1', 'vr': 'LO', name: 'DisplaySetPresentationGroupDescription' },
            '0x00720208': { 'vm': '1', 'vr': 'CS', name: 'PartialDataDisplayHandling' },
            '0x00720210': { 'vm': '1', 'vr': 'SQ', name: 'SynchronizedScrollingSequence' },
            '0x00720212': { 'vm': '2-n', 'vr': 'US', name: 'DisplaySetScrollingGroup' },
            '0x00720214': { 'vm': '1', 'vr': 'SQ', name: 'NavigationIndicatorSequence' },
            '0x00720216': { 'vm': '1', 'vr': 'US', name: 'NavigationDisplaySet' },
            '0x00720218': { 'vm': '1-n', 'vr': 'US', name: 'ReferenceDisplaySets' },
            '0x00720300': { 'vm': '1', 'vr': 'SQ', name: 'ImageBoxesSequence' },
            '0x00720302': { 'vm': '1', 'vr': 'US', name: 'ImageBoxNumber' },
            '0x00720304': { 'vm': '1', 'vr': 'CS', name: 'ImageBoxLayoutType' },
            '0x00720306': { 'vm': '1', 'vr': 'US', name: 'ImageBoxTileHorizontalDimension' },
            '0x00720308': { 'vm': '1', 'vr': 'US', name: 'ImageBoxTileVerticalDimension' },
            '0x00720310': { 'vm': '1', 'vr': 'CS', name: 'ImageBoxScrollDirection' },
            '0x00720312': { 'vm': '1', 'vr': 'CS', name: 'ImageBoxSmallScrollType' },
            '0x00720314': { 'vm': '1', 'vr': 'US', name: 'ImageBoxSmallScrollAmount' },
            '0x00720316': { 'vm': '1', 'vr': 'CS', name: 'ImageBoxLargeScrollType' },
            '0x00720318': { 'vm': '1', 'vr': 'US', name: 'ImageBoxLargeScrollAmount' },
            '0x00720320': { 'vm': '1', 'vr': 'US', name: 'ImageBoxOverlapPriority' },
            '0x00720330': { 'vm': '1', 'vr': 'FD', name: 'CineRelativetoRealTime' },
            '0x00720400': { 'vm': '1', 'vr': 'SQ', name: 'FilterOperationsSequence' },
            '0x00720402': { 'vm': '1', 'vr': 'CS', name: 'FilterbyCategory' },
            '0x00720404': { 'vm': '1', 'vr': 'CS', name: 'FilterbyAttributePresence' },
            '0x00720406': { 'vm': '1', 'vr': 'CS', name: 'FilterbyOperator' },
            '0x00720420': { 'vm': '3', 'vr': 'US', name: 'StructuredDisplayBackgroundCIELabValue' },
            '0x00720421': { 'vm': '3', 'vr': 'US', name: 'EmptyImageBoxCIELabValue' },
            '0x00720422': { 'vm': '1', 'vr': 'SQ', name: 'StructuredDisplayImageBoxSequence' },
            '0x00720424': { 'vm': '1', 'vr': 'SQ', name: 'StructuredDisplayTextBoxSequence' },
            '0x00720427': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedFirstFrameSequence' },
            '0x00720430': { 'vm': '1', 'vr': 'SQ', name: 'ImageBoxSynchronizationSequence' },
            '0x00720432': { 'vm': '2-n', 'vr': 'US', name: 'SynchronizedImageBoxList' },
            '0x00720434': { 'vm': '1', 'vr': 'CS', name: 'TypeofSynchronization' },
            '0x00720500': { 'vm': '1', 'vr': 'CS', name: 'BlendingOperationType' },
            '0x00720510': { 'vm': '1', 'vr': 'CS', name: 'ReformattingOperationType' },
            '0x00720512': { 'vm': '1', 'vr': 'FD', name: 'ReformattingThickness' },
            '0x00720514': { 'vm': '1', 'vr': 'FD', name: 'ReformattingInterval' },
            '0x00720516': { 'vm': '1', 'vr': 'CS', name: 'ReformattingOperationInitialViewDirection' },
            '0x00720520': { 'vm': '1-n', 'vr': 'CS', name: 't3DRenderingType' },
            '0x00720600': { 'vm': '1', 'vr': 'SQ', name: 'SortingOperationsSequence' },
            '0x00720602': { 'vm': '1', 'vr': 'CS', name: 'SortbyCategory' },
            '0x00720604': { 'vm': '1', 'vr': 'CS', name: 'SortingDirection' },
            '0x00720700': { 'vm': '2', 'vr': 'CS', name: 'DisplaySetPatientOrientation' },
            '0x00720702': { 'vm': '1', 'vr': 'CS', name: 'VOIType' },
            '0x00720704': { 'vm': '1', 'vr': 'CS', name: 'PseudoColorType' },
            '0x00720705': { 'vm': '1', 'vr': 'SQ', name: 'PseudoColorPaletteInstanceReferenceSequence' },
            '0x00720706': { 'vm': '1', 'vr': 'CS', name: 'ShowGrayscaleInverted' },
            '0x00720710': { 'vm': '1', 'vr': 'CS', name: 'ShowImageTrueSizeFlag' },
            '0x00720712': { 'vm': '1', 'vr': 'CS', name: 'ShowGraphicAnnotationFlag' },
            '0x00720714': { 'vm': '1', 'vr': 'CS', name: 'ShowPatientDemographicsFlag' },
            '0x00720716': { 'vm': '1', 'vr': 'CS', name: 'ShowAcquisitionTechniquesFlag' },
            '0x00720717': { 'vm': '1', 'vr': 'CS', name: 'DisplaySetHorizontalJustification' },
            '0x00720718': { 'vm': '1', 'vr': 'CS', name: 'DisplaySetVerticalJustification' },
            '0x00740120': { 'vm': '1', 'vr': 'FD', name: 'ContinuationStartMeterset' },
            '0x00740121': { 'vm': '1', 'vr': 'FD', name: 'ContinuationEndMeterset' },
            '0x00741000': { 'vm': '1', 'vr': 'CS', name: 'ProcedureStepState' },
            '0x00741002': { 'vm': '1', 'vr': 'SQ', name: 'ProcedureStepProgressInformationSequence' },
            '0x00741004': { 'vm': '1', 'vr': 'DS', name: 'ProcedureStepProgress' },
            '0x00741006': { 'vm': '1', 'vr': 'ST', name: 'ProcedureStepProgressDescription' },
            '0x00741007': { 'vm': '1', 'vr': 'SQ', name: 'ProcedureStepProgressParametersSequence' },
            '0x00741008': { 'vm': '1', 'vr': 'SQ', name: 'ProcedureStepCommunicationsURISequence' },
            '0x0074100A': { 'vm': '1', 'vr': 'UR', name: 'ContactURI' },
            '0x0074100C': { 'vm': '1', 'vr': 'LO', name: 'ContactDisplayName' },
            '0x0074100E': { 'vm': '1', 'vr': 'SQ', name: 'ProcedureStepDiscontinuationReasonCodeSequence' },
            '0x00741020': { 'vm': '1', 'vr': 'SQ', name: 'BeamTaskSequence' },
            '0x00741022': { 'vm': '1', 'vr': 'CS', name: 'BeamTaskType' },
            '0x00741024': { 'vm': '1', 'vr': 'IS', name: 'BeamOrderIndexTrial' },
            '0x00741025': { 'vm': '1', 'vr': 'CS', name: 'AutosequenceFlag' },
            '0x00741026': { 'vm': '1', 'vr': 'FD', name: 'TableTopVerticalAdjustedPosition' },
            '0x00741027': { 'vm': '1', 'vr': 'FD', name: 'TableTopLongitudinalAdjustedPosition' },
            '0x00741028': { 'vm': '1', 'vr': 'FD', name: 'TableTopLateralAdjustedPosition' },
            '0x0074102A': { 'vm': '1', 'vr': 'FD', name: 'PatientSupportAdjustedAngle' },
            '0x0074102B': { 'vm': '1', 'vr': 'FD', name: 'TableTopEccentricAdjustedAngle' },
            '0x0074102C': { 'vm': '1', 'vr': 'FD', name: 'TableTopPitchAdjustedAngle' },
            '0x0074102D': { 'vm': '1', 'vr': 'FD', name: 'TableTopRollAdjustedAngle' },
            '0x00741030': { 'vm': '1', 'vr': 'SQ', name: 'DeliveryVerificationImageSequence' },
            '0x00741032': { 'vm': '1', 'vr': 'CS', name: 'VerificationImageTiming' },
            '0x00741034': { 'vm': '1', 'vr': 'CS', name: 'DoubleExposureFlag' },
            '0x00741036': { 'vm': '1', 'vr': 'CS', name: 'DoubleExposureOrdering' },
            '0x00741038': { 'vm': '1', 'vr': 'DS', name: 'DoubleExposureMetersetTrial' },
            '0x0074103A': { 'vm': '4', 'vr': 'DS', name: 'DoubleExposureFieldDeltaTrial' },
            '0x00741040': { 'vm': '1', 'vr': 'SQ', name: 'RelatedReferenceRTImageSequence' },
            '0x00741042': { 'vm': '1', 'vr': 'SQ', name: 'GeneralMachineVerificationSequence' },
            '0x00741044': { 'vm': '1', 'vr': 'SQ', name: 'ConventionalMachineVerificationSequence' },
            '0x00741046': { 'vm': '1', 'vr': 'SQ', name: 'IonMachineVerificationSequence' },
            '0x00741048': { 'vm': '1', 'vr': 'SQ', name: 'FailedAttributesSequence' },
            '0x0074104A': { 'vm': '1', 'vr': 'SQ', name: 'OverriddenAttributesSequence' },
            '0x0074104C': { 'vm': '1', 'vr': 'SQ', name: 'ConventionalControlPointVerificationSequence' },
            '0x0074104E': { 'vm': '1', 'vr': 'SQ', name: 'IonControlPointVerificationSequence' },
            '0x00741050': { 'vm': '1', 'vr': 'SQ', name: 'AttributeOccurrenceSequence' },
            '0x00741052': { 'vm': '1', 'vr': 'AT', name: 'AttributeOccurrencePointer' },
            '0x00741054': { 'vm': '1', 'vr': 'UL', name: 'AttributeItemSelector' },
            '0x00741056': { 'vm': '1', 'vr': 'LO', name: 'AttributeOccurrencePrivateCreator' },
            '0x00741057': { 'vm': '1-n', 'vr': 'IS', name: 'SelectorSequencePointerItems' },
            '0x00741200': { 'vm': '1', 'vr': 'CS', name: 'ScheduledProcedureStepPriority' },
            '0x00741202': { 'vm': '1', 'vr': 'LO', name: 'WorklistLabel' },
            '0x00741204': { 'vm': '1', 'vr': 'LO', name: 'ProcedureStepLabel' },
            '0x00741210': { 'vm': '1', 'vr': 'SQ', name: 'ScheduledProcessingParametersSequence' },
            '0x00741212': { 'vm': '1', 'vr': 'SQ', name: 'PerformedProcessingParametersSequence' },
            '0x00741216': { 'vm': '1', 'vr': 'SQ', name: 'UnifiedProcedureStepPerformedProcedureSequence' },
            '0x00741220': { 'vm': '1', 'vr': 'SQ', name: 'RelatedProcedureStepSequence' },
            '0x00741222': { 'vm': '1', 'vr': 'LO', name: 'ProcedureStepRelationshipType' },
            '0x00741224': { 'vm': '1', 'vr': 'SQ', name: 'ReplacedProcedureStepSequence' },
            '0x00741230': { 'vm': '1', 'vr': 'LO', name: 'DeletionLock' },
            '0x00741234': { 'vm': '1', 'vr': 'AE', name: 'ReceivingAE' },
            '0x00741236': { 'vm': '1', 'vr': 'AE', name: 'RequestingAE' },
            '0x00741238': { 'vm': '1', 'vr': 'LT', name: 'ReasonforCancellation' },
            '0x00741242': { 'vm': '1', 'vr': 'CS', name: 'SCPStatus' },
            '0x00741244': { 'vm': '1', 'vr': 'CS', name: 'SubscriptionListStatus' },
            '0x00741246': { 'vm': '1', 'vr': 'CS', name: 'UnifiedProcedureStepListStatus' },
            '0x00741324': { 'vm': '1', 'vr': 'UL', name: 'BeamOrderIndex' },
            '0x00741338': { 'vm': '1', 'vr': 'FD', name: 'DoubleExposureMeterset' },
            '0x0074133A': { 'vm': '4', 'vr': 'FD', name: 'DoubleExposureFieldDelta' },
            '0x00741401': { 'vm': '1', 'vr': 'SQ', name: 'BrachyTaskSequence' },
            '0x00741402': { 'vm': '1', 'vr': 'DS', name: 'ContinuationStartTotalReferenceAirKerma' },
            '0x00741403': { 'vm': '1', 'vr': 'DS', name: 'ContinuationEndTotalReferenceAirKerma' },
            '0x00741404': { 'vm': '1', 'vr': 'IS', name: 'ContinuationPulseNumber' },
            '0x00741405': { 'vm': '1', 'vr': 'SQ', name: 'ChannelDeliveryOrderSequence' },
            '0x00741406': { 'vm': '1', 'vr': 'IS', name: 'ReferencedChannelNumber' },
            '0x00741407': { 'vm': '1', 'vr': 'DS', name: 'StartCumulativeTimeWeight' },
            '0x00741408': { 'vm': '1', 'vr': 'DS', name: 'EndCumulativeTimeWeight' },
            '0x00741409': { 'vm': '1', 'vr': 'SQ', name: 'OmittedChannelSequence' },
            '0x0074140A': { 'vm': '1', 'vr': 'CS', name: 'ReasonforChannelOmission' },
            '0x0074140B': { 'vm': '1', 'vr': 'LO', name: 'ReasonforChannelOmissionDescription' },
            '0x0074140C': { 'vm': '1', 'vr': 'IS', name: 'ChannelDeliveryOrderIndex' },
            '0x0074140D': { 'vm': '1', 'vr': 'SQ', name: 'ChannelDeliveryContinuationSequence' },
            '0x0074140E': { 'vm': '1', 'vr': 'SQ', name: 'OmittedApplicationSetupSequence' },
            '0x00760001': { 'vm': '1', 'vr': 'LO', name: 'ImplantAssemblyTemplateName' },
            '0x00760003': { 'vm': '1', 'vr': 'LO', name: 'ImplantAssemblyTemplateIssuer' },
            '0x00760006': { 'vm': '1', 'vr': 'LO', name: 'ImplantAssemblyTemplateVersion' },
            '0x00760008': { 'vm': '1', 'vr': 'SQ', name: 'ReplacedImplantAssemblyTemplateSequence' },
            '0x0076000A': { 'vm': '1', 'vr': 'CS', name: 'ImplantAssemblyTemplateType' },
            '0x0076000C': { 'vm': '1', 'vr': 'SQ', name: 'OriginalImplantAssemblyTemplateSequence' },
            '0x0076000E': { 'vm': '1', 'vr': 'SQ', name: 'DerivationImplantAssemblyTemplateSequence' },
            '0x00760010': { 'vm': '1', 'vr': 'SQ', name: 'ImplantAssemblyTemplateTargetAnatomySequence' },
            '0x00760020': { 'vm': '1', 'vr': 'SQ', name: 'ProcedureTypeCodeSequence' },
            '0x00760030': { 'vm': '1', 'vr': 'LO', name: 'SurgicalTechnique' },
            '0x00760032': { 'vm': '1', 'vr': 'SQ', name: 'ComponentTypesSequence' },
            '0x00760034': { 'vm': '1', 'vr': 'SQ', name: 'ComponentTypeCodeSequence' },
            '0x00760036': { 'vm': '1', 'vr': 'CS', name: 'ExclusiveComponentType' },
            '0x00760038': { 'vm': '1', 'vr': 'CS', name: 'MandatoryComponentType' },
            '0x00760040': { 'vm': '1', 'vr': 'SQ', name: 'ComponentSequence' },
            '0x00760055': { 'vm': '1', 'vr': 'US', name: 'ComponentID' },
            '0x00760060': { 'vm': '1', 'vr': 'SQ', name: 'ComponentAssemblySequence' },
            '0x00760070': { 'vm': '1', 'vr': 'US', name: 'Component1ReferencedID' },
            '0x00760080': { 'vm': '1', 'vr': 'US', name: 'Component1ReferencedMatingFeatureSetID' },
            '0x00760090': { 'vm': '1', 'vr': 'US', name: 'Component1ReferencedMatingFeatureID' },
            '0x007600A0': { 'vm': '1', 'vr': 'US', name: 'Component2ReferencedID' },
            '0x007600B0': { 'vm': '1', 'vr': 'US', name: 'Component2ReferencedMatingFeatureSetID' },
            '0x007600C0': { 'vm': '1', 'vr': 'US', name: 'Component2ReferencedMatingFeatureID' },
            '0x00780001': { 'vm': '1', 'vr': 'LO', name: 'ImplantTemplateGroupName' },
            '0x00780010': { 'vm': '1', 'vr': 'ST', name: 'ImplantTemplateGroupDescription' },
            '0x00780020': { 'vm': '1', 'vr': 'LO', name: 'ImplantTemplateGroupIssuer' },
            '0x00780024': { 'vm': '1', 'vr': 'LO', name: 'ImplantTemplateGroupVersion' },
            '0x00780026': { 'vm': '1', 'vr': 'SQ', name: 'ReplacedImplantTemplateGroupSequence' },
            '0x00780028': { 'vm': '1', 'vr': 'SQ', name: 'ImplantTemplateGroupTargetAnatomySequence' },
            '0x0078002A': { 'vm': '1', 'vr': 'SQ', name: 'ImplantTemplateGroupMembersSequence' },
            '0x0078002E': { 'vm': '1', 'vr': 'US', name: 'ImplantTemplateGroupMemberID' },
            '0x00780050': { 'vm': '3', 'vr': 'FD', name: 't3DImplantTemplateGroupMemberMatchingPoint' },
            '0x00780060': { 'vm': '9', 'vr': 'FD', name: 't3DImplantTemplateGroupMemberMatchingAxes' },
            '0x00780070': { 'vm': '1', 'vr': 'SQ', name: 'ImplantTemplateGroupMemberMatching2DCoordinatesSequence' },
            '0x00780090': { 'vm': '2', 'vr': 'FD', name: 't2DImplantTemplateGroupMemberMatchingPoint' },
            '0x007800A0': { 'vm': '4', 'vr': 'FD', name: 't2DImplantTemplateGroupMemberMatchingAxes' },
            '0x007800B0': { 'vm': '1', 'vr': 'SQ', name: 'ImplantTemplateGroupVariationDimensionSequence' },
            '0x007800B2': { 'vm': '1', 'vr': 'LO', name: 'ImplantTemplateGroupVariationDimensionName' },
            '0x007800B4': { 'vm': '1', 'vr': 'SQ', name: 'ImplantTemplateGroupVariationDimensionRankSequence' },
            '0x007800B6': { 'vm': '1', 'vr': 'US', name: 'ReferencedImplantTemplateGroupMemberID' },
            '0x007800B8': { 'vm': '1', 'vr': 'US', name: 'ImplantTemplateGroupVariationDimensionRank' },
            '0x00800001': { 'vm': '1', 'vr': 'SQ', name: 'SurfaceScanAcquisitionTypeCodeSequence' },
            '0x00800002': { 'vm': '1', 'vr': 'SQ', name: 'SurfaceScanModeCodeSequence' },
            '0x00800003': { 'vm': '1', 'vr': 'SQ', name: 'RegistrationMethodCodeSequence' },
            '0x00800004': { 'vm': '1', 'vr': 'FD', name: 'ShotDurationTime' },
            '0x00800005': { 'vm': '1', 'vr': 'FD', name: 'ShotOffsetTime' },
            '0x00800006': { 'vm': '1-n', 'vr': 'US', name: 'SurfacePointPresentationValueData' },
            '0x00800007': { 'vm': '3-3n', 'vr': 'US', name: 'SurfacePointColorCIELabValueData' },
            '0x00800008': { 'vm': '1', 'vr': 'SQ', name: 'UVMappingSequence' },
            '0x00800009': { 'vm': '1', 'vr': 'SH', name: 'TextureLabel' },
            '0x00800010': { 'vm': '1', 'vr': 'OF', name: 'UValueData' },
            '0x00800011': { 'vm': '1', 'vr': 'OF', name: 'VValueData' },
            '0x00800012': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedTextureSequence' },
            '0x00800013': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedSurfaceDataSequence' },
            '0x00820001': { 'vm': '1', 'vr': 'CS', name: 'AssessmentSummary' },
            '0x00820003': { 'vm': '1', 'vr': 'UT', name: 'AssessmentSummaryDescription' },
            '0x00820004': { 'vm': '1', 'vr': 'SQ', name: 'AssessedSOPInstanceSequence' },
            '0x00820005': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedComparisonSOPInstanceSequence' },
            '0x00820006': { 'vm': '1', 'vr': 'UL', name: 'NumberofAssessmentObservations' },
            '0x00820007': { 'vm': '1', 'vr': 'SQ', name: 'AssessmentObservationsSequence' },
            '0x00820008': { 'vm': '1', 'vr': 'CS', name: 'ObservationSignificance' },
            '0x0082000A': { 'vm': '1', 'vr': 'UT', name: 'ObservationDescription' },
            '0x0082000C': { 'vm': '1', 'vr': 'SQ', name: 'StructuredConstraintObservationSequence' },
            '0x00820010': { 'vm': '1', 'vr': 'SQ', name: 'AssessedAttributeValueSequence' },
            '0x00820016': { 'vm': '1', 'vr': 'LO', name: 'AssessmentSetID' },
            '0x00820017': { 'vm': '1', 'vr': 'SQ', name: 'AssessmentRequesterSequence' },
            '0x00820018': { 'vm': '1', 'vr': 'LO', name: 'SelectorAttributeName' },
            '0x00820019': { 'vm': '1', 'vr': 'LO', name: 'SelectorAttributeKeyword' },
            '0x00820021': { 'vm': '1', 'vr': 'SQ', name: 'AssessmentTypeCodeSequence' },
            '0x00820022': { 'vm': '1', 'vr': 'SQ', name: 'ObservationBasisCodeSequence' },
            '0x00820023': { 'vm': '1', 'vr': 'LO', name: 'AssessmentLabel' },
            '0x00820032': { 'vm': '1', 'vr': 'CS', name: 'ConstraintType' },
            '0x00820033': { 'vm': '1', 'vr': 'UT', name: 'SpecificationSelectionGuidance' },
            '0x00820034': { 'vm': '1', 'vr': 'SQ', name: 'ConstraintValueSequence' },
            '0x00820035': { 'vm': '1', 'vr': 'SQ', name: 'RecommendedDefaultValueSequence' },
            '0x00820036': { 'vm': '1', 'vr': 'CS', name: 'ConstraintViolationSignificance' },
            '0x00820037': { 'vm': '1', 'vr': 'UT', name: 'ConstraintViolationCondition' },
            '0x00820038': { 'vm': '1', 'vr': 'CS', name: 'ModifiableConstraintFlag' },
            '0x00880130': { 'vm': '1', 'vr': 'SH', name: 'StorageMediaFilesetID' },
            '0x00880140': { 'vm': '1', 'vr': 'UI', name: 'StorageMediaFilesetUID' },
            '0x00880200': { 'vm': '1', 'vr': 'SQ', name: 'IconImageSequence' },
            '0x00880904': { 'vm': '1', 'vr': 'LO', name: 'TopicTitle' },
            '0x00880906': { 'vm': '1', 'vr': 'ST', name: 'TopicSubject' },
            '0x00880910': { 'vm': '1', 'vr': 'LO', name: 'TopicAuthor' },
            '0x00880912': { 'vm': '1-32', 'vr': 'LO', name: 'TopicKeywords' },
            '0x01000410': { 'vm': '1', 'vr': 'CS', name: 'SOPInstanceStatus' },
            '0x01000420': { 'vm': '1', 'vr': 'DT', name: 'SOPAuthorizationDateTime' },
            '0x01000424': { 'vm': '1', 'vr': 'LT', name: 'SOPAuthorizationComment' },
            '0x01000426': { 'vm': '1', 'vr': 'LO', name: 'AuthorizationEquipmentCertificationNumber' },
            '0x04000005': { 'vm': '1', 'vr': 'US', name: 'MACIDNumber' },
            '0x04000010': { 'vm': '1', 'vr': 'UI', name: 'MACCalculationTransferSyntaxUID' },
            '0x04000015': { 'vm': '1', 'vr': 'CS', name: 'MACAlgorithm' },
            '0x04000020': { 'vm': '1-n', 'vr': 'AT', name: 'DataElementsSigned' },
            '0x04000100': { 'vm': '1', 'vr': 'UI', name: 'DigitalSignatureUID' },
            '0x04000105': { 'vm': '1', 'vr': 'DT', name: 'DigitalSignatureDateTime' },
            '0x04000110': { 'vm': '1', 'vr': 'CS', name: 'CertificateType' },
            '0x04000115': { 'vm': '1', 'vr': 'OB', name: 'CertificateofSigner' },
            '0x04000120': { 'vm': '1', 'vr': 'OB', name: 'Signature' },
            '0x04000305': { 'vm': '1', 'vr': 'CS', name: 'CertifiedTimestampType' },
            '0x04000310': { 'vm': '1', 'vr': 'OB', name: 'CertifiedTimestamp' },
            '0x04000401': { 'vm': '1', 'vr': 'SQ', name: 'DigitalSignaturePurposeCodeSequence' },
            '0x04000402': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedDigitalSignatureSequence' },
            '0x04000403': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedSOPInstanceMACSequence' },
            '0x04000404': { 'vm': '1', 'vr': 'OB', name: 'MAC' },
            '0x04000500': { 'vm': '1', 'vr': 'SQ', name: 'EncryptedAttributesSequence' },
            '0x04000510': { 'vm': '1', 'vr': 'UI', name: 'EncryptedContentTransferSyntaxUID' },
            '0x04000520': { 'vm': '1', 'vr': 'OB', name: 'EncryptedContent' },
            '0x04000550': { 'vm': '1', 'vr': 'SQ', name: 'ModifiedAttributesSequence' },
            '0x04000551': { 'vm': '1', 'vr': 'SQ', name: 'NonconformingModifiedAttributesSequence' },
            '0x04000552': { 'vm': '1', 'vr': 'OB', name: 'NonconformingDataElementValue' },
            '0x04000561': { 'vm': '1', 'vr': 'SQ', name: 'OriginalAttributesSequence' },
            '0x04000562': { 'vm': '1', 'vr': 'DT', name: 'AttributeModificationDateTime' },
            '0x04000563': { 'vm': '1', 'vr': 'LO', name: 'ModifyingSystem' },
            '0x04000564': { 'vm': '1', 'vr': 'LO', name: 'SourceofPreviousValues' },
            '0x04000565': { 'vm': '1', 'vr': 'CS', name: 'ReasonfortheAttributeModification' },
            '0x04000600': { 'vm': '1', 'vr': 'CS', name: 'InstanceOriginStatus' },
            '0x1000xxx0': { 'vm': '3', 'vr': 'US', name: 'EscapeTriplet' },
            '0x1000xxx1': { 'vm': '3', 'vr': 'US', name: 'RunLengthTriplet' },
            '0x1000xxx2': { 'vm': '1', 'vr': 'US', name: 'HuffmanTableSize' },
            '0x1000xxx3': { 'vm': '3', 'vr': 'US', name: 'HuffmanTableTriplet' },
            '0x1000xxx4': { 'vm': '1', 'vr': 'US', name: 'ShiftTableSize' },
            '0x1000xxx5': { 'vm': '3', 'vr': 'US', name: 'ShiftTableTriplet' },
            '0x1010xxxx': { 'vm': '1-n', 'vr': 'US', name: 'ZonalMap' },
            '0x20000010': { 'vm': '1', 'vr': 'IS', name: 'NumberofCopies' },
            '0x2000001E': { 'vm': '1', 'vr': 'SQ', name: 'PrinterConfigurationSequence' },
            '0x20000020': { 'vm': '1', 'vr': 'CS', name: 'PrintPriority' },
            '0x20000030': { 'vm': '1', 'vr': 'CS', name: 'MediumType' },
            '0x20000040': { 'vm': '1', 'vr': 'CS', name: 'FilmDestination' },
            '0x20000050': { 'vm': '1', 'vr': 'LO', name: 'FilmSessionLabel' },
            '0x20000060': { 'vm': '1', 'vr': 'IS', name: 'MemoryAllocation' },
            '0x20000061': { 'vm': '1', 'vr': 'IS', name: 'MaximumMemoryAllocation' },
            '0x20000062': { 'vm': '1', 'vr': 'CS', name: 'ColorImagePrintingFlag' },
            '0x20000063': { 'vm': '1', 'vr': 'CS', name: 'CollationFlag' },
            '0x20000065': { 'vm': '1', 'vr': 'CS', name: 'AnnotationFlag' },
            '0x20000067': { 'vm': '1', 'vr': 'CS', name: 'ImageOverlayFlag' },
            '0x20000069': { 'vm': '1', 'vr': 'CS', name: 'PresentationLUTFlag' },
            '0x2000006A': { 'vm': '1', 'vr': 'CS', name: 'ImageBoxPresentationLUTFlag' },
            '0x200000A0': { 'vm': '1', 'vr': 'US', name: 'MemoryBitDepth' },
            '0x200000A1': { 'vm': '1', 'vr': 'US', name: 'PrintingBitDepth' },
            '0x200000A2': { 'vm': '1', 'vr': 'SQ', name: 'MediaInstalledSequence' },
            '0x200000A4': { 'vm': '1', 'vr': 'SQ', name: 'OtherMediaAvailableSequence' },
            '0x200000A8': { 'vm': '1', 'vr': 'SQ', name: 'SupportedImageDisplayFormatsSequence' },
            '0x20000500': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedFilmBoxSequence' },
            '0x20000510': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedStoredPrintSequence' },
            '0x20100010': { 'vm': '1', 'vr': 'ST', name: 'ImageDisplayFormat' },
            '0x20100030': { 'vm': '1', 'vr': 'CS', name: 'AnnotationDisplayFormatID' },
            '0x20100040': { 'vm': '1', 'vr': 'CS', name: 'FilmOrientation' },
            '0x20100050': { 'vm': '1', 'vr': 'CS', name: 'FilmSizeID' },
            '0x20100052': { 'vm': '1', 'vr': 'CS', name: 'PrinterResolutionID' },
            '0x20100054': { 'vm': '1', 'vr': 'CS', name: 'DefaultPrinterResolutionID' },
            '0x20100060': { 'vm': '1', 'vr': 'CS', name: 'MagnificationType' },
            '0x20100080': { 'vm': '1', 'vr': 'CS', name: 'SmoothingType' },
            '0x201000A6': { 'vm': '1', 'vr': 'CS', name: 'DefaultMagnificationType' },
            '0x201000A7': { 'vm': '1-n', 'vr': 'CS', name: 'OtherMagnificationTypesAvailable' },
            '0x201000A8': { 'vm': '1', 'vr': 'CS', name: 'DefaultSmoothingType' },
            '0x201000A9': { 'vm': '1-n', 'vr': 'CS', name: 'OtherSmoothingTypesAvailable' },
            '0x20100100': { 'vm': '1', 'vr': 'CS', name: 'BorderDensity' },
            '0x20100110': { 'vm': '1', 'vr': 'CS', name: 'EmptyImageDensity' },
            '0x20100120': { 'vm': '1', 'vr': 'US', name: 'MinDensity' },
            '0x20100130': { 'vm': '1', 'vr': 'US', name: 'MaxDensity' },
            '0x20100140': { 'vm': '1', 'vr': 'CS', name: 'Trim' },
            '0x20100150': { 'vm': '1', 'vr': 'ST', name: 'ConfigurationInformation' },
            '0x20100152': { 'vm': '1', 'vr': 'LT', name: 'ConfigurationInformationDescription' },
            '0x20100154': { 'vm': '1', 'vr': 'IS', name: 'MaximumCollatedFilms' },
            '0x2010015E': { 'vm': '1', 'vr': 'US', name: 'Illumination' },
            '0x20100160': { 'vm': '1', 'vr': 'US', name: 'ReflectedAmbientLight' },
            '0x20100376': { 'vm': '2', 'vr': 'DS', name: 'PrinterPixelSpacing' },
            '0x20100500': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedFilmSessionSequence' },
            '0x20100510': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedImageBoxSequence' },
            '0x20100520': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedBasicAnnotationBoxSequence' },
            '0x20200010': { 'vm': '1', 'vr': 'US', name: 'ImageBoxPosition' },
            '0x20200020': { 'vm': '1', 'vr': 'CS', name: 'Polarity' },
            '0x20200030': { 'vm': '1', 'vr': 'DS', name: 'RequestedImageSize' },
            '0x20200040': { 'vm': '1', 'vr': 'CS', name: 'RequestedDecimateCropBehavior' },
            '0x20200050': { 'vm': '1', 'vr': 'CS', name: 'RequestedResolutionID' },
            '0x202000A0': { 'vm': '1', 'vr': 'CS', name: 'RequestedImageSizeFlag' },
            '0x202000A2': { 'vm': '1', 'vr': 'CS', name: 'DecimateCropResult' },
            '0x20200110': { 'vm': '1', 'vr': 'SQ', name: 'BasicGrayscaleImageSequence' },
            '0x20200111': { 'vm': '1', 'vr': 'SQ', name: 'BasicColorImageSequence' },
            '0x20200130': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedImageOverlayBoxSequence' },
            '0x20200140': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedVOILUTBoxSequence' },
            '0x20300010': { 'vm': '1', 'vr': 'US', name: 'AnnotationPosition' },
            '0x20300020': { 'vm': '1', 'vr': 'LO', name: 'TextString' },
            '0x20400010': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedOverlayPlaneSequence' },
            '0x20400011': { 'vm': '1-99', 'vr': 'US', name: 'ReferencedOverlayPlaneGroups' },
            '0x20400020': { 'vm': '1', 'vr': 'SQ', name: 'OverlayPixelDataSequence' },
            '0x20400060': { 'vm': '1', 'vr': 'CS', name: 'OverlayMagnificationType' },
            '0x20400070': { 'vm': '1', 'vr': 'CS', name: 'OverlaySmoothingType' },
            '0x20400072': { 'vm': '1', 'vr': 'CS', name: 'OverlayorImageMagnification' },
            '0x20400074': { 'vm': '1', 'vr': 'US', name: 'MagnifytoNumberofColumns' },
            '0x20400080': { 'vm': '1', 'vr': 'CS', name: 'OverlayForegroundDensity' },
            '0x20400082': { 'vm': '1', 'vr': 'CS', name: 'OverlayBackgroundDensity' },
            '0x20400090': { 'vm': '1', 'vr': 'CS', name: 'OverlayMode' },
            '0x20400100': { 'vm': '1', 'vr': 'CS', name: 'ThresholdDensity' },
            '0x20400500': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedImageBoxSequenceRetired' },
            '0x20500010': { 'vm': '1', 'vr': 'SQ', name: 'PresentationLUTSequence' },
            '0x20500020': { 'vm': '1', 'vr': 'CS', name: 'PresentationLUTShape' },
            '0x20500500': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPresentationLUTSequence' },
            '0x21000010': { 'vm': '1', 'vr': 'SH', name: 'PrintJobID' },
            '0x21000020': { 'vm': '1', 'vr': 'CS', name: 'ExecutionStatus' },
            '0x21000030': { 'vm': '1', 'vr': 'CS', name: 'ExecutionStatusInfo' },
            '0x21000040': { 'vm': '1', 'vr': 'DA', name: 'CreationDate' },
            '0x21000050': { 'vm': '1', 'vr': 'TM', name: 'CreationTime' },
            '0x21000070': { 'vm': '1', 'vr': 'AE', name: 'Originator' },
            '0x21000140': { 'vm': '1', 'vr': 'AE', name: 'DestinationAE' },
            '0x21000160': { 'vm': '1', 'vr': 'SH', name: 'OwnerID' },
            '0x21000170': { 'vm': '1', 'vr': 'IS', name: 'NumberofFilms' },
            '0x21000500': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPrintJobSequencePullStoredPrint' },
            '0x21100010': { 'vm': '1', 'vr': 'CS', name: 'PrinterStatus' },
            '0x21100020': { 'vm': '1', 'vr': 'CS', name: 'PrinterStatusInfo' },
            '0x21100030': { 'vm': '1', 'vr': 'LO', name: 'PrinterName' },
            '0x21100099': { 'vm': '1', 'vr': 'SH', name: 'PrintQueueID' },
            '0x21200010': { 'vm': '1', 'vr': 'CS', name: 'QueueStatus' },
            '0x21200050': { 'vm': '1', 'vr': 'SQ', name: 'PrintJobDescriptionSequence' },
            '0x21200070': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPrintJobSequence' },
            '0x21300010': { 'vm': '1', 'vr': 'SQ', name: 'PrintManagementCapabilitiesSequence' },
            '0x21300015': { 'vm': '1', 'vr': 'SQ', name: 'PrinterCharacteristicsSequence' },
            '0x21300030': { 'vm': '1', 'vr': 'SQ', name: 'FilmBoxContentSequence' },
            '0x21300040': { 'vm': '1', 'vr': 'SQ', name: 'ImageBoxContentSequence' },
            '0x21300050': { 'vm': '1', 'vr': 'SQ', name: 'AnnotationContentSequence' },
            '0x21300060': { 'vm': '1', 'vr': 'SQ', name: 'ImageOverlayBoxContentSequence' },
            '0x21300080': { 'vm': '1', 'vr': 'SQ', name: 'PresentationLUTContentSequence' },
            '0x213000A0': { 'vm': '1', 'vr': 'SQ', name: 'ProposedStudySequence' },
            '0x213000C0': { 'vm': '1', 'vr': 'SQ', name: 'OriginalImageSequence' },
            '0x22000001': { 'vm': '1', 'vr': 'CS', name: 'LabelUsingInformationExtractedFromInstances' },
            '0x22000002': { 'vm': '1', 'vr': 'UT', name: 'LabelText' },
            '0x22000003': { 'vm': '1', 'vr': 'CS', name: 'LabelStyleSelection' },
            '0x22000004': { 'vm': '1', 'vr': 'LT', name: 'MediaDisposition' },
            '0x22000005': { 'vm': '1', 'vr': 'LT', name: 'BarcodeValue' },
            '0x22000006': { 'vm': '1', 'vr': 'CS', name: 'BarcodeSymbology' },
            '0x22000007': { 'vm': '1', 'vr': 'CS', name: 'AllowMediaSplitting' },
            '0x22000008': { 'vm': '1', 'vr': 'CS', name: 'IncludeNonDICOMObjects' },
            '0x22000009': { 'vm': '1', 'vr': 'CS', name: 'IncludeDisplayApplication' },
            '0x2200000A': { 'vm': '1', 'vr': 'CS', name: 'PreserveCompositeInstancesAfterMediaCreation' },
            '0x2200000B': { 'vm': '1', 'vr': 'US', name: 'TotalNumberofPiecesofMediaCreated' },
            '0x2200000C': { 'vm': '1', 'vr': 'LO', name: 'RequestedMediaApplicationProfile' },
            '0x2200000D': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedStorageMediaSequence' },
            '0x2200000E': { 'vm': '1-n', 'vr': 'AT', name: 'FailureAttributes' },
            '0x2200000F': { 'vm': '1', 'vr': 'CS', name: 'AllowLossyCompression' },
            '0x22000020': { 'vm': '1', 'vr': 'CS', name: 'RequestPriority' },
            '0x30020002': { 'vm': '1', 'vr': 'SH', name: 'RTImageLabel' },
            '0x30020003': { 'vm': '1', 'vr': 'LO', name: 'RTImageName' },
            '0x30020004': { 'vm': '1', 'vr': 'ST', name: 'RTImageDescription' },
            '0x3002000A': { 'vm': '1', 'vr': 'CS', name: 'ReportedValuesOrigin' },
            '0x3002000C': { 'vm': '1', 'vr': 'CS', name: 'RTImagePlane' },
            '0x3002000D': { 'vm': '3', 'vr': 'DS', name: 'XRayImageReceptorTranslation' },
            '0x3002000E': { 'vm': '1', 'vr': 'DS', name: 'XRayImageReceptorAngle' },
            '0x30020010': { 'vm': '6', 'vr': 'DS', name: 'RTImageOrientation' },
            '0x30020011': { 'vm': '2', 'vr': 'DS', name: 'ImagePlanePixelSpacing' },
            '0x30020012': { 'vm': '2', 'vr': 'DS', name: 'RTImagePosition' },
            '0x30020020': { 'vm': '1', 'vr': 'SH', name: 'RadiationMachineName' },
            '0x30020022': { 'vm': '1', 'vr': 'DS', name: 'RadiationMachineSAD' },
            '0x30020024': { 'vm': '1', 'vr': 'DS', name: 'RadiationMachineSSD' },
            '0x30020026': { 'vm': '1', 'vr': 'DS', name: 'RTImageSID' },
            '0x30020028': { 'vm': '1', 'vr': 'DS', name: 'SourcetoReferenceObjectDistance' },
            '0x30020029': { 'vm': '1', 'vr': 'IS', name: 'FractionNumber' },
            '0x30020030': { 'vm': '1', 'vr': 'SQ', name: 'ExposureSequence' },
            '0x30020032': { 'vm': '1', 'vr': 'DS', name: 'MetersetExposure' },
            '0x30020034': { 'vm': '4', 'vr': 'DS', name: 'DiaphragmPosition' },
            '0x30020040': { 'vm': '1', 'vr': 'SQ', name: 'FluenceMapSequence' },
            '0x30020041': { 'vm': '1', 'vr': 'CS', name: 'FluenceDataSource' },
            '0x30020042': { 'vm': '1', 'vr': 'DS', name: 'FluenceDataScale' },
            '0x30020050': { 'vm': '1', 'vr': 'SQ', name: 'PrimaryFluenceModeSequence' },
            '0x30020051': { 'vm': '1', 'vr': 'CS', name: 'FluenceMode' },
            '0x30020052': { 'vm': '1', 'vr': 'SH', name: 'FluenceModeID' },
            '0x30040001': { 'vm': '1', 'vr': 'CS', name: 'DVHType' },
            '0x30040002': { 'vm': '1', 'vr': 'CS', name: 'DoseUnits' },
            '0x30040004': { 'vm': '1', 'vr': 'CS', name: 'DoseType' },
            '0x30040005': { 'vm': '1', 'vr': 'CS', name: 'SpatialTransformofDose' },
            '0x30040006': { 'vm': '1', 'vr': 'LO', name: 'DoseComment' },
            '0x30040008': { 'vm': '3', 'vr': 'DS', name: 'NormalizationPoint' },
            '0x3004000A': { 'vm': '1', 'vr': 'CS', name: 'DoseSummationType' },
            '0x3004000C': { 'vm': '2-n', 'vr': 'DS', name: 'GridFrameOffsetVector' },
            '0x3004000E': { 'vm': '1', 'vr': 'DS', name: 'DoseGridScaling' },
            '0x30040010': { 'vm': '1', 'vr': 'SQ', name: 'RTDoseROISequence' },
            '0x30040012': { 'vm': '1', 'vr': 'DS', name: 'DoseValue' },
            '0x30040014': { 'vm': '1-3', 'vr': 'CS', name: 'TissueHeterogeneityCorrection' },
            '0x30040040': { 'vm': '3', 'vr': 'DS', name: 'DVHNormalizationPoint' },
            '0x30040042': { 'vm': '1', 'vr': 'DS', name: 'DVHNormalizationDoseValue' },
            '0x30040050': { 'vm': '1', 'vr': 'SQ', name: 'DVHSequence' },
            '0x30040052': { 'vm': '1', 'vr': 'DS', name: 'DVHDoseScaling' },
            '0x30040054': { 'vm': '1', 'vr': 'CS', name: 'DVHVolumeUnits' },
            '0x30040056': { 'vm': '1', 'vr': 'IS', name: 'DVHNumberofBins' },
            '0x30040058': { 'vm': '2-2n', 'vr': 'DS', name: 'DVHData' },
            '0x30040060': { 'vm': '1', 'vr': 'SQ', name: 'DVHReferencedROISequence' },
            '0x30040062': { 'vm': '1', 'vr': 'CS', name: 'DVHROIContributionType' },
            '0x30040070': { 'vm': '1', 'vr': 'DS', name: 'DVHMinimumDose' },
            '0x30040072': { 'vm': '1', 'vr': 'DS', name: 'DVHMaximumDose' },
            '0x30040074': { 'vm': '1', 'vr': 'DS', name: 'DVHMeanDose' },
            '0x30060002': { 'vm': '1', 'vr': 'SH', name: 'StructureSetLabel' },
            '0x30060004': { 'vm': '1', 'vr': 'LO', name: 'StructureSetName' },
            '0x30060006': { 'vm': '1', 'vr': 'ST', name: 'StructureSetDescription' },
            '0x30060008': { 'vm': '1', 'vr': 'DA', name: 'StructureSetDate' },
            '0x30060009': { 'vm': '1', 'vr': 'TM', name: 'StructureSetTime' },
            '0x30060010': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedFrameofReferenceSequence' },
            '0x30060012': { 'vm': '1', 'vr': 'SQ', name: 'RTReferencedStudySequence' },
            '0x30060014': { 'vm': '1', 'vr': 'SQ', name: 'RTReferencedSeriesSequence' },
            '0x30060016': { 'vm': '1', 'vr': 'SQ', name: 'ContourImageSequence' },
            '0x30060018': { 'vm': '1', 'vr': 'SQ', name: 'PredecessorStructureSetSequence' },
            '0x30060020': { 'vm': '1', 'vr': 'SQ', name: 'StructureSetROISequence' },
            '0x30060022': { 'vm': '1', 'vr': 'IS', name: 'ROINumber' },
            '0x30060024': { 'vm': '1', 'vr': 'UI', name: 'ReferencedFrameofReferenceUID' },
            '0x30060026': { 'vm': '1', 'vr': 'LO', name: 'ROIName' },
            '0x30060028': { 'vm': '1', 'vr': 'ST', name: 'ROIDescription' },
            '0x3006002A': { 'vm': '3', 'vr': 'IS', name: 'ROIDisplayColor' },
            '0x3006002C': { 'vm': '1', 'vr': 'DS', name: 'ROIVolume' },
            '0x30060030': { 'vm': '1', 'vr': 'SQ', name: 'RTRelatedROISequence' },
            '0x30060033': { 'vm': '1', 'vr': 'CS', name: 'RTROIRelationship' },
            '0x30060036': { 'vm': '1', 'vr': 'CS', name: 'ROIGenerationAlgorithm' },
            '0x30060037': { 'vm': '1', 'vr': 'SQ', name: 'ROIDerivationAlgorithmIdentificationSequence' },
            '0x30060038': { 'vm': '1', 'vr': 'LO', name: 'ROIGenerationDescription' },
            '0x30060039': { 'vm': '1', 'vr': 'SQ', name: 'ROIContourSequence' },
            '0x30060040': { 'vm': '1', 'vr': 'SQ', name: 'ContourSequence' },
            '0x30060042': { 'vm': '1', 'vr': 'CS', name: 'ContourGeometricType' },
            '0x30060044': { 'vm': '1', 'vr': 'DS', name: 'ContourSlabThickness' },
            '0x30060045': { 'vm': '3', 'vr': 'DS', name: 'ContourOffsetVector' },
            '0x30060046': { 'vm': '1', 'vr': 'IS', name: 'NumberofContourPoints' },
            '0x30060048': { 'vm': '1', 'vr': 'IS', name: 'ContourNumber' },
            '0x30060049': { 'vm': '1-n', 'vr': 'IS', name: 'AttachedContours' },
            '0x30060050': { 'vm': '3-3n', 'vr': 'DS', name: 'ContourData' },
            '0x30060080': { 'vm': '1', 'vr': 'SQ', name: 'RTROIObservationsSequence' },
            '0x30060082': { 'vm': '1', 'vr': 'IS', name: 'ObservationNumber' },
            '0x30060084': { 'vm': '1', 'vr': 'IS', name: 'ReferencedROINumber' },
            '0x30060085': { 'vm': '1', 'vr': 'SH', name: 'ROIObservationLabel' },
            '0x30060086': { 'vm': '1', 'vr': 'SQ', name: 'RTROIIdentificationCodeSequence' },
            '0x30060088': { 'vm': '1', 'vr': 'ST', name: 'ROIObservationDescription' },
            '0x300600A0': { 'vm': '1', 'vr': 'SQ', name: 'RelatedRTROIObservationsSequence' },
            '0x300600A4': { 'vm': '1', 'vr': 'CS', name: 'RTROIInterpretedType' },
            '0x300600A6': { 'vm': '1', 'vr': 'PN', name: 'ROIInterpreter' },
            '0x300600B0': { 'vm': '1', 'vr': 'SQ', name: 'ROIPhysicalPropertiesSequence' },
            '0x300600B2': { 'vm': '1', 'vr': 'CS', name: 'ROIPhysicalProperty' },
            '0x300600B4': { 'vm': '1', 'vr': 'DS', name: 'ROIPhysicalPropertyValue' },
            '0x300600B6': { 'vm': '1', 'vr': 'SQ', name: 'ROIElementalCompositionSequence' },
            '0x300600B7': { 'vm': '1', 'vr': 'US', name: 'ROIElementalCompositionAtomicNumber' },
            '0x300600B8': { 'vm': '1', 'vr': 'FL', name: 'ROIElementalCompositionAtomicMassFraction' },
            '0x300600B9': { 'vm': '1', 'vr': 'SQ', name: 'AdditionalRTROIIdentificationCodeSequence' },
            '0x300600C0': { 'vm': '1', 'vr': 'SQ', name: 'FrameofReferenceRelationshipSequence' },
            '0x300600C2': { 'vm': '1', 'vr': 'UI', name: 'RelatedFrameofReferenceUID' },
            '0x300600C4': { 'vm': '1', 'vr': 'CS', name: 'FrameofReferenceTransformationType' },
            '0x300600C6': { 'vm': '16', 'vr': 'DS', name: 'FrameofReferenceTransformationMatrix' },
            '0x300600C8': { 'vm': '1', 'vr': 'LO', name: 'FrameofReferenceTransformationComment' },
            '0x30080010': { 'vm': '1', 'vr': 'SQ', name: 'MeasuredDoseReferenceSequence' },
            '0x30080012': { 'vm': '1', 'vr': 'ST', name: 'MeasuredDoseDescription' },
            '0x30080014': { 'vm': '1', 'vr': 'CS', name: 'MeasuredDoseType' },
            '0x30080016': { 'vm': '1', 'vr': 'DS', name: 'MeasuredDoseValue' },
            '0x30080020': { 'vm': '1', 'vr': 'SQ', name: 'TreatmentSessionBeamSequence' },
            '0x30080021': { 'vm': '1', 'vr': 'SQ', name: 'TreatmentSessionIonBeamSequence' },
            '0x30080022': { 'vm': '1', 'vr': 'IS', name: 'CurrentFractionNumber' },
            '0x30080024': { 'vm': '1', 'vr': 'DA', name: 'TreatmentControlPointDate' },
            '0x30080025': { 'vm': '1', 'vr': 'TM', name: 'TreatmentControlPointTime' },
            '0x3008002A': { 'vm': '1', 'vr': 'CS', name: 'TreatmentTerminationStatus' },
            '0x3008002B': { 'vm': '1', 'vr': 'SH', name: 'TreatmentTerminationCode' },
            '0x3008002C': { 'vm': '1', 'vr': 'CS', name: 'TreatmentVerificationStatus' },
            '0x30080030': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedTreatmentRecordSequence' },
            '0x30080032': { 'vm': '1', 'vr': 'DS', name: 'SpecifiedPrimaryMeterset' },
            '0x30080033': { 'vm': '1', 'vr': 'DS', name: 'SpecifiedSecondaryMeterset' },
            '0x30080036': { 'vm': '1', 'vr': 'DS', name: 'DeliveredPrimaryMeterset' },
            '0x30080037': { 'vm': '1', 'vr': 'DS', name: 'DeliveredSecondaryMeterset' },
            '0x3008003A': { 'vm': '1', 'vr': 'DS', name: 'SpecifiedTreatmentTime' },
            '0x3008003B': { 'vm': '1', 'vr': 'DS', name: 'DeliveredTreatmentTime' },
            '0x30080040': { 'vm': '1', 'vr': 'SQ', name: 'ControlPointDeliverySequence' },
            '0x30080041': { 'vm': '1', 'vr': 'SQ', name: 'IonControlPointDeliverySequence' },
            '0x30080042': { 'vm': '1', 'vr': 'DS', name: 'SpecifiedMeterset' },
            '0x30080044': { 'vm': '1', 'vr': 'DS', name: 'DeliveredMeterset' },
            '0x30080045': { 'vm': '1', 'vr': 'FL', name: 'MetersetRateSet' },
            '0x30080046': { 'vm': '1', 'vr': 'FL', name: 'MetersetRateDelivered' },
            '0x30080047': { 'vm': '1-n', 'vr': 'FL', name: 'ScanSpotMetersetsDelivered' },
            '0x30080048': { 'vm': '1', 'vr': 'DS', name: 'DoseRateDelivered' },
            '0x30080050': { 'vm': '1', 'vr': 'SQ', name: 'TreatmentSummaryCalculatedDoseReferenceSequence' },
            '0x30080052': { 'vm': '1', 'vr': 'DS', name: 'CumulativeDosetoDoseReference' },
            '0x30080054': { 'vm': '1', 'vr': 'DA', name: 'FirstTreatmentDate' },
            '0x30080056': { 'vm': '1', 'vr': 'DA', name: 'MostRecentTreatmentDate' },
            '0x3008005A': { 'vm': '1', 'vr': 'IS', name: 'NumberofFractionsDelivered' },
            '0x30080060': { 'vm': '1', 'vr': 'SQ', name: 'OverrideSequence' },
            '0x30080061': { 'vm': '1', 'vr': 'AT', name: 'ParameterSequencePointer' },
            '0x30080062': { 'vm': '1', 'vr': 'AT', name: 'OverrideParameterPointer' },
            '0x30080063': { 'vm': '1', 'vr': 'IS', name: 'ParameterItemIndex' },
            '0x30080064': { 'vm': '1', 'vr': 'IS', name: 'MeasuredDoseReferenceNumber' },
            '0x30080065': { 'vm': '1', 'vr': 'AT', name: 'ParameterPointer' },
            '0x30080066': { 'vm': '1', 'vr': 'ST', name: 'OverrideReason' },
            '0x30080067': { 'vm': '1', 'vr': 'US', name: 'ParameterValueNumber' },
            '0x30080068': { 'vm': '1', 'vr': 'SQ', name: 'CorrectedParameterSequence' },
            '0x3008006A': { 'vm': '1', 'vr': 'FL', name: 'CorrectionValue' },
            '0x30080070': { 'vm': '1', 'vr': 'SQ', name: 'CalculatedDoseReferenceSequence' },
            '0x30080072': { 'vm': '1', 'vr': 'IS', name: 'CalculatedDoseReferenceNumber' },
            '0x30080074': { 'vm': '1', 'vr': 'ST', name: 'CalculatedDoseReferenceDescription' },
            '0x30080076': { 'vm': '1', 'vr': 'DS', name: 'CalculatedDoseReferenceDoseValue' },
            '0x30080078': { 'vm': '1', 'vr': 'DS', name: 'StartMeterset' },
            '0x3008007A': { 'vm': '1', 'vr': 'DS', name: 'EndMeterset' },
            '0x30080080': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedMeasuredDoseReferenceSequence' },
            '0x30080082': { 'vm': '1', 'vr': 'IS', name: 'ReferencedMeasuredDoseReferenceNumber' },
            '0x30080090': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedCalculatedDoseReferenceSequence' },
            '0x30080092': { 'vm': '1', 'vr': 'IS', name: 'ReferencedCalculatedDoseReferenceNumber' },
            '0x300800A0': { 'vm': '1', 'vr': 'SQ', name: 'BeamLimitingDeviceLeafPairsSequence' },
            '0x300800B0': { 'vm': '1', 'vr': 'SQ', name: 'RecordedWedgeSequence' },
            '0x300800C0': { 'vm': '1', 'vr': 'SQ', name: 'RecordedCompensatorSequence' },
            '0x300800D0': { 'vm': '1', 'vr': 'SQ', name: 'RecordedBlockSequence' },
            '0x300800E0': { 'vm': '1', 'vr': 'SQ', name: 'TreatmentSummaryMeasuredDoseReferenceSequence' },
            '0x300800F0': { 'vm': '1', 'vr': 'SQ', name: 'RecordedSnoutSequence' },
            '0x300800F2': { 'vm': '1', 'vr': 'SQ', name: 'RecordedRangeShifterSequence' },
            '0x300800F4': { 'vm': '1', 'vr': 'SQ', name: 'RecordedLateralSpreadingDeviceSequence' },
            '0x300800F6': { 'vm': '1', 'vr': 'SQ', name: 'RecordedRangeModulatorSequence' },
            '0x30080100': { 'vm': '1', 'vr': 'SQ', name: 'RecordedSourceSequence' },
            '0x30080105': { 'vm': '1', 'vr': 'LO', name: 'SourceSerialNumber' },
            '0x30080110': { 'vm': '1', 'vr': 'SQ', name: 'TreatmentSessionApplicationSetupSequence' },
            '0x30080116': { 'vm': '1', 'vr': 'CS', name: 'ApplicationSetupCheck' },
            '0x30080120': { 'vm': '1', 'vr': 'SQ', name: 'RecordedBrachyAccessoryDeviceSequence' },
            '0x30080122': { 'vm': '1', 'vr': 'IS', name: 'ReferencedBrachyAccessoryDeviceNumber' },
            '0x30080130': { 'vm': '1', 'vr': 'SQ', name: 'RecordedChannelSequence' },
            '0x30080132': { 'vm': '1', 'vr': 'DS', name: 'SpecifiedChannelTotalTime' },
            '0x30080134': { 'vm': '1', 'vr': 'DS', name: 'DeliveredChannelTotalTime' },
            '0x30080136': { 'vm': '1', 'vr': 'IS', name: 'SpecifiedNumberofPulses' },
            '0x30080138': { 'vm': '1', 'vr': 'IS', name: 'DeliveredNumberofPulses' },
            '0x3008013A': { 'vm': '1', 'vr': 'DS', name: 'SpecifiedPulseRepetitionInterval' },
            '0x3008013C': { 'vm': '1', 'vr': 'DS', name: 'DeliveredPulseRepetitionInterval' },
            '0x30080140': { 'vm': '1', 'vr': 'SQ', name: 'RecordedSourceApplicatorSequence' },
            '0x30080142': { 'vm': '1', 'vr': 'IS', name: 'ReferencedSourceApplicatorNumber' },
            '0x30080150': { 'vm': '1', 'vr': 'SQ', name: 'RecordedChannelShieldSequence' },
            '0x30080152': { 'vm': '1', 'vr': 'IS', name: 'ReferencedChannelShieldNumber' },
            '0x30080160': { 'vm': '1', 'vr': 'SQ', name: 'BrachyControlPointDeliveredSequence' },
            '0x30080162': { 'vm': '1', 'vr': 'DA', name: 'SafePositionExitDate' },
            '0x30080164': { 'vm': '1', 'vr': 'TM', name: 'SafePositionExitTime' },
            '0x30080166': { 'vm': '1', 'vr': 'DA', name: 'SafePositionReturnDate' },
            '0x30080168': { 'vm': '1', 'vr': 'TM', name: 'SafePositionReturnTime' },
            '0x30080171': { 'vm': '1', 'vr': 'SQ', name: 'PulseSpecificBrachyControlPointDeliveredSequence' },
            '0x30080172': { 'vm': '1', 'vr': 'US', name: 'PulseNumber' },
            '0x30080173': { 'vm': '1', 'vr': 'SQ', name: 'BrachyPulseControlPointDeliveredSequence' },
            '0x30080200': { 'vm': '1', 'vr': 'CS', name: 'CurrentTreatmentStatus' },
            '0x30080202': { 'vm': '1', 'vr': 'ST', name: 'TreatmentStatusComment' },
            '0x30080220': { 'vm': '1', 'vr': 'SQ', name: 'FractionGroupSummarySequence' },
            '0x30080223': { 'vm': '1', 'vr': 'IS', name: 'ReferencedFractionNumber' },
            '0x30080224': { 'vm': '1', 'vr': 'CS', name: 'FractionGroupType' },
            '0x30080230': { 'vm': '1', 'vr': 'CS', name: 'BeamStopperPosition' },
            '0x30080240': { 'vm': '1', 'vr': 'SQ', name: 'FractionStatusSummarySequence' },
            '0x30080250': { 'vm': '1', 'vr': 'DA', name: 'TreatmentDate' },
            '0x30080251': { 'vm': '1', 'vr': 'TM', name: 'TreatmentTime' },
            '0x300A0002': { 'vm': '1', 'vr': 'SH', name: 'RTPlanLabel' },
            '0x300A0003': { 'vm': '1', 'vr': 'LO', name: 'RTPlanName' },
            '0x300A0004': { 'vm': '1', 'vr': 'ST', name: 'RTPlanDescription' },
            '0x300A0006': { 'vm': '1', 'vr': 'DA', name: 'RTPlanDate' },
            '0x300A0007': { 'vm': '1', 'vr': 'TM', name: 'RTPlanTime' },
            '0x300A0009': { 'vm': '1-n', 'vr': 'LO', name: 'TreatmentProtocols' },
            '0x300A000A': { 'vm': '1', 'vr': 'CS', name: 'PlanIntent' },
            '0x300A000B': { 'vm': '1-n', 'vr': 'LO', name: 'TreatmentSites' },
            '0x300A000C': { 'vm': '1', 'vr': 'CS', name: 'RTPlanGeometry' },
            '0x300A000E': { 'vm': '1', 'vr': 'ST', name: 'PrescriptionDescription' },
            '0x300A0010': { 'vm': '1', 'vr': 'SQ', name: 'DoseReferenceSequence' },
            '0x300A0012': { 'vm': '1', 'vr': 'IS', name: 'DoseReferenceNumber' },
            '0x300A0013': { 'vm': '1', 'vr': 'UI', name: 'DoseReferenceUID' },
            '0x300A0014': { 'vm': '1', 'vr': 'CS', name: 'DoseReferenceStructureType' },
            '0x300A0015': { 'vm': '1', 'vr': 'CS', name: 'NominalBeamEnergyUnit' },
            '0x300A0016': { 'vm': '1', 'vr': 'LO', name: 'DoseReferenceDescription' },
            '0x300A0018': { 'vm': '3', 'vr': 'DS', name: 'DoseReferencePointCoordinates' },
            '0x300A001A': { 'vm': '1', 'vr': 'DS', name: 'NominalPriorDose' },
            '0x300A0020': { 'vm': '1', 'vr': 'CS', name: 'DoseReferenceType' },
            '0x300A0021': { 'vm': '1', 'vr': 'DS', name: 'ConstraintWeight' },
            '0x300A0022': { 'vm': '1', 'vr': 'DS', name: 'DeliveryWarningDose' },
            '0x300A0023': { 'vm': '1', 'vr': 'DS', name: 'DeliveryMaximumDose' },
            '0x300A0025': { 'vm': '1', 'vr': 'DS', name: 'TargetMinimumDose' },
            '0x300A0026': { 'vm': '1', 'vr': 'DS', name: 'TargetPrescriptionDose' },
            '0x300A0027': { 'vm': '1', 'vr': 'DS', name: 'TargetMaximumDose' },
            '0x300A0028': { 'vm': '1', 'vr': 'DS', name: 'TargetUnderdoseVolumeFraction' },
            '0x300A002A': { 'vm': '1', 'vr': 'DS', name: 'OrganatRiskFullvolumeDose' },
            '0x300A002B': { 'vm': '1', 'vr': 'DS', name: 'OrganatRiskLimitDose' },
            '0x300A002C': { 'vm': '1', 'vr': 'DS', name: 'OrganatRiskMaximumDose' },
            '0x300A002D': { 'vm': '1', 'vr': 'DS', name: 'OrganatRiskOverdoseVolumeFraction' },
            '0x300A0040': { 'vm': '1', 'vr': 'SQ', name: 'ToleranceTableSequence' },
            '0x300A0042': { 'vm': '1', 'vr': 'IS', name: 'ToleranceTableNumber' },
            '0x300A0043': { 'vm': '1', 'vr': 'SH', name: 'ToleranceTableLabel' },
            '0x300A0044': { 'vm': '1', 'vr': 'DS', name: 'GantryAngleTolerance' },
            '0x300A0046': { 'vm': '1', 'vr': 'DS', name: 'BeamLimitingDeviceAngleTolerance' },
            '0x300A0048': { 'vm': '1', 'vr': 'SQ', name: 'BeamLimitingDeviceToleranceSequence' },
            '0x300A004A': { 'vm': '1', 'vr': 'DS', name: 'BeamLimitingDevicePositionTolerance' },
            '0x300A004B': { 'vm': '1', 'vr': 'FL', name: 'SnoutPositionTolerance' },
            '0x300A004C': { 'vm': '1', 'vr': 'DS', name: 'PatientSupportAngleTolerance' },
            '0x300A004E': { 'vm': '1', 'vr': 'DS', name: 'TableTopEccentricAngleTolerance' },
            '0x300A004F': { 'vm': '1', 'vr': 'FL', name: 'TableTopPitchAngleTolerance' },
            '0x300A0050': { 'vm': '1', 'vr': 'FL', name: 'TableTopRollAngleTolerance' },
            '0x300A0051': { 'vm': '1', 'vr': 'DS', name: 'TableTopVerticalPositionTolerance' },
            '0x300A0052': { 'vm': '1', 'vr': 'DS', name: 'TableTopLongitudinalPositionTolerance' },
            '0x300A0053': { 'vm': '1', 'vr': 'DS', name: 'TableTopLateralPositionTolerance' },
            '0x300A0055': { 'vm': '1', 'vr': 'CS', name: 'RTPlanRelationship' },
            '0x300A0070': { 'vm': '1', 'vr': 'SQ', name: 'FractionGroupSequence' },
            '0x300A0071': { 'vm': '1', 'vr': 'IS', name: 'FractionGroupNumber' },
            '0x300A0072': { 'vm': '1', 'vr': 'LO', name: 'FractionGroupDescription' },
            '0x300A0078': { 'vm': '1', 'vr': 'IS', name: 'NumberofFractionsPlanned' },
            '0x300A0079': { 'vm': '1', 'vr': 'IS', name: 'NumberofFractionPatternDigitsPerDay' },
            '0x300A007A': { 'vm': '1', 'vr': 'IS', name: 'RepeatFractionCycleLength' },
            '0x300A007B': { 'vm': '1', 'vr': 'LT', name: 'FractionPattern' },
            '0x300A0080': { 'vm': '1', 'vr': 'IS', name: 'NumberofBeams' },
            '0x300A0082': { 'vm': '3', 'vr': 'DS', name: 'BeamDoseSpecificationPoint' },
            '0x300A0083': { 'vm': '1', 'vr': 'UI', name: 'ReferencedDoseReferenceUID' },
            '0x300A0084': { 'vm': '1', 'vr': 'DS', name: 'BeamDose' },
            '0x300A0086': { 'vm': '1', 'vr': 'DS', name: 'BeamMeterset' },
            '0x300A0088': { 'vm': '1', 'vr': 'FL', name: 'BeamDosePointDepth' },
            '0x300A0089': { 'vm': '1', 'vr': 'FL', name: 'BeamDosePointEquivalentDepth' },
            '0x300A008A': { 'vm': '1', 'vr': 'FL', name: 'BeamDosePointSSD' },
            '0x300A008B': { 'vm': '1', 'vr': 'CS', name: 'BeamDoseMeaning' },
            '0x300A008C': { 'vm': '1', 'vr': 'SQ', name: 'BeamDoseVerificationControlPointSequence' },
            '0x300A008D': { 'vm': '1', 'vr': 'FL', name: 'AverageBeamDosePointDepth' },
            '0x300A008E': { 'vm': '1', 'vr': 'FL', name: 'AverageBeamDosePointEquivalentDepth' },
            '0x300A008F': { 'vm': '1', 'vr': 'FL', name: 'AverageBeamDosePointSSD' },
            '0x300A0090': { 'vm': '1', 'vr': 'CS', name: 'BeamDoseType' },
            '0x300A0091': { 'vm': '1', 'vr': 'DS', name: 'AlternateBeamDose' },
            '0x300A0092': { 'vm': '1', 'vr': 'CS', name: 'AlternateBeamDoseType' },
            '0x300A0093': { 'vm': '1', 'vr': 'CS', name: 'DepthValueAveragingFlag' },
            '0x300A0094': { 'vm': '1', 'vr': 'DS', name: 'BeamDosePointSourcetoExternalContourDistance' },
            '0x300A00A0': { 'vm': '1', 'vr': 'IS', name: 'NumberofBrachyApplicationSetups' },
            '0x300A00A2': { 'vm': '3', 'vr': 'DS', name: 'BrachyApplicationSetupDoseSpecificationPoint' },
            '0x300A00A4': { 'vm': '1', 'vr': 'DS', name: 'BrachyApplicationSetupDose' },
            '0x300A00B0': { 'vm': '1', 'vr': 'SQ', name: 'BeamSequence' },
            '0x300A00B2': { 'vm': '1', 'vr': 'SH', name: 'TreatmentMachineName' },
            '0x300A00B3': { 'vm': '1', 'vr': 'CS', name: 'PrimaryDosimeterUnit' },
            '0x300A00B4': { 'vm': '1', 'vr': 'DS', name: 'SourceAxisDistance' },
            '0x300A00B6': { 'vm': '1', 'vr': 'SQ', name: 'BeamLimitingDeviceSequence' },
            '0x300A00B8': { 'vm': '1', 'vr': 'CS', name: 'RTBeamLimitingDeviceType' },
            '0x300A00BA': { 'vm': '1', 'vr': 'DS', name: 'SourcetoBeamLimitingDeviceDistance' },
            '0x300A00BB': { 'vm': '1', 'vr': 'FL', name: 'IsocentertoBeamLimitingDeviceDistance' },
            '0x300A00BC': { 'vm': '1', 'vr': 'IS', name: 'NumberofLeafJawPairs' },
            '0x300A00BE': { 'vm': '3-n', 'vr': 'DS', name: 'LeafPositionBoundaries' },
            '0x300A00C0': { 'vm': '1', 'vr': 'IS', name: 'BeamNumber' },
            '0x300A00C2': { 'vm': '1', 'vr': 'LO', name: 'BeamName' },
            '0x300A00C3': { 'vm': '1', 'vr': 'ST', name: 'BeamDescription' },
            '0x300A00C4': { 'vm': '1', 'vr': 'CS', name: 'BeamType' },
            '0x300A00C5': { 'vm': '1', 'vr': 'FD', name: 'BeamDeliveryDurationLimit' },
            '0x300A00C6': { 'vm': '1', 'vr': 'CS', name: 'RadiationType' },
            '0x300A00C7': { 'vm': '1', 'vr': 'CS', name: 'HighDoseTechniqueType' },
            '0x300A00C8': { 'vm': '1', 'vr': 'IS', name: 'ReferenceImageNumber' },
            '0x300A00CA': { 'vm': '1', 'vr': 'SQ', name: 'PlannedVerificationImageSequence' },
            '0x300A00CC': { 'vm': '1-n', 'vr': 'LO', name: 'ImagingDeviceSpecificAcquisitionParameters' },
            '0x300A00CE': { 'vm': '1', 'vr': 'CS', name: 'TreatmentDeliveryType' },
            '0x300A00D0': { 'vm': '1', 'vr': 'IS', name: 'NumberofWedges' },
            '0x300A00D1': { 'vm': '1', 'vr': 'SQ', name: 'WedgeSequence' },
            '0x300A00D2': { 'vm': '1', 'vr': 'IS', name: 'WedgeNumber' },
            '0x300A00D3': { 'vm': '1', 'vr': 'CS', name: 'WedgeType' },
            '0x300A00D4': { 'vm': '1', 'vr': 'SH', name: 'WedgeID' },
            '0x300A00D5': { 'vm': '1', 'vr': 'IS', name: 'WedgeAngle' },
            '0x300A00D6': { 'vm': '1', 'vr': 'DS', name: 'WedgeFactor' },
            '0x300A00D7': { 'vm': '1', 'vr': 'FL', name: 'TotalWedgeTrayWaterEquivalentThickness' },
            '0x300A00D8': { 'vm': '1', 'vr': 'DS', name: 'WedgeOrientation' },
            '0x300A00D9': { 'vm': '1', 'vr': 'FL', name: 'IsocentertoWedgeTrayDistance' },
            '0x300A00DA': { 'vm': '1', 'vr': 'DS', name: 'SourcetoWedgeTrayDistance' },
            '0x300A00DB': { 'vm': '1', 'vr': 'FL', name: 'WedgeThinEdgePosition' },
            '0x300A00DC': { 'vm': '1', 'vr': 'SH', name: 'BolusID' },
            '0x300A00DD': { 'vm': '1', 'vr': 'ST', name: 'BolusDescription' },
            '0x300A00DE': { 'vm': '1', 'vr': 'DS', name: 'EffectiveWedgeAngle' },
            '0x300A00E0': { 'vm': '1', 'vr': 'IS', name: 'NumberofCompensators' },
            '0x300A00E1': { 'vm': '1', 'vr': 'SH', name: 'MaterialID' },
            '0x300A00E2': { 'vm': '1', 'vr': 'DS', name: 'TotalCompensatorTrayFactor' },
            '0x300A00E3': { 'vm': '1', 'vr': 'SQ', name: 'CompensatorSequence' },
            '0x300A00E4': { 'vm': '1', 'vr': 'IS', name: 'CompensatorNumber' },
            '0x300A00E5': { 'vm': '1', 'vr': 'SH', name: 'CompensatorID' },
            '0x300A00E6': { 'vm': '1', 'vr': 'DS', name: 'SourcetoCompensatorTrayDistance' },
            '0x300A00E7': { 'vm': '1', 'vr': 'IS', name: 'CompensatorRows' },
            '0x300A00E8': { 'vm': '1', 'vr': 'IS', name: 'CompensatorColumns' },
            '0x300A00E9': { 'vm': '2', 'vr': 'DS', name: 'CompensatorPixelSpacing' },
            '0x300A00EA': { 'vm': '2', 'vr': 'DS', name: 'CompensatorPosition' },
            '0x300A00EB': { 'vm': '1-n', 'vr': 'DS', name: 'CompensatorTransmissionData' },
            '0x300A00EC': { 'vm': '1-n', 'vr': 'DS', name: 'CompensatorThicknessData' },
            '0x300A00ED': { 'vm': '1', 'vr': 'IS', name: 'NumberofBoli' },
            '0x300A00EE': { 'vm': '1', 'vr': 'CS', name: 'CompensatorType' },
            '0x300A00EF': { 'vm': '1', 'vr': 'SH', name: 'CompensatorTrayID' },
            '0x300A00F0': { 'vm': '1', 'vr': 'IS', name: 'NumberofBlocks' },
            '0x300A00F2': { 'vm': '1', 'vr': 'DS', name: 'TotalBlockTrayFactor' },
            '0x300A00F3': { 'vm': '1', 'vr': 'FL', name: 'TotalBlockTrayWaterEquivalentThickness' },
            '0x300A00F4': { 'vm': '1', 'vr': 'SQ', name: 'BlockSequence' },
            '0x300A00F5': { 'vm': '1', 'vr': 'SH', name: 'BlockTrayID' },
            '0x300A00F6': { 'vm': '1', 'vr': 'DS', name: 'SourcetoBlockTrayDistance' },
            '0x300A00F7': { 'vm': '1', 'vr': 'FL', name: 'IsocentertoBlockTrayDistance' },
            '0x300A00F8': { 'vm': '1', 'vr': 'CS', name: 'BlockType' },
            '0x300A00F9': { 'vm': '1', 'vr': 'LO', name: 'AccessoryCode' },
            '0x300A00FA': { 'vm': '1', 'vr': 'CS', name: 'BlockDivergence' },
            '0x300A00FB': { 'vm': '1', 'vr': 'CS', name: 'BlockMountingPosition' },
            '0x300A00FC': { 'vm': '1', 'vr': 'IS', name: 'BlockNumber' },
            '0x300A00FE': { 'vm': '1', 'vr': 'LO', name: 'BlockName' },
            '0x300A0100': { 'vm': '1', 'vr': 'DS', name: 'BlockThickness' },
            '0x300A0102': { 'vm': '1', 'vr': 'DS', name: 'BlockTransmission' },
            '0x300A0104': { 'vm': '1', 'vr': 'IS', name: 'BlockNumberofPoints' },
            '0x300A0106': { 'vm': '2-2n', 'vr': 'DS', name: 'BlockData' },
            '0x300A0107': { 'vm': '1', 'vr': 'SQ', name: 'ApplicatorSequence' },
            '0x300A0108': { 'vm': '1', 'vr': 'SH', name: 'ApplicatorID' },
            '0x300A0109': { 'vm': '1', 'vr': 'CS', name: 'ApplicatorType' },
            '0x300A010A': { 'vm': '1', 'vr': 'LO', name: 'ApplicatorDescription' },
            '0x300A010C': { 'vm': '1', 'vr': 'DS', name: 'CumulativeDoseReferenceCoefficient' },
            '0x300A010E': { 'vm': '1', 'vr': 'DS', name: 'FinalCumulativeMetersetWeight' },
            '0x300A0110': { 'vm': '1', 'vr': 'IS', name: 'NumberofControlPoints' },
            '0x300A0111': { 'vm': '1', 'vr': 'SQ', name: 'ControlPointSequence' },
            '0x300A0112': { 'vm': '1', 'vr': 'IS', name: 'ControlPointIndex' },
            '0x300A0114': { 'vm': '1', 'vr': 'DS', name: 'NominalBeamEnergy' },
            '0x300A0115': { 'vm': '1', 'vr': 'DS', name: 'DoseRateSet' },
            '0x300A0116': { 'vm': '1', 'vr': 'SQ', name: 'WedgePositionSequence' },
            '0x300A0118': { 'vm': '1', 'vr': 'CS', name: 'WedgePosition' },
            '0x300A011A': { 'vm': '1', 'vr': 'SQ', name: 'BeamLimitingDevicePositionSequence' },
            '0x300A011C': { 'vm': '2-2n', 'vr': 'DS', name: 'LeafJawPositions' },
            '0x300A011E': { 'vm': '1', 'vr': 'DS', name: 'GantryAngle' },
            '0x300A011F': { 'vm': '1', 'vr': 'CS', name: 'GantryRotationDirection' },
            '0x300A0120': { 'vm': '1', 'vr': 'DS', name: 'BeamLimitingDeviceAngle' },
            '0x300A0121': { 'vm': '1', 'vr': 'CS', name: 'BeamLimitingDeviceRotationDirection' },
            '0x300A0122': { 'vm': '1', 'vr': 'DS', name: 'PatientSupportAngle' },
            '0x300A0123': { 'vm': '1', 'vr': 'CS', name: 'PatientSupportRotationDirection' },
            '0x300A0124': { 'vm': '1', 'vr': 'DS', name: 'TableTopEccentricAxisDistance' },
            '0x300A0125': { 'vm': '1', 'vr': 'DS', name: 'TableTopEccentricAngle' },
            '0x300A0126': { 'vm': '1', 'vr': 'CS', name: 'TableTopEccentricRotationDirection' },
            '0x300A0128': { 'vm': '1', 'vr': 'DS', name: 'TableTopVerticalPosition' },
            '0x300A0129': { 'vm': '1', 'vr': 'DS', name: 'TableTopLongitudinalPosition' },
            '0x300A012A': { 'vm': '1', 'vr': 'DS', name: 'TableTopLateralPosition' },
            '0x300A012C': { 'vm': '3', 'vr': 'DS', name: 'IsocenterPosition' },
            '0x300A012E': { 'vm': '3', 'vr': 'DS', name: 'SurfaceEntryPoint' },
            '0x300A0130': { 'vm': '1', 'vr': 'DS', name: 'SourcetoSurfaceDistance' },
            '0x300A0131': { 'vm': '1', 'vr': 'FL', name: 'AverageBeamDosePointSourcetoExternalContourDistance' },
            '0x300A0132': { 'vm': '1', 'vr': 'FL', name: 'SourcetoExternalContourDistance' },
            '0x300A0133': { 'vm': '3', 'vr': 'FL', name: 'ExternalContourEntryPoint' },
            '0x300A0134': { 'vm': '1', 'vr': 'DS', name: 'CumulativeMetersetWeight' },
            '0x300A0140': { 'vm': '1', 'vr': 'FL', name: 'TableTopPitchAngle' },
            '0x300A0142': { 'vm': '1', 'vr': 'CS', name: 'TableTopPitchRotationDirection' },
            '0x300A0144': { 'vm': '1', 'vr': 'FL', name: 'TableTopRollAngle' },
            '0x300A0146': { 'vm': '1', 'vr': 'CS', name: 'TableTopRollRotationDirection' },
            '0x300A0148': { 'vm': '1', 'vr': 'FL', name: 'HeadFixationAngle' },
            '0x300A014A': { 'vm': '1', 'vr': 'FL', name: 'GantryPitchAngle' },
            '0x300A014C': { 'vm': '1', 'vr': 'CS', name: 'GantryPitchRotationDirection' },
            '0x300A014E': { 'vm': '1', 'vr': 'FL', name: 'GantryPitchAngleTolerance' },
            '0x300A0150': { 'vm': '1', 'vr': 'CS', name: 'FixationEye' },
            '0x300A0151': { 'vm': '1', 'vr': 'DS', name: 'ChairHeadFramePosition' },
            '0x300A0152': { 'vm': '1', 'vr': 'DS', name: 'HeadFixationAngleTolerance' },
            '0x300A0153': { 'vm': '1', 'vr': 'DS', name: 'ChairHeadFramePositionTolerance' },
            '0x300A0154': { 'vm': '1', 'vr': 'DS', name: 'FixationLightAzimuthalAngleTolerance' },
            '0x300A0155': { 'vm': '1', 'vr': 'DS', name: 'FixationLightPolarAngleTolerance' },
            '0x300A0180': { 'vm': '1', 'vr': 'SQ', name: 'PatientSetupSequence' },
            '0x300A0182': { 'vm': '1', 'vr': 'IS', name: 'PatientSetupNumber' },
            '0x300A0183': { 'vm': '1', 'vr': 'LO', name: 'PatientSetupLabel' },
            '0x300A0184': { 'vm': '1', 'vr': 'LO', name: 'PatientAdditionalPosition' },
            '0x300A0190': { 'vm': '1', 'vr': 'SQ', name: 'FixationDeviceSequence' },
            '0x300A0192': { 'vm': '1', 'vr': 'CS', name: 'FixationDeviceType' },
            '0x300A0194': { 'vm': '1', 'vr': 'SH', name: 'FixationDeviceLabel' },
            '0x300A0196': { 'vm': '1', 'vr': 'ST', name: 'FixationDeviceDescription' },
            '0x300A0198': { 'vm': '1', 'vr': 'SH', name: 'FixationDevicePosition' },
            '0x300A0199': { 'vm': '1', 'vr': 'FL', name: 'FixationDevicePitchAngle' },
            '0x300A019A': { 'vm': '1', 'vr': 'FL', name: 'FixationDeviceRollAngle' },
            '0x300A01A0': { 'vm': '1', 'vr': 'SQ', name: 'ShieldingDeviceSequence' },
            '0x300A01A2': { 'vm': '1', 'vr': 'CS', name: 'ShieldingDeviceType' },
            '0x300A01A4': { 'vm': '1', 'vr': 'SH', name: 'ShieldingDeviceLabel' },
            '0x300A01A6': { 'vm': '1', 'vr': 'ST', name: 'ShieldingDeviceDescription' },
            '0x300A01A8': { 'vm': '1', 'vr': 'SH', name: 'ShieldingDevicePosition' },
            '0x300A01B0': { 'vm': '1', 'vr': 'CS', name: 'SetupTechnique' },
            '0x300A01B2': { 'vm': '1', 'vr': 'ST', name: 'SetupTechniqueDescription' },
            '0x300A01B4': { 'vm': '1', 'vr': 'SQ', name: 'SetupDeviceSequence' },
            '0x300A01B6': { 'vm': '1', 'vr': 'CS', name: 'SetupDeviceType' },
            '0x300A01B8': { 'vm': '1', 'vr': 'SH', name: 'SetupDeviceLabel' },
            '0x300A01BA': { 'vm': '1', 'vr': 'ST', name: 'SetupDeviceDescription' },
            '0x300A01BC': { 'vm': '1', 'vr': 'DS', name: 'SetupDeviceParameter' },
            '0x300A01D0': { 'vm': '1', 'vr': 'ST', name: 'SetupReferenceDescription' },
            '0x300A01D2': { 'vm': '1', 'vr': 'DS', name: 'TableTopVerticalSetupDisplacement' },
            '0x300A01D4': { 'vm': '1', 'vr': 'DS', name: 'TableTopLongitudinalSetupDisplacement' },
            '0x300A01D6': { 'vm': '1', 'vr': 'DS', name: 'TableTopLateralSetupDisplacement' },
            '0x300A0200': { 'vm': '1', 'vr': 'CS', name: 'BrachyTreatmentTechnique' },
            '0x300A0202': { 'vm': '1', 'vr': 'CS', name: 'BrachyTreatmentType' },
            '0x300A0206': { 'vm': '1', 'vr': 'SQ', name: 'TreatmentMachineSequence' },
            '0x300A0210': { 'vm': '1', 'vr': 'SQ', name: 'SourceSequence' },
            '0x300A0212': { 'vm': '1', 'vr': 'IS', name: 'SourceNumber' },
            '0x300A0214': { 'vm': '1', 'vr': 'CS', name: 'SourceType' },
            '0x300A0216': { 'vm': '1', 'vr': 'LO', name: 'SourceManufacturer' },
            '0x300A0218': { 'vm': '1', 'vr': 'DS', name: 'ActiveSourceDiameter' },
            '0x300A021A': { 'vm': '1', 'vr': 'DS', name: 'ActiveSourceLength' },
            '0x300A021B': { 'vm': '1', 'vr': 'SH', name: 'SourceModelID' },
            '0x300A021C': { 'vm': '1', 'vr': 'LO', name: 'SourceDescription' },
            '0x300A0222': { 'vm': '1', 'vr': 'DS', name: 'SourceEncapsulationNominalThickness' },
            '0x300A0224': { 'vm': '1', 'vr': 'DS', name: 'SourceEncapsulationNominalTransmission' },
            '0x300A0226': { 'vm': '1', 'vr': 'LO', name: 'SourceIsotopeName' },
            '0x300A0228': { 'vm': '1', 'vr': 'DS', name: 'SourceIsotopeHalfLife' },
            '0x300A0229': { 'vm': '1', 'vr': 'CS', name: 'SourceStrengthUnits' },
            '0x300A022A': { 'vm': '1', 'vr': 'DS', name: 'ReferenceAirKermaRate' },
            '0x300A022B': { 'vm': '1', 'vr': 'DS', name: 'SourceStrength' },
            '0x300A022C': { 'vm': '1', 'vr': 'DA', name: 'SourceStrengthReferenceDate' },
            '0x300A022E': { 'vm': '1', 'vr': 'TM', name: 'SourceStrengthReferenceTime' },
            '0x300A0230': { 'vm': '1', 'vr': 'SQ', name: 'ApplicationSetupSequence' },
            '0x300A0232': { 'vm': '1', 'vr': 'CS', name: 'ApplicationSetupType' },
            '0x300A0234': { 'vm': '1', 'vr': 'IS', name: 'ApplicationSetupNumber' },
            '0x300A0236': { 'vm': '1', 'vr': 'LO', name: 'ApplicationSetupName' },
            '0x300A0238': { 'vm': '1', 'vr': 'LO', name: 'ApplicationSetupManufacturer' },
            '0x300A0240': { 'vm': '1', 'vr': 'IS', name: 'TemplateNumber' },
            '0x300A0242': { 'vm': '1', 'vr': 'SH', name: 'TemplateType' },
            '0x300A0244': { 'vm': '1', 'vr': 'LO', name: 'TemplateName' },
            '0x300A0250': { 'vm': '1', 'vr': 'DS', name: 'TotalReferenceAirKerma' },
            '0x300A0260': { 'vm': '1', 'vr': 'SQ', name: 'BrachyAccessoryDeviceSequence' },
            '0x300A0262': { 'vm': '1', 'vr': 'IS', name: 'BrachyAccessoryDeviceNumber' },
            '0x300A0263': { 'vm': '1', 'vr': 'SH', name: 'BrachyAccessoryDeviceID' },
            '0x300A0264': { 'vm': '1', 'vr': 'CS', name: 'BrachyAccessoryDeviceType' },
            '0x300A0266': { 'vm': '1', 'vr': 'LO', name: 'BrachyAccessoryDeviceName' },
            '0x300A026A': { 'vm': '1', 'vr': 'DS', name: 'BrachyAccessoryDeviceNominalThickness' },
            '0x300A026C': { 'vm': '1', 'vr': 'DS', name: 'BrachyAccessoryDeviceNominalTransmission' },
            '0x300A0271': { 'vm': '1', 'vr': 'DS', name: 'ChannelEffectiveLength' },
            '0x300A0272': { 'vm': '1', 'vr': 'DS', name: 'ChannelInnerLength' },
            '0x300A0273': { 'vm': '1', 'vr': 'SH', name: 'AfterloaderChannelID' },
            '0x300A0274': { 'vm': '1', 'vr': 'DS', name: 'SourceApplicatorTipLength' },
            '0x300A0280': { 'vm': '1', 'vr': 'SQ', name: 'ChannelSequence' },
            '0x300A0282': { 'vm': '1', 'vr': 'IS', name: 'ChannelNumber' },
            '0x300A0284': { 'vm': '1', 'vr': 'DS', name: 'ChannelLength' },
            '0x300A0286': { 'vm': '1', 'vr': 'DS', name: 'ChannelTotalTime' },
            '0x300A0288': { 'vm': '1', 'vr': 'CS', name: 'SourceMovementType' },
            '0x300A028A': { 'vm': '1', 'vr': 'IS', name: 'NumberofPulses' },
            '0x300A028C': { 'vm': '1', 'vr': 'DS', name: 'PulseRepetitionInterval' },
            '0x300A0290': { 'vm': '1', 'vr': 'IS', name: 'SourceApplicatorNumber' },
            '0x300A0291': { 'vm': '1', 'vr': 'SH', name: 'SourceApplicatorID' },
            '0x300A0292': { 'vm': '1', 'vr': 'CS', name: 'SourceApplicatorType' },
            '0x300A0294': { 'vm': '1', 'vr': 'LO', name: 'SourceApplicatorName' },
            '0x300A0296': { 'vm': '1', 'vr': 'DS', name: 'SourceApplicatorLength' },
            '0x300A0298': { 'vm': '1', 'vr': 'LO', name: 'SourceApplicatorManufacturer' },
            '0x300A029C': { 'vm': '1', 'vr': 'DS', name: 'SourceApplicatorWallNominalThickness' },
            '0x300A029E': { 'vm': '1', 'vr': 'DS', name: 'SourceApplicatorWallNominalTransmission' },
            '0x300A02A0': { 'vm': '1', 'vr': 'DS', name: 'SourceApplicatorStepSize' },
            '0x300A02A2': { 'vm': '1', 'vr': 'IS', name: 'TransferTubeNumber' },
            '0x300A02A4': { 'vm': '1', 'vr': 'DS', name: 'TransferTubeLength' },
            '0x300A02B0': { 'vm': '1', 'vr': 'SQ', name: 'ChannelShieldSequence' },
            '0x300A02B2': { 'vm': '1', 'vr': 'IS', name: 'ChannelShieldNumber' },
            '0x300A02B3': { 'vm': '1', 'vr': 'SH', name: 'ChannelShieldID' },
            '0x300A02B4': { 'vm': '1', 'vr': 'LO', name: 'ChannelShieldName' },
            '0x300A02B8': { 'vm': '1', 'vr': 'DS', name: 'ChannelShieldNominalThickness' },
            '0x300A02BA': { 'vm': '1', 'vr': 'DS', name: 'ChannelShieldNominalTransmission' },
            '0x300A02C8': { 'vm': '1', 'vr': 'DS', name: 'FinalCumulativeTimeWeight' },
            '0x300A02D0': { 'vm': '1', 'vr': 'SQ', name: 'BrachyControlPointSequence' },
            '0x300A02D2': { 'vm': '1', 'vr': 'DS', name: 'ControlPointRelativePosition' },
            '0x300A02D4': { 'vm': '3', 'vr': 'DS', name: 'ControlPoint3DPosition' },
            '0x300A02D6': { 'vm': '1', 'vr': 'DS', name: 'CumulativeTimeWeight' },
            '0x300A02E0': { 'vm': '1', 'vr': 'CS', name: 'CompensatorDivergence' },
            '0x300A02E1': { 'vm': '1', 'vr': 'CS', name: 'CompensatorMountingPosition' },
            '0x300A02E2': { 'vm': '1-n', 'vr': 'DS', name: 'SourcetoCompensatorDistance' },
            '0x300A02E3': { 'vm': '1', 'vr': 'FL', name: 'TotalCompensatorTrayWaterEquivalentThickness' },
            '0x300A02E4': { 'vm': '1', 'vr': 'FL', name: 'IsocentertoCompensatorTrayDistance' },
            '0x300A02E5': { 'vm': '1', 'vr': 'FL', name: 'CompensatorColumnOffset' },
            '0x300A02E6': { 'vm': '1-n', 'vr': 'FL', name: 'IsocentertoCompensatorDistances' },
            '0x300A02E7': { 'vm': '1', 'vr': 'FL', name: 'CompensatorRelativeStoppingPowerRatio' },
            '0x300A02E8': { 'vm': '1', 'vr': 'FL', name: 'CompensatorMillingToolDiameter' },
            '0x300A02EA': { 'vm': '1', 'vr': 'SQ', name: 'IonRangeCompensatorSequence' },
            '0x300A02EB': { 'vm': '1', 'vr': 'LT', name: 'CompensatorDescription' },
            '0x300A0302': { 'vm': '1', 'vr': 'IS', name: 'RadiationMassNumber' },
            '0x300A0304': { 'vm': '1', 'vr': 'IS', name: 'RadiationAtomicNumber' },
            '0x300A0306': { 'vm': '1', 'vr': 'SS', name: 'RadiationChargeState' },
            '0x300A0308': { 'vm': '1', 'vr': 'CS', name: 'ScanMode' },
            '0x300A0309': { 'vm': '1', 'vr': 'CS', name: 'ModulatedScanModeType' },
            '0x300A030A': { 'vm': '2', 'vr': 'FL', name: 'VirtualSourceAxisDistances' },
            '0x300A030C': { 'vm': '1', 'vr': 'SQ', name: 'SnoutSequence' },
            '0x300A030D': { 'vm': '1', 'vr': 'FL', name: 'SnoutPosition' },
            '0x300A030F': { 'vm': '1', 'vr': 'SH', name: 'SnoutID' },
            '0x300A0312': { 'vm': '1', 'vr': 'IS', name: 'NumberofRangeShifters' },
            '0x300A0314': { 'vm': '1', 'vr': 'SQ', name: 'RangeShifterSequence' },
            '0x300A0316': { 'vm': '1', 'vr': 'IS', name: 'RangeShifterNumber' },
            '0x300A0318': { 'vm': '1', 'vr': 'SH', name: 'RangeShifterID' },
            '0x300A0320': { 'vm': '1', 'vr': 'CS', name: 'RangeShifterType' },
            '0x300A0322': { 'vm': '1', 'vr': 'LO', name: 'RangeShifterDescription' },
            '0x300A0330': { 'vm': '1', 'vr': 'IS', name: 'NumberofLateralSpreadingDevices' },
            '0x300A0332': { 'vm': '1', 'vr': 'SQ', name: 'LateralSpreadingDeviceSequence' },
            '0x300A0334': { 'vm': '1', 'vr': 'IS', name: 'LateralSpreadingDeviceNumber' },
            '0x300A0336': { 'vm': '1', 'vr': 'SH', name: 'LateralSpreadingDeviceID' },
            '0x300A0338': { 'vm': '1', 'vr': 'CS', name: 'LateralSpreadingDeviceType' },
            '0x300A033A': { 'vm': '1', 'vr': 'LO', name: 'LateralSpreadingDeviceDescription' },
            '0x300A033C': { 'vm': '1', 'vr': 'FL', name: 'LateralSpreadingDeviceWaterEquivalentThickness' },
            '0x300A0340': { 'vm': '1', 'vr': 'IS', name: 'NumberofRangeModulators' },
            '0x300A0342': { 'vm': '1', 'vr': 'SQ', name: 'RangeModulatorSequence' },
            '0x300A0344': { 'vm': '1', 'vr': 'IS', name: 'RangeModulatorNumber' },
            '0x300A0346': { 'vm': '1', 'vr': 'SH', name: 'RangeModulatorID' },
            '0x300A0348': { 'vm': '1', 'vr': 'CS', name: 'RangeModulatorType' },
            '0x300A034A': { 'vm': '1', 'vr': 'LO', name: 'RangeModulatorDescription' },
            '0x300A034C': { 'vm': '1', 'vr': 'SH', name: 'BeamCurrentModulationID' },
            '0x300A0350': { 'vm': '1', 'vr': 'CS', name: 'PatientSupportType' },
            '0x300A0352': { 'vm': '1', 'vr': 'SH', name: 'PatientSupportID' },
            '0x300A0354': { 'vm': '1', 'vr': 'LO', name: 'PatientSupportAccessoryCode' },
            '0x300A0355': { 'vm': '1', 'vr': 'LO', name: 'TrayAccessoryCode' },
            '0x300A0356': { 'vm': '1', 'vr': 'FL', name: 'FixationLightAzimuthalAngle' },
            '0x300A0358': { 'vm': '1', 'vr': 'FL', name: 'FixationLightPolarAngle' },
            '0x300A035A': { 'vm': '1', 'vr': 'FL', name: 'MetersetRate' },
            '0x300A0360': { 'vm': '1', 'vr': 'SQ', name: 'RangeShifterSettingsSequence' },
            '0x300A0362': { 'vm': '1', 'vr': 'LO', name: 'RangeShifterSetting' },
            '0x300A0364': { 'vm': '1', 'vr': 'FL', name: 'IsocentertoRangeShifterDistance' },
            '0x300A0366': { 'vm': '1', 'vr': 'FL', name: 'RangeShifterWaterEquivalentThickness' },
            '0x300A0370': { 'vm': '1', 'vr': 'SQ', name: 'LateralSpreadingDeviceSettingsSequence' },
            '0x300A0372': { 'vm': '1', 'vr': 'LO', name: 'LateralSpreadingDeviceSetting' },
            '0x300A0374': { 'vm': '1', 'vr': 'FL', name: 'IsocentertoLateralSpreadingDeviceDistance' },
            '0x300A0380': { 'vm': '1', 'vr': 'SQ', name: 'RangeModulatorSettingsSequence' },
            '0x300A0382': { 'vm': '1', 'vr': 'FL', name: 'RangeModulatorGatingStartValue' },
            '0x300A0384': { 'vm': '1', 'vr': 'FL', name: 'RangeModulatorGatingStopValue' },
            '0x300A0386': { 'vm': '1', 'vr': 'FL', name: 'RangeModulatorGatingStartWaterEquivalentThickness' },
            '0x300A0388': { 'vm': '1', 'vr': 'FL', name: 'RangeModulatorGatingStopWaterEquivalentThickness' },
            '0x300A038A': { 'vm': '1', 'vr': 'FL', name: 'IsocentertoRangeModulatorDistance' },
            '0x300A038F': { 'vm': '1-n', 'vr': 'FL', name: 'ScanSpotTimeOffset' },
            '0x300A0390': { 'vm': '1', 'vr': 'SH', name: 'ScanSpotTuneID' },
            '0x300A0391': { 'vm': '1-n', 'vr': 'IS', name: 'ScanSpotPrescribedIndices' },
            '0x300A0392': { 'vm': '1', 'vr': 'IS', name: 'NumberofScanSpotPositions' },
            '0x300A0393': { 'vm': '1', 'vr': 'CS', name: 'ScanSpotReordered' },
            '0x300A0394': { 'vm': '1-n', 'vr': 'FL', name: 'ScanSpotPositionMap' },
            '0x300A0395': { 'vm': '1', 'vr': 'CS', name: 'ScanSpotReorderingAllowed' },
            '0x300A0396': { 'vm': '1-n', 'vr': 'FL', name: 'ScanSpotMetersetWeights' },
            '0x300A0398': { 'vm': '2', 'vr': 'FL', name: 'ScanningSpotSize' },
            '0x300A039A': { 'vm': '1', 'vr': 'IS', name: 'NumberofPaintings' },
            '0x300A03A0': { 'vm': '1', 'vr': 'SQ', name: 'IonToleranceTableSequence' },
            '0x300A03A2': { 'vm': '1', 'vr': 'SQ', name: 'IonBeamSequence' },
            '0x300A03A4': { 'vm': '1', 'vr': 'SQ', name: 'IonBeamLimitingDeviceSequence' },
            '0x300A03A6': { 'vm': '1', 'vr': 'SQ', name: 'IonBlockSequence' },
            '0x300A03A8': { 'vm': '1', 'vr': 'SQ', name: 'IonControlPointSequence' },
            '0x300A03AA': { 'vm': '1', 'vr': 'SQ', name: 'IonWedgeSequence' },
            '0x300A03AC': { 'vm': '1', 'vr': 'SQ', name: 'IonWedgePositionSequence' },
            '0x300A0401': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedSetupImageSequence' },
            '0x300A0402': { 'vm': '1', 'vr': 'ST', name: 'SetupImageComment' },
            '0x300A0410': { 'vm': '1', 'vr': 'SQ', name: 'MotionSynchronizationSequence' },
            '0x300A0412': { 'vm': '3', 'vr': 'FL', name: 'ControlPointOrientation' },
            '0x300A0420': { 'vm': '1', 'vr': 'SQ', name: 'GeneralAccessorySequence' },
            '0x300A0421': { 'vm': '1', 'vr': 'SH', name: 'GeneralAccessoryID' },
            '0x300A0422': { 'vm': '1', 'vr': 'ST', name: 'GeneralAccessoryDescription' },
            '0x300A0423': { 'vm': '1', 'vr': 'CS', name: 'GeneralAccessoryType' },
            '0x300A0424': { 'vm': '1', 'vr': 'IS', name: 'GeneralAccessoryNumber' },
            '0x300A0425': { 'vm': '1', 'vr': 'FL', name: 'SourcetoGeneralAccessoryDistance' },
            '0x300A0431': { 'vm': '1', 'vr': 'SQ', name: 'ApplicatorGeometrySequence' },
            '0x300A0432': { 'vm': '1', 'vr': 'CS', name: 'ApplicatorApertureShape' },
            '0x300A0433': { 'vm': '1', 'vr': 'FL', name: 'ApplicatorOpening' },
            '0x300A0434': { 'vm': '1', 'vr': 'FL', name: 'ApplicatorOpeningX' },
            '0x300A0435': { 'vm': '1', 'vr': 'FL', name: 'ApplicatorOpeningY' },
            '0x300A0436': { 'vm': '1', 'vr': 'FL', name: 'SourcetoApplicatorMountingPositionDistance' },
            '0x300A0440': { 'vm': '1', 'vr': 'IS', name: 'NumberofBlockSlabItems' },
            '0x300A0441': { 'vm': '1', 'vr': 'SQ', name: 'BlockSlabSequence' },
            '0x300A0442': { 'vm': '1', 'vr': 'DS', name: 'BlockSlabThickness' },
            '0x300A0443': { 'vm': '1', 'vr': 'US', name: 'BlockSlabNumber' },
            '0x300A0450': { 'vm': '1', 'vr': 'SQ', name: 'DeviceMotionControlSequence' },
            '0x300A0451': { 'vm': '1', 'vr': 'CS', name: 'DeviceMotionExecutionMode' },
            '0x300A0452': { 'vm': '1', 'vr': 'CS', name: 'DeviceMotionObservationMode' },
            '0x300A0453': { 'vm': '1', 'vr': 'SQ', name: 'DeviceMotionParameterCodeSequence' },
            '0x300A0501': { 'vm': '1', 'vr': 'FL', name: 'DistalDepthFraction' },
            '0x300A0502': { 'vm': '1', 'vr': 'FL', name: 'DistalDepth' },
            '0x300A0503': { 'vm': '2', 'vr': 'FL', name: 'NominalRangeModulationFractions' },
            '0x300A0504': { 'vm': '2', 'vr': 'FL', name: 'NominalRangeModulatedRegionDepths' },
            '0x300A0505': { 'vm': '1', 'vr': 'SQ', name: 'DepthDoseParametersSequence' },
            '0x300A0506': { 'vm': '1', 'vr': 'SQ', name: 'DeliveredDepthDoseParametersSequence' },
            '0x300A0507': { 'vm': '1', 'vr': 'FL', name: 'DeliveredDistalDepthFraction' },
            '0x300A0508': { 'vm': '1', 'vr': 'FL', name: 'DeliveredDistalDepth' },
            '0x300A0509': { 'vm': '2', 'vr': 'FL', name: 'DeliveredNominalRangeModulationFractions' },
            '0x300A0510': { 'vm': '2', 'vr': 'FL', name: 'DeliveredNominalRangeModulatedRegionDepths' },
            '0x300A0511': { 'vm': '1', 'vr': 'CS', name: 'DeliveredReferenceDoseDefinition' },
            '0x300A0512': { 'vm': '1', 'vr': 'CS', name: 'ReferenceDoseDefinition' },
            '0x300C0002': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedRTPlanSequence' },
            '0x300C0004': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedBeamSequence' },
            '0x300C0006': { 'vm': '1', 'vr': 'IS', name: 'ReferencedBeamNumber' },
            '0x300C0007': { 'vm': '1', 'vr': 'IS', name: 'ReferencedReferenceImageNumber' },
            '0x300C0008': { 'vm': '1', 'vr': 'DS', name: 'StartCumulativeMetersetWeight' },
            '0x300C0009': { 'vm': '1', 'vr': 'DS', name: 'EndCumulativeMetersetWeight' },
            '0x300C000A': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedBrachyApplicationSetupSequence' },
            '0x300C000C': { 'vm': '1', 'vr': 'IS', name: 'ReferencedBrachyApplicationSetupNumber' },
            '0x300C000E': { 'vm': '1', 'vr': 'IS', name: 'ReferencedSourceNumber' },
            '0x300C0020': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedFractionGroupSequence' },
            '0x300C0022': { 'vm': '1', 'vr': 'IS', name: 'ReferencedFractionGroupNumber' },
            '0x300C0040': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedVerificationImageSequence' },
            '0x300C0042': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedReferenceImageSequence' },
            '0x300C0050': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedDoseReferenceSequence' },
            '0x300C0051': { 'vm': '1', 'vr': 'IS', name: 'ReferencedDoseReferenceNumber' },
            '0x300C0055': { 'vm': '1', 'vr': 'SQ', name: 'BrachyReferencedDoseReferenceSequence' },
            '0x300C0060': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedStructureSetSequence' },
            '0x300C006A': { 'vm': '1', 'vr': 'IS', name: 'ReferencedPatientSetupNumber' },
            '0x300C0080': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedDoseSequence' },
            '0x300C00A0': { 'vm': '1', 'vr': 'IS', name: 'ReferencedToleranceTableNumber' },
            '0x300C00B0': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedBolusSequence' },
            '0x300C00C0': { 'vm': '1', 'vr': 'IS', name: 'ReferencedWedgeNumber' },
            '0x300C00D0': { 'vm': '1', 'vr': 'IS', name: 'ReferencedCompensatorNumber' },
            '0x300C00E0': { 'vm': '1', 'vr': 'IS', name: 'ReferencedBlockNumber' },
            '0x300C00F0': { 'vm': '1', 'vr': 'IS', name: 'ReferencedControlPointIndex' },
            '0x300C00F2': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedControlPointSequence' },
            '0x300C00F4': { 'vm': '1', 'vr': 'IS', name: 'ReferencedStartControlPointIndex' },
            '0x300C00F6': { 'vm': '1', 'vr': 'IS', name: 'ReferencedStopControlPointIndex' },
            '0x300C0100': { 'vm': '1', 'vr': 'IS', name: 'ReferencedRangeShifterNumber' },
            '0x300C0102': { 'vm': '1', 'vr': 'IS', name: 'ReferencedLateralSpreadingDeviceNumber' },
            '0x300C0104': { 'vm': '1', 'vr': 'IS', name: 'ReferencedRangeModulatorNumber' },
            '0x300C0111': { 'vm': '1', 'vr': 'SQ', name: 'OmittedBeamTaskSequence' },
            '0x300C0112': { 'vm': '1', 'vr': 'CS', name: 'ReasonforOmission' },
            '0x300C0113': { 'vm': '1', 'vr': 'LO', name: 'ReasonforOmissionDescription' },
            '0x300E0002': { 'vm': '1', 'vr': 'CS', name: 'ApprovalStatus' },
            '0x300E0004': { 'vm': '1', 'vr': 'DA', name: 'ReviewDate' },
            '0x300E0005': { 'vm': '1', 'vr': 'TM', name: 'ReviewTime' },
            '0x300E0008': { 'vm': '1', 'vr': 'PN', name: 'ReviewerName' },
            '0x30100001': { 'vm': '1', 'vr': 'SQ', name: 'RadiobiologicalDoseEffectSequence' },
            '0x30100002': { 'vm': '1', 'vr': 'CS', name: 'RadiobiologicalDoseEffectFlag' },
            '0x30100003': { 'vm': '1', 'vr': 'SQ', name: 'EffectiveDoseCalculationMethodCategoryCodeSequence' },
            '0x30100004': { 'vm': '1', 'vr': 'SQ', name: 'EffectiveDoseCalculationMethodCodeSequence' },
            '0x30100005': { 'vm': '1', 'vr': 'LO', name: 'EffectiveDoseCalculationMethodDescription' },
            '0x30100006': { 'vm': '1', 'vr': 'UI', name: 'ConceptualVolumeUID' },
            '0x30100007': { 'vm': '1', 'vr': 'SQ', name: 'OriginatingSOPInstanceReferenceSequence' },
            '0x30100008': { 'vm': '1', 'vr': 'SQ', name: 'ConceptualVolumeConstituentSequence' },
            '0x30100009': { 'vm': '1', 'vr': 'SQ', name: 'EquivalentConceptualVolumeInstanceReferenceSequence' },
            '0x3010000A': { 'vm': '1', 'vr': 'SQ', name: 'EquivalentConceptualVolumesSequence' },
            '0x3010000B': { 'vm': '1', 'vr': 'UI', name: 'ReferencedConceptualVolumeUID' },
            '0x3010000C': { 'vm': '1', 'vr': 'UT', name: 'ConceptualVolumeCombinationExpression' },
            '0x3010000D': { 'vm': '1', 'vr': 'US', name: 'ConceptualVolumeConstituentIndex' },
            '0x3010000E': { 'vm': '1', 'vr': 'CS', name: 'ConceptualVolumeCombinationFlag' },
            '0x3010000F': { 'vm': '1', 'vr': 'ST', name: 'ConceptualVolumeCombinationDescription' },
            '0x30100010': { 'vm': '1', 'vr': 'CS', name: 'ConceptualVolumeSegmentationDefinedFlag' },
            '0x30100011': { 'vm': '1', 'vr': 'SQ', name: 'ConceptualVolumeSegmentationReferenceSequence' },
            '0x30100012': { 'vm': '1', 'vr': 'SQ', name: 'ConceptualVolumeConstituentSegmentationReferenceSequence' },
            '0x30100013': { 'vm': '1', 'vr': 'UI', name: 'ConstituentConceptualVolumeUID' },
            '0x30100014': { 'vm': '1', 'vr': 'SQ', name: 'DerivationConceptualVolumeSequence' },
            '0x30100015': { 'vm': '1', 'vr': 'UI', name: 'SourceConceptualVolumeUID' },
            '0x30100016': { 'vm': '1', 'vr': 'SQ', name: 'ConceptualVolumeDerivationAlgorithmSequence' },
            '0x30100017': { 'vm': '1', 'vr': 'ST', name: 'ConceptualVolumeDescription' },
            '0x30100018': { 'vm': '1', 'vr': 'SQ', name: 'SourceConceptualVolumeSequence' },
            '0x30100019': { 'vm': '1', 'vr': 'SQ', name: 'AuthorIdentificationSequence' },
            '0x3010001A': { 'vm': '1', 'vr': 'LO', name: 'ManufacturersModelVersion' },
            '0x3010001B': { 'vm': '1', 'vr': 'UC', name: 'DeviceAlternateIdentifier' },
            '0x3010001C': { 'vm': '1', 'vr': 'CS', name: 'DeviceAlternateIdentifierType' },
            '0x3010001D': { 'vm': '1', 'vr': 'LT', name: 'DeviceAlternateIdentifierFormat' },
            '0x3010001E': { 'vm': '1', 'vr': 'LO', name: 'SegmentationCreationTemplateLabel' },
            '0x3010001F': { 'vm': '1', 'vr': 'UI', name: 'SegmentationTemplateUID' },
            '0x30100020': { 'vm': '1', 'vr': 'US', name: 'ReferencedSegmentReferenceIndex' },
            '0x30100021': { 'vm': '1', 'vr': 'SQ', name: 'SegmentReferenceSequence' },
            '0x30100022': { 'vm': '1', 'vr': 'US', name: 'SegmentReferenceIndex' },
            '0x30100023': { 'vm': '1', 'vr': 'SQ', name: 'DirectSegmentReferenceSequence' },
            '0x30100024': { 'vm': '1', 'vr': 'SQ', name: 'CombinationSegmentReferenceSequence' },
            '0x30100025': { 'vm': '1', 'vr': 'SQ', name: 'ConceptualVolumeSequence' },
            '0x30100026': { 'vm': '1', 'vr': 'SQ', name: 'SegmentedRTAccessoryDeviceSequence' },
            '0x30100027': { 'vm': '1', 'vr': 'SQ', name: 'SegmentCharacteristicsSequence' },
            '0x30100028': { 'vm': '1', 'vr': 'SQ', name: 'RelatedSegmentCharacteristicsSequence' },
            '0x30100029': { 'vm': '1', 'vr': 'US', name: 'SegmentCharacteristicsPrecedence' },
            '0x3010002A': { 'vm': '1', 'vr': 'SQ', name: 'RTSegmentAnnotationSequence' },
            '0x3010002B': { 'vm': '1', 'vr': 'SQ', name: 'SegmentAnnotationCategoryCodeSequence' },
            '0x3010002C': { 'vm': '1', 'vr': 'SQ', name: 'SegmentAnnotationTypeCodeSequence' },
            '0x3010002D': { 'vm': '1', 'vr': 'LO', name: 'DeviceLabel' },
            '0x3010002E': { 'vm': '1', 'vr': 'SQ', name: 'DeviceTypeCodeSequence' },
            '0x30100030': { 'vm': '1', 'vr': 'SQ', name: 'PatientEquipmentRelationshipCodeSequence' },
            '0x30100031': { 'vm': '1', 'vr': 'UI', name: 'ReferencedFiducialsUID' },
            '0x30100032': { 'vm': '1', 'vr': 'SQ', name: 'PatientTreatmentOrientationSequence' },
            '0x30100033': { 'vm': '1', 'vr': 'SH', name: 'UserContentLabel' },
            '0x30100034': { 'vm': '1', 'vr': 'LO', name: 'UserContentLongLabel' },
            '0x30100035': { 'vm': '1', 'vr': 'SH', name: 'EntityLabel' },
            '0x30100036': { 'vm': '1', 'vr': 'LO', name: 'EntityName' },
            '0x30100037': { 'vm': '1', 'vr': 'ST', name: 'EntityDescription' },
            '0x30100038': { 'vm': '1', 'vr': 'LO', name: 'EntityLongLabel' },
            '0x30100039': { 'vm': '1', 'vr': 'US', name: 'DeviceIndex' },
            '0x3010003A': { 'vm': '1', 'vr': 'US', name: 'RTTreatmentPhaseIndex' },
            '0x3010003B': { 'vm': '1', 'vr': 'UI', name: 'RTTreatmentPhaseUID' },
            '0x3010003C': { 'vm': '1', 'vr': 'US', name: 'RTPrescriptionIndex' },
            '0x3010003D': { 'vm': '1', 'vr': 'US', name: 'RTSegmentAnnotationIndex' },
            '0x3010003E': { 'vm': '1', 'vr': 'US', name: 'BasisRTTreatmentPhaseIndex' },
            '0x3010003F': { 'vm': '1', 'vr': 'US', name: 'RelatedRTTreatmentPhaseIndex' },
            '0x30100040': { 'vm': '1', 'vr': 'US', name: 'ReferencedRTTreatmentPhaseIndex' },
            '0x30100041': { 'vm': '1', 'vr': 'US', name: 'ReferencedRTPrescriptionIndex' },
            '0x30100042': { 'vm': '1', 'vr': 'US', name: 'ReferencedParentRTPrescriptionIndex' },
            '0x30100043': { 'vm': '1', 'vr': 'ST', name: 'ManufacturersDeviceIdentifier' },
            '0x30100044': { 'vm': '1', 'vr': 'SQ', name: 'InstanceLevelReferencedPerformedProcedureStepSequence' },
            '0x30100045': { 'vm': '1', 'vr': 'CS', name: 'RTTreatmentPhaseIntentPresenceFlag' },
            '0x30100046': { 'vm': '1', 'vr': 'CS', name: 'RadiotherapyTreatmentType' },
            '0x30100047': { 'vm': '1-n', 'vr': 'CS', name: 'TeletherapyRadiationType' },
            '0x30100048': { 'vm': '1-n', 'vr': 'CS', name: 'BrachytherapySourceType' },
            '0x30100049': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedRTTreatmentPhaseSequence' },
            '0x3010004A': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedDirectSegmentInstanceSequence' },
            '0x3010004B': { 'vm': '1', 'vr': 'SQ', name: 'IntendedRTTreatmentPhaseSequence' },
            '0x3010004C': { 'vm': '1', 'vr': 'DA', name: 'IntendedPhaseStartDate' },
            '0x3010004D': { 'vm': '1', 'vr': 'DA', name: 'IntendedPhaseEndDate' },
            '0x3010004E': { 'vm': '1', 'vr': 'SQ', name: 'RTTreatmentPhaseIntervalSequence' },
            '0x3010004F': { 'vm': '1', 'vr': 'CS', name: 'TemporalRelationshipIntervalAnchor' },
            '0x30100050': { 'vm': '1', 'vr': 'FD', name: 'MinimumNumberofIntervalDays' },
            '0x30100051': { 'vm': '1', 'vr': 'FD', name: 'MaximumNumberofIntervalDays' },
            '0x30100052': { 'vm': '1-n', 'vr': 'UI', name: 'PertinentSOPClassesinStudy' },
            '0x30100053': { 'vm': '1-n', 'vr': 'UI', name: 'PertinentSOPClassesinSeries' },
            '0x30100054': { 'vm': '1', 'vr': 'LO', name: 'RTPrescriptionLabel' },
            '0x30100055': { 'vm': '1', 'vr': 'SQ', name: 'RTPhysicianIntentPredecessorSequence' },
            '0x30100056': { 'vm': '1', 'vr': 'LO', name: 'RTTreatmentApproachLabel' },
            '0x30100057': { 'vm': '1', 'vr': 'SQ', name: 'RTPhysicianIntentSequence' },
            '0x30100058': { 'vm': '1', 'vr': 'US', name: 'RTPhysicianIntentIndex' },
            '0x30100059': { 'vm': '1', 'vr': 'CS', name: 'RTTreatmentIntentType' },
            '0x3010005A': { 'vm': '1', 'vr': 'UT', name: 'RTPhysicianIntentNarrative' },
            '0x3010005B': { 'vm': '1', 'vr': 'SQ', name: 'RTProtocolCodeSequence' },
            '0x3010005C': { 'vm': '1', 'vr': 'ST', name: 'ReasonforSuperseding' },
            '0x3010005D': { 'vm': '1', 'vr': 'SQ', name: 'RTDiagnosisCodeSequence' },
            '0x3010005E': { 'vm': '1', 'vr': 'US', name: 'ReferencedRTPhysicianIntentIndex' },
            '0x3010005F': { 'vm': '1', 'vr': 'SQ', name: 'RTPhysicianIntentInputInstanceSequence' },
            '0x30100060': { 'vm': '1', 'vr': 'SQ', name: 'RTAnatomicPrescriptionSequence' },
            '0x30100061': { 'vm': '1', 'vr': 'UT', name: 'PriorTreatmentDoseDescription' },
            '0x30100062': { 'vm': '1', 'vr': 'SQ', name: 'PriorTreatmentReferenceSequence' },
            '0x30100063': { 'vm': '1', 'vr': 'CS', name: 'DosimetricObjectiveEvaluationScope' },
            '0x30100064': { 'vm': '1', 'vr': 'SQ', name: 'TherapeuticRoleCategoryCodeSequence' },
            '0x30100065': { 'vm': '1', 'vr': 'SQ', name: 'TherapeuticRoleTypeCodeSequence' },
            '0x30100066': { 'vm': '1', 'vr': 'US', name: 'ConceptualVolumeOptimizationPrecedence' },
            '0x30100067': { 'vm': '1', 'vr': 'SQ', name: 'ConceptualVolumeCategoryCodeSequence' },
            '0x30100068': { 'vm': '1', 'vr': 'CS', name: 'ConceptualVolumeBlockingConstraint' },
            '0x30100069': { 'vm': '1', 'vr': 'SQ', name: 'ConceptualVolumeTypeCodeSequence' },
            '0x3010006A': { 'vm': '1', 'vr': 'SQ', name: 'ConceptualVolumeTypeModifierCodeSequence' },
            '0x3010006B': { 'vm': '1', 'vr': 'SQ', name: 'RTPrescriptionSequence' },
            '0x3010006C': { 'vm': '1', 'vr': 'SQ', name: 'DosimetricObjectiveSequence' },
            '0x3010006D': { 'vm': '1', 'vr': 'SQ', name: 'DosimetricObjectiveTypeCodeSequence' },
            '0x3010006E': { 'vm': '1', 'vr': 'UI', name: 'DosimetricObjectiveUID' },
            '0x3010006F': { 'vm': '1', 'vr': 'UI', name: 'ReferencedDosimetricObjectiveUID' },
            '0x30100070': { 'vm': '1', 'vr': 'SQ', name: 'DosimetricObjectiveParameterSequence' },
            '0x30100071': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedDosimetricObjectivesSequence' },
            '0x30100073': { 'vm': '1', 'vr': 'CS', name: 'AbsoluteDosimetricObjectiveFlag' },
            '0x30100074': { 'vm': '1', 'vr': 'FD', name: 'DosimetricObjectiveWeight' },
            '0x30100075': { 'vm': '1', 'vr': 'CS', name: 'DosimetricObjectivePurpose' },
            '0x30100076': { 'vm': '1', 'vr': 'SQ', name: 'PlanningInputInformationSequence' },
            '0x30100077': { 'vm': '1', 'vr': 'LO', name: 'TreatmentSite' },
            '0x30100078': { 'vm': '1', 'vr': 'SQ', name: 'TreatmentSiteCodeSequence' },
            '0x30100079': { 'vm': '1', 'vr': 'SQ', name: 'FractionPatternSequence' },
            '0x3010007A': { 'vm': '1', 'vr': 'UT', name: 'TreatmentTechniqueNotes' },
            '0x3010007B': { 'vm': '1', 'vr': 'UT', name: 'PrescriptionNotes' },
            '0x3010007C': { 'vm': '1', 'vr': 'IS', name: 'NumberofIntervalFractions' },
            '0x3010007D': { 'vm': '1', 'vr': 'US', name: 'NumberofFractions' },
            '0x3010007E': { 'vm': '1', 'vr': 'US', name: 'IntendedDeliveryDuration' },
            '0x3010007F': { 'vm': '1', 'vr': 'UT', name: 'FractionationNotes' },
            '0x30100080': { 'vm': '1', 'vr': 'SQ', name: 'RTTreatmentTechniqueCodeSequence' },
            '0x30100081': { 'vm': '1', 'vr': 'SQ', name: 'PrescriptionNotesSequence' },
            '0x30100082': { 'vm': '1', 'vr': 'SQ', name: 'FractionBasedRelationshipSequence' },
            '0x30100083': { 'vm': '1', 'vr': 'CS', name: 'FractionBasedRelationshipIntervalAnchor' },
            '0x30100084': { 'vm': '1', 'vr': 'FD', name: 'MinimumHoursbetweenFractions' },
            '0x30100085': { 'vm': '1-n', 'vr': 'TM', name: 'IntendedFractionStartTime' },
            '0x30100086': { 'vm': '1', 'vr': 'LT', name: 'IntendedStartDayofWeek' },
            '0x30100087': { 'vm': '1', 'vr': 'SQ', name: 'WeekdayFractionPatternSequence' },
            '0x30100088': { 'vm': '1', 'vr': 'SQ', name: 'DeliveryTimeStructureCodeSequence' },
            '0x40000010': { 'vm': '1', 'vr': 'LT', name: 'Arbitrary' },
            '0x40004000': { 'vm': '1', 'vr': 'LT', name: 'TextComments' },
            '0x40080040': { 'vm': '1', 'vr': 'SH', name: 'ResultsID' },
            '0x40080042': { 'vm': '1', 'vr': 'LO', name: 'ResultsIDIssuer' },
            '0x40080050': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedInterpretationSequence' },
            '0x400800FF': { 'vm': '1', 'vr': 'CS', name: 'ReportProductionStatusTrial' },
            '0x40080100': { 'vm': '1', 'vr': 'DA', name: 'InterpretationRecordedDate' },
            '0x40080101': { 'vm': '1', 'vr': 'TM', name: 'InterpretationRecordedTime' },
            '0x40080102': { 'vm': '1', 'vr': 'PN', name: 'InterpretationRecorder' },
            '0x40080103': { 'vm': '1', 'vr': 'LO', name: 'ReferencetoRecordedSound' },
            '0x40080108': { 'vm': '1', 'vr': 'DA', name: 'InterpretationTranscriptionDate' },
            '0x40080109': { 'vm': '1', 'vr': 'TM', name: 'InterpretationTranscriptionTime' },
            '0x4008010A': { 'vm': '1', 'vr': 'PN', name: 'InterpretationTranscriber' },
            '0x4008010B': { 'vm': '1', 'vr': 'ST', name: 'InterpretationText' },
            '0x4008010C': { 'vm': '1', 'vr': 'PN', name: 'InterpretationAuthor' },
            '0x40080111': { 'vm': '1', 'vr': 'SQ', name: 'InterpretationApproverSequence' },
            '0x40080112': { 'vm': '1', 'vr': 'DA', name: 'InterpretationApprovalDate' },
            '0x40080113': { 'vm': '1', 'vr': 'TM', name: 'InterpretationApprovalTime' },
            '0x40080114': { 'vm': '1', 'vr': 'PN', name: 'PhysicianApprovingInterpretation' },
            '0x40080115': { 'vm': '1', 'vr': 'LT', name: 'InterpretationDiagnosisDescription' },
            '0x40080117': { 'vm': '1', 'vr': 'SQ', name: 'InterpretationDiagnosisCodeSequence' },
            '0x40080118': { 'vm': '1', 'vr': 'SQ', name: 'ResultsDistributionListSequence' },
            '0x40080119': { 'vm': '1', 'vr': 'PN', name: 'DistributionName' },
            '0x4008011A': { 'vm': '1', 'vr': 'LO', name: 'DistributionAddress' },
            '0x40080200': { 'vm': '1', 'vr': 'SH', name: 'InterpretationID' },
            '0x40080202': { 'vm': '1', 'vr': 'LO', name: 'InterpretationIDIssuer' },
            '0x40080210': { 'vm': '1', 'vr': 'CS', name: 'InterpretationTypeID' },
            '0x40080212': { 'vm': '1', 'vr': 'CS', name: 'InterpretationStatusID' },
            '0x40080300': { 'vm': '1', 'vr': 'ST', name: 'Impressions' },
            '0x40084000': { 'vm': '1', 'vr': 'ST', name: 'ResultsComments' },
            '0x40100001': { 'vm': '1', 'vr': 'CS', name: 'LowEnergyDetectors' },
            '0x40100002': { 'vm': '1', 'vr': 'CS', name: 'HighEnergyDetectors' },
            '0x40100004': { 'vm': '1', 'vr': 'SQ', name: 'DetectorGeometrySequence' },
            '0x40101001': { 'vm': '1', 'vr': 'SQ', name: 'ThreatROIVoxelSequence' },
            '0x40101004': { 'vm': '3', 'vr': 'FL', name: 'ThreatROIBase' },
            '0x40101005': { 'vm': '3', 'vr': 'FL', name: 'ThreatROIExtents' },
            '0x40101006': { 'vm': '1', 'vr': 'OB', name: 'ThreatROIBitmap' },
            '0x40101007': { 'vm': '1', 'vr': 'SH', name: 'RouteSegmentID' },
            '0x40101008': { 'vm': '1', 'vr': 'CS', name: 'GantryType' },
            '0x40101009': { 'vm': '1', 'vr': 'CS', name: 'OOIOwnerType' },
            '0x4010100A': { 'vm': '1', 'vr': 'SQ', name: 'RouteSegmentSequence' },
            '0x40101010': { 'vm': '1', 'vr': 'US', name: 'PotentialThreatObjectID' },
            '0x40101011': { 'vm': '1', 'vr': 'SQ', name: 'ThreatSequence' },
            '0x40101012': { 'vm': '1', 'vr': 'CS', name: 'ThreatCategory' },
            '0x40101013': { 'vm': '1', 'vr': 'LT', name: 'ThreatCategoryDescription' },
            '0x40101014': { 'vm': '1', 'vr': 'CS', name: 'ATDAbilityAssessment' },
            '0x40101015': { 'vm': '1', 'vr': 'CS', name: 'ATDAssessmentFlag' },
            '0x40101016': { 'vm': '1', 'vr': 'FL', name: 'ATDAssessmentProbability' },
            '0x40101017': { 'vm': '1', 'vr': 'FL', name: 'Mass' },
            '0x40101018': { 'vm': '1', 'vr': 'FL', name: 'Density' },
            '0x40101019': { 'vm': '1', 'vr': 'FL', name: 'ZEffective' },
            '0x4010101A': { 'vm': '1', 'vr': 'SH', name: 'BoardingPassID' },
            '0x4010101B': { 'vm': '3', 'vr': 'FL', name: 'CenterofMass' },
            '0x4010101C': { 'vm': '3', 'vr': 'FL', name: 'CenterofPTO' },
            '0x4010101D': { 'vm': '6-n', 'vr': 'FL', name: 'BoundingPolygon' },
            '0x4010101E': { 'vm': '1', 'vr': 'SH', name: 'RouteSegmentStartLocationID' },
            '0x4010101F': { 'vm': '1', 'vr': 'SH', name: 'RouteSegmentEndLocationID' },
            '0x40101020': { 'vm': '1', 'vr': 'CS', name: 'RouteSegmentLocationIDType' },
            '0x40101021': { 'vm': '1-n', 'vr': 'CS', name: 'AbortReason' },
            '0x40101023': { 'vm': '1', 'vr': 'FL', name: 'VolumeofPTO' },
            '0x40101024': { 'vm': '1', 'vr': 'CS', name: 'AbortFlag' },
            '0x40101025': { 'vm': '1', 'vr': 'DT', name: 'RouteSegmentStartTime' },
            '0x40101026': { 'vm': '1', 'vr': 'DT', name: 'RouteSegmentEndTime' },
            '0x40101027': { 'vm': '1', 'vr': 'CS', name: 'TDRType' },
            '0x40101028': { 'vm': '1', 'vr': 'CS', name: 'InternationalRouteSegment' },
            '0x40101029': { 'vm': '1-n', 'vr': 'LO', name: 'ThreatDetectionAlgorithmandVersion' },
            '0x4010102A': { 'vm': '1', 'vr': 'SH', name: 'AssignedLocation' },
            '0x4010102B': { 'vm': '1', 'vr': 'DT', name: 'AlarmDecisionTime' },
            '0x40101031': { 'vm': '1', 'vr': 'CS', name: 'AlarmDecision' },
            '0x40101033': { 'vm': '1', 'vr': 'US', name: 'NumberofTotalObjects' },
            '0x40101034': { 'vm': '1', 'vr': 'US', name: 'NumberofAlarmObjects' },
            '0x40101037': { 'vm': '1', 'vr': 'SQ', name: 'PTORepresentationSequence' },
            '0x40101038': { 'vm': '1', 'vr': 'SQ', name: 'ATDAssessmentSequence' },
            '0x40101039': { 'vm': '1', 'vr': 'CS', name: 'TIPType' },
            '0x4010103A': { 'vm': '1', 'vr': 'CS', name: 'DICOSVersion' },
            '0x40101041': { 'vm': '1', 'vr': 'DT', name: 'OOIOwnerCreationTime' },
            '0x40101042': { 'vm': '1', 'vr': 'CS', name: 'OOIType' },
            '0x40101043': { 'vm': '3', 'vr': 'FL', name: 'OOISize' },
            '0x40101044': { 'vm': '1', 'vr': 'CS', name: 'AcquisitionStatus' },
            '0x40101045': { 'vm': '1', 'vr': 'SQ', name: 'BasisMaterialsCodeSequence' },
            '0x40101046': { 'vm': '1', 'vr': 'CS', name: 'PhantomType' },
            '0x40101047': { 'vm': '1', 'vr': 'SQ', name: 'OOIOwnerSequence' },
            '0x40101048': { 'vm': '1', 'vr': 'CS', name: 'ScanType' },
            '0x40101051': { 'vm': '1', 'vr': 'LO', name: 'ItineraryID' },
            '0x40101052': { 'vm': '1', 'vr': 'SH', name: 'ItineraryIDType' },
            '0x40101053': { 'vm': '1', 'vr': 'LO', name: 'ItineraryIDAssigningAuthority' },
            '0x40101054': { 'vm': '1', 'vr': 'SH', name: 'RouteID' },
            '0x40101055': { 'vm': '1', 'vr': 'SH', name: 'RouteIDAssigningAuthority' },
            '0x40101056': { 'vm': '1', 'vr': 'CS', name: 'InboundArrivalType' },
            '0x40101058': { 'vm': '1', 'vr': 'SH', name: 'CarrierID' },
            '0x40101059': { 'vm': '1', 'vr': 'CS', name: 'CarrierIDAssigningAuthority' },
            '0x40101060': { 'vm': '3', 'vr': 'FL', name: 'SourceOrientation' },
            '0x40101061': { 'vm': '3', 'vr': 'FL', name: 'SourcePosition' },
            '0x40101062': { 'vm': '1', 'vr': 'FL', name: 'BeltHeight' },
            '0x40101064': { 'vm': '1', 'vr': 'SQ', name: 'AlgorithmRoutingCodeSequence' },
            '0x40101067': { 'vm': '1', 'vr': 'CS', name: 'TransportClassification' },
            '0x40101068': { 'vm': '1', 'vr': 'LT', name: 'OOITypeDescriptor' },
            '0x40101069': { 'vm': '1', 'vr': 'FL', name: 'TotalProcessingTime' },
            '0x4010106C': { 'vm': '1', 'vr': 'OB', name: 'DetectorCalibrationData' },
            '0x4010106D': { 'vm': '1', 'vr': 'CS', name: 'AdditionalScreeningPerformed' },
            '0x4010106E': { 'vm': '1', 'vr': 'CS', name: 'AdditionalInspectionSelectionCriteria' },
            '0x4010106F': { 'vm': '1', 'vr': 'SQ', name: 'AdditionalInspectionMethodSequence' },
            '0x40101070': { 'vm': '1', 'vr': 'CS', name: 'AITDeviceType' },
            '0x40101071': { 'vm': '1', 'vr': 'SQ', name: 'QRMeasurementsSequence' },
            '0x40101072': { 'vm': '1', 'vr': 'SQ', name: 'TargetMaterialSequence' },
            '0x40101073': { 'vm': '1', 'vr': 'FD', name: 'SNRThreshold' },
            '0x40101075': { 'vm': '1', 'vr': 'DS', name: 'ImageScaleRepresentation' },
            '0x40101076': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedPTOSequence' },
            '0x40101077': { 'vm': '1', 'vr': 'SQ', name: 'ReferencedTDRInstanceSequence' },
            '0x40101078': { 'vm': '1', 'vr': 'ST', name: 'PTOLocationDescription' },
            '0x40101079': { 'vm': '1', 'vr': 'SQ', name: 'AnomalyLocatorIndicatorSequence' },
            '0x4010107A': { 'vm': '3', 'vr': 'FL', name: 'AnomalyLocatorIndicator' },
            '0x4010107B': { 'vm': '1', 'vr': 'SQ', name: 'PTORegionSequence' },
            '0x4010107C': { 'vm': '1', 'vr': 'CS', name: 'InspectionSelectionCriteria' },
            '0x4010107D': { 'vm': '1', 'vr': 'SQ', name: 'SecondaryInspectionMethodSequence' },
            '0x4010107E': { 'vm': '6', 'vr': 'DS', name: 'PRCStoRCSOrientation' },
            '0x4FFE0001': { 'vm': '1', 'vr': 'SQ', name: 'MACParametersSequence' },
            '0x50xx0005': { 'vm': '1', 'vr': 'US', name: 'CurveDimensions' },
            '0x50xx0010': { 'vm': '1', 'vr': 'US', name: 'NumberofPoints' },
            '0x50xx0020': { 'vm': '1', 'vr': 'CS', name: 'TypeofData' },
            '0x50xx0022': { 'vm': '1', 'vr': 'LO', name: 'CurveDescription' },
            '0x50xx0030': { 'vm': '1-n', 'vr': 'SH', name: 'AxisUnits' },
            '0x50xx0040': { 'vm': '1-n', 'vr': 'SH', name: 'AxisLabels' },
            '0x50xx0103': { 'vm': '1', 'vr': 'US', name: 'DataValueRepresentation' },
            '0x50xx0104': { 'vm': '1-n', 'vr': 'US', name: 'MinimumCoordinateValue' },
            '0x50xx0105': { 'vm': '1-n', 'vr': 'US', name: 'MaximumCoordinateValue' },
            '0x50xx0106': { 'vm': '1-n', 'vr': 'SH', name: 'CurveRange' },
            '0x50xx0110': { 'vm': '1-n', 'vr': 'US', name: 'CurveDataDescriptor' },
            '0x50xx0112': { 'vm': '1-n', 'vr': 'US', name: 'CoordinateStartValue' },
            '0x50xx0114': { 'vm': '1-n', 'vr': 'US', name: 'CoordinateStepValue' },
            '0x50xx1001': { 'vm': '1', 'vr': 'CS', name: 'CurveActivationLayer' },
            '0x50xx2000': { 'vm': '1', 'vr': 'US', name: 'AudioType' },
            '0x50xx2002': { 'vm': '1', 'vr': 'US', name: 'AudioSampleFormat' },
            '0x50xx2004': { 'vm': '1', 'vr': 'US', name: 'NumberofChannels' },
            '0x50xx2006': { 'vm': '1', 'vr': 'UL', name: 'NumberofSamples' },
            '0x50xx2008': { 'vm': '1', 'vr': 'UL', name: 'SampleRate' },
            '0x50xx200A': { 'vm': '1', 'vr': 'UL', name: 'TotalTime' },
            '0x50xx200C': { 'vm': '1', 'vr': 'OB', name: 'AudioSampleData' },
            '0x50xx200E': { 'vm': '1', 'vr': 'LT', name: 'AudioComments' },
            '0x50xx2500': { 'vm': '1', 'vr': 'LO', name: 'CurveLabel' },
            '0x50xx2600': { 'vm': '1', 'vr': 'SQ', name: 'CurveReferencedOverlaySequence' },
            '0x50xx2610': { 'vm': '1', 'vr': 'US', name: 'CurveReferencedOverlayGroup' },
            '0x50xx3000': { 'vm': '1', 'vr': 'OB', name: 'CurveData' },
            '0x52009229': { 'vm': '1', 'vr': 'SQ', name: 'SharedFunctionalGroupsSequence' },
            '0x52009230': { 'vm': '1', 'vr': 'SQ', name: 'PerframeFunctionalGroupsSequence' },
            '0x54000100': { 'vm': '1', 'vr': 'SQ', name: 'WaveformSequence' },
            '0x54000110': { 'vm': '1', 'vr': 'OB', name: 'ChannelMinimumValue' },
            '0x54000112': { 'vm': '1', 'vr': 'OB', name: 'ChannelMaximumValue' },
            '0x54001004': { 'vm': '1', 'vr': 'US', name: 'WaveformBitsAllocated' },
            '0x54001006': { 'vm': '1', 'vr': 'CS', name: 'WaveformSampleInterpretation' },
            '0x5400100A': { 'vm': '1', 'vr': 'OB', name: 'WaveformPaddingValue' },
            '0x54001010': { 'vm': '1', 'vr': 'OB', name: 'WaveformData' },
            '0x56000010': { 'vm': '1', 'vr': 'OF', name: 'FirstOrderPhaseCorrectionAngle' },
            '0x56000020': { 'vm': '1', 'vr': 'OF', name: 'SpectroscopyData' },
            '0x60xx0010': { 'vm': '1', 'vr': 'US', name: 'OverlayRows' },
            '0x60xx0011': { 'vm': '1', 'vr': 'US', name: 'OverlayColumns' },
            '0x60xx0012': { 'vm': '1', 'vr': 'US', name: 'OverlayPlanes' },
            '0x60xx0015': { 'vm': '1', 'vr': 'IS', name: 'NumberofFramesinOverlay' },
            '0x60xx0022': { 'vm': '1', 'vr': 'LO', name: 'OverlayDescription' },
            '0x60xx0040': { 'vm': '1', 'vr': 'CS', name: 'OverlayType' },
            '0x60xx0045': { 'vm': '1', 'vr': 'LO', name: 'OverlaySubtype' },
            '0x60xx0050': { 'vm': '2', 'vr': 'SS', name: 'OverlayOrigin' },
            '0x60xx0051': { 'vm': '1', 'vr': 'US', name: 'ImageFrameOrigin' },
            '0x60xx0052': { 'vm': '1', 'vr': 'US', name: 'OverlayPlaneOrigin' },
            '0x60xx0060': { 'vm': '1', 'vr': 'CS', name: 'OverlayCompressionCode' },
            '0x60xx0061': { 'vm': '1', 'vr': 'SH', name: 'OverlayCompressionOriginator' },
            '0x60xx0062': { 'vm': '1', 'vr': 'SH', name: 'OverlayCompressionLabel' },
            '0x60xx0063': { 'vm': '1', 'vr': 'CS', name: 'OverlayCompressionDescription' },
            '0x60xx0066': { 'vm': '1-n', 'vr': 'AT', name: 'OverlayCompressionStepPointers' },
            '0x60xx0068': { 'vm': '1', 'vr': 'US', name: 'OverlayRepeatInterval' },
            '0x60xx0069': { 'vm': '1', 'vr': 'US', name: 'OverlayBitsGrouped' },
            '0x60xx0100': { 'vm': '1', 'vr': 'US', name: 'OverlayBitsAllocated' },
            '0x60xx0102': { 'vm': '1', 'vr': 'US', name: 'OverlayBitPosition' },
            '0x60xx0110': { 'vm': '1', 'vr': 'CS', name: 'OverlayFormat' },
            '0x60xx0200': { 'vm': '1', 'vr': 'US', name: 'OverlayLocation' },
            '0x60xx0800': { 'vm': '1-n', 'vr': 'CS', name: 'OverlayCodeLabel' },
            '0x60xx0802': { 'vm': '1', 'vr': 'US', name: 'OverlayNumberofTables' },
            '0x60xx0803': { 'vm': '1-n', 'vr': 'AT', name: 'OverlayCodeTableLocation' },
            '0x60xx0804': { 'vm': '1', 'vr': 'US', name: 'OverlayBitsForCodeWord' },
            '0x60xx1001': { 'vm': '1', 'vr': 'CS', name: 'OverlayActivationLayer' },
            '0x60xx1100': { 'vm': '1', 'vr': 'US', name: 'OverlayDescriptorGray' },
            '0x60xx1101': { 'vm': '1', 'vr': 'US', name: 'OverlayDescriptorRed' },
            '0x60xx1102': { 'vm': '1', 'vr': 'US', name: 'OverlayDescriptorGreen' },
            '0x60xx1103': { 'vm': '1', 'vr': 'US', name: 'OverlayDescriptorBlue' },
            '0x60xx1200': { 'vm': '1-n', 'vr': 'US', name: 'OverlaysGray' },
            '0x60xx1201': { 'vm': '1-n', 'vr': 'US', name: 'OverlaysRed' },
            '0x60xx1202': { 'vm': '1-n', 'vr': 'US', name: 'OverlaysGreen' },
            '0x60xx1203': { 'vm': '1-n', 'vr': 'US', name: 'OverlaysBlue' },
            '0x60xx1301': { 'vm': '1', 'vr': 'IS', name: 'ROIArea' },
            '0x60xx1302': { 'vm': '1', 'vr': 'DS', name: 'ROIMean' },
            '0x60xx1303': { 'vm': '1', 'vr': 'DS', name: 'ROIStandardDeviation' },
            '0x60xx1500': { 'vm': '1', 'vr': 'LO', name: 'OverlayLabel' },
            '0x60xx3000': { 'vm': '1', 'vr': 'OB', name: 'OverlayData' },
            '0x60xx4000': { 'vm': '1', 'vr': 'LT', name: 'OverlayComments' },
            '0x7FE00001': { 'vm': '1', 'vr': 'OV', name: 'ExtendedOffsetTable' },
            '0x7FE00002': { 'vm': '1', 'vr': 'OV', name: 'ExtendedOffsetTableLengths' },
            '0x7FE00008': { 'vm': '1', 'vr': 'OF', name: 'FloatPixelData' },
            '0x7FE00009': { 'vm': '1', 'vr': 'OD', name: 'DoubleFloatPixelData' },
            '0x7FE00010': { 'vm': '1', 'vr': 'OB', name: 'PixelData' },
            '0x7FE00020': { 'vm': '1', 'vr': 'OW', name: 'CoefficientsSDVN' },
            '0x7FE00030': { 'vm': '1', 'vr': 'OW', name: 'CoefficientsSDHN' },
            '0x7FE00040': { 'vm': '1', 'vr': 'OW', name: 'CoefficientsSDDN' },
            '0x7Fxx0010': { 'vm': '1', 'vr': 'OB', name: 'VariablePixelData' },
            '0x7Fxx0011': { 'vm': '1', 'vr': 'US', name: 'VariableNextDataGroup' },
            '0x7Fxx0020': { 'vm': '1', 'vr': 'OW', name: 'VariableCoefficientsSDVN' },
            '0x7Fxx0030': { 'vm': '1', 'vr': 'OW', name: 'VariableCoefficientsSDHN' },
            '0x7Fxx0040': { 'vm': '1', 'vr': 'OW', name: 'VariableCoefficientsSDDN' },
            '0xFFFAFFFA': { 'vm': '1', 'vr': 'SQ', name: 'DigitalSignaturesSequence' },
            '0xFFFCFFFC': { 'vm': '1', 'vr': 'OB', name: 'DataSetTrailingPadding' },
            '0xFFFEE000': { 'vm': '1', 'vr': 'See Note', name: 'Item' },
            '0xFFFEE00D': { 'vm': '1', 'vr': 'See Note', name: 'ItemDelimitationItem' },
            '0xFFFEE0DD': { 'vm': '1', 'vr': 'See Note', name: 'SequenceDelimitationItem' }
        };


        //Dicom 정보를 저장하기 위한 변수
        this.DicomDataList = {};

        this.DicomDataList.element = [];
        this.DicomDataList.sequence = null;


        this.pixelData = null;
        this.pixelByteArray = null;
        this.byteBuffer = new ArrayBuffer(0);
        this.pixelVR = null;
        this.location = 0;
        this.tag = '';
        this.elemnetLength = 0;
        this.vr = '';
        this.byteString = '\\';
        this.specificCharacterSet = null;

        this.bigEndianTransferSyntax = false;
        this.bFlagBigEndian = false;


        this.anonymizationList = [
            '0x00100010',
            '0x00100020',
            '0x00100030', '0x00101000', '0x00101001', '0x00080080', '0x00080081', '0x00080050', '0x00200010'];


    };

    /**
     * bufferr 위치를 skip
     * @param {skipLengh} Skip buffer lenght
     */
    skip(sikpLengh) {
        this.location += sikpLengh;
    };


    /**
     * DICM 키값 체크
     * dicom file인지 여부를 체크하는 함수
     */
    chkMagicWord() {
        this.location += MAGIKEY_OFFSET;
        let chkkey = this.getString(4);
        //MAGIKEY_OFFSET 위치에 DCIM이 있으면 DCM 파일 타입으로 판정
        if (chkkey === MAGICKEY) {
            return true;
        }
        //MAGIKEY_OFFSET위치가 없을경우 처음 시작이 0008  group으로 시작될 경우 dicom 타입으로 읽기 시작
        else {
            this.location = 0;
            let chkBigEndian = '0x0800';
            let chkLittleEndian = '0x0008';
            let groupWord = this.readHex(this.getShort());
            if ((groupWord !== chkBigEndian) &&
                (groupWord !== chkLittleEndian)) {
                console.error('This is No Dicom File : No Magic & No 0008 Group');
                return false;
            } else {
                this.location = 0;
                return true;
            }
        }

    };

    /**
     *  * Ref: [Dicom VR ]{@link http://dicom.nema.org/dicom/2013/output/chtml/part05/sect_6.2.html}.
     * file bufurt에서  dicom element 값을 가져오는 함수
     * VR에 따라서 처리방법이 다름
     */
    getValue() {

        let ielemnetLength = parseInt(this.elemnetLength);
        if (ielemnetLength === -1 || ielemnetLength === 0)
            return '';

        let b0, b1;
        let tmpString = '';
        let result = '';
        let ivm = 0;
        // console.log("this vr" + this.vr)
        switch (this.vr) {
            //Other Byte String
            case 'OB':
            //UNKNON
            case 'UN':

                ivm = ielemnetLength / 2;
                tmpString = '';

                b0 = this.getByte();
                b1 = this.getByte();
                //tmpString = b0.toString(16) + this.byteString + b1.toString(16);
                tmpString = new Int8Array(b0).toString() + this.byteString + new Int8Array(b1).toString();
                //
                for (let i = 1; i < ivm; i++) {
                    b0 = this.getByte();
                    b1 = this.getByte();
                    tmpString += this.byteString + new Int8Array(b0).toString() + this.byteString + new Int8Array(b1).toString();
                }
                result = tmpString;
                //alue = getString(m_nElementLength);
                break;

            //Unsigned Long
            case 'UL':
                ivm = ielemnetLength / 4;

                result = '';

                result += this.getInt();

                for (let i = 1; i < ivm; i++) {
                    result += this.byteString + this.getInt();
                }
                break;
            //Floating Point Double
            case 'FD':

                ivm = ielemnetLength / 8;

                result = '';

                result += this.getDouble();

                for (let i = 1; i < ivm; i++) {
                    result += this.byteString + this.getDouble();
                }
                break;
            //Floating Point Single
            case 'FL':

                if (ielemnetLength === 4)
                    result = this.getFloat();
                else {
                    result = '';
                    let n = ielemnetLength / 4;
                    for (let i = 0; i < n; i++)
                        result += this.getFloat();
                }
                break;

            /*
            * AE : Application Entity
            * AS : Age String
            * AT : Attribute Tag
            * CS : Code String
             */
            case 'AE':
            case 'AS':
            case 'AT':
            case 'CS':
            case 'DA':
            case 'DS':
            case  'DT':
            case  'IS':
            case  'LO':
            case 'LT':
            case 'PN':
            case 'SH':
            case 'ST':
            case 'TM':
            case 'UI':
                result = this.getString(ielemnetLength);
                break;
            case 'US':
                if (ielemnetLength === 2)
                    result = this.getShort();
                else {
                    let n = ielemnetLength / 2;
                    for (let i = 0; i < n; i++)
                        result += this.getShort() + this.byteString;
                }
                break;
            case 'Undefined':
                ivm = ielemnetLength / 2;
                tmpString = '';

                b0 = this.getByte();
                b1 = this.getByte();
                tmpString = b0.toString(16) + this.byteString + b1.toString(16);
                //
                for (let i = 1; i < ivm; i++) {
                    b0 = this.getByte();
                    b1 = this.getByte();
                    tmpString += this.byteString + b0.toString(16) + this.byteString + b1.toString(16);
                }
                result = tmpString;
                //alue = getString(m_nElementLength);
                break;
            case 'SQ':
                this.skip(ielemnetLength);
                result = 'SQ';
                break;

            default:
                this.skip(ielemnetLength);
                result = '';
        }
        let tmpData = {
            'tag': this.tag,
            'vr': this.vr,
            'length': this.elemnetLength,
            'value': result
        };

        return tmpData;
    }


    /**
     * DCM file을 읽는 가장 기본 구조
     * @param {ArrayBuffer}   dcm raw file buffer array
     */
    readFile(abBuff) {

        try {
            this.byteBuffer = abBuff;

            if (!this.chkMagicWord())
                return false;

            let flagEnd = true;

            while (flagEnd) {
                this.getNextTag();

                //tranferSyntanx
                if(this.tag==='0x00020010'){
                    let value = this.getValue();
                    if(value.value.includes('1.2.840.10008.1.2.2'))
                        this.bigEndianTransferSyntax = true;

                }
                else if (this.vr === 'SQ') {
                    /*let tmpdata = {
                        "tag": this.tag,
                        "vr": 'SQ',
                        "length": this.elemnetLength,
                        "value": ''
                    };
                    this.DicomDataList.element[tmpdata.tag] = tmpdata;*/
                    // console.log("in reqd seq" + this.elemnetLength);
                    this.readSequnce(this.elemnetLength, this.DicomDataList);
                } else if (this.tag === '0x7FE00010') {
                    // console.log("in pixel data");
                    flagEnd = false;
                    this.readPixelData();
                    break;
                } else {
                    let value = this.getValue();
                    // console.log(value);

                    if (this.tag == '0x00080005') {
                        this.specificCharacterSet = value.value;
                    }

                    this.DicomDataList.element[value.tag] = value;
                }
            }
        } catch (e) {
            console.log('Failed load Dicom File : ' + e);
            return false;
        }
        return true;

    };


    /**
     * SQ vr을 읽는 함수 T파일 쓰는 방법에 따라서 여러 타입이 존재함
     *
     * @param {integer}   sqence itme 의 길이
     *  @param {Object}   sqence itme들을 담는 변수
     */

    readSequnce(sqenceLenght, datalist) {
        let ouputSeqList = new Object();
        let nowTag = this.tag;
        ouputSeqList.elemnet = new Array();
        ouputSeqList.sequnce = null;

        if (datalist.sequnce == null) {
            datalist.sequnce = new Array();
        }

        if (sqenceLenght === -1) {

            while (true) {

                this.getNextTag();
                // console.log(this.tag)

                if (this.tag === SequenceDelimitationItem) {
                    datalist.sequnce[nowTag] = ouputSeqList;

                    break;
                } else if (this.vr == 'SQ') {

                    let value = this.getValue();

                    ouputSeqList.elemnet[value.tag] = value;


                    this.readSequnce(this.elemnetLength, ouputSeqList);
                } else {

                    let value = this.getValue();

                    ouputSeqList.elemnet[value.tag] = value;
                }
            }
        } else {
            let startSQLocatoin = this.location;
            let SQLenght = this.elemnetLength;

            while (this.location - startSQLocatoin < SQLenght) {
                this.getNextTag();
                if (this.tag === ITEM) {
                    continue;
                } else if (this.vr == 'SQ') {
                    let tmpdata = {
                        'tag': this.tag,
                        'vr': 'undefined',
                        'length': this.elemnetLength,
                        'value': ''
                    };

                    ouputSeqList.elemnet[tmpdata.tag] = tmpdata;

                    this.readSequnce(this.elemnetLength, ouputSeqList);
                } else {
                    let value = this.getValue();
                    ouputSeqList.elemnet[value.tag] = value;

                }

            }
            datalist.sequnce[nowTag] = ouputSeqList;
        }

    }


    /**
     * btye 타입을 16진수 String으로 바꾸는 코드
     * @param {ArrayBuffer}   byte data
     */
    readHex(byteOffset) {
        // read and convert to hex string
        var str = byteOffset.toString(16);
        // return padded
        return '0x0000'.substr(0, 6 - str.length) + str.toUpperCase();
    };


    /**
     *
     * file byteBuffer에서 String type으로 가져오는 함수
     * @param {Integer}  elemnet lenght
     */
    getString(tmpLenght) {
        let tmpString = new Uint8Array(this.byteBuffer, this.location, tmpLenght);
        this.location += tmpLenght;
        return this.ab2str(tmpString);
    }

    /**
     * file byteBuffer에서 byte type으로 가져오는 함수
     */
    getByte() {
        let b0 = new Uint8Array(this.byteBuffer, this.location, 1);
        this.location++;
        return b0;
    }

    /**
     * file byteBuffer에서 short int type으로 가져오는 함수
     */
    getShort() {
        let b0 = this.getByte();
        let b1 = this.getByte();

        if (this.bFlagBigEndian )
            return ((b0 << 8) | b1);
        else
            return ((b1 << 8) | b0);



    };

    /**
     *
     * Uint8Array에서 String 으로 변환 하는 함수
     * @param {Uint8Array}  Uint8Array Type의 String buffer
     */

    ab2str(buffer) {
        // if (this.specificCharacterSet !== null || this.specificCharacterSet !== 'ISO_IR 100') {
        //     return convertBytes(this.specificCharacterSet, new Uint8Array(buffer));
        // }
        // else
        return String.fromCharCode.apply(null, new Uint16Array(buffer));

    };

    /**
     * file byteBuffer에서  int type으로 가져오는 함수
     */
    getInt() {
        let b0 = this.getByte();
        let b1 = this.getByte();
        let b2 = this.getByte();
        let b3 = this.getByte();

        if (this.bFlagBigEndian)
            return ((b0 << 24) | (b1 << 16) | (b2 << 8) | b3);
        else
            return ((b3 << 24) | (b2 << 16) | (b1 << 8) | b0);

    };

    /**
     * file byteBuffer에서  float type으로 가져오는 함수
     */
    getFloat() {
        let b0 = this.getByte();
        let b1 = this.getByte();
        let b2 = this.getByte();
        let b3 = this.getByte();

        if (this.bFlagBigEndian)
            return ((b0 << 24) | (b1 << 16) | (b2 << 8) | b3);
        else
            return ((b3 << 24) | (b2 << 16) | (b1 << 8) | b0);
    };

    /**
     * file byteBuffer에서  double type으로 가져오는 함수
     */
    getDouble() {
        let view = new DataView(this.byteBuffer, this.location, this.elemnetLength);

        this.location +=  8;
        if (this.elemnetLength < 8) {
            this.location -=  8;
            this.location +=  this.elemnetLength;
            return 0.0;
        }


        if (this.bFlagBigEndian)
            return view.getFloat64(0, false);
        else
            return view.getFloat64(0, true);
    }

    /**
     *  다음 Teg를 읽어오는 함수. elemnet의 길이도 함게 호출
     */
    getNextTag() {


        let groupWord = this.readHex(this.getShort());

        if (groupWord === '0x0800' && this.bigEndianTransferSyntax) {
            console.log('in bi')
            this.bFlagBigEndian= true;
            groupWord = '0x0080';
        }



        let elementWord = this.getShort();




        this.tag = groupWord + this.readHex(elementWord).substr(2, 4);


        this.elemnetLength = this.getLenght();

    };

    /**
     *  . elemnet와 VR을 읽는 함수
     *
     */
    getLenght() {
        let b0 = this.getByte();
        let b1 = this.getByte();
        let b2 = this.getByte();
        let b3 = this.getByte();

        let tmp = new Uint8Array(2);
        tmp[0] = b0;
        tmp[1] = b1;

        let tmpVrString = this.ab2str(tmp);
        this.vr = tmpVrString;

        //console.log("vr :   " + tmpVrString);

        switch (tmpVrString) {
            case  'SQ' :
                return this.getInt();
            // Explicit VR with 32-bit length if other two bytes are zero
            case 'OB':
            case 'OW' :
            case 'OF' :
            case 'UN' :
            case 'UT' :
                if ((b2 == 0) || b3 == 0)
                    return this.getInt();
                break;

            case 'AE':
            case 'AS':
            case 'AT':
            case 'CS':
            case 'DA':
            case 'DS':
            case 'DT':
            case 'FD':
            case 'FL':
            case 'IS':
            case 'LO':
            case 'LT':
            case 'PN':
            case 'SH':
            case 'SL':
            case 'SS':
            case 'ST':
            case 'TM':
            case 'UI':
            case 'UL':
            case 'US':
            case 'QQ':
                // Explicit vr with 16-bit length
                if (this.bFlagBigEndian)
                    return ((b2 << 8) | b3);
                else
                    return ((b3 << 8) | b2);


            default:
                this.vr = this.getVRinDic(this.tag);

                if (this.bFlagBigEndian)
                    return ((b0 << 24) | (b1 << 16) | (b2 << 8) | b3);

                else
                    return ((b3 << 24) | (b2 << 16) | (b1 << 8) | b0);
        }

    }


    /**
     *  . Tag를 이용하여 VR 값을 dicom dictionary에
     *
     */

    getVRinDic(tmpTag) {
        let output = '';

        try {
            output = this.dic[tmpTag].vr;
        } catch (e) {
            return 'UN';
        }
        return output;


    }

    /**
     *  .read된 Dicom date를 가져오 는 함수
     *
     */





    Anonymization(test) {
        let output = '';
        let elementArray = new Array();
        let anonymizeList = new Array();
        try {
            elementArray = this.DicomDataList.element;
            anonymizeList = this.anonymizationList;

            if (test.patientsNameAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00100010') {
                        elementArray[i].value = test.patientsName;
                    }
                }
            }

            if (test.patientIDAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00100020') {
                        elementArray[i].value = test.patientID;
                    }
                }
            }

            if (test.patientsBirthDateAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00100030') {
                        elementArray[i].value = test.patientsBirthDate;
                    }
                }
            }

            if (test.otherPatientIDsAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00101000') {
                        elementArray[i].value = test.otherPatientIDs;
                    }
                }
            }

            if (test.otherPatientNamesAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00101001') {
                        elementArray[i].value = test.otherPatientNames;
                    }
                }
            }

            if (test.institutionNameAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00080080') {
                        elementArray[i].value = test.institutionName;
                    }
                }
            }

            if (test.institutionAddressAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00080081') {
                        elementArray[i].value = test.institutionAddress;
                    }
                }
            }

            if (test.accessionNumberAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00080050') {
                        elementArray[i].value = test.accessionNumber;
                    }
                }
            }

            if (test.studyIDAct === 'Replace') {
                for (let i in elementArray) {
                    if (elementArray[i].tag === '0x00200010') {
                        elementArray[i].value = test.studyID;
                    }
                }
            }


            output = elementArray;
        } catch (e) {
            return 'Anonymization Failed';
        }
        return output;

    }

    getPixelVR() {
        return this.pixelVR;
    }

    getTagValue(tmptag) {
        let output = '';
        try {
            output = this.DicomDataList.element[tmptag].value;
        } catch (e) {
            return undefined;
        }
        return output;
    }

    getDicomElement() {
        let output = '';
        try {

            // for (let aTag in this.anonymizationList) {
            //     if(aTag === '0x00' ){
            //         this.DicomDataList.element[aTag].value = '';
            //     }
            //     else if(){
            //         this.DicomDataList.element[aTag].value = '';
            //     } else
            //
            //     this.DicomDataList.element[aTag].value = '';
            // }

            output = this.DicomDataList.element;
        } catch (e) {
            return 'undefined';
        }
        return output;
    }

    getDicomElement() {
        let output = '';
        try {
            output = this.DicomDataList.element;
        } catch (e) {
            return 'undefined';
        }
        return output;
    }

    makeTrasferData() {
        let output = new Array();
        try {
            output = this.DicomDataList.element;
        } catch (e) {
            return 'undefined';
        }
        return output;

    }

    getDicomELement2ByteArray() {
        this.DicomDataList.element.forEach(function(value, index, array) {


        });

    }


    /**
     *  .read된 Dicom date를 가져오 는 함수
     *  참고 dicom Header : bitsAllocated(0028, 0100), pixelRepresentation(0028,0103)
     *
     *   {@link  http://dicomiseasy.blogspot.com/2012/08/chapter-12-pixel-data.html}
     *
     *
     */
    readPixelData() {
        let bitsAllocated = 16;
        if (this.getTagValue('0x00280100') !== undefined) {
            bitsAllocated = this.DicomDataList.element['0x00280100'].value;
        } else {
            //console.warn("Reading DICOM pixel data with default bitsAllocated.");
        }


        if (bitsAllocated === 8 && this.vr === 'OW') {
            //console.warn("Reading DICOM pixel data with vr=OW and bitsAllocated=8 (should be 16).");
        }
        if (bitsAllocated === 16 && this.vr === 'OB') {
            //console.warn("Reading DICOM pixel data with vr=OB and bitsAllocated=16 (should be 8).");
        }
        // PixelRepresentation 0->unsigned, 1->signed
        let pixelRepresentation = 0;
        if (this.getTagValue('x00280103') !== undefined) {
            pixelRepresentation = this.DicomDataList.element['x00280103'].value;
        } else {
            //console.warn("Reading DICOM pixel data with default pixelRepresentation.");
        }
        //
        //
        this.pixelByteArray = new Int8Array(this.byteBuffer, this.location, this.elemnetLength);

        if(this.bFlagBigEndian){
            let tmp ;
            for (let i=0; i<this.pixelByteArray.length; i+=2) {
                tmp = this.pixelByteArray[i];
                this.pixelByteArray[i] = this.pixelByteArray[i+1];
                this.pixelByteArray[i+1] = tmp;

            }
        }


        this.pixelVR = this.vr;

        // read
        if (bitsAllocated === 8) {
            if (pixelRepresentation === 0) {
                this.pixelData = new Uint8Array(this.byteBuffer, this.location, this.elemnetLength);
            } else {
                this.pixelData = new Int8Array(this.byteBuffer, this.location, this.elemnetLength);
            }
        } else if (bitsAllocated === 16) {
            if (pixelRepresentation === 0) {
                this.pixelData = new Uint16Array(this.byteBuffer, this.location, this.elemnetLength / 2);
            } else {
                this.pixelData = new Int16Array(this.byteBuffer, this.location, this.elemnetLength / 2);
            }
        } else if (bitsAllocated === 32) {
            if (pixelRepresentation === 0) {
                this.pixelData = new Uint32Array(this.byteBuffer, this.location, this.elemnetLength / 4);
            } else {
                this.pixelData = new Int32Array(this.byteBuffer, this.location, this.elemnetLength / 4);
            }
        } else if (bitsAllocated === 64) {
            if (pixelRepresentation === 0) {
                this.pixelData = new BigUint64Array(this.byteBuffer, this.location, this.elemnetLength / 8);
            } else {
                this.pixelData = new BigInt64Array(this.byteBuffer, this.location, this.elemnetLength / 8);
            }
        }




    }


    /**
     * Data 확인용 메서드
     */
    getArrayData(arraydata, idx) {
        let output = 'undefined';
        try {
            output = arraydata.hasOwnProperty(idx);
            if (output)
                return arraydata[idx];
            else
                return 'undefined';

        } catch (e) {
            return 'undefined';
        }
        return output;
    }

    print() {
        this.printData(this.DicomDataList, '');

    }

    printData(datalist, sqenStr) {
        let elemnet2 = datalist.elemnet;
        let sq = datalist.sequnce;
        let interator = Object.keys(elemnet2);
        for (let e of interator) {
            //  console.log(e);
            let chkSQ = this.getArrayData(sq, e);
            if (chkSQ === 'undefined') {
                //console.log(sqenStr+" "+elemnet2[e].tag+" "+elemnet2[e].vr+" "+" "+elemnet2[e].value);
            } else {
                this.printData(sq[e], sqenStr + ' ');
            }
        }
    }

    /**
     * Print Pixel Data
     */

    printpixelData() {
        console.table(this.pixelData);
    }


    getPixelData() {


        return this.pixelByteArray;
    }

    getImageData() {
        var canvas = document.createElement('canvas');
        let width = this.getTagValue('0x00280011');//Colum
        let height = this.getTagValue('0x00280010');//row
        canvas.width = width;
        canvas.height = height;

        var ctx = canvas.getContext('2d');


        let iWindowWidth = this.getTagValue('0x00281051');
        let iwindowCenter = this.getTagValue('0x00281050');
        let imagePixelData = new Array();

        let imin = (iwindowCenter - 0.5 - (iWindowWidth - 1) / 2);
        let imax = (iwindowCenter - 0.5 + (iWindowWidth - 1) / 2);


        let dRescaleSlope = this.getTagValue('0x00281053');//(0028,1053) DS 1.0                                               # 1, 4 Rescale Slope
        let dRescaleIntercept = this.getTagValue('0x00281052');//((0028,1053) DS 1.0                                               # 1, 4 Rescale Slope

        if (dRescaleSlope === undefined) {
            dRescaleSlope = 1.0;
        }
        if (dRescaleIntercept === undefined) {
            dRescaleIntercept = 0.0;
        }


        // b0*dRescaleSlope+dRescaleIntercept;

        for (let idx = 0; idx < this.pixelData.length; idx++) {
            let recondata = (this.pixelData[idx] * parseFloat(dRescaleSlope)) + parseFloat(dRescaleIntercept);

            if (recondata <= imin)
                imagePixelData[idx] = 0;
            else if (recondata > imax)
                imagePixelData[idx] = 255;
            else {
                let tmp = ((recondata - imin) / (iWindowWidth) * 255);
                imagePixelData[idx] = tmp;
            }
        }


        return imagePixelData;

    }

    writer(buffer, isLittleEndian) {
        if (typeof isLittleEndian === 'undefined') {
            isLittleEndian = true;
        }

        var view = new DataView(buffer);

        this.writeUint8 = function(byteOffset, value) {
            view.setUint8(byteOffset, value);
            return byteOffset + Uint8Array.BYTES_PER_ELEMENT;
        };

        this.writeInt8 = function(byteOffset, value) {
            view.setUint8(byteOffset, value);
            return byteOffset + Int8Array.BYTES_PER_ELEMENT;
        };

        this.writeUint16 = function(byteOffset, value) {
            view.setUint16(byteOffset, value, isLittleEndian);
            return byteOffset + Uint16Array.BYTES_PER_ELEMENT;
        };

        this.writeInt16 = function(byteOffset, value) {
            view.setInt16(byteOffset, value, isLittleEndian);
            return byteOffset + Int16Array.BYTES_PER_ELEMENT;
        };

        this.writeUint32 = function(byteOffset, value) {
            view.setUint32(byteOffset, value, isLittleEndian);
            return byteOffset + Uint32Array.BYTES_PER_ELEMENT;
        };

        this.writeInt32 = function(byteOffset, value) {
            view.setInt32(byteOffset, value, isLittleEndian);
            return byteOffset + Int32Array.BYTES_PER_ELEMENT;
        };

        this.writeHex = function(byteOffset, str) {
            var value = parseInt(str.substr(2), 16);
            view.setUint16(byteOffset, value, isLittleEndian);
            return byteOffset + Uint16Array.BYTES_PER_ELEMENT;
        };

        this.writeString = function(byteOffset, str) {
            for (var i = 0; i < str.length; i++) {
                view.setUint8(byteOffset, str.charCodeAt(i));
                byteOffset += Uint8Array.BYTES_PER_ELEMENT;
            }
            return byteOffset;
        };

    }
}