import * as actionTypes from '../_actions/queryAction';

export const initialState = {
    queryList: [],
    selectedQuery: {},
    selectedQueryKey: '',
    unreadCount: 0,

    projectList: [],
    subjectList: [],
    visitList: [],

    selectedProject: null,
    selectedSubject: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.OPEN_QUERY_SUCCESS :
            return {
                ...state,
                queryList: [...state.queryList, action.payload],
            }
        case actionTypes.OPEN_QUERY_FAILURE :
            return {
                ...state,
            }
        case actionTypes.LOAD_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_QUERY_SUCCESS :
            return {
                ...state,
                queryList: [...action.payload],
            }
        case actionTypes.LOAD_QUERY_FAILURE :
            return {
                ...state,
            }
        case actionTypes.LOAD_TO_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_TO_QUERY_SUCCESS :
            return {
                ...state,
                queryList: [...action.payload],
            }
        case actionTypes.LOAD_TO_QUERY_FAILURE :
            return {
                ...state,
            }
        case actionTypes.LOAD_FROM_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_FROM_QUERY_SUCCESS :
            return {
                ...state,
                queryList: [...action.payload],
            }
        case actionTypes.LOAD_FROM_QUERY_FAILURE :
            return {
                ...state,
            }

        case actionTypes.CLOSE_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.CLOSE_QUERY_SUCCESS :
            return {
                ...state,
                queryList: [...action.payload],
            }
        case actionTypes.CLOSE_QUERY_FAILURE :
            return {
                ...state,
            }

        case actionTypes.REPLY_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.REPLY_QUERY_SUCCESS :
            const list = state.queryList.map(function(v) {
                if(v.id === action.payload.id) {
                    return action.payload;
                } else {
                    return v;
                }
            })
            return {
                ...state,
                queryList: [...list],
                selectedQuery: action.payload
            }
        case actionTypes.REPLY_QUERY_FAILURE :
            return {
                ...state,
            }

        case actionTypes.GET_UNREAD_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.GET_UNREAD_QUERY_SUCCESS :
            return {
                ...state,
                unreadCount: action.payload,
            }
        case actionTypes.GET_UNREAD_QUERY_FAILURE :
            return {
                ...state,
            }

        case actionTypes.READ_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.READ_QUERY_SUCCESS :
            console.log()
            return {
                ...state,
                unreadCount: action.payload.unreadCount,
                queryList: [...action.payload.queryList],
            }
        case actionTypes.READ_QUERY_FAILURE :
            return {
                ...state,
            }

        case actionTypes.LOAD_PROJECT_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_PROJECT_QUERY_SUCCESS :
            if(window.location.pathname.includes('overview') || window.location.pathname.includes('list')) {

                if(window.location.pathname.includes('list')) {
                    if(window.location.pathname.includes('psv')) {

                    } else if(window.location.pathname.includes('pv')) {

                    }
                }
            }
            return {
                ...state,
                projectList: [...action.payload],
                selectedProject: window.location.pathname.includes('overview') || window.location.pathname.includes('list') ?
                    action.payload.filter(v => v.id === parseInt(window.location.pathname.split('/')[2]))[0] : null,
            }
        case actionTypes.LOAD_PROJECT_QUERY_FAILURE :
            return {
                ...state,
            }

        case actionTypes.LOAD_SUBJECT_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_SUBJECT_QUERY_SUCCESS :
            return {
                ...state,
                subjectList: [...action.payload],
            }
        case actionTypes.LOAD_SUBJECT_QUERY_FAILURE :
            return {
                ...state,
            }

        case actionTypes.SELECT_SUBJECT_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SELECT_SUBJECT_QUERY_SUCCESS :
            return {
                ...state,
                visitList: [...action.payload],
            }
        case actionTypes.SELECT_SUBJECT_QUERY_FAILURE :
            return {
                ...state,
            }

        case actionTypes.CLEAR_SUBJECT_QUERY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.CLEAR_SUBJECT_QUERY_SUCCESS :
            return {
                ...state,
                selectedSubject: null,
                subjectList: [],
                visitList: [],
            }
        case actionTypes.CLEAR_SUBJECT_QUERY_FAILURE :
            return {
                ...state,
            }
        default:
            return {
                ...state
            };
    }
}

export default reducer;