import React from 'react';

const useMovePage = (history, path, newTab, feature, refresh) => {
    if(newTab === true) {
        if(refresh !== undefined) {
            refresh();
        }
        return window.open(path, '_blank', feature === undefined ? null : feature);
    } else {
        return history.push(path);
    }
};

export default useMovePage;