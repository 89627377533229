import * as actionTypes from '../_actions/uploadAction';
import Upload from '../components/Component/Uploader/Dicom/Function/Upload';

export const initialState = {
    dicom: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_DICOM_REQUEST :
            return {
                ...state,
            };
        case actionTypes.UPLOAD_DICOM_SUCCESS :
            Upload(action.action.array, action.action.values, action.action.seriesArray, action.action.selectList, action.action.setProgress, action.action.dispatch,
                action.action.setViewUploadProgress, action.action.setIsUpload, action.action.setSuccessModal, action.action.totalLength, action.action.projectID, action.action.result);
            return {
                ...state,
            };
        case actionTypes.UPLOAD_DICOM_FAILURE :
            return {
                ...state,
            };

        case actionTypes.UPLOAD_IMAGE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.UPLOAD_IMAGE_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.UPLOAD_IMAGE_FAILURE :
            return {
                ...state,
            };
            
        default:
            return {
                ...state
            };
    }
};

export default reducer;