import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/dmAction';

// Load Project ////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadProjectAPI() {
    return axios.get('/project/get-list');
}
function infoAPI() {
    return axios.get('/user/get-info');
}
function* loadProject() {
    try {
        const result = yield call(loadProjectAPI);
        const info = yield call(infoAPI);
        yield put({
            type: actionTypes.LOAD_PROJECT_SUCCESS,
            payload: result.data,
            info: info.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_PROJECT_FAILURE,
        });
    }
}
function* watchLoadProject() {
    yield takeLatest(actionTypes.LOAD_PROJECT_REQUEST, loadProject);
}

// Search Project //////////////////////////////////////////////////////////////////////////////////////////////////////
function* searchProject(action) {
    try {
        yield put({
            type: actionTypes.SEARCH_PROJECT_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SEARCH_PROJECT_FAILURE,
        });
    }
}
function* watchSearchProject() {
    yield takeLatest(actionTypes.SEARCH_PROJECT_REQUEST, searchProject);
}

function downSDTMAPI(data) {
    const config = {headers: {'TI-DS-ID': data.id, 'TI-DS-PW': data.password}, responseType: 'blob'};
    return axios.post('/project/export-sdtm/' + data.pk, null, config).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.project + '_SDTM.xlsx');
        document.body.appendChild(link);
        link.click();
    });
}

function* downSDTM(action) {
    yield call(() => downSDTMAPI(action.data));
    try {
        setTimeout(() => {
            action.data.setVisible(false);
        }, [500])
        yield put({
            type: actionTypes.DOWNLOAD_SDTM_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DOWNLOAD_SDTM_FAILURE,
        })
    }
}
function* watchDownSDTM() {
    yield takeLatest(actionTypes.DOWNLOAD_SDTM_REQUEST, downSDTM);
}


function downMMFAPI(data) {
    const config = {headers: {'TI-DS-ID': data.id, 'TI-DS-PW': data.password}, responseType: 'blob'};
    return axios.post('/project/export-mmf/' + data.pk, null, config).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.project + '_MMF.xlsx');
        document.body.appendChild(link);
        link.click();
    });
}
function* downMMF(action) {
    yield call(() => downMMFAPI(action.data));
    try {
        setTimeout(() => {
            action.data.setVisible(false);
        }, [500])
        yield put({
            type: actionTypes.DOWNLOAD_MMF_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DOWNLOAD_MMF_FAILURE,
        })
    }
}
function* watchDownMMF() {
    yield takeLatest(actionTypes.DOWNLOAD_MMF_REQUEST, downMMF);
}

function databaseLockAPI(data) {
    const config = {headers: {'TI-DS-ID': data.id, 'TI-DS-PW': data.password}};
    return axios.post('/project/lock/' + data.pk, null, config);
}
function* databaseLock(action) {
    const result = yield call(() => databaseLockAPI(action.data));
    try {
        setTimeout(() => {
            action.data.setVisible(false);
        }, [500])
        yield put({
            type: actionTypes.DATABASE_LOCK_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DATABASE_LOCK_FAILURE,
        })
    }
}
function* watchDatabaseLock() {
    yield takeLatest(actionTypes.DATABASE_LOCK_REQUEST, databaseLock);
}

function databaseUnLockAPI(data) {
    const config = {headers: {'TI-DS-ID': data.id, 'TI-DS-PW': data.password}};
    return axios.post('/project/unlock/' + data.pk, null, config);
}
function* databaseUnLock(action) {
    const result = yield call(() => databaseUnLockAPI(action.data));
    try {
        setTimeout(() => {
            action.data.setVisible(false);
        }, [500])
        yield put({
            type: actionTypes.DATABASE_UNLOCK_SUCCESS,
            payload: result.data
        });
    } catch (err) {
        yield put({
            type: actionTypes.DATABASE_UNLOCK_FAILURE,
        })
    }
}
function* watchDatabaseUnLock() {
    yield takeLatest(actionTypes.DATABASE_UNLOCK_REQUEST, databaseUnLock);
}

function getCorrelationAPI(action) {
    return axios.get('/mock/correlation/' + action.projectID);
}
function* getCorrelation(action) {
    const result = yield call(() =>getCorrelationAPI(action));
    try {
        yield put({
            type: actionTypes.GET_CORRELATION_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_CORRELATION_FAILURE,
        })
    }
}
function* watchGetCorrelation() {
    yield takeLatest(actionTypes.GET_CORRELATION_REQUEST, getCorrelation);
}


export default function* loginSaga() {
    yield all([
        fork(watchLoadProject),
        fork(watchSearchProject),
        fork(watchDownSDTM),
        fork(watchDownMMF),
        fork(watchDatabaseLock),
        fork(watchDatabaseUnLock),
        fork(watchGetCorrelation),
    ])
}