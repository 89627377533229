import * as actionTypes from '../_actions/reportAction';

export const initialState = {
    reportData: {},
    reportInfo: {},
    reportTypeList: [],
    multi: [],
    info: {},
    audit: [],
    multiAudit: [],
    result: {},
    pick: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_REPORT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_REPORT_SUCCESS :
            return {
                ...state,
                reportData: action.payload,
                info: action.info,
            };
        case actionTypes.LOAD_REPORT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_REPORT_INFO_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_REPORT_INFO_SUCCESS :
            return {
                ...state,
                reportInfo: action.payload,
            };
        case actionTypes.LOAD_REPORT_INFO_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_MULTI_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_MULTI_SUCCESS :
            return {
                ...state,
                multi: action.payload,
                info: action.info,
            };
        case actionTypes.LOAD_MULTI_FAILURE :
            return {
                ...state,
            };

        case actionTypes.SAVE_REPORT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SAVE_REPORT_SUCCESS :
            window.localStorage.setItem('updateReport', JSON.stringify(action.payload));
            return {
                ...state,
                result: action.payload,
            };
        case actionTypes.SAVE_REPORT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.MODIFY_REPORT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.MODIFY_REPORT_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.MODIFY_REPORT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.REVIEW_RECIST_REQUEST :
            return {
                ...state,
            };
        case actionTypes.REVIEW_RECIST_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.REVIEW_RECIST_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_REPORT_AUDIT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_REPORT_AUDIT_SUCCESS :
            return {
                ...state,
                audit: [...action.payload],
            };
        case actionTypes.LOAD_REPORT_AUDIT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_MULTI_AUDIT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_MULTI_AUDIT_SUCCESS :
            return {
                ...state,
                multiAudit: [...action.payload],
            };
        case actionTypes.LOAD_MULTI_AUDIT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.PICK_REPORT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.PICK_REPORT_SUCCESS :
            return {
                ...state,
                pick: action.pick,
            };
        case actionTypes.PICK_REPORT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.PICK_INFO_REQUEST :
            return {
                ...state,
            };
        case actionTypes.PICK_INFO_SUCCESS :
            return {
                ...state,
                pick: action.payload === '' ? null : action.payload
            };
        case actionTypes.PICK_INFO_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_TYPE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_TYPE_SUCCESS :
            return {
                ...state,
                reportTypeList: [...action.payload],
            };
        case actionTypes.LOAD_TYPE_FAILURE :
            return {
                ...state,
            };

        default:
            return {
                ...state
            };
    }
};

export default reducer;