import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import axios from "axios";
import * as actionTypes from '../_actions/qcAction';

// Load QC List ////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadQCAPI() {
    return axios.get('/time-point/get-qc-list');
}
function* loadQC(action) {
    try {
        const result = yield call(loadQCAPI);
        if(result.data.length >= 0) {
            action.setIsLoading(false);
        }
        yield put({
            type: actionTypes.LOAD_QC_SUCCESS,
            payload: result.data,
        });

    } catch (err) {
        yield put({
            type: actionTypes.LOAD_QC_FAILURE,
        });
    }
}
function* watchLoadQC() {
    yield takeLatest(actionTypes.LOAD_QC_REQUEST, loadQC);
}

// Save QC /////////////////////////////////////////////////////////////////////////////////////////////////////////////
function saveQCAPI(action) {
    const config = { headers: { 'TI-DS-ID': action.data.sign.id, 'TI-DS-PW': action.data.sign.password } };
    return axios.put('/time-point/qc-list', action.data.data, config);
}
function openQueryAPI(action) {
    const config = {headers: {'TI-DS-ID': action.data.sign.id, 'TI-DS-PW': action.data.sign.password}};
    return axios.post('/query/open', action.data.query, config);
}
function* saveQC(action) {
    try {
        const response = yield call(() => saveQCAPI(action));
        if(response.status === 200) {
            message.success({ content: 'QC update complete'}).then();
            if(action.data.query.toUserEmail !== '' && action.data.query.toUserEmail !== undefined) {
                yield call(() => openQueryAPI(action));
            }
            setTimeout(() => {
                action.setSpinning(false)
                action.setVisible(false);
            }, [1000]);
            yield put({
                type: actionTypes.SAVE_QC_SUCCESS,
                qc: response.data,
                state : response.data.qcState,
            });
        }
    } catch (err) {
        if(err.response.status === 403) {
            action.setSpinning(false);
        }
        yield put({
            type: actionTypes.SAVE_QC_FAILURE,
        });
    }
}
function* watchSaveQC() {
    yield takeLatest(actionTypes.SAVE_QC_REQUEST, saveQC);
}

// Load QC List ////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadPSQAPI(action) {
    return axios.get('/project/get-file/psq/' + action.projectID + '/' + action.siteID, {responseType: 'arraybuffer'});
}
function* loadPSQ(action) {
    try {
        const result = yield call(() => loadPSQAPI(action));
        yield put({
            type: actionTypes.LOAD_PSQ_SUCCESS,
            payload: result.data,
            projectID: action.projectID,
            siteID: action.siteID,
        });

    } catch (err) {
        yield put({
            type: actionTypes.LOAD_PSQ_FAILURE,
            status: err.response.status
        });
    }
}
function* watchLoadPSQ() {
    yield takeLatest(actionTypes.LOAD_PSQ_REQUEST, loadPSQ);
}

// Modify QC ///////////////////////////////////////////////////////////////////////////////////////////////////////////
function modifyQCAPI(data) {
    const config = {headers: {'TI-DS-ID': data.id, 'TI-DS-PW': data.password}};
    return axios.post('/time-point/modify-qc-state/' + data.timePointID, {comment: data.comment}, config);
}
function* modifyQC(action) {
    try {
        const result = yield call(() => modifyQCAPI(action.data));
        if(result.status === 200) {
            action.setVisible(false);
        }
        yield put({
            type: actionTypes.MODIFY_QC_SUCCESS,
            qc: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.MODIFY_QC_FAILURE,
        })
    }
}
function* watchModifyQC() {
    yield takeLatest(actionTypes.MODIFY_QC_REQUEST, modifyQC);
}

// RE QC ///////////////////////////////////////////////////////////////////////////////////////////////////////
function reQCAPI(action) {
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}};
    return axios.post('/time-point/re-request-qc/' + action.timePointID, {comment: action.comment}, config);
}
function* reQC(action) {
    try {
        yield call(() => reQCAPI(action));
        yield put({
            type: actionTypes.RE_QC_SUCCESS,
            setVisible: action.setVisible
        });
    } catch (err) {
        yield put({
            type: actionTypes.RE_QC_FAILURE,
        })
    }
}
function* watchReQC() {
    yield takeLatest(actionTypes.RE_QC_REQUEST, reQC);
}


export default function* qcSaga() {
    yield all([
        fork(watchLoadQC),
        fork(watchSaveQC),
        fork(watchLoadPSQ),
        fork(watchModifyQC),
        fork(watchReQC),
    ])
}