export const LOAD_SITE_REQUEST = 'LOAD_SITE_REQUEST';
export const LOAD_SITE_SUCCESS = 'LOAD_SITE_SUCCESS';
export const LOAD_SITE_FAILURE = 'LOAD_SITE_FAILURE';

export const ADD_SITE_REQUEST = 'ADD_SITE_REQUEST';
export const ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS';
export const ADD_SITE_FAILURE = 'ADD_SITE_FAILURE';

export const EDIT_SITE_REQUEST = 'EDIT_SITE_REQUEST';
export const EDIT_SITE_SUCCESS = 'EDIT_SITE_SUCCESS';
export const EDIT_SITE_FAILURE = 'EDIT_SITE_FAILURE';

export const DELETE_SITE_REQUEST = 'DELETE_SITE_REQUEST';
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAILURE = 'DELETE_SITE_FAILURE';


export const loadSiteAction = () => {
    return {
        type: LOAD_SITE_REQUEST,
    }
};

export const addSiteAction = (data) => {
    return {
        type: ADD_SITE_REQUEST,
        data,
    }
};

export const editSiteAction = (data) => {
    return {
        type: EDIT_SITE_REQUEST,
        data,
    }
};

export const deleteSiteAction = (data) => {
    return {
        type: DELETE_SITE_REQUEST,
        data,
    }
};