export const LOAD_PROJECT_REQUEST = 'LOAD_PROJECT_REQUEST';
export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';
export const LOAD_PROJECT_FAILURE = 'LOAD_PROJECT_FAILURE';

export const SEARCH_PROJECT_REQUEST = 'SEARCH_PROJECT_REQUEST';
export const SEARCH_PROJECT_SUCCESS = 'SEARCH_PROJECT_SUCCESS';
export const SEARCH_PROJECT_FAILURE = 'SEARCH_PROJECT_FAILURE';

export const DOWNLOAD_SDTM_REQUEST = 'DOWNLOAD_SDTM_REQUEST';
export const DOWNLOAD_SDTM_SUCCESS = 'DOWNLOAD_SDTM_SUCCESS';
export const DOWNLOAD_SDTM_FAILURE = 'DOWNLOAD_SDTM_FAILURE';

export const DOWNLOAD_MMF_REQUEST = 'DOWNLOAD_MMF_REQUEST';
export const DOWNLOAD_MMF_SUCCESS = 'DOWNLOAD_MMF_SUCCESS';
export const DOWNLOAD_MMF_FAILURE = 'DOWNLOAD_MMF_FAILURE';

export const DATABASE_LOCK_REQUEST = 'DATABASE_LOCK_REQUEST';
export const DATABASE_LOCK_SUCCESS = 'DATABASE_LOCK_SUCCESS';
export const DATABASE_LOCK_FAILURE = 'DATABASE_LOCK_FAILURE';

export const DATABASE_UNLOCK_REQUEST = 'DATABASE_UNLOCK_REQUEST';
export const DATABASE_UNLOCK_SUCCESS = 'DATABASE_UNLOCK_SUCCESS';
export const DATABASE_UNLOCK_FAILURE = 'DATABASE_UNLOCK_FAILURE';

export const GET_CORRELATION_REQUEST = 'GET_CORRELATION_REQUEST';
export const GET_CORRELATION_SUCCESS = 'GET_CORRELATION_SUCCESS';
export const GET_CORRELATION_FAILURE = 'GET_CORRELATION_FAILURE';

export const loadProjectAction = () => {
    return {
        type: LOAD_PROJECT_REQUEST,
    }
};

export const downloadSDTMAction = (data) => {
    return {
        type: DOWNLOAD_SDTM_REQUEST,
        data
    }
};

export const downloadMMFAction = (data) => {
    return {
        type: DOWNLOAD_MMF_REQUEST,
        data
    }
};

export const databaseLockAction = (data) => {
    return {
        type: DATABASE_LOCK_REQUEST,
        data
    }
};

export const databaseUnlockAction = (data) => {
    return {
        type: DATABASE_UNLOCK_REQUEST,
        data
    }
};

export const getCorrelationAction = (projectID) => {
    return {
        type: GET_CORRELATION_REQUEST,
        projectID,
    }
}