export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_FAILURE = 'SEND_MAIL_FAILURE';

export const sendMailAction = (data) => {
    return {
        type: SEND_MAIL_REQUEST,
        data
    }
};
