import * as actionTypes from '../_actions/qcAction';
import axios from 'axios';
import { message } from 'antd';

export const initialState = {
    qcList: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_QC_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_QC_SUCCESS :
            return {
                qcList: [...action.payload],
            }
        case actionTypes.LOAD_QC_FAILURE :
            return {
                ...state,
            }

        case actionTypes.SAVE_QC_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SAVE_QC_SUCCESS :
            return {
                ...state,
                qcList: [...action.state === 'PASS' ? [...state.qcList.filter(v => v.id !== action.qc.id)] : [action.qc, ...state.qcList.filter(v => v.id !== action.qc.id)]],
            }
        case actionTypes.SAVE_QC_FAILURE :
            return {
                ...state,
            }

        case actionTypes.LOAD_PSQ_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_PSQ_SUCCESS :
            window.open(axios.defaults.baseURL + '/project/get-file/psq/' + action.projectID + '/' + action.siteID + '?t=' + localStorage.getItem('cookie'),"", '_blank');
            return {
                ...state,
            }
        case actionTypes.LOAD_PSQ_FAILURE :
            message.error({content: 'There is no PSQ file uploaded.'}).then();
            return {
                ...state,
            }

        case actionTypes.MODIFY_QC_REQUEST :
            return {
                ...state,
            };
        case actionTypes.MODIFY_QC_SUCCESS :
            message.success({content: 'Please refresh your browser and run the QC again'}).then();
            return {
                ...state,
            }
        case actionTypes.MODIFY_QC_FAILURE :
            return {
                ...state,
            }

        case actionTypes.RE_QC_REQUEST :
            return {
                ...state,
            };
        case actionTypes.RE_QC_SUCCESS :
            message.success({content: 'Email has been sent to QC.'}).then();
            action.setVisible(false);
            return {
                ...state,
            }
        case actionTypes.RE_QC_FAILURE :
            return {
                ...state,
            }
        default:
            return {
                ...state
            };
    }
}

export default reducer;