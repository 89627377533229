import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/logAction';

function loadLogAPI() {
    return axios.get('/log-login');
}

function* loadLog() {
    const result = yield call(loadLogAPI);
    try {
        yield put({
            type: actionTypes.LOAD_LOG_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_LOG_FAILURE,
        })
    }
}
function* watchLoadLog() {
    yield takeLatest(actionTypes.LOAD_LOG_REQUEST, loadLog);
}

function downLogAPI() {
    return axios.post('/log-login').then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'test.txt');
        document.body.appendChild(link);
        link.click();
    });
}

function* downLog() {
    yield call(() => downLogAPI());
    try {
        yield put({
            type: actionTypes.DOWN_LOG_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DOWN_LOG_FAILURE,
        })
    }
}
function* watchDownLog() {
    yield takeLatest(actionTypes.DOWN_LOG_REQUEST, downLog);
}

export default function* loginSaga() {
    yield all([
        fork(watchLoadLog),
        fork(watchDownLog),
    ])
}