import * as actionTypes from '../_actions/eligibilityAction';

export const initialState = {
    eligibilityData: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ELIGIBILITY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.GET_ELIGIBILITY_SUCCESS :
            return {
                ...state,
                eligibilityData: action.payload
            };
        case actionTypes.GET_ELIGIBILITY_FAILURE :
            return {
                ...state,
            };

        case actionTypes.SAVE_ELIGIBILITY_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SAVE_ELIGIBILITY_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.SAVE_ELIGIBILITY_FAILURE :
            return {
                ...state,
            };

        default:
            return {
                ...state
            };
    }
};

export default reducer;