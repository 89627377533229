import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './_reducers';
import rootSaga from './_sagas';
import logger from 'redux-logger';
import { applyMiddleware, legacy_createStore as createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import AxiosConfig from './AxiosConfig';
import './lang/i18n';
import 'antd/dist/reset.css';

const themes = { dark: `${process.env.PUBLIC_URL}/dark-theme.css`, light: `${process.env.PUBLIC_URL}/light-theme.css` };
const theme = JSON.parse(window.localStorage.getItem('theme'));
const language = JSON.parse(window.localStorage.getItem('lang'));
const setTheme = theme === null ? 'light' : theme === 'light' ? 'light' : 'dark';
const root = createRoot(document.getElementById('root'));

window.localStorage.setItem("theme", JSON.stringify(setTheme));
window.localStorage.setItem("lang", language === null ? JSON.stringify('en') : JSON.stringify(language));

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, (process.env.REACT_APP_API_URL === 'tii-api' || process.env.REACT_APP_API_URL === 'uat-api' || process.env.REACT_APP_API_URL === 'aim-api') ?
    compose(applyMiddleware(sagaMiddleware)) : composeWithDevTools(applyMiddleware(sagaMiddleware, logger))
);

sagaMiddleware.run(rootSaga);

root.render(
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={setTheme}>
        <Provider store={store}>
            <AxiosConfig>
                <App/>
            </AxiosConfig>
        </Provider>
    </ThemeSwitcherProvider>,
);
