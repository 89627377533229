import React from 'react';
import i18n from "i18next"
import { initReactI18next } from "react-i18next";

import translationEn from './translation.en'
import translationKo from './translation.ko'

const resource =  {
  en: { lang: translationEn },
  ko: { lang: translationKo }
};

i18n.use(initReactI18next).init({
    resources: resource,
    lng: "en",
    fallbackLng: 'en',
    ns: ['lang'],
    debug: true,
    keySeparator: false,
    interpolation: { escapeValue: false }
}).then();