import React, { useEffect } from 'react';

import { message } from 'antd';

import axios from 'axios';

const AxiosConfig = ({ children }) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL === undefined ? 'https://dev-api.imagetrial.com' : 'https://' + process.env.REACT_APP_API_URL + '.imagetrial.com';
    axios.defaults.headers['X-AUTH-TOKEN'] = window.localStorage.getItem('cookie');

    useEffect(() => {
        const resInterceptor = response => {
            return response;
        }
 
        const errInterceptor = error => {
            if (error.response.status === 403) {
                message.error({ content: 'Please check ID or Password.'}).then();
            } else if(error.response.status === 400) {
                message.error({ content: 'Please check ID or Password.'}).then();
            } else if(error.response.status === 500) {
                message.error({ content: 'This is system error. Please contact the IT manager.' }).then();
            } else if(error.response.status === 404) {
                message.error({ content: 'Not Found.' }).then();
            } else {
                message.error({ content: 'This is system error. Please contact the IT manager.' }).then();
            }
            return Promise.reject(error);
        }

        const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);

        return () => axios.interceptors.response.eject(interceptor);
    }, []);
    return children;
}

export default AxiosConfig;