import * as actionTypes from '../_actions/logAction';

export const initialState = {
    log: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_LOG_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_LOG_SUCCESS :
            return {
                ...state,
                log: [...action.payload],
            };
        case actionTypes.LOAD_LOG_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DOWN_LOG_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DOWN_LOG_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.DOWN_LOG_FAILURE :
            return {
                ...state,
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;