import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/userAction';
import { message } from 'antd';

// Load User ///////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadUserAPI() {
    return axios.get('/user/get-all');
}
function* loadUser() {
    try {
        const result = yield call(loadUserAPI);
        yield put({
            type: actionTypes.LOAD_USER_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_USER_FAILURE,
        })
    }
}
function* watchLoadUser() {
    yield takeLatest(actionTypes.LOAD_USER_REQUEST, loadUser);
}

// Edit User ///////////////////////////////////////////////////////////////////////////////////////////////////////////
function editUserAPI(data) {
    return axios.put('/user', data);
}
function* editUser(action) {
    try {
        yield call(() => editUserAPI(action.data));
        yield put({
            type: actionTypes.EDIT_USER_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.EDIT_USER_FAILURE,
        })
    }
}
function* watchEditUser() {
    yield takeLatest(actionTypes.EDIT_USER_REQUEST, editUser);
}


// Delete User /////////////////////////////////////////////////////////////////////////////////////////////////////////
function deleteUserAPI(data) {
    return axios.delete('/user/' + data.id);
}
function* deleteUser(action) {
    try {
        yield call(() => deleteUserAPI(action.data));
        yield put({
            type: actionTypes.DELETE_USER_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DELETE_USER_FAILURE,
        })
    }
}
function* watchDeleteUser() {
    yield takeLatest(actionTypes.DELETE_USER_REQUEST, deleteUser);
}

// Load User In Project ////////////////////////////////////////////////////////////////////////////////////////////////
function loadInProjectUserAPI(data) {
    return axios.get('/project/get-user-list/' + data);
}
function* loadInProjectUser(action) {
    try {
        const result = yield call(() => loadInProjectUserAPI(action.data));
        if(result.data.length > 0) {
            action.setUserList([...result.data]);
        }
        yield put({
            type: actionTypes.LOAD_USER_IN_PROJECT_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_USER_IN_PROJECT_FAILURE,
        })
    }
}
function* watchLoadInProjectUser() {
    yield takeLatest(actionTypes.LOAD_USER_IN_PROJECT_REQUEST, loadInProjectUser);
}

// Search User /////////////////////////////////////////////////////////////////////////////////////////////////////////
function* searchUser(action) {
    try {
        yield put({
            type: actionTypes.SEARCH_USER_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SEARCH_USER_FAILURE,
        });
    }
}
function* watchSearchUser() {
    yield takeLatest(actionTypes.SEARCH_USER_REQUEST, searchUser);
}

// Load Role ///////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadRoleAPI() {
    return axios.get('/code-list/role');
}
function* loadRole() {
    const result = yield call(loadRoleAPI);
    try {
        yield put({
            type: actionTypes.LOAD_ROLE_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_ROLE_FAILURE,
        });
    }
}
function* watchLoadRole() {
    yield takeLatest(actionTypes.LOAD_ROLE_REQUEST, loadRole);
}

// Send Email //////////////////////////////////////////////////////////////////////////////////////////////////////////
function sendEmailAPI(data) {
    return axios.post('/admin/create-user', data);
}
function* sendEmail(action) {
    try {
        const result = yield call(() => sendEmailAPI(action.data));
        if(result.status === 200) {
            message.success({ content: 'Email Sending Success'}).then();
            action.isSpinning(false);
        }
        yield put({
            type: actionTypes.SEND_EMAIL_SUCCESS,
        });
    } catch (err) {
        if(err.response.status === 409) {
            message.error({ content: 'Email Duplicated'}).then();
        }
        action.isSpinning(false);
        yield put({
            type: actionTypes.SEND_EMAIL_FAILURE,
        });
    }
}
function* watchSendEmail() {
    yield takeLatest(actionTypes.SEND_EMAIL_REQUEST, sendEmail);
}

// Send Email //////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadSiteInfoAPI() {
    return axios.get('/user/get-info', {headers: {'X-AUTH-TOKEN': window.location.search.replace('?jwt=', '')}});
}
function* loadSiteInfo() {
    const result = yield call(loadSiteInfoAPI);
    try {
        yield put({
            type: actionTypes.LOAD_SITE_INFO_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_SITE_INFO_FAILURE,
        });
    }
}
function* watchLoadSiteInfo() {
    yield takeLatest(actionTypes.LOAD_SITE_INFO_REQUEST, loadSiteInfo);
}

// Registration User ///////////////////////////////////////////////////////////////////////////////////////////////////
function registrationUserAPI(data) {
    return axios.post('/user/reg-by-email-link', data, {headers: {'X-AUTH-TOKEN': window.location.search.replace('?jwt=', '')}});
}
function* registrationUser(action) {
    const result = yield call(() => registrationUserAPI(action.data));
    try {
        yield put({
            type: actionTypes.REGISTRATION_USER_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.REGISTRATION_USER_FAILURE,
        });
    }
}
function* watchRegistrationUser() {
    yield takeLatest(actionTypes.REGISTRATION_USER_REQUEST, registrationUser);
}

export default function* userSaga() {
    yield all([
        fork(watchLoadUser),
        fork(watchEditUser),
        fork(watchDeleteUser),
        fork(watchLoadInProjectUser),
        fork(watchSearchUser),
        fork(watchLoadRole),
        fork(watchSendEmail),
        fork(watchLoadSiteInfo),
        fork(watchRegistrationUser),
    ])
}