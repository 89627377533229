import { all, fork } from 'redux-saga/effects'

import authSaga from './auth';
import loginSaga from './log'
import projectSaga from './project'
import userSaga from './user';
import reportSaga from './report';
import qcSaga from './qc';
import querySaga from './query';
import uploadSaga from './upload';
import siteSaga from './site';
import dmSaga from './dm';
import mailSaga from './mail';
import eligibilitySaga from './eligibility';

export default function* rootSaga () {
    yield all([
        fork(authSaga),
        fork(loginSaga),
        fork(projectSaga),
        fork(userSaga),
        fork(reportSaga),
        fork(qcSaga),
        fork(querySaga),
        fork(uploadSaga),
        fork(siteSaga),
        fork(dmSaga),
        fork(mailSaga),
        fork(eligibilitySaga),
    ]);
}