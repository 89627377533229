export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const REG_AUTH_REQUEST = 'REG_AUTH_REQUEST';
export const REG_AUTH_SUCCESS = 'REG_AUTH_SUCCESS';
export const REG_AUTH_FAILURE = 'REG_AUTH_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const CHECK_ACCOUNT_REQUEST = 'CHECK_ACCOUNT_REQUEST';
export const CHECK_ACCOUNT_SUCCESS = 'CHECK_ACCOUNT_SUCCESS';
export const CHECK_ACCOUNT_FAILURE = 'CHECK_ACCOUNT_FAILURE';

export const CHANGE_INFO_REQUEST = 'CHANGE_INFO_REQUEST';
export const CHANGE_INFO_SUCCESS = 'CHANGE_INFO_SUCCESS';
export const CHANGE_INFO_FAILURE = 'CHANGE_INFO_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const loginAction = (data, setIsLoading) => {
    return {
        type: LOGIN_REQUEST,
        data, setIsLoading
    }
};

export const logoutAction = (data) => {
    return {
        type: LOGOUT_REQUEST,
        data,
    }
};

export const authAction = (data) => {
    return {
        type: AUTH_REQUEST,
        data,
    }
};

export const regAuthAction = (data) => {
    return {
        type: REG_AUTH_REQUEST,
        data,
    }
};

export const refreshTokenAction = () => {
    return {
        type: REFRESH_TOKEN_REQUEST,
    }
}

export const checkAccountAction = (data) => {
    return {
        type: CHECK_ACCOUNT_REQUEST,
        data,
    }
}

export const changeInfoAction = (data, setVisible) => {
    return {
        type: CHANGE_INFO_REQUEST,
        data, setVisible
    }
}

export const forgotPasswordAction = (data, setVisible) => {
    return {
        type: FORGOT_PASSWORD_REQUEST,
        data, setVisible
    }
}