import * as actionTypes from '../_actions/siteAction';
import { message } from 'antd';

export const initialState = {
    siteList: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.LOAD_SITE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_SITE_SUCCESS :
            return {
                ...state,
                siteList: [...action.payload],
            };
        case actionTypes.LOAD_SITE_FAILURE :
            return {
                ...state,
            };

        case actionTypes.ADD_SITE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.ADD_SITE_SUCCESS :
            message.success({ content: action.payload.name + ' is Created'}).then();
            return {
                ...state,
                siteList: [...state.siteList, action.payload],
            };
        case actionTypes.ADD_SITE_FAILURE :
            message.error({ content: action.payload.name + ' is not Created'}).then();
            return {
                ...state,
            };

        case actionTypes.EDIT_SITE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.EDIT_SITE_SUCCESS :
            message.success({ content: action.payload.name + ' is Updated'}).then();
            return {
                ...state,
                siteList: [...state.siteList.map(function(v) {
                    if(v.id === action.payload.id)
                        return action.payload
                    else
                        return v
                })],
            };
        case actionTypes.EDIT_SITE_FAILURE :
            message.error({ content: action.payload.name + ' is not Updated'}).then();
            return {
                ...state,
            };

        case actionTypes.DELETE_SITE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DELETE_SITE_SUCCESS :
            message.success({ content: action.payload.name + ' is Deleted'}).then();
            return {
                ...state,
                siteList: [...state.siteList.filter(v => v.id !== action.payload.id)],
            };
        case actionTypes.DELETE_SITE_FAILURE :
            message.error({ content: action.payload.name + ' is not Deleted'}).then();
            return {
                ...state,
            };


        default:
            return {
                ...state
            };
    }
};

export default reducer;