import React from 'react';

import { message } from 'antd';

import { IAidDicomLib } from '../../../../../library/iAidDicomLib';
import { uploadDicomAction } from '../../../../../_actions/uploadAction';
import { sendMailAction } from '../../../../../_actions/mailAction';

const Upload = ( uploadArray, values, seriesArray, selectList, setProgress, dispatch, setViewUploadProgress, setIsUpload, setSuccessModal, totalLength, selectedProjectPK, result) => {
    const formData = new FormData();

    if(uploadArray.length > 0) {
        formData.append('comment', values.comment === undefined ? '' : values.comment)
        uploadArray[0].map((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const dicomLib = new IAidDicomLib();
                const binaryStr = reader.result;
                let attribute = {};
                if(dicomLib.readFile(binaryStr)) {
                    attribute.studyUID = dicomLib.getTagValue('0x0020000D');
                    attribute.seriesUID = dicomLib.getTagValue('0x0020000E');

                    if(selectList.includes(attribute.studyUID)) {
                        if(seriesArray.filter(s => s.seriesUID === attribute.seriesUID).length > 0) {
                            for (let i = 0; i < Object.keys(values).length; i++) {
                                if (attribute.studyUID === Object.keys(values)[i].split('_')[1]) {
                                    attribute[Object.keys(values)[i].split('_')[0]] = values[Object.keys(values)[i]];
                                }
                            }
                            formData.append('fileName', file.name);
                            console.log(file.name)
                            formData.append('element', JSON.stringify(Object.values(dicomLib.getDicomElement())));
                            formData.append('pixelData', new File([dicomLib.getPixelData()], file.name));
                            formData.append('subject', attribute.subject);
                            formData.append('visitType', attribute.visitType);
                            formData.append('visitNumber', attribute['visitNumber']);

                            if (formData.getAll('fileName').length === uploadArray[0].length) {
                                const sign = { id: values.id, password: values.password };
                                dispatch(uploadDicomAction(formData, sign, setProgress, uploadArray, values, setViewUploadProgress, setIsUpload, seriesArray, selectList, dispatch,
                                    uploadArray.length, selectedProjectPK, setSuccessModal, totalLength, result));
                                uploadArray.shift();
                            }
                        }
                    }
                }
            };
            reader.readAsArrayBuffer(file);
        });
    } else {
        message.success({content: 'Upload is Completed!'}).then();
        setProgress(100);
        setTimeout(() => {
            dispatch(sendMailAction({projectPk: selectedProjectPK, value: result, category: 'IMAGE_UPLOAD'}));
        }, [1000]);
        setTimeout(() => {
            setSuccessModal(true);
            setIsUpload(false);
        }, [1000]);
    }
};

export default Upload;