import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/uploadAction';

// Upload Dicom ///////////////////////////////////////////////////////////////////////////////////////////////////
function uploadDicomAPI(action) {
    return axios({ method: 'post', url: '/dicom-upload/' + action.projectID, data: action.data, headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password, 'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
            const currentArrayLength = action.totalLength - action.uploadLength;
            const step = (100 / action.totalLength).toFixed(0);
            let temp = Math.round(step * currentArrayLength);
            let percent = Math.round((((progressEvent.loaded * 100) / progressEvent.total) / action.totalLength) + temp);
            action.setProgress(percent)
    }});
}
function* uploadDicom(action) {
    try {
        yield call(() => uploadDicomAPI(action));
        yield put({
            type: actionTypes.UPLOAD_DICOM_SUCCESS,
            action: action,
        });
    } catch (err) {
        action.setViewUploadProgress(false);
        action.setIsUpload(false);
        yield put({
            type: actionTypes.UPLOAD_DICOM_FAILURE,
            action: action,
        })
    }
}
function* watchUploadDicom() {
    yield takeLatest(actionTypes.UPLOAD_DICOM_REQUEST, uploadDicom);
}

// Upload Image ///////////////////////////////////////////////////////////////////////////////////////////////////
function uploadImageAPI(action) {
    return axios({ method: 'post', url: '/image-upload/' + action.projectID, data: action.data, headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password},
        onUploadProgress: progressEvent => {
            let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            action.setPercentage(percent)
        }});
}
function* uploadImage(action) {
    try {
        const result = yield call(() => uploadImageAPI(action));
        if(result.status === 200) {
            action.setViewPercentage(false);
            action.setIsUploading(false);
            action.setSuccessModal(true);
        }
        yield put({
            type: actionTypes.UPLOAD_IMAGE_SUCCESS,
            payload: action
        });
    } catch (err) {
        action.setViewPercentage(false);
        action.setIsUploading(false);
        yield put({
            type: actionTypes.UPLOAD_IMAGE_FAILURE,
            action: action,
        })
    }
}

function* watchUploadImage() {
    yield takeLatest(actionTypes.UPLOAD_IMAGE_REQUEST, uploadImage);
}


export default function* uploadSaga() {
    yield all([
        fork(watchUploadDicom),
        fork(watchUploadImage),
    ])
}