import * as actionTypes from '../_actions/mailAction';

export const initialState = {

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEND_MAIL_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SEND_MAIL_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.SEND_MAIL_FAILURE :
            return {
                ...state,
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;